import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';

import * as turf from '@turf/turf';
import domtoimage from 'dom-to-image-chrome-fix';
import * as mapboxgl from 'mapbox-gl';

import { DeviceHelperService } from '@core/services/device-helper.service';
import { CenterMapControlComponent } from '@shared/components/map/controls/center-map-control/center-map-control.component';

import { environment } from '../../../../environments/environment';

@Component({
    selector: 'vendo-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit, OnDestroy {
    @Input() location;
    @ViewChild('map') mapElement;
    map: mapboxgl.Map;
    style = 'mapbox://styles/mapbox/satellite-v9';
    containerName = 'map';
    markers = [];
    private readonly feetInMile = 5280;
    private locationMarker;

    constructor(private deviceHelperService: DeviceHelperService) {}

    ngAfterViewInit(): void {
        const locationSet = this.location && this.location.latitude && this.location.longitude;

        if (!locationSet && !this.markers?.length) {
            return;
        }

        if (locationSet) {
            this.location.latitude = parseFloat(this.location.latitude);
            this.location.longitude = parseFloat(this.location.longitude);
            this.markers.unshift({
                latitude: this.location.latitude,
                longitude: this.location.longitude,
                title: 'House Location'
            });
        }
    }

    ngOnDestroy(): void {
        if (this.map) {
            this.map.remove();
        }
    }

    initMap(): void {
        this.map = new mapboxgl.Map({
            accessToken: environment.mapbox.accessToken,
            container: this.containerName,
            style: this.style,
            zoom: 13,
            center: [this.location.longitude, this.location.latitude],
            preserveDrawingBuffer: true
        });

        // Add map controls
        this.map.addControl(new mapboxgl.NavigationControl());
        this.map.addControl(
            new CenterMapControlComponent({
                className: 'mapbox-gl-draw_point',
                title: 'Re-center map',
                eventHandler: this.recenter.bind(this)
            })
        );

        this.markers.forEach((marker) => {
            this.addMarker(marker.latitude, marker.longitude, marker.title);
        });
        this.map.fitBounds([
            [this.location.longitude - 0.00006, this.location.latitude - 0.00006],
            [this.location.longitude + 0.00006, this.location.latitude + 0.00006]
        ]);

        this.map.on('load', () => {
            this.setMapStyles();
        });
    }

    private recenter(): void {
        this.map.flyTo({
            center: [this.location.longitude, this.location.latitude]
        });
    }

    async captureImage(): Promise<any> {
        const bounds = this.map.getBounds();
        const boundsDistance = turf.distance([bounds._ne.lng, bounds._ne.lat], [bounds._sw.lng, bounds._sw.lat]);
        const mapSizeInPixels = Math.sqrt(
            Math.pow(this.mapElement?.nativeElement.clientWidth, 2) +
                Math.pow(this.mapElement?.nativeElement.clientHeight, 2)
        );

        this.locationMarker.remove();
        const mapElement = document.getElementById(this.containerName);
        const controlContainer = mapElement.querySelector('.mapboxgl-control-container');

        controlContainer.remove();

        return new Promise(async (resolve, reject) => {
            this.removeAllMarkers();
            setTimeout(async () => {
                const blobWithoutDrawings = await this.takePicture(mapElement, reject);
                const result = {
                    images: [blobWithoutDrawings],
                    feetPerPixel: (boundsDistance * this.feetInMile) / mapSizeInPixels
                };

                resolve(result);
            }, 200);
        });
    }

    private async takePicture(mapElement, reject): Promise<Blob> {
        return domtoimage
            .toBlob(mapElement, { filter: () => true })
            .then((blob: Blob) => {
                if (this.deviceHelperService.isIOSPlatform) {
                    return domtoimage.toBlob(mapElement, { filter: () => true });
                }

                return Promise.resolve(blob);
            })
            .catch((error) => reject(error));
    }

    private addMarker(lat, lng, title?: string): void {
        const el = document.createElement('div');

        el.innerHTML = '<i class="material-icons">location_on</i>';
        el.className = 'marker';

        const popup = new mapboxgl.Popup({ offset: 35 }).setText(title);

        this.locationMarker = new mapboxgl.Marker(el).setLngLat({ lng, lat }).setPopup(popup).addTo(this.map);
    }

    private removeAllMarkers(): void {
        if (this.locationMarker) {
            this.locationMarker.remove();
        }
    }

    private setMapStyles(): void {
        this.map.setPaintProperty('background', 'background-color', '#ffffff');
        this.map.setPaintProperty('satellite', 'raster-opacity-transition', {
            duration: 0,
            delay: 0
        });
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import chunk from 'lodash/chunk';
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import isNil from 'lodash/isNil';

import { DictionaryService } from '@core/services/dictionary.service';
import { QuoteService } from '@core/services/quote.service';
import { ComparisonViewOptionEnum } from '@shared/enums/comparison-view-option.enum';
import { DictionaryType } from '@shared/enums/dictionary-type';
import { NewComparisonSetting } from '@shared/enums/new-comparison-setting.enum';
import { Dictionary } from '@shared/interfaces/dictionary';
import { FinancingOption } from '@shared/interfaces/financing-option';
import { Quote } from '@shared/interfaces/quote';

@Component({
    selector: 'vendo-new-quote-comparison',
    templateUrl: './new-quote-comparison.component.html',
    styleUrls: ['./new-quote-comparison.component.scss']
})
export class NewQuoteComparisonComponent implements OnInit {
    @Input() totalPromotion: number[] = [];
    @Input() settings;
    @Input() selectedComparisonType: ComparisonViewOptionEnum;
    @Input() monthlyPayments: number[] = [];

    @Input() set quotesList(quotes: Quote[]) {
        if (quotes) {
            this.quotes = quotes;
            this.remapQuotes();
        }
    }

    @Input() set financingOptions(options: FinancingOption[]) {
        this.mappedFinancing = cloneDeep(options);

        if (!options[0]?.id) {
            this.mappedFinancing.shift();
        }
    }

    @Output() activeViewChanged = new EventEmitter<number>();

    columnSize;
    mappedQuotes: any[] = [];
    mappedFinancing: any[] = [];
    quotes: Quote[];
    adderNameSetting: Dictionary;

    readonly selectedComparisonTypes: typeof ComparisonViewOptionEnum = ComparisonViewOptionEnum;
    readonly comparisonSettingList: typeof NewComparisonSetting = NewComparisonSetting;

    constructor(
        private quoteService: QuoteService,
        private dictionaryService: DictionaryService
    ) {}

    ngOnInit(): void {
        this.setColumnsSize();
    }

    async remapQuotes(): Promise<void> {
        this.clearItems();
        const lineItems = [];
        const questions = [];
        const promotions = [];
        const lineAdders = [];
        let adderOrder = [];
        const isShowPricing = this.quotes[0].show_prices;

        this.adderNameSetting = await this.dictionaryService.getDictionaryName(DictionaryType.Adder);

        this.quotes.forEach((quote) => {
            adderOrder = quote.adders.map((adder) => adder.name);
            this.settings[NewComparisonSetting.PACKAGE_PRICE].prices.push(quote.sub_total_price);
            this.settings[NewComparisonSetting.SUB_FINAL_PRICE].prices.push(quote.sub_final_price);
            this.settings[NewComparisonSetting.TAX].prices.push(quote.tax);
            this.settings[NewComparisonSetting.DEPOSIT].prices.push(quote.deposit);
            this.settings[NewComparisonSetting.BALANCE_WITH_DEPOSIT].prices.push(
                quote.deposit ? quote.final_price - quote.deposit : quote.final_price
            );

            quote.line_items.map((item) =>
                item.details.map((tQuestion) =>
                    questions.push(
                        ...tQuestion.answers.map((aItem) => {
                            aItem.package_id = quote.quote_id;
                            aItem.opening_id = item.opening_id;
                            aItem.question_type =
                                this.prepareLabel(tQuestion.label) === this.prepareLabel(this.adderNameSetting.plural)
                                    ? this.adderNameSetting.type
                                    : this.prepareLabel(tQuestion.label);

                            return aItem;
                        })
                    )
                )
            );

            promotions.push(...this.calculateItem(quote.discount_details, quote.quote_id));
            lineItems.push(...this.calculateItem(quote.line_items, quote.quote_id));
            lineAdders.push(...this.calculateItem(quote.adders, quote.quote_id));
        });

        this.calculateLineItems(lineItems, isShowPricing);
        this.calculatePromotions(promotions);
        this.calculateLineAdders(lineAdders, isShowPricing, adderOrder);
        this.calculateQuestions(questions);
        this.getShortLongTerms();
        this.calculateMonthlyPayments();
        this.checkIsAllValues(0, 'isAllZero', [
            NewComparisonSetting.DEPOSIT,
            NewComparisonSetting.TAX,
            NewComparisonSetting.DISCOUNT_AMOUNT
        ]);
        this.checkIsAllValues('N/A', 'isAllNA', [NewComparisonSetting.BALANCE_WITH_FINANCING]);
    }

    openPackage(index: number): void {
        this.activeViewChanged.emit(index);
    }

    private prepareLabel(label: string): string {
        return label.replace(' ', '_').toLowerCase();
    }

    private checkIsAllValues(checkedItem: any, paramToCheck: string, arrayToCheck: NewComparisonSetting[]): void {
        arrayToCheck.forEach((setting) => {
            this.settings[setting][paramToCheck] = this.settings[setting].prices.every(
                (price) => price === checkedItem
            );
        });
    }

    private calculateMonthlyPayments(): void {
        this.monthlyPayments.forEach((item) => {
            this.settings[NewComparisonSetting.BALANCE_WITH_FINANCING].prices.push(!isNil(item) ? item : 'N/A');
        });
    }

    private getShortLongTerms(): void {
        if (
            this.settings[NewComparisonSetting.SHORT_TERM_FINANCING].values.length ||
            this.settings[NewComparisonSetting.LONG_TERM_FINANCING].values.length
        ) {
            const extraFields = this.getExtraFields();

            this.quoteService.getBatchExtraPriceFields(extraFields).subscribe((result) => this.calculateTerms(result));
        }
    }

    private calculateTerms(result): void {
        const terms = {
            short_term_financing: [],
            long_term_financing: []
        };

        const chunkedFinancing = {
            short_term_financing: [],
            long_term_financing: []
        };

        [NewComparisonSetting.SHORT_TERM_FINANCING, NewComparisonSetting.LONG_TERM_FINANCING].forEach((term) => {
            this.settings[term].values.forEach((financingId) => {
                const mappedFinancingItem = this.mappedFinancing.find(
                    (financing) => parseInt(financing.id, 10) === financingId
                );

                if (mappedFinancingItem) {
                    terms[term].push(mappedFinancingItem);
                }
            });

            chunkedFinancing[term] = this.getSlicedArray(result, term);

            terms[term].forEach((financing, index) => {
                this.settings[term].prices.push({
                    label: financing.name,
                    value: chunkedFinancing[term][index]
                });
            });
        });
    }

    private calculateLineItems(lineItems: any, isShowPricing: boolean): void {
        const groupedLineItems = groupBy(lineItems, 'opening_id');

        for (const key in groupedLineItems) {
            const pricesMap = new Map();

            groupedLineItems[key].forEach((item) => {
                pricesMap.set(item.package_id, {
                    calculated_display_price: item.price + item.adders_total_price,
                    calculated_total_price: item.final_price + item.adders_total_price
                });
            });

            const unitPrice = this.calculateUnitPrice(pricesMap, isShowPricing);
            const quantity = this.calculateQuantity(groupedLineItems[key], unitPrice);

            const lineItemTotalPrice = unitPrice.map((price, index) => {
                if (typeof price === 'number' && !isNaN(price)) {
                    return isShowPricing ? price * quantity[index] : '-';
                } else {
                    return isShowPricing ? 'N/A' : '-';
                }
            });

            this.mappedQuotes.push({
                opening_name: groupedLineItems[key][0].opening_name,
                opening_id: key,
                unit_price: unitPrice,
                line_item_total_price: lineItemTotalPrice,
                quantity,
                questions: [],
                adders: []
            });
        }

        this.calculateAllItemsPrices(isShowPricing);
    }

    private calculateQuestions(questions: any): void {
        const groupedQuestions = groupBy(questions, 'opening_id');
        const order = ['design_details', 'technical_details', this.adderNameSetting.type];

        for (const [index, key] of Object.keys(groupedQuestions).entries()) {
            const questionMap = new Map();
            const newArray = [];

            groupedQuestions[key].forEach((question) => {
                const existingQuestions = questionMap.get(question.question || question.name) || [];

                questionMap.set(question.question || question.name, [question, ...existingQuestions]);
            });

            for (const [question, values] of questionMap.entries()) {
                const valuesMap: any = new Map(
                    values.map((item) => [
                        item.package_id,
                        {
                            name: this.getItemAnswer(item),
                            question_type: item.question_type
                        }
                    ])
                );

                const arr = this.quotes.map((quote) => {
                    return valuesMap.has(quote.quote_id)
                        ? valuesMap.get(quote.quote_id).question_type === this.adderNameSetting.type
                            ? 'Yes'
                            : valuesMap.get(quote.quote_id).name
                        : 'N/A';
                });

                newArray.push({
                    question,
                    answers: arr,
                    isUniq: new Set(arr).size > 1,
                    question_type: values[0].question_type
                });
            }

            const sortedQuestions = this.sortArray(newArray, order, 'question_type');

            this.mappedQuotes[index].questions = this.remapAdders(sortedQuestions);
        }
    }

    private getItemAnswer(item): string {
        return item.question_type === this.adderNameSetting.type ? item.type : item.answer ? item.answer : '';
    }

    private calculateLineAdders(lineAdders: any, isShowPricing: boolean, order: string[]): void {
        const groupLineAdders = groupBy(lineAdders, 'id');
        const mappedAdders = [];

        for (const key in groupLineAdders) {
            const lineAddersMap = new Map();

            groupLineAdders[key].forEach((adder) => {
                const existingAdder = lineAddersMap.get(adder.name) || [];

                lineAddersMap.set(adder.name, [
                    {
                        price: adder.total_price * adder.quantity,
                        quote_id: adder.package_id,
                        visible: adder.visible
                    },
                    ...existingAdder
                ]);
            });

            for (const [name, values] of lineAddersMap.entries()) {
                const map = new Map(values.map((item) => [item.quote_id, item.price]));

                const arr = this.quotes.map((quote) =>
                    map.has(quote.quote_id) ? (isShowPricing ? map.get(quote.quote_id) : '-') : 'N/A'
                );

                const visible = values[0].visible;

                mappedAdders.push({ name, amounts: arr, visible });
            }
        }

        this.settings[NewComparisonSetting.ADDERS].prices = this.sortArray(mappedAdders, order, 'name');

        this.calculateAdders(isShowPricing);
    }

    private calculateAllItemsPrices(isShowPricing: boolean): void {
        if (this.mappedQuotes[0]) {
            this.mappedQuotes[0].line_item_total_price.forEach((_, i) => {
                let sum = isShowPricing ? 0 : '-';

                this.mappedQuotes.forEach((catalog) => {
                    if (isShowPricing && typeof catalog.line_item_total_price[i] === 'number') {
                        sum += catalog.line_item_total_price[i];
                    }
                });
                this.settings[NewComparisonSetting.LINE_ITEMS].prices.push(sum);
            });
        }
    }

    private calculateQuantity(groupedLineItems: any, unitPrice: any[]): number[] {
        return unitPrice.map((price) => (price === 'N/A' ? 0 : groupedLineItems[0].quantity));
    }

    private calculateAdders(isShowPricing: boolean): void {
        this.settings[NewComparisonSetting.ADDERS].total = this.settings[NewComparisonSetting.ADDERS].prices.reduce(
            (acc, curr) => {
                curr.amounts.forEach((amount, index) => {
                    if (isNaN(acc[index])) {
                        acc[index] = 0;
                    }

                    if (typeof amount === 'number' && !isNaN(amount)) {
                        acc[index] = (acc[index] || 0) + amount;
                    }

                    if (!isShowPricing) {
                        acc[index] = '-';
                    }
                });

                return acc;
            },
            []
        );
    }

    private calculatePromotions(promotions: any[]): void {
        const groupedPromotions = groupBy(promotions, 'id');

        for (const key in groupedPromotions) {
            const promotionMap = new Map();

            groupedPromotions[key].forEach((adder) => {
                const existingPromotion = promotionMap.get(adder.name) || [];

                promotionMap.set(adder.name, [
                    {
                        discount_amount: adder.discount_amount,
                        quote_id: adder.package_id
                    },
                    ...existingPromotion
                ]);
            });

            const newArr = [];

            for (const [name, values] of promotionMap.entries()) {
                const map = new Map(values.map((item) => [item.quote_id, item.discount_amount]));

                const arr = this.quotes.map((quote) => (map.has(quote.quote_id) ? map.get(quote.quote_id) : 'N/A'));

                newArr.push({ name, discount_amount: arr });

                this.settings[this.comparisonSettingList.DISCOUNT_AMOUNT].total.push(...newArr);
            }
        }

        this.settings[this.comparisonSettingList.DISCOUNT_AMOUNT].prices = this.totalPromotion;
    }

    private calculateItem(array: any, quoteId: any): any {
        return array.map((item: any) => {
            item.package_id = quoteId;

            return item;
        });
    }

    private calculateUnitPrice(pricesMap: any, isShowPricing: boolean): any[] {
        return this.quotes.map((quote) =>
            pricesMap.has(quote.quote_id)
                ? isShowPricing
                    ? pricesMap.get(quote.quote_id).calculated_display_price
                    : '-'
                : 'N/A'
        );
    }

    private clearItems(): void {
        this.mappedQuotes = [];

        for (const key in this.comparisonSettingList) {
            this.settings[NewComparisonSetting[key]].prices = [];
            this.settings[NewComparisonSetting[key]].total = [];
        }
    }

    private getExtraFields(): any[] {
        const extraFields = [];
        const mappedFinancing = [];

        [NewComparisonSetting.SHORT_TERM_FINANCING, NewComparisonSetting.LONG_TERM_FINANCING].forEach((term) => {
            this.settings[term].values.forEach((value) => {
                const financingOption = this.mappedFinancing.find((financing) => parseInt(financing.id, 10) === value);

                if (financingOption) {
                    mappedFinancing.push(financingOption);
                }
            });
        });

        mappedFinancing.forEach((option) => {
            this.quotes.forEach((quote) => {
                extraFields.push({
                    final_price: quote.final_price,
                    deposit: {
                        deposit_type: quote.deposit_type,
                        deposit_amount: quote.deposit_amount
                    },
                    financing_option: {
                        id: option.id,
                        payment_factor: option.payment_factor,
                        interest_rate: option.interest_rate,
                        months: option.months
                    }
                });
            });
        });

        return extraFields;
    }

    private setColumnsSize(): void {
        switch (this.quotes.length) {
            case 2:
                this.columnSize = 4.5;
                break;
            case 3:
                this.columnSize = 3;
                break;
            case 4:
                this.columnSize = 2.25;
                break;
            case 5:
                this.columnSize = 1.8;
                break;
        }
    }

    private getSlicedArray(result: any[], term: string): any[] {
        return chunk(
            term === NewComparisonSetting.SHORT_TERM_FINANCING
                ? result.slice(0, this.settings[term].values.length * this.quotes.length)
                : result.slice(result.length - this.settings[term].values.length * this.quotes.length),
            this.quotes.length
        );
    }

    private sortArray(arrayToSort: any[], order: string[], propToSort: string): any[] {
        return arrayToSort.sort((a, b) => order.indexOf(a[propToSort]) - order.indexOf(b[propToSort]));
    }

    private remapAdders(sortedArray: any[]): any[] {
        return sortedArray.map((question) => {
            if (question.question_type === this.adderNameSetting.type) {
                const remappedAnswers = question.answers.map((answer) => {
                    return answer === 'N/A' ? 'No' : answer;
                });

                return {
                    ...question,
                    answers: remappedAnswers
                };
            } else {
                return question;
            }
        });
    }
}

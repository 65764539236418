<ion-grid>
    <ion-row class="mb-25">
        <ion-text class="label-text">Promotions</ion-text>
        <vendo-discounts-dropdown class="w-100"
                                  [activeTabId]="activeTabId"
                                  [isCustomPromotionsAvailable]="isCustomPromotionsAvailable"
                                  [availableDiscountIds]="availableDiscountIds"
                                  [promotions]="promotions | sortItemsByValues: selectedPromotionIds:'id'"
                                  [selectedPromotions]="selectedPromotions"
                                  (discountApplied)="discountApplied.emit($event)"
                                  (createCustomPromotion)="createCustomPromotion()"></vendo-discounts-dropdown>
    </ion-row>

    <ion-row *ngIf="isShowFinancingDropdown || quote.financing_options"
             class="flex-column"
             [ngClass]="{
                             'mb-0': !depositPricePresentation.is_active && !depositControl.value,
                             'mb-20': selectedPromotionIds?.length > 1,
                             'mb-30': selectedPromotionIds?.length <= 1
                         }">
        <ion-text class="label-text">Financing Option</ion-text>
        <ion-text class="details-link" (click)="navigateToFinancingPage()">Details</ion-text>

        <div class="financing-input">
            <ion-input legacy="true" type="text"
                       name="Select An Offer"
                       placeholder="Select An Offer"
                       readonly
                       [formControl]="financingOptionControl"
                       (click)="selectFinancingOption($event)"
                       autocomplete="off"></ion-input>
            <ion-button *ngIf="!financingOptionControl.value"
                        (click)="selectFinancingOption($event)">
                <ion-icon [name]="isCaretUp ? 'caret-up-outline' : 'caret-down-outline'" class="arrow-icon"></ion-icon>
            </ion-button>
            <ion-button *ngIf="financingOptionControl.value"
                        class="icon-only"
                        size="small"
                        (click)="clearFinancingOption()">
                <ion-icon name="close-outline"></ion-icon>
            </ion-button>
        </div>
    </ion-row>

    <ion-row class="flex-column" *ngIf="depositPricePresentation.is_active || depositControl.value > 0">
        <ion-text class="label-text">{{ depositPricePresentation.fields.deposit }}</ion-text>
        <div class="deposit-input">
            <ion-input legacy="true" [formControl]="depositControl"
                       inputmode="decimal"
                       type="number"
                       min="0"
                       select-all-text
                       [max]="isAmountDeposit ? quote.final_price : 100"
                       pattern="[0-9]*"
                       title=""></ion-input>

            <ion-item class="ion-text-center" lines="none">
                <ion-label [attr.data-active]="!isAmountDeposit">%</ion-label>
                <ion-toggle mode="md"
                            [ngModel]="isAmountDeposit"
                            (ngModelChange)="toggleDepositType()"></ion-toggle>
                <ion-label [attr.data-active]="isAmountDeposit">$</ion-label>
            </ion-item>
        </div>
    </ion-row>
</ion-grid>

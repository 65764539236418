import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { RequestCancelService } from '@core/services/request-cancel.service';

@Injectable()
export class RequestsCancelInterceptor implements HttpInterceptor {
    private readonly ignoredRequests: string[] = [
        'AppleStoreLastVersion',
        'GooglePlayStoreLastVersion',
        'CheckEmail',
        'LoginMutation',
        'SSOSignIn',
        'BiometricLoginMutation',
        'RefreshToken',
        'forgotPassword',
        'resetPasswordLogin',
        'AppointmentDeeplink',
        'GetMe',
        'logout',
        'approvalRequests'
    ];

    constructor(private requestCancelService: RequestCancelService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (
            (req.body.operationName && this.ignoredRequests.includes(req.body.operationName)) ||
            req.url.includes('?background=true')
        ) {
            return next.handle(req);
        }

        return next.handle(req).pipe(takeUntil(this.requestCancelService.onCancelPendingRequests()));
    }
}

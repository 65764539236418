import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { CookieService } from 'ngx-cookie-service';

import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import get from 'lodash/get';

import { isActiveOfflineMode } from '@core/functions/is-active-offline-mode';
import { RollbarErrorHandler } from '@core/handlers/rollbar-error-handler';
import { ThemeService } from '@core/modules/theme/services/theme.service';
import { DeviceHelperService } from '@core/services/device-helper.service';
import { FeaturesService } from '@core/services/features.service';
import { IntercomService } from '@core/services/intercom.service';
import { PaysimpleService } from '@core/services/paysimple.service';
import { PendoService } from '@core/services/pendo.service';
import { SecureStorageService } from '@core/services/secure-storage.service';
import { StorageService } from '@core/services/storage.service';
import { FEATURES } from '@shared/constants/features';
import { IGNORED_ROUTES } from '@shared/constants/ignore-routes';
import { PaymentSystem } from '@shared/enums/payment-system';
import { DomainHelper } from '@shared/helpers/domain-helper';
import { DeeplinkInfo } from '@shared/interfaces/deeplink-info';

import { environment } from '../../../environments/environment';
import { ResetPasswordHashModel } from '../../components/auth/models/reset-password-hash-model';
import {
    AcceptUpdatedPrivacyPolicy,
    AppointmentDeeplink,
    BiometricLoginMutation,
    CheckAuthTypes,
    ForgotPassword,
    GetMe,
    LoginMutation,
    LogoutMutation,
    RefreshToken,
    ResetPasswordLogin,
    SSO_SIGN_IN
} from '../../components/auth/mutations/auth.mutations';
import { CheckResetPasswordHash, GetBiometricID } from '../../components/auth/queries/auth.queries';
import { PrivacyPolicyService } from '../../components/auth/services/privacy-policy.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    signedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    userInfo: any;
    token: string;
    private orgHash: string;
    private _isLoginViaDeeplink: boolean;
    get isLoginViaDeeplink(): boolean {
        return this._isLoginViaDeeplink;
    }
    set isLoginViaDeeplink(value: boolean) {
        this._isLoginViaDeeplink = value;
    }

    private readonly isOffline: boolean = isActiveOfflineMode();

    constructor(
        private apollo: Apollo,
        private storageService: StorageService,
        private termsAndConditionsModalService: PrivacyPolicyService,
        private intercomService: IntercomService,
        private deviceHelperService: DeviceHelperService,
        private rollbarErrorHandler: RollbarErrorHandler,
        private themeService: ThemeService,
        private featuresService: FeaturesService,
        private pendoService: PendoService,
        private cookieService: CookieService,
        private paysimpleService: PaysimpleService,
        private secureStorageService: SecureStorageService
    ) {
        this.storageService.isStorageInitialized$.pipe(filter((value: boolean) => value)).subscribe(() => {
            this.storageService.get('user').then((userInfo) => {
                this.userInfo = userInfo;
                if (userInfo) {
                    const user = this.getUser();

                    this.featuresService.setFeatures(user.organization_features);
                    this.themeService.init(user);
                    this.pendoService.initUser(user);
                    this.intercomService.setUser(user);
                    this.intercomService.update();
                    this.rollbarErrorHandler.addRollbarPerson(user);
                }
            });
            this.storageService.get('token').then((token) => {
                this.token = token;
                if (this.token && this.deviceHelperService.isWeb) {
                    this.addLocalStorageEventListener();
                }
                this.signedIn.next(this.isLoggedIn());
            });
        });
    }

    getUser(): any {
        return JSON.parse(this.userInfo);
    }

    login(user: any): Observable<any> {
        this.userInfo = null;
        this.token = null;
        const device_id: string = this.deviceHelperService.getDeviceUUID;

        return this.apollo
            .mutate({
                mutation: LoginMutation,
                variables: {
                    userName: user.userName,
                    password: user.password,
                    device_id,
                    device: this.deviceHelperService.getDevicePlatform,
                    use_touch: !!device_id,
                    org_hash: this.orgHash
                }
            })
            .pipe(
                map((res: any) => {
                    if (res && !res.errors && !res.data.mobileSignIn.reset_password) {
                        res.data.mobileSignIn.me.role.role_permissions = this.remapPermissions(
                            res.data.mobileSignIn.me.role.role_permissions
                        );
                        this.saveUserLogin(res.data.mobileSignIn, user, device_id);
                    }

                    return get(res, 'data.mobileSignIn', {});
                })
            );
    }

    ssoSignIn(code, code_verifier, redirect_url: string, org_hash: string): Observable<any> {
        this.userInfo = null;
        this.token = null;
        const device_id: string = this.deviceHelperService.getDeviceUUID;

        return this.apollo
            .mutate({
                mutation: SSO_SIGN_IN,
                variables: {
                    code,
                    code_verifier,
                    redirect_url,
                    device_id,
                    device: this.deviceHelperService.getDevicePlatform,
                    org_hash
                }
            })
            .pipe(
                map(async (res: any) => {
                    if (res && !res.errors && res.data.ssoMobileSignIn) {
                        res.data.ssoMobileSignIn.me.role.role_permissions = this.remapPermissions(
                            res.data.ssoMobileSignIn.me.role.role_permissions
                        );
                        await this.saveUserLogin(
                            res.data.ssoMobileSignIn,
                            {
                                userName: res.data.ssoMobileSignIn.me.email
                            },
                            device_id
                        );
                        this.storageService.set('isSSOLogin', 'true');
                    }

                    return get(res, 'data.ssoMobileSignIn', {});
                })
            );
    }

    checkEmail(userEmail: string): Observable<any> {
        this.userInfo = null;
        this.token = null;

        return this.apollo
            .mutate({
                mutation: CheckAuthTypes,
                variables: {
                    email: userEmail
                }
            })
            .pipe(map((res: any) => res.data.checkAuthTypes));
    }

    async loginWithBiometric(userName: string): Promise<boolean> {
        const device_id: string = this.deviceHelperService.getDeviceUUID;
        const [t_id_hash, userNameForBiometricLogin]: string[] = await this.getDataForBiometricLogin();

        if (userName !== userNameForBiometricLogin) {
            return;
        }

        return new Promise((resolve, reject) => {
            this.apollo
                .mutate({
                    mutation: BiometricLoginMutation,
                    variables: {
                        userName,
                        device_id,
                        t_id_hash,
                        org_hash: this.orgHash
                    }
                })
                .pipe(
                    map((res: any) => {
                        if (res && !res.errors) {
                            res.data.biometricSignIn.me.role.role_permissions = this.remapPermissions(
                                res.data.biometricSignIn.me.role.role_permissions
                            );
                            this.saveUserLogin(res.data.biometricSignIn, { userName }, device_id);
                        }

                        return res;
                    })
                )
                .subscribe(
                    (res) => (res && !res.errors ? resolve(true) : reject(false)),
                    () => reject(false)
                );
        });
    }

    forgotPassword(email: string, resetPasswordUrl: string): Observable<any> {
        return this.apollo
            .mutate({
                mutation: ForgotPassword,
                variables: {
                    email,
                    reset_password_url: resetPasswordUrl,
                    org_hash: this.orgHash
                }
            })
            .pipe(
                map((res: any) => {
                    return !res.errors;
                })
            );
    }

    checkResetPasswordHash(hash: string): Observable<ResetPasswordHashModel> {
        return this.apollo
            .query({
                query: CheckResetPasswordHash,
                variables: {
                    hash
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.resetPassword as ResetPasswordHashModel));
    }

    resetPassword(
        hash: string,
        password: string,
        compare_password: string,
        accept_terms_and_conditions: boolean
    ): Observable<any> {
        return this.apollo
            .mutate({
                mutation: ResetPasswordLogin,
                variables: {
                    hash,
                    password,
                    compare_password,
                    accept_terms_and_conditions
                }
            })
            .pipe(
                map((res: any) => {
                    if (!res.errors) {
                        res.data.resetPasswordLogin.me.role.role_permissions = this.remapPermissions(
                            res.data.resetPasswordLogin.me.role.role_permissions
                        );
                        this.saveUserLogin(res.data.resetPasswordLogin);
                    }

                    return !res.errors;
                })
            );
    }

    logout(): void {
        if (this.isLoggedIn() && !this.isOffline) {
            this.apollo
                .mutate({
                    mutation: LogoutMutation,
                    context: {
                        extensions: {
                            background: true
                        }
                    }
                })
                .pipe(catchError(() => of(null)))
                .subscribe();
        }

        if (this.deviceHelperService.isWeb) {
            if (this.cookieService.check('isVendoMobileUser')) {
                this.cookieService.delete(
                    'isVendoMobileUser',
                    '/',
                    DomainHelper.getDomainWithoutSubdomain(environment.siteUrl)
                );
            }
            this.removeLocalStorageEventListener();
            localStorage.removeItem('token');
        }

        this.rollbarErrorHandler.removeRollbarPerson();
        this.pendoService.clearSession();
        this.storageService.remove('token');
        this.storageService.remove('user');
        this.isLoginViaDeeplink = false;
        this.token = null;
        this.userInfo = null;
        this.signedIn.next(false);
        this.intercomService.clearUserData();
        this.featuresService.setFeatures([]);

        if (this.isOffline) {
            window.localStorage.setItem('offline_mode', '0');
            (window as any).location.reload();
        }
    }

    getMe(): Observable<any> {
        if (this.isOffline) {
            return of(null);
        }

        return this.apollo
            .query({
                query: GetMe
            })
            .pipe(
                switchMap((res: ApolloQueryResult<any>) => {
                    if (res) {
                        const logo =
                            this.featuresService.hasFeature(FEATURES.OFFICE_LOGO_PRIORITIZATION) &&
                            res.data.me.office?.logo_url
                                ? res.data.me.office.logo_url
                                : res.data.me.organization.logo_url;
                        const oldUserInfo = this.getUser();
                        const userInfo = {
                            ...res.data.me,
                            application_role_hash: oldUserInfo.application_role_hash,
                            organization_features: oldUserInfo.organization_features,
                            role: oldUserInfo.role
                        };

                        userInfo.office.crm_name = oldUserInfo.office.crm_name;
                        userInfo.office.payment_system = oldUserInfo.office.payment_system;
                        this.userInfo = JSON.stringify(userInfo);
                        this.themeService.init(res.data.me);
                        this.intercomService.setUser(res.data.me);
                        this.intercomService.update();

                        if (userInfo?.office.payment_system === PaymentSystem.Paysimple) {
                            this.paysimpleService.init();
                        }

                        return from(
                            Promise.all([
                                this.storageService.set('user', this.userInfo),
                                this.storageService.set('logo_url', logo),
                                this.storageService.set('background_url', res.data.me.organization.background_url)
                            ])
                        ).pipe(map(() => res.data.me));
                    }

                    return of(res.data.me);
                })
            );
    }

    async getBiometricID(deviceId: string, userName: string): Promise<void> {
        if (await this.deviceHelperService.isFingerPrintAvailable()) {
            this.apollo
                .query({
                    query: GetBiometricID,
                    variables: {
                        deviceId
                    }
                })
                .pipe(map((res: ApolloQueryResult<any>) => res.data.myBiometricID))
                .subscribe((value: string) => {
                    if (value) {
                        this.secureStorageService.setValue('t_id_hash', value);
                        this.secureStorageService.setValue('userNameBiometric', userName);
                    }
                });
        }
    }

    async getDataForBiometricLogin(): Promise<string[]> {
        return Promise.all([
            this.secureStorageService.getValue('t_id_hash'),
            this.secureStorageService.getValue('userNameBiometric')
        ]).catch(() => []);
    }

    async removeDataForBiometricLogin(): Promise<void> {
        this.secureStorageService.removeValue('t_id_hash');
        this.secureStorageService.removeValue('userNameBiometric');
    }

    refreshToken(officeId): Observable<any> {
        return this.apollo
            .mutate({
                mutation: RefreshToken,
                variables: {
                    officeId
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) => {
                    res.data.refreshToken.me.role.role_permissions = this.remapPermissions(
                        res.data.refreshToken.me.role.role_permissions
                    );

                    this.pendoService.clearSession();
                    this.saveUserLogin(res.data.refreshToken);

                    return res.data.refreshToken;
                })
            );
    }

    getAppointmentDeeplinkInfo(hash: string): Observable<string> {
        return this.apollo
            .mutate({
                mutation: AppointmentDeeplink,
                variables: {
                    hash
                }
            })
            .pipe(
                switchMap(async (res: ApolloQueryResult<{ appointmentDeeplink: DeeplinkInfo }>) => {
                    if (!res.errors && res.data?.appointmentDeeplink?.auth?.me) {
                        res.data.appointmentDeeplink.auth.me.role.role_permissions = this.remapPermissions(
                            res.data.appointmentDeeplink.auth.me.role.role_permissions
                        );
                        await this.saveUserLogin(res.data.appointmentDeeplink.auth, {
                            userName: res.data.appointmentDeeplink.auth.me.email
                        });
                    }

                    return res.data?.appointmentDeeplink?.appointment_id;
                })
            );
    }

    private isLoggedIn(): boolean {
        return !!this.token;
    }

    private async saveUserLogin(userResponse: any, user?: any, deviceId?: string): Promise<void> {
        let privacyPolicyAccepted: boolean;

        if (user && userResponse.me.privacy_policy_updated) {
            privacyPolicyAccepted = await this.termsAndConditionsModalService.openTermsAndConditionsModal(true);
            if (!privacyPolicyAccepted) {
                this.logout();

                return;
            }
        }

        if (user) {
            this.storageService.set('userName', user.userName);

            if (deviceId) {
                this.getBiometricID(deviceId, user.userName);
            }
        }

        this.pendoService.initUser(userResponse.me);

        this.intercomService.setUser(userResponse.me);

        this.rollbarErrorHandler.addRollbarPerson(userResponse.me);

        this.themeService.init(userResponse.me);
        this.featuresService.setFeatures(userResponse.me.organization_features);

        this.fillCustomerSettings(userResponse.me.office);
        const logo = this.featuresService.hasFeature(FEATURES.OFFICE_LOGO_PRIORITIZATION)
            ? userResponse.me.office.logo_url
            : userResponse.me.organization.logo_url;
        const userInfo = JSON.stringify(userResponse.me);

        this.userInfo = userInfo;
        this.token = userResponse.token;

        await Promise.all([
            this.storageService.set('token', userResponse.token),
            this.storageService.set('user', userInfo),
            this.storageService.set('logo_url', logo),
            this.storageService.set('background_url', userResponse.me.organization.background_url)
        ]);

        if (this.deviceHelperService.isWeb) {
            localStorage.setItem('token', userResponse.token);
            this.addLocalStorageEventListener();
        }

        if (privacyPolicyAccepted) {
            this.acceptUpdatedPrivacyPolicy().subscribe();
        }

        if (userResponse.me?.office.payment_system === PaymentSystem.Paysimple) {
            this.paysimpleService.init();
        }

        this.rollbarErrorHandler.handleInfo('Login');
        this.signedIn.next(true);
    }

    private fillCustomerSettings(office: any): void {
        if (!office.customer_settings) {
            office.customer_settings = {};
        }

        ['is_show_email', 'is_show_phone_number'].forEach((key: string) => {
            const value = office.customer_settings[key];

            if (typeof value !== 'boolean') {
                office.customer_settings[key] = true;
            }
        });
    }

    async getLastLoggedUser(): Promise<string> {
        return this.storageService.get('userName');
    }

    setOrgHash(orgHash: string): void {
        this.orgHash = orgHash;
    }

    getReturnUrl(): string {
        const returnUrl = `${decodeURI(window.location.pathname)}${
            window.location.search ? decodeURI(window.location.search) : ''
        }`;

        if (returnUrl === '/' || returnUrl.includes('login') || returnUrl.includes('logout')) {
            return '';
        }

        return returnUrl;
    }

    private acceptUpdatedPrivacyPolicy(): Observable<any> {
        return this.apollo
            .mutate({
                mutation: AcceptUpdatedPrivacyPolicy
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.acceptUpdatedPrivacyPolicy));
    }

    private addLocalStorageEventListener(): void {
        window.addEventListener('storage', this.storageEventListener.bind(this));
    }

    private removeLocalStorageEventListener(): void {
        window.removeEventListener('storage', this.storageEventListener.bind(this));
    }

    private storageEventListener(event: StorageEvent): void {
        if (
            event.storageArea == localStorage &&
            event.key === 'token' &&
            !localStorage.getItem('token') &&
            !IGNORED_ROUTES.some((item) => window.location.href.includes(item))
        ) {
            this.logout();
        }
    }

    private remapPermissions(permissions: any[]): any {
        return permissions.reduce((acc, obj) => {
            acc[obj.permission_hash] = obj.permissions;

            return acc;
        }, {});
    }
}

<div class="modal-header">
    <ion-text class="title">Uploads</ion-text>
    <ion-button fill="outline"
                color="dark"
                [disabled]="isEditableFileLabel.includes(true)"
                (click)="closeModal(true)">
        <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
</div>

<div class="modal-body">
    <ng-container *ngIf="!filesList.length; else list">
        <ion-icon class="upload-icon" name="cloud-upload-outline"></ion-icon>
        <p class="empty-text">
            You do not have any files uploaded<br>to the project. Click <b>+ Add File</b> to upload.
        </p>
        <p class="type-only">
            Only <b>PDF</b> files are accepted.<br>Maximum file size is <b>25 MBs.</b>
        </p>
    </ng-container>

    <ng-template #list>
        <ion-grid class="table">
            <ion-row class="header-row">
                <ion-col size="3.1" class="ion-text-left">Label*</ion-col>
                <ion-col class="name-col ion-text-left" size="5.2">File Name</ion-col>
                <ion-col size="2.8" class="ion-text-left">Uploaded Date & Time</ion-col>
                <ion-col size="0.9" class="ion-text-left">Delete</ion-col>
            </ion-row>

            <ion-row *ngFor="let file of filesList; let i = index;" class="grid-row ion-align-items-left">
                <ion-item lines="none" [class.odd]="0 === i % 2" class="ion-no-padding grid-item">
                    <ion-col class="name-col" size="3.1" (click)="$event.stopPropagation()">
                        <ng-container *ngIf="!isEditableFileLabel[i]; else fileLabelInputBlock">
                            <div class="edit-wrap">
                                <ion-text>{{ file.name }}</ion-text>
                                <ion-button class="icon-only"
                                            *ngIf="!file.locked"
                                            fill="clear"
                                            color="medium"
                                            (click)="setEditableFileLabel(true, i, false)">
                                    <i slot="icon-only" class="material-icons">edit</i>
                                </ion-button>
                            </div>
                        </ng-container>

                        <ng-template #fileLabelInputBlock>
                            <div class="file-label">
                                <ion-input legacy="true" [placeholder]="file.name"
                                           color="primary"
                                           maxlength="25"
                                           [(ngModel)]="file.name"
                                           (ionBlur)="setEditableFileLabel(false, i, true)"
                                           (keyup.enter)="setEditableFileLabel(false, i, true)">
                                </ion-input>
                                <ion-button class="icon-only"
                                            fill="clear"
                                            color="medium"
                                            (click)="setEditableFileLabel(false, i, true)">
                                    <i slot="icon-only" class="material-icons">save</i>
                                </ion-button>
                            </div>
                        </ng-template>
                    </ion-col>

                    <ion-col size="5.2">
                        <a class="url" (click)="openFile(file)">
                            {{ file.url | appointmentFileName }}
                            <i class="ion-no-margin material-icons pdf-icon">picture_as_pdf</i>
                        </a>
                    </ion-col>

                    <ion-col size="2.8">
                        {{ file.created_at | localizedDate : 'MMMM dd, yyyy h:mm a' }}
                    </ion-col>

                    <ion-col size="0.9">
                        <ion-button fill="clear"
                                    *ngIf="!file.locked"
                                    [disabled]="isDisabledDeleteIcon[i]"
                                    class="delete-icon">
                            <i slot="icon-only" class="material-icons" (click)="deleteFile(file, i)">
                                delete
                            </i>
                        </ion-button>
                    </ion-col>
                </ion-item>
            </ion-row>
        </ion-grid>
    </ng-template>

    <input class="upload-file-input full-size"
           type="file"
           accept="application/pdf"
           #fileUpload
           (change)="saveFile($event)"
           (click)="$event.stopPropagation()"/>
</div>

<div class="modal-footer" [class.end]="filesList.length">
    <ion-button fill="outline" (click)="closeModal(true)" *ngIf="!filesList.length">
        Cancel
    </ion-button>
    <div>
        <ion-button fill="clear"
                    [disabled]="filesList.length >= 10 || isEditableFileLabel.includes(true)"
                    (click)="addFile()">
            <ion-icon class="add-icon" slot="icon-only" name="add-circle"></ion-icon>
            Add File
        </ion-button>
        <ion-button class="classic-width"
                    *ngIf="filesList.length"
                    (click)="closeModal(true)"
                    [disabled]="isEditableFileLabel.includes(true)">
            Close
        </ion-button>
    </div>
</div>


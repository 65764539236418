import { Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { BaseModal } from '@shared/modals/base-modal';

@Component({
    selector: 'vendo-price-modifiers-modal',
    templateUrl: './price-modifiers-modal.component.html',
    styleUrls: ['./price-modifiers-modal.component.scss']
})
export class PriceModifiersModalComponent extends BaseModal {
    @Input() pricingInfoGroups;

    constructor(modalController: ModalController) {
        super(modalController);
    }
}

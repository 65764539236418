import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnInit } from '@angular/core';

import { takeUntil } from 'rxjs/operators';

import { Theme } from '../symbols';
import { ThemeService } from '@core/modules/theme/services/theme.service';
import { Unsubscriber } from '@shared/helpers/unsubscriber';

@Directive({
    selector: '[tqTheme]'
})
export class ThemeDirective extends Unsubscriber implements OnInit {
    /**
     * Whether the styles are scoped or not.
     */
    @Input() scoped = false;

    constructor(
        private _elementRef: ElementRef,
        private _themeService: ThemeService,
        @Inject(DOCUMENT) private _document: any
    ) {
        super();
    }

    ngOnInit(): void {
        const active = this._themeService.getActiveTheme();

        if (active) {
            this.updateTheme(active);
        }

        this._themeService.themeChange
            .pipe(takeUntil(this.destroy$))
            .subscribe((theme: Theme) => this.updateTheme(theme));
    }

    /**
     * Update the theme on the scoped element.
     */
    updateTheme(theme: Theme): void {
        const element = this.getElement();

        // project properties onto the element
        for (const key in theme.properties) {
            element.style.setProperty(key, theme.properties[key]);
        }

        // remove old theme
        for (const name of this._themeService.theme) {
            element.classList.remove(`${name}-theme`);
        }

        // alias element with theme name
        element.classList.add(`${theme.name}-theme`);
    }

    /**
     * Element to attach the styles to.
     */
    getElement(): any {
        return this.scoped ? this._elementRef.nativeElement : this._document.body;
    }
}

import { from, Observable } from 'rxjs';

import { AuthService } from '@core/services/auth.service';
import { OfflineStorageService } from '@core/services/offline/offline-storage.service';
import { OfflineTableName } from '@shared/enums/offline-table-name';

export class UserPreferencesOffline {
    constructor(
        private authService: AuthService,
        private offlineStorageService: OfflineStorageService
    ) {}

    getPreference(type: string): Observable<any> {
        const user = this.authService.getUser();
        const userPreference = this.offlineStorageService
            .findOne(
                `SELECT *
                 FROM ${OfflineTableName.UserPreferences}
                 WHERE user_id = '${user.id}'`,
                OfflineTableName.UserPreferences
            )
            .then((res: any) => res.preferences.find((preference) => type === preference.type));

        return from(userPreference);
    }

    getPreferences(types: string[]): Observable<any> {
        const user = this.authService.getUser();
        const preferences = this.offlineStorageService
            .findOne(
                `SELECT *
                 FROM ${OfflineTableName.UserPreferences}
                 WHERE user_id = '${user.id}'`,
                OfflineTableName.UserPreferences
            )
            .then((res: any) => res.preferences.filter((preference) => types.includes(preference.type)));

        return from(preferences);
    }
}

<div class="modal-header">
    <ion-text class="title">Duplicate Opening - {{ opening.name }}</ion-text>
    <ion-button fill="outline" color="dark" (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
</div>
<div class="modal-body">
    <div class="help-text" [class.extended]="!!disclosure">
        {{ disclosure }}
        How many copies of this
        opening
        would you like to add?
    </div>
    <vendo-number-picker [(ngModel)]="copyCount" [max]="10" [min]="1"></vendo-number-picker>
</div>
<div class="modal-footer">
    <ion-button class="classic-width" (click)="save()">Done</ion-button>
</div>

import { Injectable } from '@angular/core';

import { AuthService } from '@core/services/auth.service';
import { StorageService } from '@core/services/storage.service';
import { AppointmentType } from '@shared/enums/appointment-type';
import { DictionaryType } from '@shared/enums/dictionary-type';
import { Status } from '@shared/enums/status.enum';
import { Appointment } from '@shared/interfaces/appointment';
import { Dictionary, DictionarySettings } from '@shared/interfaces/dictionary';

@Injectable({
    providedIn: 'root'
})
export class DictionaryService {
    defaultSettings: Dictionary[] = [
        {
            type: DictionaryType.Adder,
            single: 'Adder',
            plural: 'Adders'
        },
        {
            type: DictionaryType.ProceedButton,
            single: 'Proceed',
            plural: null
        },
        {
            type: DictionaryType.ConfirmConfiguration,
            single: 'Confirm Configuration',
            plural: null
        },
        {
            type: DictionaryType.EditConfiguration,
            single: 'Edit Configuration',
            plural: null
        },
        {
            type: DictionaryType.ReviewChanges,
            single: 'Review Changes',
            plural: null
        },
        {
            type: DictionaryType.QuoteHeader,
            single: 'Quote Header',
            plural: null
        }
    ];
    constructor(
        private authService: AuthService,
        private storageService: StorageService
    ) {}

    async getDictionaryName(
        type: DictionaryType,
        office?: any,
        appointmentType?: AppointmentType
    ): Promise<Dictionary> {
        if (!office) {
            office = this.authService.getUser().office;
        }

        if (!appointmentType) {
            appointmentType = await this.getAppointmentType();
        }

        const dictionarySettings: DictionarySettings = office.dictionary_settings.find(
            ({ appointment_type }) => appointment_type === appointmentType
        );

        if (dictionarySettings.status === Status.Active) {
            const dictionaryItem: Dictionary = this.getDictionaryItem(dictionarySettings.items, type);

            if (dictionaryItem) {
                return dictionaryItem;
            }
        }

        return this.getDictionaryItem(this.defaultSettings, type);
    }

    async getDictionaryNames(
        types: DictionaryType[],
        office?: any,
        appointmentType?: AppointmentType
    ): Promise<Dictionary[]> {
        if (!office) {
            office = this.authService.getUser().office;
        }

        if (!appointmentType) {
            appointmentType = await this.getAppointmentType();
        }

        return Promise.all(types.map((type: DictionaryType) => this.getDictionaryName(type, office, appointmentType)));
    }

    private getDictionaryItem(items: Dictionary[], type: DictionaryType): Dictionary {
        return items.find((item: Dictionary) => item.type === type);
    }

    private async getAppointmentType(): Promise<AppointmentType> {
        const appointment: Appointment = await this.storageService.get('activeAppointment');

        return appointment.type;
    }
}

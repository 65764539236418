<div class="modal-header">
    <ion-text class="title">{{packageName}} Series Change</ion-text>
    <ion-button fill="outline" color="dark" (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
</div>

<div class="modal-body">
    <ion-content>
        <div *ngFor="let line of series; let i = index" class="series-changes">
            <ion-grid>
                <ion-row class="header-row">
                    <ion-col size="1">
                        <ion-checkbox legacy="true" mode="md" [(ngModel)]="allItemsSelected[i]" (ngModelChange)="handleMassSelect(i)"></ion-checkbox>
                    </ion-col>
                    <ion-col>
                        <ion-text>Lines</ion-text>
                    </ion-col>
                </ion-row>
                <ion-row *ngFor="let item of line.lineItems"
                         [ngClass]="{
                            'has-error': item?.errors?.length,
                            'success': item?.errors && !item.errors.length
                         }">
                    <ion-col size="1">
                        <ion-checkbox legacy="true" mode="md" [(ngModel)]="item.selected" (ngModelChange)="itemSelectionChanged(i)"></ion-checkbox>
                    </ion-col>
                    <ion-col>
                        <ion-text>
                            {{ item.name }}
                        </ion-text>
                    </ion-col>
                    <ion-col *ngIf="item.errors?.length" size="7">
                        <div *ngFor="let error of item.errors">
                            <ion-text>
                                {{ error }}
                            </ion-text>
                        </div>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <ion-select legacy="true"
                        mode="md"
                        interface="popover"
                        [interfaceOptions]="{mode: 'md', alignment: 'start'}"
                        [placeholder]="series[i].series_changes.length ? 'Select one series' : 'No available series'"
                        [(ngModel)]="selectedSeries[i]"
                        (ngModelChange)="setOneItemAtLeastSelected()"
                        [disabled]="!series[i].series_changes.length">
                <ion-select-option *ngFor="let item of line.series_changes" [value]="item.Name">
                    {{ item.DisplayName }}
                </ion-select-option>
            </ion-select>
        </div>
    </ion-content>
</div>

<div class="modal-footer">
    <ion-button fill="outline" (click)="dismiss(isNeedToReloadConfigurationList)">
        Cancel
    </ion-button>
    <ion-button (click)="save()" [disabled]="!selectedSeries || !oneItemAtLeastSelected">
        Save
    </ion-button>
</div>

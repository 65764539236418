<div class="modal-header">
  <ion-text class="title">Additional Promotions</ion-text>

  <ion-button fill="outline" color="dark" (click)="closeModal()">
    <ion-icon slot="icon-only" name="close"></ion-icon>
  </ion-button>
</div>

<div class="modal-body" [formGroup]="form">
  <ion-content>
    <ion-list lines="none">
      <ion-item *ngFor="let item of items; let i = index" lines="none">
        <ion-label>
          <span class="text-ellipsis">{{ item.name }}</span>
        </ion-label>

        <ion-checkbox legacy="true" slot="start"
                      mode="md"
                      [formControlName]="item.id">
        </ion-checkbox>
      </ion-item>
    </ion-list>
  </ion-content>
</div>

<div class="modal-footer">
  <ion-button class="classic-width outline" (click)="closeModal()">Cancel</ion-button>

  <ion-button class="classic-width" [disabled]="form.invalid" (click)="apply()">Update</ion-button>
</div>

<div class="modal-header">
	<ion-text class="title">Select Package</ion-text>
	<ion-button fill="outline" color="dark" (click)="closeModal()">
		<ion-icon slot="icon-only" name="close"></ion-icon>
	</ion-button>
</div>

<div class="modal-body">
	<p>Select which package to add the configuration to.</p>

	<ion-radio-group #radioGroup [value]="packages[0]?.id">
		<ion-item *ngFor="let option of packages"
		          lines="none">
			<ion-label>{{ option.name }}</ion-label>
			<ion-radio legacy="true" slot="start" mode="md" [value]="option.id"></ion-radio>
		</ion-item>
	</ion-radio-group>
</div>

<div class="modal-footer flex-end">
	<ion-button class="classic-width"
	            fill="outline"
	            (click)="closeModal()">
		Cancel
	</ion-button>
	<ion-button class="classic-width"
	            (click)="confirm()">
		Confirm
	</ion-button>
</div>

<div class="modal-header">
    <ion-text class="title">Copying Opening Configurations - {{ copyingOpening.name }}</ion-text>
</div>

<div class="modal-body">
    <div class="help-text">
        Select Opening to which you want to copy the configurations (Max 5)
    </div>

    <ion-list>
        <ion-item *ngFor="let opening of copyToList">
            <ion-checkbox mode="md"
                          [(ngModel)]="opening.isChecked"
                          [disabled]="reachedLimit && !opening.isChecked"
                          (ionChange)="checkHandler()">
                {{ opening.name }}
            </ion-checkbox>
        </ion-item>
    </ion-list>

    <ion-item class="include-section" lines="none">
        <ion-label>Include: {{ adderNameSetting?.plural }}?</ion-label>
        <ion-checkbox mode="md" [(ngModel)]="includeAdders"></ion-checkbox>
    </ion-item>
</div>

<div class="modal-footer">
    <ion-button class="classic-width outline" (click)="closeModal()">Cancel</ion-button>
    <ion-button class="classic-width" (click)="copy()" [disabled]="!canDoCopy">Copy</ion-button>
</div>

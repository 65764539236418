import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

import find from 'lodash/find';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

import { DetailsStep } from './configuration-info-modal.interfaces';
import { BaseModal } from '@shared/modals/base-modal';

import { ConfigureService } from '../../../main/appointments/configure/services/configure.service';

@Component({
    selector: 'vendo-configuration-info-modal',
    templateUrl: './configuration-info-modal.component.html',
    styleUrls: ['./configuration-info-modal.component.scss']
})
export class ConfigurationInfoModalComponent extends BaseModal implements OnInit {
    @Input() configurator: any;
    @Input() openingData: any;
    @Input() selectedDrawing: any;
    @Input() availableDrawings = [];
    @Input() quoteId: number;
    @Input() openingId: number;
    @Input() configureTitle: string;

    userSelections = [];
    currentDrawing: any;
    tabs = [
        {
            label: 'Drawing',
            hash: 'drawing'
        }
    ];
    activeTab;

    private activeDetailsStepIndex = 0;

    get activeDetailsStep(): DetailsStep | null {
        return get(this.userSelections, this.activeDetailsStepIndex, null);
    }

    constructor(
        modalController: ModalController,
        private configureService: ConfigureService,
        private cdr: ChangeDetectorRef
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        if (this.configureTitle) {
            this.tabs.push({
                label: `${this.configureTitle} Details`,
                hash: 'details'
            });
        }
        this.setActiveTab(this.tabs[0]);
        this.setUserSelections();

        this.currentDrawing = find(
            this.availableDrawings,
            isNil(this.selectedDrawing.DrawingID)
                ? { DisplayName: this.selectedDrawing.DisplayName }
                : { DrawingID: this.selectedDrawing.DrawingID }
        );

        this.currentDrawing.image = {
            url: this.configurator.CurrentDrawing.DrawingUrl
        };
    }

    setActiveTab(tab: any): void {
        this.activeTab = tab;
    }

    private setUserSelections(): void {
        const userSelections = [];

        this.configurator.StepsInfo.forEach((stepInfo) => {
            if (
                !stepInfo.Questions ||
                stepInfo.Name === 'Hidden' ||
                (stepInfo.hasOwnProperty('Hidden') && stepInfo.Hidden)
            ) {
                return;
            }

            const stepAnswers = {
                stepName: stepInfo.Name,
                answers: []
            };

            stepInfo.Questions.forEach((question) => {
                if (!question.SelectedAnswerID) {
                    return;
                }

                stepAnswers.answers.push({
                    question: question.QuestionText,
                    answer:
                        question.QuestionType === 'FreeEntry'
                            ? question.SelectedAnswerID
                            : find(question.Answers, { ID: question.SelectedAnswerID }).Text
                });
            });

            if (stepAnswers.answers.length) {
                userSelections.push(stepAnswers);
            }
        });

        this.userSelections = userSelections;
    }

    setActiveDrawing(newDrawing: any): void {
        if (newDrawing.DrawingID === this.currentDrawing.DrawingID) {
            return;
        }

        this.currentDrawing = newDrawing;

        if (this.currentDrawing.DrawingID === 'view') {
            const exteriorDrawing = this.availableDrawings.find(
                (drawing) =>
                    drawing.DisplayName.toLowerCase().includes('exterior') && drawing.hasOwnProperty('DrawingID')
            );

            if (exteriorDrawing) {
                this.configureService
                    .getSpecificDrawing(this.quoteId, this.openingId, exteriorDrawing.DrawingID, true)
                    .subscribe((res) => {
                        const imageUrl = res?.ViewDrawing?.DrawingUrl || this.currentDrawing.DrawingUrl;

                        this.setViewImage(imageUrl);
                    });
            } else {
                this.setViewImage(this.currentDrawing.DrawingUrl);
            }

            return;
        }

        if (this.currentDrawing.DrawingID || this.currentDrawing.DrawingID === 0) {
            this.configureService
                .getSpecificDrawing(this.quoteId, this.openingId, this.currentDrawing.DrawingID, true)
                .subscribe((res) => {
                    if (res) {
                        this.currentDrawing.image = {
                            url: res.CurrentDrawing.DrawingUrl
                        };
                    }
                });
        } else {
            this.currentDrawing.image = {
                url: this.availableDrawings[0].DrawingUrl
            };
        }
    }

    nextDetailsStep(): void {
        if (!this.userSelections) {
            return;
        }

        if (!this.userSelections[this.activeDetailsStepIndex + 1]) {
            this.activeDetailsStepIndex = 0;
        } else {
            this.activeDetailsStepIndex++;
        }
    }

    private setViewImage(imageUrl): void {
        this.currentDrawing.image = {
            url: imageUrl
        };
        this.openingData.configurationDrawingUrl = imageUrl;
        this.cdr.detectChanges();
    }
}

export const OFFLINE_APPOINTMENT_FIELDS = [
    'activities',
    'assessment',
    'crm_results',
    'customer',
    'deeplinks',
    'family_name',
    'house',
    'id',
    'is_archived',
    'lock_type',
    'notes',
    'openings',
    'products_interests',
    'result_type',
    'schedule_time',
    'scheduled_to',
    'second_customer',
    'second_house',
    'status',
    'type',
    // custom added
    'created',
    'deleted',
    'office_id',
    'schedule_time_timestamp',
    'seller_id',
    'started_at',
    'updated'
];

import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Apollo } from 'apollo-angular';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RESEND_EMAIL } from '@core/mutations/email.mutations';
import { EmailClientOptions } from '@shared/interfaces/email-options';

@Injectable({
    providedIn: 'root'
})
export class EmailService {
    private readonly EMAIL_REGEXP = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{1,20}$/i;

    constructor(
        private apollo: Apollo,
        private browser: InAppBrowser
    ) {}

    async openClient(options: EmailClientOptions): Promise<any> {
        const email = {
            cc: options.cc_recipients.map((recipient) => recipient.Email),
            bcc: options.bcc_recipients.map((recipient) => recipient.Email),
            subject: options.subject,
            body: options.body
        };

        const mailTo: string = this.getMailToLink(options.recipients, email);

        return this.browser.create(mailTo, '_system');
    }

    resendEmail(
        appointmentId: number,
        mailId: string,
        recipients: string[],
        message: string,
        useLocalClient: boolean
    ): Observable<any> {
        return this.apollo
            .mutate({
                mutation: RESEND_EMAIL,
                variables: {
                    appointmentId,
                    mailId,
                    recipients,
                    message,
                    useLocalClient
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.resendEmail));
    }

    async hasClients(): Promise<any> {
        return true;
    }

    private getMailToLink(recipients: any[], data: any = {}): string {
        let link = `mailto:${recipients.map((recipient) => recipient.Email).join(',')}`;

        const params: string[] = [];

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                if (!data[key] || (Array.isArray(data[key]) && !data[key].length)) {
                    continue;
                }

                const values: string = Array.isArray(data[key]) ? data[key].join(',') : data[key];

                params.push(`${key}=${encodeURIComponent(values)}`);
            }
        }

        if (params.length > 0) {
            link += `?${params.join('&')}`;
        }

        return link;
    }

    isEmailValid(email: string): boolean {
        return email === '' || this.EMAIL_REGEXP.test(email);
    }
}

import { Injectable } from '@angular/core';

import get from 'lodash/get';

import { AuthService } from '@core/services/auth.service';
import { DeviceHelperService } from '@core/services/device-helper.service';
import { StorageService } from '@core/services/storage.service';
import { VoiceRecording } from '@shared/enums/voice-recording';
import { Appointment } from '@shared/interfaces/appointment';

import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class VoiceRecordingService {
    private readonly siroUrl = 'https://app.siro.ai/record';
    private readonly rillaUrl = 'com.rillavoice://recording';

    private get vendoDomain(): string {
        return `${environment.stage && this.deviceHelperService.isIOSPlatform ? 'stage.' : ''}paradigmvendo`;
    }

    constructor(
        private authService: AuthService,
        private deviceHelperService: DeviceHelperService,
        private storageService: StorageService
    ) {}

    isAvailableIntegration(): boolean {
        if (
            this.deviceHelperService.isWeb ||
            (!this.deviceHelperService.isIOSPlatform && !this.deviceHelperService.isAndroidPlatform)
        ) {
            return false;
        }

        return this.authService.getUser().office.voice_recording.hash !== VoiceRecording.None;
    }

    start(appointment: Appointment): void {
        this.storageService.set('isRecordingStartedForAppointmentId', appointment.id);
        const voiceRecordingIntegration: VoiceRecording = this.authService.getUser().office.voice_recording.hash;
        let url: URL;

        switch (voiceRecordingIntegration) {
            case VoiceRecording.Siro:
                url = new URL(this.siroUrl);
                url.searchParams.set('action', 'start');
                url.searchParams.set(
                    'redirectUrl',
                    `https://${this.vendoDomain}.com/main/appointments/${appointment.id}`
                );
                break;
            case VoiceRecording.Rilla:
                url = new URL(this.rillaUrl);
                url.searchParams.set('start', 'true');
                url.searchParams.set('event_id', appointment.id.toString());
                url.searchParams.set('deep_link', `paradigmvendo://main/appointments/${appointment.id}`);

                const houseAddressParsedInfo = get(appointment.house, ['additional_info', 'address'], {});
                const houseAddress: any = {};

                houseAddress.address1 =
                    get(houseAddressParsedInfo, 'line1') || get(appointment.house, 'address1', null);
                houseAddress.address2 = get(appointment.house, 'address2', null);
                houseAddress.city = get(houseAddressParsedInfo, 'locality') || get(appointment.house, 'city', null);
                houseAddress.state = get(houseAddressParsedInfo, 'state') || get(appointment.house, 'state', null);
                houseAddress.postal_code =
                    get(houseAddressParsedInfo, 'zip') || get(appointment.house, 'postal_code', null);
                url.searchParams.set(
                    'title',
                    `${houseAddress.address1}${houseAddress.address2 ? ', ' + houseAddress.address2 : ''}, ${houseAddress.city}, ${houseAddress.state} ${houseAddress.postal_code}`
                );
                break;
        }

        this.openApp(url);
    }

    stop(): void {
        this.storageService.remove('isRecordingStartedForAppointmentId');
        const voiceRecordingIntegration: VoiceRecording = this.authService.getUser().office.voice_recording.hash;
        let url: URL;

        switch (voiceRecordingIntegration) {
            case VoiceRecording.Siro:
                url = new URL(this.siroUrl);
                url.searchParams.set('action', 'stop');
                url.searchParams.set('redirectUrl', `https://${this.vendoDomain}.com/main/appointments`);
                break;
            case VoiceRecording.Rilla:
                url = new URL(this.rillaUrl);
                url.searchParams.set('stop', 'true');
                url.searchParams.set('deep_link', 'paradigmvendo://main/appointments');
                break;
        }

        this.openApp(url);
    }

    private openApp(url: URL): void {
        this.deviceHelperService.openUrl(url.toString(), '_system');
    }
}

import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { ModalController } from '@ionic/angular';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IMPORT_PROVIA_ORDER, REMOVE_PROVIA_ORDER } from '@core/mutations/provia.mutations';
import { GET_PROVIA_ORDERS, GET_PROVIA_REMEMBER } from '@core/queries/provia.queries';
import { ProviaOrderModalComponent } from '@shared/modals/provia-order-modal/provia-order-modal.component';

@Injectable({
    providedIn: 'root'
})
export class ProviaService {
    constructor(
        private apollo: Apollo,
        private modalController: ModalController
    ) {}

    async openProviaOrderModal(
        appointmentId: string,
        quotes: any[],
        usePortalLogin: boolean,
        packageNames?: string[]
    ): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            this.getRemember().subscribe(async (rememberData) => {
                const modal = await this.modalController.create({
                    component: ProviaOrderModalComponent,
                    componentProps: {
                        appointmentId,
                        quotes,
                        packageNames,
                        usePortalLogin,
                        rememberData
                    },
                    cssClass: 'import-provia-order-modal',
                    backdropDismiss: false
                });

                await modal.present();

                const { data } = await modal.onWillDismiss();

                resolve(data);
            });
        });
    }

    getOrders(data: any): Observable<any> {
        return this.apollo
            .query({
                query: GET_PROVIA_ORDERS,
                variables: data
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.proviaOrders));
    }

    getRemember(): Observable<any> {
        return this.apollo
            .query({
                query: GET_PROVIA_REMEMBER,
                variables: {
                    type: 'provia'
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) =>
                    res.data.publicRememberProperties && res.data.publicRememberProperties.provia
                        ? res.data.publicRememberProperties.provia
                        : {}
                )
            );
    }

    importOrder(userData: any, quoteIds: number[], appointmentId: number, orderNumber: string): Observable<any> {
        return this.apollo
            .mutate({
                mutation: IMPORT_PROVIA_ORDER,
                variables: {
                    userName: userData.user_name,
                    password: userData.password,
                    remember: userData.remember,
                    usedRemember: userData.used_remember,
                    quoteIds,
                    orderNumber,
                    appointmentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.importProviaOrder));
    }

    removeOrder(appointmentId: string): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: REMOVE_PROVIA_ORDER,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.removeProviaOrder));
    }
}

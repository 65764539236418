import { environment } from '../../../../../environments/environment';

export const defaultTheme: any = {
    name: 'light',
    properties: {
        '--ion-color-primary': '#21467e',
        '--ion-color-on-primary': '#FFFFFF',
        '--ion-color-secondary':
            environment.marvin || environment.andersen || environment.genesis || environment.revival
                ? '#000000'
                : '#1cc16e',
        '--ion-color-on-secondary':
            environment.marvin || environment.andersen || environment.genesis || environment.revival
                ? '#FFFFFF'
                : '#000000',
        '--ion-color-tertiary': '#8a8a8a',
        '--ion-color-on-tertiary': '#fff',
        '--warning': '#F6BB42',
        '--on-warning': '#fff',
        '--surface': '#fff',
        '--on-surface': '#0F0F0F',
        '--error': '#EB5945',
        '--on-error': '#fff',
        '--logo-background': '#202834',
        '--background': '#EFF0F4',
        '--on-background': '#0F0F0F',
        '--ion-color-grey': '#8a8a8a',
        '--ion-color-black': '#000000',
        '--ion-color-white': '#FFFFFF'
    }
};

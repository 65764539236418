import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DOWNLOAD_E_DOCUMENT_PDF, SIGN_E_DOCUMENT, UPLOAD_E_DOCUMENT_IMAGE } from '../mutations/e-document.mutations';
import { GET_E_DOCUMENT } from '../queries/e-document.queries';
import { SignatureType } from '@shared/enums/signature-type';
import { CustomerDocumentDownload } from '@shared/interfaces/document';
import { DocumentInstruction } from '@shared/interfaces/document-instruction';

@Injectable({
    providedIn: 'root'
})
export class EDocumentService {
    constructor(private apollo: Apollo) {}

    getDocument(hash: string, authToken?: string): Observable<any> {
        return this.apollo
            .query({
                query: GET_E_DOCUMENT,
                variables: {
                    hash,
                    authToken
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.customerEDocument));
    }

    downloadDocumentPdf(hash: string, authToken?: string): Observable<CustomerDocumentDownload> {
        return this.apollo
            .mutate({
                mutation: DOWNLOAD_E_DOCUMENT_PDF,
                variables: {
                    hash,
                    authToken
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.customerDownloadEDocument));
    }

    signDocument(
        hash: string,
        instructions: DocumentInstruction[],
        applicationUrl: string,
        updatedAt?: string
    ): Observable<any> {
        return this.apollo
            .mutate({
                mutation: SIGN_E_DOCUMENT,
                variables: {
                    hash,
                    instructions,
                    applicationUrl,
                    updatedAt
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.customerSignEDocument));
    }

    uploadCustomerSignatureImage(hash: string, type: SignatureType, file: File): Observable<string> {
        return this.apollo
            .mutate({
                mutation: UPLOAD_E_DOCUMENT_IMAGE,
                variables: {
                    hash,
                    type,
                    file
                },
                context: {
                    useMultipart: true
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.customerUploadImageEDocument));
    }
}

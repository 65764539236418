<div class="modal-header">
    <ion-text class="title">Import entryLINK Order</ion-text>
    <ion-button fill="outline" color="dark" (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
</div>

<div class="modal-body">
    <vendo-provia-form *ngIf="!orders?.length"
                       [userData]="userData"
                       [usePortalLogin]="usePortalLogin"
                       (searchOrders)="getOrders($event)"
                       (orderSelected)="importOrder($event)"></vendo-provia-form>
    <vendo-provia-orders-list *ngIf="orders?.length"
                              [orders]="orders"
                              (orderSelected)="importOrder($event)"></vendo-provia-orders-list>
</div>

<div class="modal-header">
  <ion-text class="title">Price Books</ion-text>
  <ion-button fill="outline" color="dark" (click)="closeModal()">
    <ion-icon slot="icon-only" name="close"></ion-icon>
  </ion-button>
</div>

<div class="modal-body">
  <ng-container *ngIf="priceBooks">
    <vendo-select-item [(selectedValue)]="selectedPriceBookId"
                      [items]="priceBooks"
                      labelProperty="DisplayName"
                      valueProperty="ID"
                      noDataText="No price books are available"></vendo-select-item>
  </ng-container>
</div>

<div class="modal-footer">
  <ion-button fill="outline" (click)="closeModal()">
    Cancel
  </ion-button>
  <ion-button *ngIf="priceBooks?.length"
              [disabled]="!selectedPriceBookId"
              (click)="apply()">
    Save
  </ion-button>
</div>

import { DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { BLE } from '@awesome-cordova-plugins/ble/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FingerprintAIO } from '@awesome-cordova-plugins/fingerprint-aio/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { SQLite } from '@awesome-cordova-plugins/sqlite/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { SortablejsModule } from '@dustfoundation/ngx-sortablejs';
import { NgSelectModule } from '@ng-select/ng-select';
import { MeasureSwift } from '@paradigm-awesome-cordova-plugins/measurements/ngx';
import { MiSnapPlugin } from '@paradigm-awesome-cordova-plugins/misnap-ios/ngx';
import { CookieService } from 'ngx-cookie-service';

import { IonicModule, isPlatform } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { AuthComponent } from './components/auth/auth.component';
import { ForgotPasswordComponent } from './components/auth/components/forgot-password/forgot-password.component';
import { LogoutComponent } from './components/auth/components/logout/logout.component';
import { RedirectCallbackComponent } from './components/auth/components/redirect-callback/redirect-callback.component';
import { ResetPasswordComponent } from './components/auth/components/reset-password/reset-password.component';
import { TermsAndConditionsModalComponent } from './components/auth/components/terms-and-conditions-modal/terms-and-conditions-modal.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { ConfigureService } from './main/appointments/configure/services/configure.service';
import { DemoService } from './main/appointments/demo/services/demo.service';
import { NeedsAssessmentService } from './main/appointments/needs-assessment/services/needs-assessment.service';
import { AppointmentsService } from './main/appointments/services/appointments.service';
import { TakeoffService } from './main/appointments/takeoff/services/takeoff.service';
import { CoreModule } from '@core/core.module';
import { isActiveOfflineMode } from '@core/functions/is-active-offline-mode';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from '@core/handlers/rollbar-error-handler';
import { ApiInterceptor } from '@core/interceptors/api.interceptor';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { RequestsCancelInterceptor } from '@core/interceptors/requests-cancel.interceptor';
import { VersionInterceptor } from '@core/interceptors/version.interceptor';
import { defaultTheme } from '@core/modules/theme/constants/themes.constant';
import { ThemeModule } from '@core/modules/theme/theme.module';
import { IonicGestureConfig } from '@core/providers/hammer';
import { AppInitializerService } from '@core/services/app-initializer.service';
import { CommonService } from '@core/services/common.service';
import { OfflineService } from '@core/services/offline/offline.service';
import { QuoteService } from '@core/services/quote.service';
import { StepsService } from '@core/services/steps.service';
import { StorageService } from '@core/services/storage.service';
import { UserPreferencesService } from '@core/services/user-preferences.service';
import { SharedModule } from '@shared/shared.module';

const isOfflineMode =
    isActiveOfflineMode() &&
    (!window.location.host || !window.location.host.includes('paradigmvendo')) &&
    !environment.local;
let offlineProviders = [];

if (isOfflineMode) {
    offlineProviders = offlineProviders.concat([
        {
            provide: AppointmentsService,
            useClass: OfflineService
        },
        {
            provide: CommonService,
            useClass: OfflineService
        },
        {
            provide: TakeoffService,
            useClass: OfflineService
        },
        {
            provide: ConfigureService,
            useClass: OfflineService
        },
        {
            provide: UserPreferencesService,
            useClass: OfflineService
        },
        {
            provide: QuoteService,
            useClass: OfflineService
        },
        {
            provide: DemoService,
            useClass: OfflineService
        },
        {
            provide: StepsService,
            useClass: OfflineService
        },
        {
            provide: NeedsAssessmentService,
            useClass: OfflineService
        }
    ]);
}

export function initializeApp(appInitializerService: AppInitializerService, storageService: StorageService) {
    return () => storageService.init().then(() => appInitializerService.getGlobalSettings().toPromise());
}

@NgModule({
    declarations: [
        AppComponent,
        AuthComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        TermsAndConditionsModalComponent,
        RedirectCallbackComponent,
        HomePageComponent,
        LogoutComponent,
        ConfirmationModalComponent
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        CoreModule,
        NgSelectModule,
        IonicModule.forRoot({
            scrollPadding: false,
            scrollAssist: !isPlatform('ios'),
            animated: false,
            swipeBackEnabled: false,
            innerHTMLTemplatesEnabled: true
        }),
        SortablejsModule.forRoot({ animation: 150 }),
        AppRoutingModule,
        IonicStorageModule.forRoot(),
        ThemeModule.forRoot({
            themes: [defaultTheme],
            active: 'light'
        }),
        ReactiveFormsModule,
        NoopAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.stage || environment.production }),
        HammerModule,
        SharedModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        ScreenOrientation,
        InAppBrowser,
        Network,
        Keyboard,
        FingerprintAIO,
        Device,
        Geolocation,
        Diagnostic,
        File,
        Camera,
        Deeplinks,
        MeasureSwift,
        MiSnapPlugin,
        BLE,
        SQLite,
        Clipboard,
        DecimalPipe,
        CookieService,
        ...offlineProviders,
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: RequestsCancelInterceptor, multi: true },
        { provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig },
        { provide: ErrorHandler, useClass: RollbarErrorHandler },
        { provide: RollbarService, useFactory: rollbarFactory },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppInitializerService, StorageService],
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule {}

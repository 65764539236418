import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { RollbarErrorHandler } from '@core/handlers/rollbar-error-handler';
import { CommonService } from '@core/services/common.service';
import { GlobalSettings } from '@shared/interfaces/global-settings';

@Injectable({
    providedIn: 'root'
})
export class AppInitializerService {
    globalSettings: GlobalSettings;
    constructor(
        private commonService: CommonService,
        private rollbarErrorHandler: RollbarErrorHandler
    ) {}

    getGlobalSettings(): Observable<GlobalSettings> {
        return this.commonService.getCommonItem('third_party_code').pipe(
            catchError(() => of({ values: { auth0: true, pendo: true, rollbar: true } })),
            map((result) => result.values),
            tap((values: GlobalSettings) => {
                this.globalSettings = values;

                if (!this.globalSettings?.rollbar) {
                    this.rollbarErrorHandler.disable();
                }
            })
        );
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'appointmentFileName'
})
export class AppointmentFileNamePipe implements PipeTransform {
    transform(url: string): string {
        if (!url) {
            return 'file-name.pdf';
        }

        const pathname = new URL(url).pathname;
        const filename = pathname.substring(pathname.lastIndexOf('/') + 1);

        return filename;
    }
}

import { ChangeDetectorRef, Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { BIOMETRIC_ERRORS } from '@awesome-cordova-plugins/fingerprint-aio/ngx';
import { CookieService } from 'ngx-cookie-service';

import { ModalController, NavController } from '@ionic/angular';

import { from, of } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { PrivacyPolicyService } from './services/privacy-policy.service';
import { isActiveOfflineMode } from '@core/functions/is-active-offline-mode';
import { ThemeService } from '@core/modules/theme/services/theme.service';
import { AppService } from '@core/services/app.service';
import { AuthService } from '@core/services/auth.service';
import { Auth0Service } from '@core/services/auth0.service';
import { DeviceHelperService } from '@core/services/device-helper.service';
import { FeaturesService } from '@core/services/features.service';
import { StorageService } from '@core/services/storage.service';
import { ToastService } from '@core/services/toast.service';
import { FEATURES } from '@shared/constants/features';
import { ApplicationRole } from '@shared/enums/application-role.enum';
import { DomainHelper } from '@shared/helpers/domain-helper';
import { Unsubscriber } from '@shared/helpers/unsubscriber';
import { ListSelectModalComponent } from '@shared/modals/list-select-modal/list-select-modal.component';

import { environment } from '../../../environments/environment';

@Component({
    selector: 'vendo-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AuthComponent extends Unsubscriber implements OnInit {
    @HostBinding('class.is-phone') isPhone = this.deviceHelperService.isPhone;
    form = new UntypedFormGroup({
        userName: new UntypedFormControl('', [Validators.required]),
        password: new UntypedFormControl('', [])
    });
    currentYear = new Date().getFullYear();
    biometricAvailable = false;
    isHigherImage: boolean = this.appService.checkIsAndersen() || this.appService.checkIsGenesis();
    logoUrl: string;
    backgroundUrl: any;
    loginConfirmation: any;
    private subDomain: string;

    constructor(
        private appService: AppService,
        private authService: AuthService,
        private route: ActivatedRoute,
        private navController: NavController,
        private themeService: ThemeService,
        private termsAndConditionsModalService: PrivacyPolicyService,
        private featureService: FeaturesService,
        public cdr: ChangeDetectorRef,
        private deviceHelperService: DeviceHelperService,
        private toastService: ToastService,
        private cookieService: CookieService,
        private modalController: ModalController,
        private auth0Service: Auth0Service,
        private storageService: StorageService
    ) {
        super();
    }

    ngOnInit(): void {
        this.themeService.setTheme('light');
        this.authService.signedIn.pipe(takeUntil(this.destroy$)).subscribe((value) => {
            if (value && !this.authService.isLoginViaDeeplink) {
                const user = this.authService.getUser();

                if (user.application_role_hash === ApplicationRole.CapturePayment) {
                    this.navController.navigateRoot(['payment']);

                    return;
                }

                const returnUrl = this.route.snapshot.queryParams.returnUrl;
                const returnUrlParts = returnUrl ? returnUrl.split('?') : null;
                const queryParams = {};

                if (returnUrl?.includes('https')) {
                    window.location.href = returnUrl;
                }

                if (returnUrlParts && returnUrlParts[1]) {
                    const queryParts = returnUrlParts[1].split('=');

                    queryParams[queryParts[0]] = queryParts[1];
                }

                this.navController
                    .navigateRoot([returnUrlParts && returnUrlParts[0] ? returnUrlParts[0] : 'main'], { queryParams })
                    .then(() => {
                        if (!this.featureService.hasFeature(FEATURES.OFFLINE_MODE) && isActiveOfflineMode()) {
                            window.localStorage.setItem('offline_mode', '0');
                            (window as any).location.reload();
                        }
                    })
                    .catch(() => {
                        if (returnUrlParts && returnUrlParts[0]) {
                            this.navController.navigateRoot(['main']);
                        }
                    });
            }
        });

        [this.subDomain] = window.location.hostname.split('.');

        this.themeService.getOrganizationThemeOptions(this.subDomain).subscribe(async (res) => {
            if (res) {
                this.appService.setLogoUrl(res.logo_url);
            }

            this.logoUrl = await this.appService.getLogoUrl();
        });

        this.storageService.get('background_url').then((backgroundUrl) => (this.backgroundUrl = backgroundUrl));

        this.handleLastLoggedUser();
        this.isAvailableUpdate();
        this.appService.isAvailableUpdate();
    }

    checkEmail(): void {
        if (this.form.invalid) {
            return;
        }

        const organizationData = [];
        const userEmail = this.form.getRawValue().userName;

        this.authService
            .checkEmail(userEmail)
            .pipe(
                switchMap((res) => {
                    organizationData.push(...res);

                    if (res.length > 1) {
                        return from(this.openSelectOrganizationModal(res.map((org) => org.organization))).pipe(
                            map((res) => res.data)
                        );
                    } else {
                        return of(...res);
                    }
                }),
                filter((res) => res)
            )
            .subscribe(async (res) => {
                this.authService.setOrgHash(res.hash);
                this.loginConfirmation =
                    organizationData.length > 1
                        ? organizationData.find((data) => data.organization.hash === res.hash)
                        : res;

                if (this.loginConfirmation.external) {
                    this.auth0Service.login(
                        this.loginConfirmation,
                        organizationData.length > 1 ? `?orgHash=${res.hash}` : ''
                    );
                } else {
                    this.form.get('password').setValidators(Validators.required);
                    this.form.get('userName').disable();
                }
            });
    }

    handlePressEnter(event: any): void {
        if (event.keyCode !== 13) {
            return;
        }

        this.loginConfirmation && !this.loginConfirmation.external ? this.login() : this.checkEmail();
    }
    private async openSelectOrganizationModal(organizationList: { hash: string; name: string }[]): Promise<any> {
        const modal: HTMLIonModalElement = await this.modalController.create({
            backdropDismiss: false,
            component: ListSelectModalComponent,
            componentProps: {
                resources: organizationList,
                propToShow: 'name',
                title: 'Choose Your Organization',
                notice: 'We noticed this email is connected to multiple organizations. Which organization would you like to use?'
            },
            cssClass: 'select-organization-modal'
        });

        await modal.present();

        return modal.onWillDismiss();
    }

    login(): void {
        if (this.form.invalid) {
            return;
        }

        this.authService.login(this.form.getRawValue()).subscribe((res) => {
            this.setCookie();
            if (res.reset_password) {
                this.navController.navigateRoot(`reset-password/${res.reset_password}`);
            }
        });
    }

    loginWithBiometric(): void {
        this.deviceHelperService
            .showFingerPrintDialog()
            .then(() => {
                const userName: string = this.form.get('userName').value;

                return this.authService.loginWithBiometric(userName).catch(() => (this.biometricAvailable = false));
            })
            .catch((error) => {
                if (error?.code === BIOMETRIC_ERRORS.BIOMETRIC_LOCKED_OUT) {
                    this.biometricAvailable = false;
                    this.toastService.showMessage('Too much attempts. Please use your credentials to login!');
                }
            });
    }

    backToEmailVerification(): void {
        this.loginConfirmation = null;
        this.authService.setOrgHash(null);

        this.form.get('password').setValidators([]);
        this.form.get('userName').enable();

        this.form.get('password').updateValueAndValidity();
    }

    async openTermsAndConditionsModal(): Promise<void> {
        await this.termsAndConditionsModalService.openTermsAndConditionsModal();
    }

    private isAvailableUpdate(): void {
        this.appService.isAvailableUpdateObservable().pipe(takeUntil(this.destroy$)).subscribe();
    }

    private setCookie(): void {
        if (this.deviceHelperService.isWeb) {
            this.cookieService.set(
                'isVendoMobileUser',
                'yes',
                31,
                '/',
                DomainHelper.getDomainWithoutSubdomain(environment.siteUrl)
            );
        }
    }

    private async handleLastLoggedUser(): Promise<void> {
        const lastLoggedUser: string = await this.authService.getLastLoggedUser();

        this.form.get('userName').setValue(lastLoggedUser);

        if (await this.deviceHelperService.isFingerPrintAvailable()) {
            const [t_id_hash, userName]: string[] = await this.authService.getDataForBiometricLogin();

            this.biometricAvailable = !!t_id_hash && lastLoggedUser === userName;

            if (this.biometricAvailable) {
                this.form
                    .get('userName')
                    .valueChanges.pipe(takeUntil(this.destroy$))
                    .subscribe((value: string) => (this.biometricAvailable = userName === value));
            }
        }
    }
}

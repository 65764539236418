import { Component, Input, ViewChild } from '@angular/core';

import { IonRadioGroup, ModalController } from '@ionic/angular';

import { BaseModal } from '@shared/modals/base-modal';

@Component({
    selector: 'vendo-select-package-modal',
    templateUrl: './select-package-modal.component.html',
    styleUrls: ['./select-package-modal.component.scss']
})
export class SelectPackageModalComponent extends BaseModal {
    @Input() packages: { id: number; name: string }[] = [];
    @ViewChild('radioGroup') radioGroup: IonRadioGroup;

    constructor(public modalController: ModalController) {
        super(modalController);
    }

    confirm(): void {
        this.dismiss(this.radioGroup.value);
    }
}

<div class="items-container">
    <ion-row>
        <ion-col>
            <ion-item lines="none">
                <i class="material-icons" [class.inactive]="generalVisibility"
                   (click)="toggleAllItemsVisibility()"
                   slot="start">visibility</i>
            </ion-item>
        </ion-col>
    </ion-row>
    <ion-row *ngFor="let item of items" [class.deleted]="item.is_deleted">
        <ion-col>
            <ion-item lines="none" (click)="setActiveItem($event, item)">
                <i class="material-icons" *ngIf="!item.is_deleted" [class.inactive]="visibilityMap[item.id]"
                   (click)="toggleItemVisibility($event, item.id)"
                   slot="start">visibility</i>
                <i class="material-icons" *ngIf="item.is_deleted"
                   (click)="restoreItem($event, item)"
                   slot="start">restore_from_trash</i>
                <ion-text>
                    {{item.name}}: {{item.distance | number}}{{item.measurement_type}}
                </ion-text>
            </ion-item>
            <ng-container [ngTemplateOutlet]="editForm" *ngIf="item.id === activeItem?.id"
                          [ngTemplateOutletContext]="{form: form}"></ng-container>
        </ion-col>
    </ion-row>
</div>
<div class="ion-text-center">
    <ion-button (click)="close()">{{modifiedItems.length || deletedItems.length ? 'Save' : 'Close'}}</ion-button>
</div>
<ng-template #editForm let-form="form">
    <form [formGroup]="form">
        <ion-input legacy="true" [class.has-error]="form.get('name').invalid" type="text" formControlName="name"></ion-input>
        <ion-input legacy="true" [class.has-error]="form.get('distance').invalid" type="number"
                   formControlName="distance"></ion-input>
        <ion-button color="danger" fill="outline" (click)="deleteItem(form.get('id').value)">Delete</ion-button>
        <ion-button (click)="saveChanges()">Done</ion-button>
    </form>
</ng-template>

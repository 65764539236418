import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';

import { InfiniteScrollCustomEvent, IonInfiniteScroll, ModalController } from '@ionic/angular';

import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';

import { AuthService } from '@core/services/auth.service';
import { FormHelperService } from '@core/services/form-helper.service';
import { MeasurementReportService } from '@core/services/measurement-report.service';
import { MeasurementSystemService } from '@core/services/measurement-system.service';
import { ToastService } from '@core/services/toast.service';
import { FieldType } from '@shared/enums/field-type.enum';
import { MeasurementSystemType } from '@shared/enums/measurement-system-type';
import { Appointment } from '@shared/interfaces/appointment';
import { DropdownItem } from '@shared/interfaces/dropdown-item';
import { FormFieldsSettings } from '@shared/interfaces/form-fields-settings';
import {
    MeasurementSystem,
    MeasurementSystemReport,
    MeasurementSystemUserDetails
} from '@shared/interfaces/measurement-system';
import { PaginationItems } from '@shared/interfaces/pagination-items';
import { BaseModal } from '@shared/modals/base-modal';

@Component({
    selector: 'vendo-measurement-reports-modal',
    templateUrl: './measurement-reports-modal.component.html',
    styleUrls: ['./measurement-reports-modal.component.scss']
})
export class MeasurementReportsModalComponent extends BaseModal implements OnInit {
    @Input() userDetails: MeasurementSystemUserDetails;
    @Input() userData: any;
    @Input() measurementSystem: MeasurementSystem;
    @Input() appointment: Appointment;
    @Input() initialReportsData: PaginationItems<MeasurementSystemReport>;
    @Input() orderFieldsForm: FormFieldsSettings;
    @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
    reports: MeasurementSystemReport[] = [];
    form: UntypedFormGroup;
    tabs = [];
    activeTabId;
    emails: DropdownItem[] = [];
    measurementSystemTypes: typeof MeasurementSystemType = MeasurementSystemType;
    isShowAdditionalOrderFields: boolean;
    private enableInfinityScroll = true;
    private pageIndex = 0;
    private pageSize = 10;

    get addressDetailsFormGroup(): UntypedFormGroup {
        return this.form.get('address_details') as UntypedFormGroup;
    }

    get ownerDetailsFormGroup(): UntypedFormGroup {
        return this.form.get('owner_details') as UntypedFormGroup;
    }

    constructor(
        private authService: AuthService,
        private formBuilder: UntypedFormBuilder,
        private formHelperService: FormHelperService,
        public modalController: ModalController,
        private measurementReportService: MeasurementReportService,
        private measurementSystemService: MeasurementSystemService,
        private toastService: ToastService
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.tabs = [
            {
                label: 'Capture',
                hash: 'capture',
                hidden: !this.userDetails?.email
            },
            {
                label: 'Import',
                hash: 'import'
            }
        ];
        this.activeTabId = this.tabs[1].hash;

        if (this.initialReportsData) {
            this.reports = this.initialReportsData.entities;
            this.enableInfinityScroll = this.initialReportsData.totalCount > 10;
        }

        if (this.measurementSystem.hash === MeasurementSystemType.Hover) {
            const user = this.authService.getUser();

            this.emails = [
                ...(this.appointment.customer.email
                    ? [
                          {
                              label: 'Primary Customer',
                              value: this.appointment.customer.email
                          }
                      ]
                    : []),
                ...(this.appointment.second_customer?.email
                    ? [
                          {
                              label: 'Secondary Customer',
                              value: this.appointment.second_customer.email
                          }
                      ]
                    : []),
                {
                    label: 'Seller',
                    value: user.email
                },
                {
                    label: 'Other',
                    value: ''
                }
            ];
        }

        this.initForm();
    }

    selectTab(tab: any): void {
        if (this.activeTabId === tab.hash) {
            return;
        }

        this.activeTabId = tab.hash;

        const reportControl: AbstractControl = this.form.get('report');

        if (this.activeTabId === this.tabs[1].hash) {
            reportControl.setValidators(Validators.required);
            reportControl.reset();
            this.resetAddressValidators(false);

            if (this.measurementSystem.hash === MeasurementSystemType.Hover) {
                this.resetOwnerValidators(false);
            }

            this.form.get('search').setValue(this.appointment.house.address1);
        } else {
            reportControl.clearValidators();
            this.addressDetailsFormGroup.patchValue(this.appointment.house);
            this.resetAddressValidators(true);

            if (this.measurementSystem.hash === MeasurementSystemType.Hover) {
                this.form.patchValue({
                    email_selection: this.emails[0],
                    owner_details: {
                        first_name: this.appointment.customer.first_name,
                        last_name: this.appointment.customer.last_name
                    }
                });
                this.resetOwnerValidators(true);
            }
        }
        reportControl.updateValueAndValidity();
    }

    useOtherAccount(): void {
        this.dismiss('relogin');
    }

    loadNextPage(event: InfiniteScrollCustomEvent): void {
        if (!this.enableInfinityScroll) {
            event.target.complete();
            event.target.disabled = true;

            return;
        }

        this.pageIndex++;
        this.searchReports(this.form.get('search').value)
            .pipe(finalize(() => event.target.complete()))
            .subscribe((res: PaginationItems<MeasurementSystemReport>) => this.reports.push(...res.entities));
    }

    getMessages(errors): string[] {
        return this.formHelperService.getMessages(errors);
    }

    proceed(): void {
        if (this.activeTabId === this.tabs[0].hash && this.orderFieldsForm && !this.isShowAdditionalOrderFields) {
            this.isShowAdditionalOrderFields = true;

            return;
        }

        if (this.form.invalid) {
            this.form.markAllAsTouched();
            this.toastService.showMessage('Please fill in all the required fields!');

            return;
        }

        const data = this.form.getRawValue();

        if (this.activeTabId === this.tabs[1].hash) {
            this.next(data);
        } else {
            this.order(data);
        }
    }

    cancel(): void {
        if (this.activeTabId === this.tabs[0].hash && this.orderFieldsForm && this.isShowAdditionalOrderFields) {
            this.isShowAdditionalOrderFields = false;

            return;
        }

        this.closeModal();
    }

    private initForm(): void {
        this.form = this.formBuilder.group({
            search: this.appointment.house.address1,
            report: [null, Validators.required],
            email_selection: null,
            owner_details: this.formBuilder.group({
                first_name: this.appointment.customer.first_name,
                last_name: this.appointment.customer.last_name,
                email: null
            }),
            address_details: this.formBuilder.group({
                address1: this.appointment.house.address1,
                address2: this.appointment.house.address2,
                city: this.appointment.house.city,
                state: this.appointment.house.state,
                postal_code: this.appointment.house.postal_code,
                country: this.appointment.house.country
            }),
            ...(this.orderFieldsForm && {
                order_details: [
                    [
                        this.orderFieldsForm.fields.reduce((acc, { fieldType, key }) => {
                            acc[key] = fieldType === FieldType.Checkbox ? false : null;

                            return acc;
                        }, {})
                    ]
                ]
            })
        });

        this.form
            .get('search')
            .valueChanges.pipe(
                debounceTime(500),
                distinctUntilChanged(),
                tap(() => {
                    this.pageIndex = 0;
                    this.form.get('report').setValue(null);
                }),
                switchMap((search: string) => this.searchReports(search)),
                takeUntil(this.destroy$)
            )
            .subscribe((data: PaginationItems<MeasurementSystemReport>) => {
                this.reports = data.entities;
                this.infiniteScroll.disabled = !this.enableInfinityScroll;
            });

        if (this.measurementSystem.hash === MeasurementSystemType.Hover) {
            this.form
                .get('email_selection')
                .valueChanges.pipe(takeUntil(this.destroy$))
                .subscribe(({ label, value }) => {
                    const emailControl: AbstractControl = this.ownerDetailsFormGroup.get('email');

                    emailControl.setValue(value);

                    if (label === 'Other') {
                        emailControl.enable();
                        emailControl.markAsTouched();
                    } else {
                        emailControl.disable();
                    }
                });

            this.form.get('email_selection').setValue(this.emails[0]);
        }
    }

    private next(data: any): void {
        this.measurementReportService
            .openMeasurementSystemReportDetailsDialog(
                this.measurementSystem,
                this.appointment,
                data.report,
                this.userCredentials,
                this.userData?.remember,
                this.userData?.used_remember
            )
            .then((result) => result && this.dismiss(result));
    }

    private order(data: any): void {
        this.measurementSystemService
            .orderReport(
                this.measurementSystem.hash,
                this.appointment.id,
                this.userCredentials,
                this.measurementSystem.hash === MeasurementSystemType.Hover ? data.owner_details : undefined,
                data.address_details,
                data.order_details ? data.order_details[0] : undefined
            )
            .subscribe((res: boolean) => this.dismiss(res));
    }

    private searchReports(search: string): Observable<PaginationItems<MeasurementSystemReport>> {
        return this.measurementSystemService
            .searchReport(
                this.measurementSystem.hash,
                this.appointment.id,
                search,
                this.userCredentials,
                this.userData?.remember,
                this.userData?.used_remember,
                this.pageSize * this.pageIndex,
                this.pageSize
            )
            .pipe(
                tap(
                    (res: PaginationItems<MeasurementSystemReport>) =>
                        (this.enableInfinityScroll = res.totalCount > (this.pageIndex + 1) * this.pageSize)
                )
            );
    }

    private get userCredentials(): any {
        if (!this.userData) {
            return undefined;
        }

        return this.userData.username && this.userData.password
            ? {
                  username: this.userData.username,
                  password: this.userData.password
              }
            : undefined;
    }

    private resetAddressValidators(isRequired: boolean): void {
        Object.keys(this.addressDetailsFormGroup.controls).forEach((key: string) => {
            if (key === 'address2') {
                return;
            }

            const control: AbstractControl = this.addressDetailsFormGroup.get(key);

            isRequired ? control.setValidators(Validators.required) : control.clearValidators();
            control.updateValueAndValidity();
        });
    }

    private resetOwnerValidators(isRequired: boolean): void {
        Object.keys(this.ownerDetailsFormGroup.controls).forEach((key: string) => {
            const control: AbstractControl = this.ownerDetailsFormGroup.get(key);

            if (isRequired) {
                const validators: ValidatorFn[] = [Validators.required];

                if (key === 'email') {
                    validators.push(Validators.email);
                }
                control.setValidators(validators);
            } else {
                control.clearValidators();
            }

            control.updateValueAndValidity();
        });
    }
}

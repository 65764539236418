import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { MapComponent } from '@shared/components/map/map.component';
import { BaseModal } from '@shared/modals/base-modal';

@Component({
    selector: 'vendo-house-on-map',
    templateUrl: './house-on-map.component.html',
    styleUrls: ['./house-on-map.component.scss']
})
export class HouseOnMapComponent extends BaseModal implements OnInit {
    @Input() houseData;
    @ViewChild(MapComponent) map: MapComponent;

    constructor(modalController: ModalController) {
        super(modalController);
    }

    ngOnInit(): void {
        setTimeout(() => this.map.initMap(), 100);
    }

    async capture(): Promise<void> {
        const data = await this.map.captureImage();

        this.dismiss(data);
    }
}

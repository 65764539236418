import { Injectable } from '@angular/core';

import ceil from 'lodash/ceil';
import floor from 'lodash/floor';
import round from 'lodash/round';

import { AuthService } from '@core/services/auth.service';
import { PriceConfigurationRoundingType } from '@shared/enums/price-configuration-rounding-type';

@Injectable({
    providedIn: 'root'
})
export class RoundingService {
    constructor(private authService: AuthService) {}

    round(value: number): number {
        if (typeof value !== 'number') {
            throw new Error(`${value} is not a number`);
        }

        const user = this.authService.getUser();
        const digitsCount: number = user.office.price_configuration.base_settings.round_to_decimals;

        switch (user.office.price_configuration.base_settings.rounding_type) {
            case PriceConfigurationRoundingType.Up:
                return ceil(value, digitsCount);
            case PriceConfigurationRoundingType.Down:
                return floor(value, digitsCount);
            case PriceConfigurationRoundingType.Nearest:
            default:
                return round(value, digitsCount);
        }
    }
}

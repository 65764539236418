import { Component, Input, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

import find from 'lodash/find';

import { QuoteService } from '@core/services/quote.service';
import { QuotePriceBook, QuotePriceBookListItem } from '@shared/interfaces/quote-price-book';
import { BaseModal } from '@shared/modals/base-modal';

@Component({
    selector: 'vendo-select-price-book',
    templateUrl: './select-price-book.component.html',
    styleUrls: ['./select-price-book.component.scss']
})
export class SelectPriceBookComponent extends BaseModal implements OnInit {
    @Input() quoteId: string;
    priceBooks: QuotePriceBook[];
    selectedPriceBookId: string;

    constructor(
        modalController: ModalController,
        private quoteService: QuoteService
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.quoteService.getQuotePriceBooks(this.quoteId).subscribe((data: QuotePriceBookListItem[]) => {
            this.priceBooks = data[0].items;
            this.selectedPriceBookId = find(this.priceBooks, 'IsSelected')?.ID;
        });
    }

    apply(): void {
        this.quoteService.setQuotePriceBook(this.quoteId, this.selectedPriceBookId).subscribe(() => this.closeModal());
    }
}

import { gql } from 'apollo-angular';

const GET_PAYMENT_TOKEN = gql`
    query PaymentToken {
        paymentToken
    }
`;

const GENERATE_PAYMENT_FORM = gql`
    query GeneratePaymentForm(
        $paymentType: String!
        $quoteId: ID!
        $paymentMethod: String!
        $contactData: JSON!
        $applySurcharge: Boolean
    ) {
        generatePaymentForm(
            payment_type: $paymentType
            quote_id: $quoteId
            payment_method: $paymentMethod
            contact_info: $contactData
            apply_surcharge: $applySurcharge
        )
    }
`;

const GENERATE_UNLINKED_PAYMENT_FORM = gql`
    query GenerateUnlinkedPaymentForm(
        $paymentType: String!
        $paymentMethod: String!
        $contactData: JSON!
        $applySurcharge: Boolean
    ) {
        generateUnlinkedPaymentForm(
            payment_type: $paymentType
            payment_method: $paymentMethod
            contact_info: $contactData
            apply_surcharge: $applySurcharge
        )
    }
`;

const GET_QUOTE_INVOICES = gql`
    query GetQuoteInvoices($appointmentId: ID!) {
        myQuoteInvoices(appointment_id: $appointmentId) {
            title
            due_date
            email
            amount
            payment_status
        }
    }
`;

const GET_TRANSACTION_STATUS = gql`
    query GetTransactionStatus($quoteId: ID!) {
        getTransactionStatus(quote_id: $quoteId)
    }
`;

const GET_UNLINKED_TRANSACTION_STATUS = gql`
    query GetUnlinkedTransactionStatus($transactionId: String!) {
        getUnlinkedTransactionStatus(transaction_api_id: $transactionId)
    }
`;

const GET_UNLINKED_PAYMENTS = gql`
    query GetUnlinkedPayments {
        myUnlinkedPayments {
            status
            transaction_amount
            created_at
            additional_info
        }
    }
`;

const GET_PAYMENT_DETAILS = gql`
    query GetPaymentDetails {
        getPaymentDetails {
            has_cc_product
            has_ach_product
            surcharge {
                surcharge_fee
                surcharge_rate
                min_fee_amount
                max_fee_amount
                surcharge_label
            }
        }
    }
`;

export {
    GET_PAYMENT_TOKEN,
    GENERATE_PAYMENT_FORM,
    GET_TRANSACTION_STATUS,
    GET_QUOTE_INVOICES,
    GENERATE_UNLINKED_PAYMENT_FORM,
    GET_UNLINKED_TRANSACTION_STATUS,
    GET_UNLINKED_PAYMENTS,
    GET_PAYMENT_DETAILS
};

import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren
} from '@angular/core';

import { AlertController, PopoverController } from '@ionic/angular';

import find from 'lodash/find';
import get from 'lodash/get';
import round from 'lodash/round';

import { DictionaryService } from '@core/services/dictionary.service';
import { DictionaryType } from '@shared/enums/dictionary-type';
import { Appointment } from '@shared/interfaces/appointment';
import { Dictionary } from '@shared/interfaces/dictionary';
import { OpeningDrawing } from '@shared/interfaces/opening-drawing';
import { Promotion } from '@shared/interfaces/promotion';
import { QuoteLineItem } from '@shared/interfaces/quote';
import { FreeTextPopoverComponent } from '@shared/popovers/free-text-popover/free-text-popover.component';

import { ConfigureService } from '../../../main/appointments/configure/services/configure.service';

@Component({
    selector: 'vendo-line-item-details',
    templateUrl: './line-item-details.component.html',
    styleUrls: ['./line-item-details.component.scss']
})
export class LineItemDetailsComponent implements OnInit, OnChanges {
    @Input() appointment: Appointment;
    @Input() showPrice = false;
    @Input() lineItem: QuoteLineItem;
    @Input() index: number;
    @Input() isAllowQuantityChange = true;
    @Input() isNotAllowExclude = false;
    @Input() isFirst: boolean;
    @Input() isLast: boolean;
    @Input() isAllowReordering: boolean;
    @Input() isDisableReordering: boolean;
    @Input() isSinglePackage: boolean;
    @Input() isPromotionApplied: boolean;
    @Input() promotions: Promotion[] = [];
    @Output() quantityChanged: EventEmitter<void> = new EventEmitter<void>();
    @Output() itemExcluded: EventEmitter<any> = new EventEmitter<any>();
    @Output() moveItem: EventEmitter<number> = new EventEmitter<number>();
    isShowDetails = false;
    activeDrawing: any;
    displayPrice = 0;
    totalPrice = 0;
    activeStepIndex: any;
    isExpanded: boolean[] = new Array(5).fill(false);
    @ViewChildren('detailElement') detailElements: QueryList<ElementRef>;
    previousQuantity: number;
    adderNameSetting: Dictionary;
    isExistAddersChanges: boolean;
    isExpandedAddersChanges = false;

    constructor(
        private alertController: AlertController,
        private configureService: ConfigureService,
        private dictionaryService: DictionaryService,
        private popoverController: PopoverController
    ) {}

    async ngOnInit(): Promise<void> {
        this.activeStepIndex = 0;
        this.setDefaultDrawing();
        this.adderNameSetting = await this.dictionaryService.getDictionaryName(DictionaryType.Adder);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.lineItem && get(changes, 'lineItem.currentValue')) {
            this.setPrices();
            this.previousQuantity = this.lineItem.quantity;
            this.isExistAddersChanges = this.lineItem.adders.some(({ changes }) => !!changes?.length);
        }
    }

    async showFullDescriptionPopover(event: Event, text: string): Promise<any> {
        event.stopPropagation();
        const popover = await this.popoverController.create({
            component: FreeTextPopoverComponent,
            event,
            translucent: true,
            mode: 'md',
            cssClass: 'auto-width',
            componentProps: {
                text
            }
        });

        return await popover.present();
    }

    getDrawingDisplayName(drawing: any): string {
        return drawing.name.split(' ')[0];
    }

    setActiveDrawing(newDrawing: any): void {
        if (this.lineItem.excluded) {
            return;
        }
        this.activeDrawing = newDrawing;
    }

    toggleDetailsSection(): void {
        this.isShowDetails = !this.isShowDetails;
        this.isExpanded.fill(false);
    }

    toggleDetail(index: number): void {
        this.isExpanded = this.isExpanded.map((isExpanded: boolean, i: number) =>
            i === index ? !this.isExpanded[i] : false
        );
        this.isExpandedAddersChanges = false;
        this.activeStepIndex = Number(index === 1);
        this.setDefaultDrawing();

        if (this.isExpanded[index]) {
            setTimeout(() => this.detailElements.toArray()[index].nativeElement.scrollIntoView());
        }
    }

    async quantityChanges(): Promise<void> {
        this.lineItem.quantity = round(this.lineItem.quantity);

        if (this.lineItem.quantity <= 0) {
            this.lineItem.quantity = 1;
        }

        if (this.isSinglePackage) {
            this.updateQuantity();

            return;
        }

        const alert = await this.alertController.create({
            header: 'Please confirm',
            message:
                'You are about to change quantity of line item. Changes will apply to all packages. Would you like to proceed?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        this.lineItem.quantity = this.previousQuantity;
                    }
                },
                {
                    text: 'Proceed',
                    handler: () => this.updateQuantity()
                }
            ]
        });

        await alert.present();
    }

    updateQuantity(): void {
        this.configureService
            .updateOpeningQuantity(this.appointment.id, this.lineItem.opening_id, this.lineItem.quantity)
            .subscribe(
                () => {
                    this.previousQuantity = this.lineItem.quantity;
                    this.quantityChanged.emit();
                },
                () => {
                    this.lineItem.quantity = this.previousQuantity;
                }
            );
    }

    private setPrices(): void {
        this.displayPrice = this.lineItem.display_price + this.lineItem.adders_display_price;
        this.totalPrice = this.lineItem.price + this.lineItem.adders_total_price;
    }

    private setDefaultDrawing(): void {
        let defaultDrawing;

        if (this.lineItem.details?.length) {
            defaultDrawing = find(this.lineItem.opening_drawings, (drawing: OpeningDrawing) =>
                drawing.name.toLowerCase().includes(this.lineItem.details[this.activeStepIndex].defaultDrawing)
            );
        }

        this.activeDrawing = defaultDrawing || this.lineItem.opening_drawings[0];
    }
}

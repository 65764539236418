import { OFFLINE_APPOINTMENT_FIELDS } from '@core/services/offline/offline-tables/appointment-fields';
import { OFFLINE_CATEGORIES_FIELDS } from '@core/services/offline/offline-tables/categories-fields';
import { OFFLINE_COMMON_FIELDS } from '@core/services/offline/offline-tables/common-fields';
import { OFFLINE_DEMO_RESOURCES_FIELDS } from '@core/services/offline/offline-tables/demo-resources-fields';
import { OFFLINE_NEEDS_ASSESSMENT_FIELDS } from '@core/services/offline/offline-tables/needs-assessment-fields';
import { OFFLINE_USER_PREFERENCES_FIELDS } from '@core/services/offline/offline-tables/user-preferences-fields';
import { OfflineTableConfig } from '@shared/enums/offline-table-config';
import { OfflineTableName } from '@shared/enums/offline-table-name';

// MODIFY THIS IF OFFLINE TABLES STRUCTURE NEED TO BE CHANGED
export const OFFLINE_DB_VERSION = '0.0.20';

export const OFFLINE_TABLES: OfflineTableConfig[] = [
    {
        table_name: OfflineTableName.Appointments,
        fields: OFFLINE_APPOINTMENT_FIELDS,
        primary_key: 'id',
        column_types: {
            activities: 'TEXT', // JSON
            assessment: 'TEXT', // JSON
            crm_results: 'TEXT', // JSON
            customer: 'TEXT', // JSON
            deeplinks: 'TEXT', // JSON
            family_name: 'TEXT',
            house: 'TEXT', // JSON
            id: 'TEXT',
            is_archived: 'TEXT', // boolean
            lock_type: 'TEXT',
            notes: 'TEXT',
            openings: 'TEXT', // JSON
            products_interests: 'TEXT', // JSON
            result_type: 'TEXT',
            schedule_time: 'TEXT',
            scheduled_to: 'TEXT',
            second_customer: 'TEXT', // JSON
            second_house: 'TEXT', // JSON
            status: 'TEXT',
            type: 'TEXT',
            // custom added
            created: 'INTEGER DEFAULT 0 NOT NULL',
            deleted: 'INTEGER DEFAULT 0 NOT NULL',
            office_id: 'TEXT',
            schedule_time_timestamp: 'INTEGER',
            seller_id: 'TEXT',
            started_at: 'TEXT',
            updated: 'INTEGER DEFAULT 0 NOT NULL'
        },
        columns_to_convert: [
            'activities',
            'assessment',
            'crm_results',
            'customer',
            'deeplinks',
            'house',
            'is_archived',
            'openings',
            'products_interests',
            'second_customer',
            'second_house'
        ]
    },
    {
        table_name: OfflineTableName.NeedsAssessments,
        fields: OFFLINE_NEEDS_ASSESSMENT_FIELDS,
        primary_key: 'user_id',
        column_types: {
            user_id: 'TEXT',
            office_id: 'TEXT',
            needs_assessment: 'TEXT'
        },
        columns_to_convert: ['needs_assessment']
    },
    {
        table_name: OfflineTableName.Common,
        fields: OFFLINE_COMMON_FIELDS,
        primary_key: 'id',
        column_types: {
            id: 'TEXT',
            value: 'TEXT'
        },
        columns_to_convert: ['value']
    },
    {
        table_name: OfflineTableName.OfflineSyncs,
        fields: ['user_id', 'office_id', 'synchronized_at', 'last_schedule_time'],
        primary_key: 'user_id',
        column_types: {
            user_id: 'TEXT',
            office_id: 'TEXT',
            synchronized_at: 'TEXT',
            last_schedule_time: 'TEXT'
        },
        columns_to_convert: []
    },
    {
        table_name: OfflineTableName.Categories,
        fields: OFFLINE_CATEGORIES_FIELDS,
        primary_key: 'id',
        column_types: {
            category: 'TEXT', // JSON
            id: 'TEXT',
            name: 'TEXT',
            opening_conditions: 'TEXT', // JSON
            predefined_images: 'TEXT', // JSON
            // custom added
            user_id: 'TEXT',
            office_id: 'TEXT',
            position: 'INTEGER'
        },
        columns_to_convert: ['category', 'opening_conditions', 'predefined_images']
    },
    {
        table_name: OfflineTableName.UserPreferences,
        fields: OFFLINE_USER_PREFERENCES_FIELDS,
        primary_key: 'user_id',
        column_types: {
            user_id: 'TEXT',
            office_id: 'TEXT',
            preferences: 'TEXT'
        },
        columns_to_convert: ['preferences']
    },
    {
        table_name: OfflineTableName.DemoResources,
        fields: OFFLINE_DEMO_RESOURCES_FIELDS,
        primary_key: 'id',
        column_types: {
            badge: 'TEXT', // JSON
            categories: 'TEXT', // JSON
            icon: 'TEXT',
            id: 'TEXT',
            name: 'TEXT',
            resource_src: 'TEXT',
            resource_type: 'TEXT',
            text_color: 'TEXT',
            widget_color: 'TEXT',
            // custom added
            user_id: 'TEXT',
            office_id: 'TEXT'
        },
        columns_to_convert: ['badge', 'categories']
    }
];

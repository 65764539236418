<div class="modal-header">
    <ion-text class="title">{{ measurementSystem.hash | replace: '_':'' | titlecase }} Report</ion-text>
    <ion-button fill="outline" color="dark" (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
</div>

<div class="modal-body">
    <vendo-tabs [tabs]="tabs"
                [activeTabId]="activeTab.hash"
                (tabSelected)="setActiveTab($event)"></vendo-tabs>

    <ng-container [ngSwitch]="activeTab.hash">
        <vendo-pdf-document-viewer *ngSwitchCase="tabs[0].hash"
                                   class="report-wrapper"
                                   [url]="report.report_link"></vendo-pdf-document-viewer>


        <ion-content *ngSwitchCase="tabs[1]?.hash">
            <ion-grid>
                <ion-row>
                    <ion-col size="4"
                             class="image-wrapper"
                             *ngFor="let image of images"
                             (click)="setImage(image)">
                        <ion-img [src]="image.url"></ion-img>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-content>
    </ng-container>
</div>

<div *ngIf="selectedImage" class="selected-image" (click)="setImage()">
    <ion-img [src]="selectedImage.url"></ion-img>
</div>

import { Injectable } from '@angular/core';

import { SecureStorageEcho, SecureStorageEchoObject } from '@awesome-cordova-plugins/secure-storage-echo/ngx';

import { DeviceHelperService } from '@core/services/device-helper.service';

@Injectable({
    providedIn: 'root'
})
export class SecureStorageService {
    private secureStorage: SecureStorageEchoObject;

    get isInitialized(): boolean {
        return !!this.secureStorage;
    }

    constructor(
        private deviceHelperService: DeviceHelperService,
        private secureStorageEcho: SecureStorageEcho
    ) {}

    async init(): Promise<void> {
        try {
            this.secureStorage = await this.secureStorageEcho.create(
                `my_store_${this.deviceHelperService.getDeviceUUID}`
            );
        } catch (e) {
            console.error('Secure storage is not created', e);
        }
    }

    async setValue(key: string, value: string): Promise<void> {
        if (this.isInitialized) {
            await this.secureStorage.set(key, value);
        }
    }

    async getValue(key: string): Promise<string> {
        if (this.isInitialized) {
            return this.secureStorage.get(key);
        }

        return null;
    }

    async removeValue(key: string): Promise<void> {
        if (this.isInitialized) {
            await this.secureStorage.remove(key);
        }
    }
}

import { gql } from 'apollo-angular';

const UPDATE_PACKAGE = gql`
    mutation QuotePackage($quoteId: ID!, $packageName: String!) {
        quotePackage(quote_id: $quoteId, package_name: $packageName)
    }
`;

const COPY_PACKAGE = gql`
    mutation CopyPackage($quoteId: ID!, $copyToQuoteIds: [ID!]!) {
        copyPackage(quote_id: $quoteId, copy_to_quote_ids: $copyToQuoteIds)
    }
`;

const SET_QUOTES_FIELDS = gql`
    mutation SetQuoteFields($quoteId: ID!, $quoteFields: [JSON!]!) {
        setQuoteFields(quote_id: $quoteId, quote_fields: $quoteFields)
    }
`;

const SET_CLIENT = gql`
    mutation SetQuoteClient(
        $quoteId: ID!
        $clientId: ID!
        $clientNumber: String
        $applyTo: QuoteClientApplyToTypeEnum!
    ) {
        setQuoteClient(quote_id: $quoteId, client_id: $clientId, client_number: $clientNumber, apply_to: $applyTo)
    }
`;

const SET_PRICING_PROFILE = gql`
    mutation SetQuotePricingProfile(
        $quoteId: ID!
        $applyTo: QuotePricingProfileApplyToTypeEnum!
        $pricingProfileId: ID
        $isUpdateExistingLines: Boolean
    ) {
        setQuotePricingProfile(
            quote_id: $quoteId
            apply_to: $applyTo
            pricing_profile_id: $pricingProfileId
            is_update_existing_lines: $isUpdateExistingLines
        )
    }
`;

const SET_PRICE_BOOK = gql`
    mutation SetQuotePriceBook($quoteId: ID!, $priceBookId: ID!) {
        setQuotePriceBook(quote_id: $quoteId, price_book_id: $priceBookId)
    }
`;

const QUOTES_UPDATE_PK = gql`
    mutation quotesUpdatePk($appointmentId: ID!, $isBackground: Boolean) {
        quotesUpdatePk(appointment_id: $appointmentId, is_background: $isBackground) {
            package_name
            results {
                opening_name
                old_price
                new_price
                attributes {
                    question
                    old_answer
                    new_answer
                }
                errors
            }
        }
    }
`;

const AUTO_CONFIGURATOR = gql`
    mutation autoConfigurator($appointmentId: ID!, $catalogId: ID, $openingId: ID) {
        autoConfigurator(appointment_id: $appointmentId, catalog_id: $catalogId, opening_id: $openingId)
    }
`;

const RELEASE_DOCUMENTS = gql`
    mutation ReleaseDocuments($appointmentId: ID!, $quoteIds: [ID!], $cancellationReason: String) {
        releaseDocuments(appointment_id: $appointmentId, quote_ids: $quoteIds, cancellation_reason: $cancellationReason)
    }
`;

export {
    UPDATE_PACKAGE,
    COPY_PACKAGE,
    SET_CLIENT,
    SET_PRICING_PROFILE,
    SET_PRICE_BOOK,
    SET_QUOTES_FIELDS,
    QUOTES_UPDATE_PK,
    AUTO_CONFIGURATOR,
    RELEASE_DOCUMENTS
};

import { gql } from 'apollo-angular';

const CheckResetPasswordHash = gql`
    query resetPassword($hash: String!) {
        resetPassword(hash: $hash) {
            hash
            valid
            error
            errorCode
        }
    }
`;

const GetBiometricID = gql`
    query myBiometricID($deviceId: String!) {
        myBiometricID(device_id: $deviceId)
    }
`;

export { CheckResetPasswordHash, GetBiometricID };

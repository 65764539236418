import { from, Observable } from 'rxjs';

import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';

import { AuthService } from '@core/services/auth.service';
import { OfflineStorageService } from '@core/services/offline/offline-storage.service';
import { CategoryType } from '@shared/enums/category-type';
import { OfflineTableName } from '@shared/enums/offline-table-name';
import { SaleStepType } from '@shared/enums/sale-step-type.enum';

declare let moment: any;

export class TakeoffOffline {
    constructor(
        private authService: AuthService,
        private offlineStorageService: OfflineStorageService
    ) {}

    getOpening(openingId: number): Observable<any> {
        const user = this.authService.getUser();
        const opening = this.offlineStorageService
            .findOne(
                `
                    SELECT *
                    FROM ${OfflineTableName.Appointments}
                    WHERE seller_id = '${user.id}'
                      AND office_id = '${user.office.id}'
                      AND openings LIKE '%\"id\":\"${openingId}\"%'`,
                OfflineTableName.Appointments
            )
            .then((res: any) => res.openings.find((opening) => opening.id == openingId && opening.is_deleted !== '1'));

        return from(opening);
    }

    createOpening(
        categoryId: number,
        catalogId: number,
        appointmentId: number,
        opening: any,
        geolocation: any = {},
        images: any = []
    ): Observable<any> {
        if (opening.hasOwnProperty('image_details')) {
            delete opening['image_details'];
        }

        const openingData = {
            created: '1',
            id: `offline_${new Date().valueOf()}0`, // adding zero to the end for the proper ordering with copied items
            category_id: categoryId,
            additional_info: opening.additional_info || {},
            catalog_id: catalogId,
            appointment_id: appointmentId,
            notes: opening.notes || [],
            mulled: opening.mulled || false,
            name: opening.name,
            images,
            opening_configurations: [],
            temporary: false,
            quantity: opening.quantity,
            view_coordinates: [],
            view_photo_id: null,
            opening_inclusions: opening.opening_inclusions || [],
            opening_details: Object.keys(opening.answers).map((questionId) => ({
                question_id: questionId,
                answer: opening.answers[questionId] ? opening.answers[questionId].toString() : null
            }))
        };

        return from(this.prepareAndSaveOpening(openingData, appointmentId, geolocation));
    }

    updateOpening(
        categoryId: number,
        catalogId: number,
        appointmentId: number,
        opening: any,
        images: any = []
    ): Observable<any> {
        if (opening.hasOwnProperty('opening_details')) {
            delete opening['opening_details'];
        }

        if (opening.hasOwnProperty('image_details')) {
            delete opening['image_details'];
        }

        if (opening.hasOwnProperty('__typename')) {
            delete opening['__typename'];
        }

        if (opening.hasOwnProperty('catalog_id')) {
            delete opening['catalog_id'];
        }

        if (opening.hasOwnProperty('category_id')) {
            delete opening['category_id'];
        }

        const openingData = {
            created: opening.created,
            updated: '1',
            id: opening.id,
            category_id: categoryId,
            additional_info: opening.additional_info || {},
            catalog_id: catalogId,
            appointment_id: appointmentId,
            notes: opening.notes,
            mulled: opening.mulled,
            name: opening.name,
            images,
            opening_configurations: opening.opening_configurations,
            temporary: opening.temporary,
            quantity: opening.quantity,
            view_coordinates: opening.view_coordinates,
            view_photo_id: opening.view_photo_id,
            opening_inclusions: opening.opening_inclusions || [],
            opening_details: Object.keys(opening.answers).map((questionId) => ({
                question_id: questionId,
                answer: opening.answers[questionId] ? opening.answers[questionId].toString() : null
            }))
        };

        return from(this.prepareAndSaveOpening(openingData, appointmentId));
    }

    updateOpeningQuantity(appointmentId: number, openingId: any, quantity: number): Observable<any> {
        const user = this.authService.getUser();
        const promise = new Promise(async (resolve, reject) => {
            const appointment = await this.offlineStorageService.findOne(
                `
                    SELECT *
                    FROM ${OfflineTableName.Appointments}
                    WHERE id = '${appointmentId}'
                      AND seller_id = '${user.id}'
                      AND office_id = '${user.office.id}'
                `,
                OfflineTableName.Appointments
            );

            if (appointment) {
                const openingIndex = appointment.openings.findIndex((opening) => opening.id == openingId);

                if (isNumber(openingIndex)) {
                    appointment.openings[openingIndex].quantity = quantity;
                    appointment.openings[openingIndex].updated = '1';

                    await this.offlineStorageService.insertOne(OfflineTableName.Appointments, appointment);
                }
            }
        });

        return from(promise);
    }

    deleteOpening(openingId: any, appointmentId: any): Observable<any> {
        const user = this.authService.getUser();
        const promise = new Promise<void>(async (resolve, reject) => {
            const appointment = await this.offlineStorageService.findOne(
                `
                    SELECT *
                    FROM ${OfflineTableName.Appointments}
                    WHERE seller_id = '${user.id}'
                      AND id = '${appointmentId}'
                      AND office_id = '${user.office.id}'
                `,
                OfflineTableName.Appointments
            );

            if (!appointment) {
                reject(false);

                return;
            }

            let openingIndex;
            const opening = appointment.openings.find((item, index) => {
                if (item.id == openingId) {
                    openingIndex = index;

                    return true;
                }

                return false;
            });

            if (opening) {
                if (opening.created === '1') {
                    appointment.openings.splice(openingIndex, 1);
                } else {
                    opening.is_deleted = '1';
                    appointment.openings[openingIndex] = opening;
                }
                await this.offlineStorageService.insertOne(OfflineTableName.Appointments, appointment);
                resolve();

                return;
            }

            reject();
        });

        return from(promise);
    }

    copyOpening(openingId: any, copyCount: number): Observable<any> {
        const promise = new Promise<void>(async (resolve, reject) => {
            const appointment = await this.offlineStorageService.findOne(
                `
                    SELECT Appointments.id, openings
                    FROM ${OfflineTableName.Appointments}
                    WHERE openings LIKE '%\"id\":\"${openingId}\"%'
                `,
                OfflineTableName.Appointments
            );

            if (!appointment || !appointment.openings?.length) {
                reject('Opening not found');

                return;
            }

            const openingToCopy = appointment.openings.find((opening) => opening.id === openingId);
            const sameCatalogOpenings = appointment.openings.filter(
                (opening) =>
                    opening.catalog_id === openingToCopy.catalog_id &&
                    opening.id !== openingToCopy.id &&
                    opening.is_deleted !== '1'
            );
            const usedNames = sameCatalogOpenings.map((opening) => opening.name);
            const copiedOpeningsData = [];

            for (let i = 0; i < copyCount; i++) {
                const copyName = this.generateCopyName(`${openingToCopy.name} ${i + 2}`, usedNames, 0);

                // for correct ordering by created_at
                const createdAt = moment.utc().add(i, 'millisecond').format('YYYY-MM-DD HH:mm:ss');

                copiedOpeningsData.push({
                    id: 'offline_' + new Date().valueOf() + i,
                    name: copyName,
                    category_id: openingToCopy.category_id,
                    catalog_id: openingToCopy.catalog_id,
                    appointment_id: openingToCopy.appointment_id,
                    mulled: openingToCopy.mulled,
                    opening_details: openingToCopy.opening_details,
                    opening_inclusions: openingToCopy.opening_inclusions,
                    created: '1',
                    quantity: 1,
                    images: [],
                    image_details: [],
                    created_at: createdAt,
                    updated_at: createdAt
                });
            }

            const appointmentToUpdate = await this.offlineStorageService.findOne(
                `
                    SELECT *
                    FROM ${OfflineTableName.Appointments}
                    WHERE id = '${appointment.id}'
                `,
                OfflineTableName.Appointments
            );

            appointmentToUpdate.openings = appointmentToUpdate.openings.concat(copiedOpeningsData);

            await this.offlineStorageService.insertOne(OfflineTableName.Appointments, appointmentToUpdate);

            resolve();
        });

        return from(promise);
    }

    getProductCategories(appointmentId: number): Observable<any[]> {
        const user = this.authService.getUser();
        const categoriesPromise = this.offlineStorageService.read(
            `SELECT *
             FROM ${OfflineTableName.Categories}
             WHERE user_id = '${user.id}'
               AND office_id = '${user.office.id}'
             ORDER BY position DESC`,
            OfflineTableName.Categories
        );

        const appointmentPromise = this.offlineStorageService
            .findOne(
                `SELECT *
                 FROM ${OfflineTableName.Appointments}
                 WHERE id = '${appointmentId}'
                   AND seller_id = '${user.id}'
                   AND office_id = '${user.office.id}'`,
                OfflineTableName.Appointments
            )
            .then((appointment) => appointment.openings.filter((opening) => opening.is_deleted !== '1'));

        const promise = Promise.all([categoriesPromise, appointmentPromise]).then(([categories, openings]) => {
            const hideQuestions = ['location'];

            categories.forEach((category) => {
                category.category.openings = openings.filter((opening) => opening.category_id === category.category.id);
                category.category.questions = category.category.questions
                    .filter((question) => question.display_on_grid)
                    .map((question) => {
                        const questionNames = {
                            width: 'W',
                            height: 'H',
                            'product type': 'Style',
                            'door style': 'Style'
                        };

                        question.cssClass = question.title.toLowerCase().replace(' ', '');

                        if (
                            questionNames.hasOwnProperty(question.title.toLowerCase()) &&
                            category.category.category_type !== CategoryType.Siding
                        ) {
                            question.title = questionNames[question.title.toLowerCase()];
                        }

                        return question;
                    })
                    .filter(
                        (question) => !hideQuestions.includes(question.title.toLowerCase()) && isEmpty(question.show_if)
                    );
            });

            return categories;
        });

        return from(promise);
    }

    getCategoryWithOpenings(categoryId: number, appointmentId: number): Observable<any> {
        const user = this.authService.getUser();
        const categoryPromise = this.offlineStorageService.findOne(
            `SELECT *
             FROM ${OfflineTableName.Categories}
             WHERE user_id = '${user.id}'
               AND id = '${categoryId}'`,
            OfflineTableName.Categories
        );
        const appointmentPromise = this.offlineStorageService
            .findOne(
                `SELECT *
                 FROM ${OfflineTableName.Appointments}
                 WHERE id = '${appointmentId}'
                   AND seller_id = '${user.id}'
                   AND office_id = '${user.office.id}'`,
                OfflineTableName.Appointments
            )
            .then((appointment) => appointment.openings.filter((opening) => opening.is_deleted !== '1'));

        const promise = Promise.all([categoryPromise, appointmentPromise]).then((res: any[]) => {
            const category = res[0].category;

            category.openings = sortBy(
                res[1].filter((opening) => opening.category_id === category.id),
                (opening) => {
                    const isOpeningOnline = opening.id.indexOf('offline') === -1;
                    let openingId = parseInt(opening.id, 10);

                    if (!isOpeningOnline) {
                        openingId = 1 * opening.id.split('offline_')[1];
                    }

                    return openingId;
                }
            );
            category.questions = category.questions.filter((question) => question.display_on_grid);

            if (!category.questions) {
                category.questions = [];

                return category;
            }

            if (!category.openings) {
                category.openings = [];
            }

            if (!category.openings?.length) {
                forEach(category.questions, (question) => {
                    question.cssClass = question.title.toLowerCase().replace(' ', '');
                });
            }

            category.openings = category.openings.filter((opening) => !opening.temporary);

            forEach(category.openings, (opening) => {
                opening.initial_quantity = opening.quantity;
                const remappedDetails = [];

                forEach(category.questions, (question) => {
                    if (!isEmpty(question.show_if)) {
                        return;
                    }

                    question.cssClass = question.title.toLowerCase().replace(' ', '');
                    let answer = find(opening.opening_details, { question_id: question.id });

                    if (!answer) {
                        remappedDetails.push({
                            question_id: question.id,
                            answer: '',
                            cssClass: question.cssClass
                        });
                    } else {
                        const childQuestion = find(
                            category.questions,
                            (item) => item.show_if[question.hash] === answer.answer
                        );

                        if (childQuestion) {
                            answer = find(opening.opening_details, { question_id: childQuestion.id });
                        }

                        answer.cssClass = question.cssClass;
                        remappedDetails.push(answer);
                    }
                });
                opening.all_details = opening.opening_details;
                opening.opening_details = remappedDetails;
            });

            category.questions = category.questions.filter((question) => isEmpty(question.show_if));

            return category;
        });

        return from(promise);
    }

    getAppointmentProductCategories(appointmentId: number): Observable<any[]> {
        const user = this.authService.getUser();
        const categoriesPromise = this.offlineStorageService.read(
            `SELECT *
             FROM ${OfflineTableName.Categories}
             WHERE user_id = '${user.id}'
               AND office_id = '${user.office.id}'
             ORDER BY position DESC`,
            OfflineTableName.Categories
        );

        return from(categoriesPromise);
    }

    getOpeningConditions(categoryType: string): Observable<any[]> {
        const user = this.authService.getUser();
        const conditions = this.offlineStorageService
            .read(
                `SELECT *
                 FROM ${OfflineTableName.Categories}
                 WHERE user_id = '${user.id}'
                   AND office_id = '${user.office.id}'`,
                OfflineTableName.Categories
            )
            .then((categories: any[]) => {
                const category = categories.find((category) => category.category.category_type === categoryType);

                return category.opening_conditions.map((condition) => condition.name);
            });

        return from(conditions);
    }

    getCategoryWithQuestions(categoryId: number, appointmentId: number, openingCountBy?: string): Observable<any> {
        const user = this.authService.getUser();
        const categoryPromise = this.offlineStorageService.findOne(
            `SELECT *
             FROM ${OfflineTableName.Categories}
             WHERE user_id = '${user.id}'
               AND id = '${categoryId}'`,
            OfflineTableName.Categories
        );
        const appointmentPromise = this.offlineStorageService
            .findOne(
                `SELECT *
                 FROM ${OfflineTableName.Appointments}
                 WHERE id = '${appointmentId}'
                   AND seller_id = '${user.id}'
                   AND office_id = '${user.office.id}'`,
                OfflineTableName.Appointments
            )
            .then((appointment) => appointment.openings.filter((opening) => opening.is_deleted !== '1'));

        const promise = Promise.all([categoryPromise, appointmentPromise]).then((res: any[]) => {
            const category = res[0].category;

            category.openings = res[1].filter((opening) => opening.category_id === category.id);

            category.questions = orderBy(
                (category.questions || category.all_questions).map((question) => {
                    if (question.depends_on) {
                        question.depends_on = find(category.all_questions, { hash: question.depends_on }).id;
                    }

                    if (!isEmpty(question.show_if)) {
                        const parentQuestionHash = Object.keys(question.show_if)[0];
                        const parentQuestion = find(category.questions || category.all_questions, {
                            hash: parentQuestionHash
                        });

                        parentQuestion.child = question;
                    }

                    return question;
                }),
                'order'
            );

            category.openings_count = category.openings?.length || 0;

            category.questions = category.questions.filter((question) => isEmpty(question.show_if));

            const openingsDetailedCount = {};

            if (openingCountBy) {
                const openingCountByQuestion = category.questions.find((question) => question.hash === openingCountBy);

                if (openingCountByQuestion) {
                    const openingDetails = category.openings.filter(
                        ({ question_id }) => question_id === openingCountByQuestion.id
                    );

                    openingDetails.forEach((openingDetail) => {
                        if (openingsDetailedCount[openingDetail.answer]) {
                            openingsDetailedCount[openingDetail.answer].count++;
                            openingsDetailedCount[openingDetail.answer].ids.push(openingDetail.opening_id.toString());
                        } else {
                            openingsDetailedCount[openingDetail.answer] = {
                                count: 1,
                                ids: [openingDetail.opening_id.toString()]
                            };
                        }
                    });
                }
            }

            category.openings_detailed_count = openingsDetailedCount;

            return category;
        });

        return from(promise);
    }

    getCatalogImage(appointmentId: number, catalogId: number): Observable<any> {
        const user = this.authService.getUser();
        const categoryPromise = this.offlineStorageService.findOne(
            `SELECT *
             FROM ${OfflineTableName.Categories}
             WHERE id = '${catalogId}'
               AND office_id = '${user.office.id}'`,
            OfflineTableName.Categories
        );
        const appointmentPromise = this.offlineStorageService.findOne(
            `SELECT *
             FROM ${OfflineTableName.Appointments}
             WHERE id = '${appointmentId}'
               AND seller_id = '${user.id}'
               AND office_id = '${user.office.id}'`,
            OfflineTableName.Appointments
        );
        const promise = Promise.all([appointmentPromise, categoryPromise]).then(([appointment, category]) =>
            category.predefined_images.find(({ appointment_type }) => appointment_type === appointment.type)
        );

        return from(promise);
    }

    private async prepareAndSaveOpening(openingData, appointmentId, geolocation: any = {}): Promise<void> {
        const user = this.authService.getUser();

        let appointment = await this.offlineStorageService.findOne(
            `
                SELECT *
                FROM ${OfflineTableName.Appointments}
                WHERE id = '${appointmentId}'
                  AND seller_id = '${user.id}'
                  AND office_id = '${user.office.id}'
            `,
            OfflineTableName.Appointments
        );

        if (openingData.updated === '1') {
            const openingIndex = findIndex(appointment.openings, (opening) => opening.id === openingData.id);

            appointment.openings[openingIndex] = openingData;
        } else {
            appointment.openings.push(openingData);
        }

        // @ts-ignore
        appointment = await this.addViewHistory(
            SaleStepType.TAKE_OFF,
            appointmentId,
            {
                category_id: openingData.category_id,
                items: 1
            },
            geolocation,
            appointment
        );

        await this.offlineStorageService.insertOne(OfflineTableName.Appointments, appointment);
    }

    private generateCopyName(name, usedNames, iteration): string {
        if (iteration === 0 && usedNames.indexOf(name) === -1) {
            return name;
        }

        const iterationName = `${name} (${iteration + 1})`;

        if (usedNames.indexOf(iterationName) === -1) {
            return iterationName;
        }

        return this.generateCopyName(name, usedNames, iteration + 1);
    }
}

<ng-container *ngIf="isSigning && viewMode !== documentViewModes.SellerReview; else mainContent">
    <ng-container *ngIf="isShowResume; else actionButtons">
        <ion-button class="classic-width on-primary-btn-outlined"
                    fill="outline"
                    (click)="scrollToField()">
            Resume
        </ion-button>
    </ng-container>

    <ng-template #actionButtons>
        <ion-button class="classic-width on-primary-btn-outlined"
                    fill="outline"
                    [disabled]="isDisableActions"
                    (click)="skip()">
            Skip
        </ion-button>
        <ion-button class="classic-width on-primary-btn"
                    fill="outline"
                    [disabled]="isDisableActions"
                    (click)="confirm()">
            {{ notSignedFields[this.activeFieldIndex].isInitials ? 'Initial' : 'Sign' }}
        </ion-button>
    </ng-template>
</ng-container>

<ng-template #mainContent>
    <ion-text>
        Homeowner agrees to use electronic signatures and documents. <br/>
        If you do not wish to use electronic signatures or documents, please
        <a class="disclosure-link" (click)="showDisclosure()">See Details</a>
    </ion-text>

    <ion-button class="classic-width on-primary-btn"
                fill="outline"
                [disabled]="customerSignatureDone"
                (click)="showAgreement()">
        Agree & Sign
    </ion-button>
</ng-template>

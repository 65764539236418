<div class="modal-header">
  <ion-text class="title">{{ adder ? 'Edit' : 'New' }} Custom {{ adderNameSetting?.single }}</ion-text>
  <ion-button fill="outline" color="dark" (click)="closeModal()">
    <ion-icon slot="icon-only" name="close"></ion-icon>
  </ion-button>
</div>

<div class="modal-body">
  <ion-content>
    <form class="modal-body__form" [formGroup]="form" autocomplete="off">
      <div class="field-block">
        <ion-label class="required">Name</ion-label>
        <ion-input legacy="true" type="text"
                   formControlName="name"
                   required
                   placeholder="Insert your {{ adderNameSetting?.single }}’s name"
                   autocomplete="off"></ion-input>
        <div class="errors-wrapper" *ngIf="form.get('name').touched">
          <ion-text color="danger" class="error"
                    *ngFor="let error of getMessages(form.get('name').errors)">
            {{error}}
          </ion-text>
        </div>
      </div>

      <div class="field-block">
        <ion-label>Description</ion-label>
        <ion-textarea rows="3"
                      placeholder="Give more details about this {{ adderNameSetting?.single }} to be displayed on the contract"
                      formControlName="short_description"></ion-textarea>
        <div class="errors-wrapper" *ngIf="form.get('short_description').touched">
          <ion-text color="danger" class="error"
                    *ngFor="let error of getMessages(form.get('short_description').errors)">
            {{error}}
          </ion-text>
        </div>
      </div>

      <ion-row class="price-info">
        <ion-col size="7.5">
          <div class="field-block">
            <ion-label class="required">Price</ion-label>
            <div class="price-field">
              <ion-text>$</ion-text>
              <ion-input legacy="true" type="number"
                         inputmode="numeric"
                         pattern="^[0-9]*[.]?[0-9]{0,2}$"
                         min="0"
                         step="100"
                         placeholder="0"
                         formControlName="amount"
                         required
                         autocomplete="off"></ion-input>
            </div>
            <div class="errors-wrapper" *ngIf="form.get('amount').touched">
              <ion-text color="danger" class="error"
                        *ngFor="let error of getMessages(form.get('amount').errors)">
                {{error}}
              </ion-text>
            </div>
          </div>
        </ion-col>

        <ion-col size="4.5">
          <div class="field-block">
            <ion-label class="required">Quantity</ion-label>
            <div class="quantity">
              <ion-button class="icon-only"
                          fill="outline"
                          [disabled]="!form.get('quantity').value || form.get('quantity').value === 1"
                          (click)="count(-1)">
                <ion-icon name="remove-outline"></ion-icon>
              </ion-button>

              <ion-input legacy="true" type="number"
                         inputmode="numeric"
                         pattern="[0-9]*"
                         min="1"
                         step="1"
                         formControlName="quantity"
                         required
                         autocomplete="off"></ion-input>

              <ion-button class="icon-only"
                          fill="outline"
                          (click)="count(1)">
                <ion-icon name="add-outline"></ion-icon>
              </ion-button>
            </div>

            <div class="errors-wrapper" *ngIf="form.get('quantity').touched">
              <ion-text color="danger" class="error"
                        *ngFor="let error of getMessages(form.get('quantity').errors)">
                {{error}}
              </ion-text>
            </div>
          </div>
        </ion-col>
      </ion-row>

      <div class="field-block taxable">
        <ion-item lines="none">
          <ion-label class="ion-text-right">Taxable service</ion-label>
          <ion-checkbox legacy="true" mode="md"
                        slot="start"
                        formControlName="taxable"></ion-checkbox>
        </ion-item>
        <ion-text>
          This service will be subject to taxes and should be declared as such
        </ion-text>
      </div>

      <div *ngIf="isProjectAdder && countOfPackages > 1" class="field-block">
        <ion-label class="required">Select packages to add</ion-label>
        <ion-row class="packages"
                 formArrayName="packages">
          <ion-col size="6">
            <ion-item lines="none">
              <ion-label>All packages</ion-label>
              <ion-checkbox legacy="true" mode="md"
                            slot="start"
                            formControlName="0"></ion-checkbox>
            </ion-item>
          </ion-col>

          <ion-col *ngFor="let name of packageNames; let i = index" size="6">
            <ion-item lines="none">
              <ion-label>{{ name }}</ion-label>
              <ion-checkbox legacy="true" mode="md"
                            slot="start"
                            [formControlName]="i + 1"></ion-checkbox>
            </ion-item>
          </ion-col>
        </ion-row>

        <div class="errors-wrapper" *ngIf="packagesFormArray.touched">
          <ion-text color="danger" class="error"
                    *ngFor="let error of getMessages(packagesFormArray.errors)">
            {{error}}
          </ion-text>
        </div>
      </div>
    </form>
  </ion-content>
</div>

<div class="modal-footer">
  <ion-button fill="outline"
              (click)="closeModal()">
    Cancel
  </ion-button>
  <ion-button id="save-custom-adder-button"
              [disabled]="form.invalid"
              (click)="save()">
    Save
  </ion-button>
</div>

import { Component, Input } from '@angular/core';

import { PopoverController } from '@ionic/angular';

import { Adder } from '@shared/interfaces/adder';
import { Discount } from '@shared/interfaces/discount';
import { Promotion } from '@shared/interfaces/promotion';
import { FreeTextPopoverComponent } from '@shared/popovers/free-text-popover/free-text-popover.component';

@Component({
    selector: 'vendo-adders-details',
    templateUrl: './adders-details.component.html',
    styleUrls: ['./adders-details.component.scss']
})
export class AddersDetailsComponent {
    @Input() set adders(adders: Adder[]) {
        this.addersList = adders.filter((adder) => adder.visible);
        this.isExpanded = new Array(this.addersList.length).fill(false);
    }
    @Input() showPrice = false;
    @Input() discounts: Discount[];
    @Input() isPromotionApplied: boolean;
    @Input() promotions: Promotion[] = [];
    isExpanded: boolean[];
    addersList: Adder[] = [];

    constructor(private popoverController: PopoverController) {}

    async showFullDescriptionPopover(event: Event, description: string): Promise<any> {
        event.stopPropagation();
        const popover = await this.popoverController.create({
            component: FreeTextPopoverComponent,
            event,
            translucent: true,
            mode: 'md',
            cssClass: 'auto-width',
            componentProps: {
                text: description
            }
        });

        return await popover.present();
    }

    toggleDetail(index: number): void {
        this.isExpanded = this.isExpanded.map((isExpanded: boolean, i: number) =>
            i === index ? !this.isExpanded[i] : false
        );
    }
}

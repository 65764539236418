<div class="modal-header">
  <ion-text class="title">{{ integrationHash | replace: '_':'' | titlecase }} Authentication</ion-text>
  <ion-button fill="outline" color="dark" (click)="closeModal()">
    <ion-icon slot="icon-only" name="close"></ion-icon>
  </ion-button>
</div>

<div class="modal-body">
  <form [formGroup]="form">
    <ng-container *ngIf="isAllowManualCredentials">
      <div class="field-block">
        <ion-label class="required">User Name</ion-label>
        <ion-input legacy="true" type="text"
                   placeholder="User Name"
                   formControlName="username"
                   required
                   autocomplete="off"></ion-input>
        <div class="errors-wrapper" *ngIf="form?.get('username').touched">
          <ion-text color="danger" class="error"
                    *ngFor="let error of getMessages(form.get('username').errors)">
            {{ error }}
          </ion-text>
        </div>
      </div>

      <div class="field-block">
        <ion-label class="required">Password</ion-label>
        <ion-input legacy="true" type="password"
                   placeholder="Password"
                   formControlName="password"
                   required
                   autocomplete="off"></ion-input>
        <div class="errors-wrapper" *ngIf="form?.get('password').touched">
          <ion-text color="danger" class="error"
                    *ngFor="let error of getMessages(form.get('password').errors)">
            {{ error }}
          </ion-text>
        </div>
      </div>
    </ng-container>

    <ion-item lines="none" class="use-credential">
      <ion-checkbox legacy="true" slot="start"
                    mode="md"
                    formControlName="remember"></ion-checkbox>
      <ion-label>Remember Me</ion-label>
    </ion-item>

    <ion-item *ngIf="allowUseOfficeCredential" lines="none" class="use-credential">
      <ion-checkbox legacy="true" slot="start"
                    mode="md"
                    formControlName="use_office_credential"></ion-checkbox>
      <ion-label>Use Office Credential</ion-label>
    </ion-item>
  </form>
</div>

<div class="modal-footer">
  <ion-button class="classic-width" (click)="login()">Login</ion-button>
</div>

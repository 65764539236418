import { Injectable } from '@angular/core';

import { TagifySettings } from 'ngx-tagify';

import { EmailService } from '@core/services/email.service';

@Injectable({
    providedIn: 'root'
})
export class TagifyService {
    constructor(private emailService: EmailService) {}

    getEmailAutocompleteFieldSettings(emailsAutocompleteItems: string[]): TagifySettings {
        return {
            placeholder: '+ Email address',
            trim: true,
            editTags: false,
            maxTags: 5,
            whitelist: emailsAutocompleteItems,
            dropdown: {
                enabled: emailsAutocompleteItems.length ? 1 : 0,
                fuzzySearch: true,
                position: 'all'
            },
            delimiters: ',| ',
            callbacks: {
                add: (e: any) => this.validateEmailTag(e),
                'edit:updated': (e: any) => this.validateEmailTag(e)
            }
        };
    }

    private validateEmailTag(event: any): void {
        const isValidEmail = this.emailService.isEmailValid(event.detail.data.value);

        if (!isValidEmail) {
            event.detail.tagify.removeTags(event.detail.data.value);
        }
    }
}

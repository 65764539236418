import { gql } from 'apollo-angular';

const START_APPOINTMENT = gql`
    mutation StartAppointment($appointment_id: ID!) {
        startAppointment(appointment_id: $appointment_id) {
            id
            custom_tax
            actual_tax
            accessibility {
                change_tax
            }
        }
    }
`;

const REOPEN_APPOINTMENT = gql`
    mutation ReopenAppointment($appointment_id: ID!) {
        reopenAppointment(appointment_id: $appointment_id) {
            id
            schedule_time
            status
            notes
            result_type
            family_name
            reopens_count
            customer {
                first_name
                last_name
                phone_number
                email
            }
            house {
                address1
                address2
                city
                country
                state
                postal_code
                additional_info
                properties
            }
            crm_results
        }
    }
`;

const CHANGE_CUSTOM_TAX = gql`
    mutation ChangeCustomTax($id: ID!, $customTax: Float) {
        changeCustomTax(id: $id, custom_tax: $customTax)
    }
`;

const RESULT_APPOINTMENT = gql`
    mutation ResultAppointment(
        $appointment_id: ID!
        $result_type: String!
        $result_id: String
        $result_name: String!
        $result_reason_id: String
        $result_reason_name: String
        $send_email: Boolean!
        $email_message: String
        $emails_send_to: [String!]
        $notes: String
        $attachments: JSON
        $use_local_client: Boolean
        $opportunity_results: [OpportunityResultInput!]
    ) {
        resultAppointment(
            appointment_id: $appointment_id
            result_type: $result_type
            result_id: $result_id
            result_name: $result_name
            result_reason_id: $result_reason_id
            result_reason_name: $result_reason_name
            send_email: $send_email
            email_message: $email_message
            emails_send_to: $emails_send_to
            notes: $notes
            attachments: $attachments
            use_local_client: $use_local_client
            opportunity_results: $opportunity_results
        ) {
            id
            send_mail_details
        }
    }
`;

const SAVE_APPOINTMENT = gql`
    mutation CreateAppointment(
        $appointmentId: ID
        $schedule_time: String!
        $scheduled_to: String!
        $notes: String
        $products_interests: [String!]
        $customer: SaveAppointmentCustomer!
        $second_customer: SaveAppointmentCustomer
        $house: SaveAppointmentHouse!
        $second_house: SaveAppointmentHouse
    ) {
        mobileCreateAppointment(
            id: $appointmentId
            schedule_time: $schedule_time
            scheduled_to: $scheduled_to
            notes: $notes
            products_interests: $products_interests
            customer: $customer
            second_customer: $second_customer
            house: $house
            second_house: $second_house
        ) {
            id
        }
    }
`;

const SAVE_APPOINTMENT_DOCUMENT_DETAILS = gql`
    mutation SaveAppointmentDocumentDetails(
        $id: ID!
        $photos_glossary: SaveAppointmentPhotosGlossary
        $project_description: String
    ) {
        saveDocumentDetails(id: $id, photos_glossary: $photos_glossary, project_description: $project_description)
    }
`;

const PULL_QUOTES = gql`
    mutation PullQuotes($appointmentId: ID!, $appointmentType: AppointmentTypesEnum!, $quoteIds: [ID!]!) {
        pullQuotes(appointment_id: $appointmentId, appointment_type: $appointmentType, quote_ids: $quoteIds)
    }
`;

const OPEN_APPOINTMENT_DEEPLINK = gql`
    mutation openAppointmentDeeplink($id: ID!, $hash: DeeplinkHashesEnum!) {
        openAppointmentDeeplink(id: $id, hash: $hash)
    }
`;

const UPLOAD_IMAGE = gql`
    mutation UploadImage($file: Upload!) {
        uploadImage(file: $file)
    }
`;
const SAVE_APPOINTMENT_IMAGES = gql`
    mutation SaveAppointmentImages(
        $appointment_id: ID!
        $type: AppointmentImageTypesEnum!
        $images_data: [AppointmentImageDataInput!]!
    ) {
        saveAppointmentImages(appointment_id: $appointment_id, type: $type, images_data: $images_data)
    }
`;

const CREATE_APPOINTMENT_FILE = gql`
    mutation CreateAppointmentFile($appointment_id: ID!, $name: String!, $file: Upload!) {
        createAppointmentFile(appointment_id: $appointment_id, name: $name, file: $file) {
            id
            name
            url
            locked
            created_at
        }
    }
`;

const EDIT_APPOINTMENT_FILE = gql`
    mutation EditAppointmentFile($id: ID!, $appointment_id: ID!, $name: String!) {
        editAppointmentFile(id: $id, appointment_id: $appointment_id, name: $name) {
            id
            name
            url
            locked
            created_at
        }
    }
`;

const DELETE_APPOINTMENT_FILE = gql`
    mutation DeleteAppointmentFile($id: ID!, $appointment_id: ID!) {
        deleteAppointmentFile(id: $id, appointment_id: $appointment_id)
    }
`;

export {
    START_APPOINTMENT,
    RESULT_APPOINTMENT,
    SAVE_APPOINTMENT,
    CHANGE_CUSTOM_TAX,
    REOPEN_APPOINTMENT,
    SAVE_APPOINTMENT_DOCUMENT_DETAILS,
    PULL_QUOTES,
    OPEN_APPOINTMENT_DEEPLINK,
    UPLOAD_IMAGE,
    SAVE_APPOINTMENT_IMAGES,
    CREATE_APPOINTMENT_FILE,
    EDIT_APPOINTMENT_FILE,
    DELETE_APPOINTMENT_FILE
};

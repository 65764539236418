<div class="modal-header">
    <ion-text class="title">Photo Annotation</ion-text>
    <ion-button fill="outline" color="dark" (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
</div>
<div class="modal-body">
    <ion-content>
        <div class="drawing-container">
            <div class="canvas-container">
                <span class="relative"
                      draggable="false">
                  <canvas draggable="false" #inputCanvas></canvas>
                  <canvas draggable="false" #bgCanvas class="d-none"></canvas>
                </span>
            </div>
            <div class="tools-container">
                <div class="tool" *ngFor="let tool of tools" (click)="changeTool(tool)">
                    <i class="material-icons"
                       [ngClass]="{
                            selected: selectedTool.hash === tool.hash,
                            'rotate-180': tool.hash === 'erase'
                        }">
                        {{ tool.icon }}
                    </i>
                </div>
                <div class="tool">
                    <input class="color-picker"
                           readonly
                           [colorPicker]="color"
                           (colorPickerChange)="setColor($event)"
                           [style.background]="color"
                           cpPositionOffset="20%"
                           [cpDisableInput]="true"/>
                </div>
            </div>
        </div>
    </ion-content>
</div>
<div class="modal-footer">
    <ion-button class="classic-width outline" [disabled]="!actionsHistory.length" (click)="undo()">Undo</ion-button>
    <ion-button class="classic-width" (click)="save()">Done</ion-button>
</div>


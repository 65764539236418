import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

declare let moment: any;

@Pipe({
    name: 'localizedDate',
    pure: false
})
export class LocalizedDatePipe implements PipeTransform {
    constructor() {}

    transform(value: any, pattern = 'MM/dd/yyyy', isLocalDate = false): any {
        if (!value) {
            return value;
        }

        const datePipe: DatePipe = new DatePipe('en-US');
        const momentDate = isLocalDate ? moment(value) : moment.utc(value);

        return datePipe.transform(momentDate.local(), pattern);
    }
}

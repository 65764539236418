import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NavController } from '@ionic/angular';

import { AuthService } from '@core/services/auth.service';
import { Auth0Service } from '@core/services/auth0.service';
import { StorageService } from '@core/services/storage.service';

@Component({
    selector: 'vendo-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
    constructor(
        private authService: AuthService,
        private auth0Service: Auth0Service,
        private navController: NavController,
        private route: ActivatedRoute,
        private storageService: StorageService
    ) {}

    async ngOnInit(): Promise<void> {
        const isSSOLogin: boolean = (await this.storageService.get('isSSOLogin')) === 'true';

        if (isSSOLogin) {
            await this.storageService.remove('isSSOLogin');
            this.authService.logout();
            this.auth0Service.logout();
        } else {
            await this.authService.logout();
            const returnUrl = this.route.snapshot.queryParams.returnUrl;

            this.navController.navigateRoot('/login', {
                queryParams: {
                    ...(returnUrl && { returnUrl })
                }
            });
        }
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { TagifySettings } from 'ngx-tagify';

import { ModalController } from '@ionic/angular';

import { tap } from 'rxjs/operators';

import { EmailService } from '@core/services/email.service';
import { TagifyService } from '@core/services/helpers/tagify.service';
import { ToastService } from '@core/services/toast.service';
import { AppointmentWonStatuses } from '@shared/constants/appointment-statuses';
import { BaseModal } from '@shared/modals/base-modal';

@Component({
    selector: 'vendo-email-resend-modal',
    templateUrl: './email-resend-modal.component.html',
    styleUrls: ['./email-resend-modal.component.scss']
})
export class EmailResendModalComponent extends BaseModal implements OnInit {
    @Input() appointmentId: any;
    @Input() sentEmails: any;
    @Input() familyName: any;
    tagifySettings: TagifySettings;
    form: UntypedFormGroup;
    sentEmail: any;
    sentEmailDocuments: any[] = [];

    constructor(
        private formBuilder: UntypedFormBuilder,
        private emailService: EmailService,
        public modalController: ModalController,
        private toastService: ToastService,
        private tagifyService: TagifyService
    ) {
        super(modalController);
    }

    async ngOnInit(): Promise<void> {
        this.initForm();

        const sentEmails = this.sentEmails.reverse();

        this.sentEmail = sentEmails.find((email) => AppointmentWonStatuses.includes(email.result_type));

        this.tagifySettings = this.tagifyService.getEmailAutocompleteFieldSettings([]);

        if (this.sentEmail) {
            this.form.get('emails_send_to').setValue(
                this.sentEmail.recipients.map((value) => ({
                    value
                }))
            );
            this.form.get('email_message').setValue(this.sentEmail.message);

            this.sentEmailDocuments = ['email_attachments', 'package_documents', 'signed_documents'].reduce(
                (acc, item) => acc.concat(this.sentEmail[item] || []),
                []
            );
        }
    }

    async sendEmail(): Promise<void> {
        if (this.form.invalid || !this.sentEmail.id) {
            return;
        }

        const formData = this.form.getRawValue();

        formData.emails_send_to = formData.emails_send_to.map(({ value }) => value);

        this.emailService
            .resendEmail(this.appointmentId, this.sentEmail.id, formData.emails_send_to, formData.email_message, false)
            .pipe(
                tap(async (result) => {
                    if (result.send_mail_details) {
                        this.emailService.openClient(result.send_mail_details);
                    } else {
                        this.toastService.showMessage('Email has been successfully sent');
                        this.closeModal();
                    }
                })
            )
            .subscribe();
    }

    private initForm(): void {
        this.form = this.formBuilder.group({
            email_message: ['', Validators.required],
            emails_send_to: [[], Validators.required]
        });
    }
}

<div class="preview-block">
    <div class="header-section">
        <div class="opening-title">
            <ion-text class="opening-name"
                      [ngClass]="{'pointer': isShowPriceModifiers}"
                      (click)="openPriceModifiersModal()">
                {{ openingData.name }}
            </ion-text>
        </div>
        <div class="price ion-text-center"
             [class.deactivated]="priceConfigurations.display_status === priceConfigurationDisplayStatuses.DefaultOff"
             *ngIf="isFullStepsShowing
                     && priceConfigurations.display_status !== priceConfigurationDisplayStatuses.AlwaysOff
                     && priceConfigurations.display_status !== priceConfigurationDisplayStatuses.AlwaysOffInConfigurator
                     && isShowAfter"
             (press)="togglePricesVisibility()">
            {{ priceConfigurations.price | customCurrency: '1.0-0' }}
            <div class="price-desc">{{ openingData.quantity > 1 ? 'Each Item' : 'List Price' }}</div>
        </div>
    </div>

    <div class="preview-body">
        <div class="expanded-info">
            <ion-icon name="expand"
                      *ngIf="allowExpandedInfo && (isShowAfter ? configuratorData.CurrentDrawing : (openingData.images?.length || (takeoffDetails.length && takeoffDetailsTitle)))"
                      (click)="showExpandedInfo()"></ion-icon>
        </div>

        <div class="drawing">
            <ion-img class="drawing-image"
                     [src]="currentDrawing.image.url | wcpDrawingUrl:configuratorData.image_base_url"
                     *ngIf="isShowAfter && currentDrawing?.image?.url && currentDrawing.DrawingID !== 'view'">
            </ion-img>

            <vendo-masked-image class="mask-adjust"
                                *ngIf="isShowAfter && currentDrawing.DrawingID == 'view'"
                                [openings]="[openingData]"
                                [imageUrl]="openingData.view_photo?.img_url"
                                [disableEdit]="true"
                                [activeOpening]="openingData">
            </vendo-masked-image>

            <ng-container *ngIf="!isShowAfter">
	            <div class="swiper-container" #swiperContainer>
		            <div class="swiper-wrapper">
			            <div class="swiper-slide" *ngIf="takeoffDetails.length && takeoffDetailsTitle">
				            <ion-grid class="takeoff-details">
					            <ion-row>
						            <ion-col>
							            {{ takeoffDetailsTitle }}
						            </ion-col>
					            </ion-row>
					            <ion-row *ngFor="let item of takeoffDetails">
						            <ion-col size="6">
							            {{ item?.question?.title }}
						            </ion-col>
						            <ion-col size="6" class="text-ellipsis">
							            <span class="value">{{ item.answer }}</span>
						            </ion-col>
					            </ion-row>
				            </ion-grid>
			            </div>

			            <div class="swiper-slide" *ngFor="let image of openingData.images">
				            <ion-img class="slide-image"
					            [src]="image.url"
					            (click)="handleImages()"></ion-img>
			            </div>

			            <div class="swiper-slide" *ngIf="openingData.images?.length < maxImageCount">
				            <div class="add-photo" (click)="handleImages()">
					            <ion-icon name="add" color="medium"></ion-icon>
				            </div>
			            </div>
		            </div>
	            </div>

	            <div class="swiper-button-next"></div>
	            <div class="swiper-button-prev"></div>
	            <div class="swiper-pagination"></div>
            </ng-container>

            <div class="preferences" *ngIf="isShowAfter && !isHideDrawingSwitch && currentDrawing?.image?.url">
                <ion-label class="section-label">Configuration Views</ion-label>
                <div class="custom-segment">
                    <ion-label *ngFor="let drawing of configuratorData.drawings"
                               class="ion-text-center"
                               [ngClass]="{'active-segment': drawing.DrawingID === currentDrawing.DrawingID}"
                               (click)="setActiveDrawing(drawing)">
                        {{ drawing.DisplayName }}
                    </ion-label>
                </div>
            </div>
        </div>
    </div>

    <div class="preview-footer">
        <ion-label class="section-label">Configuration State</ion-label>
        <ion-item class="ion-text-center" lines="none">
            <ion-label class="left-label" [attr.data-active]="!isShowAfter">Before</ion-label>
            <ion-toggle mode="md"
                        [disabled]="isDisableShowAfter"
                        [ngModel]="isShowAfter"
                        (ngModelChange)="toggleShowMode()"></ion-toggle>
            <ion-label class="right-label" [attr.data-active]="isShowAfter">After</ion-label>
        </ion-item>
    </div>
</div>

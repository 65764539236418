import { gql } from 'apollo-angular';

const PICK_QUOTE_DOCUMENTS = gql`
    mutation PickQuoteDocuments($quoteId: ID!, $documentTemplateIds: [ID!]!) {
        pickQuoteDocuments(quote_id: $quoteId, document_template_ids: $documentTemplateIds) {
            id
            status
            package_name
            quote_documents {
                id
                status
                template_id
                document_template {
                    name
                    is_sign_able
                }
            }
        }
    }
`;

const SET_DOCUMENT_SIGNERS = gql`
    mutation SetDocumentSigners($appointmentId: ID!, $customerIds: [ID!]!) {
        setQuoteDocumentsSigners(appointment_id: $appointmentId, signer_ids: $customerIds)
    }
`;

const PICK_CUSTOMER_SIGNATURE_TYPE = gql`
    mutation PickSignatureType($appointmentId: ID!, $signingOn: AppointmentSigningOnEnum!, $customerId: ID) {
        pickCustomerSignatureType(appointment_id: $appointmentId, signing_on: $signingOn, customer_id: $customerId)
    }
`;

const STORE_QUOTE_DOCUMENT = gql`
    mutation StoreQuoteDocument(
        $id: ID!
        $instructions: [DocumentInstruction!]!
        $updatedAt: String!
        $leftPlacesToSign: Int!
    ) {
        storeMyQuoteDocument(
            id: $id
            instructions: $instructions
            updated_at: $updatedAt
            left_places_to_sign: $leftPlacesToSign
        ) {
            id
            status
        }
    }
`;

const COMPLETE_QUOTE_DOCUMENT = gql`
    mutation CompleteQuoteDocument(
        $id: ID!
        $customerId: ID!
        $instructions: [DocumentInstruction!]!
        $updatedAt: String!
        $leftPlacesToSign: Int!
    ) {
        customerSignQuoteDocument(
            id: $id
            customer_id: $customerId
            instructions: $instructions
            updated_at: $updatedAt
            left_places_to_sign: $leftPlacesToSign
        ) {
            id
            status
        }
    }
`;

const UPLOAD_SELLER_DOCUMENT_IMAGE = gql`
    mutation UploadUserImage($type: UserImageTypeEnum!, $file: Upload!) {
        uploadUserImage(type: $type, file: $file)
    }
`;

const UPLOAD_CUSTOMER_DOCUMENT_IMAGE = gql`
    mutation UploadCustomerImage($customerId: ID!, $type: CustomerImageTypeEnum!, $file: Upload!) {
        uploadCustomerImage(customer_id: $customerId, type: $type, file: $file)
    }
`;

export {
    PICK_QUOTE_DOCUMENTS,
    SET_DOCUMENT_SIGNERS,
    STORE_QUOTE_DOCUMENT,
    PICK_CUSTOMER_SIGNATURE_TYPE,
    COMPLETE_QUOTE_DOCUMENT,
    UPLOAD_SELLER_DOCUMENT_IMAGE,
    UPLOAD_CUSTOMER_DOCUMENT_IMAGE
};

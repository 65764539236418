import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Apollo } from 'apollo-angular';

import { ModalController, Platform } from '@ionic/angular';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import get from 'lodash/get';

import { isActiveOfflineMode } from '@core/functions/is-active-offline-mode';
import { GetAppleStoreLastVersion, GetGooglePlayLastVersion } from '@core/queries/app-version.queries';
import { DeviceHelperService } from '@core/services/device-helper.service';
import { StorageService } from '@core/services/storage.service';
import { ApplicationVersion } from '@shared/interfaces/application-version';

import versions from '../../../environments/app-versions.js';
import { environment } from '../../../environments/environment';
import { ConfirmationModalComponent } from '../../components/confirmation-modal/confirmation-modal.component';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    private readonly storageVersionControlKey = 'available-update-version';
    private wasInit = false;
    // next 4 variables related only to native application on IOS/Android
    private appName: string;
    private packageName: string;
    private versionNumber: string;
    private apiVersion: string;
    private is = {
        stage: environment.stage,
        production: environment.production,
        marvin: environment.marvin || location.host.indexOf('infinity') !== -1,
        andersen: environment.andersen || location.host.indexOf('andersen') !== -1,
        genesis: environment.genesis || location.host.indexOf('genesis') !== -1,
        revival: environment.revival
    };
    private versionsNotified: { [key: string]: { [key: string]: boolean } } = {};
    private availableUpdateEvent: BehaviorSubject<UpdateAppInfo | null> = new BehaviorSubject<UpdateAppInfo | null>(
        null
    );
    private logoUrl: string;

    constructor(
        private apollo: Apollo,
        private appVersion: AppVersion,
        private browser: InAppBrowser,
        private deviceHelperService: DeviceHelperService,
        private modalController: ModalController,
        private platform: Platform,
        private storageService: StorageService
    ) {}

    async getApplicationVersion(): Promise<ApplicationVersion> {
        const versionsObject: ApplicationVersion = {
            actualVersion: null,
            apiVersion: await this.getApiVersion(),
            installedVersion: null
        };

        if (this.deviceHelperService.isWeb) {
            versionsObject.installedVersion = versions.appVersion;

            return versionsObject;
        }

        versionsObject.installedVersion = await this.getVersionNumber();

        if (versionsObject.installedVersion !== versions.appVersion) {
            versionsObject.actualVersion = versions.appVersion;
        }

        return versionsObject;
    }

    async getApiVersion(): Promise<string> {
        if (!this.apiVersion) {
            this.apiVersion = await this.storageService.get('apiVersion');
        }

        return this.apiVersion;
    }

    async setApiVersion(version: string): Promise<void> {
        await this.storageService.set('apiVersion', version);
        this.apiVersion = version;
    }

    /**
     * Function initialize all services and set data
     *
     * @private
     */
    private async initVersionInfo(): Promise<void> {
        if (this.wasInit) {
            return;
        }

        this.wasInit = true;

        try {
            await this.platform.ready();

            const [versionsNotified, appName, packageName, versionNumber] = await Promise.all([
                this.storageService.get(this.storageVersionControlKey),
                this.appVersion.getAppName(),
                this.appVersion.getPackageName(),
                this.appVersion.getVersionNumber()
            ]);

            this.versionsNotified = versionsNotified || {};
            this.appName = appName;
            this.packageName = packageName;
            this.versionNumber = versionNumber;

            // https://ionicframework.com/docs/angular/platform#resume
            this.platform.resume.subscribe(async () => await this.isAvailableUpdate());
        } catch (error) {
            console.error('Error during initialization:', error);
        }
    }

    isAvailableUpdateObservable(): Observable<UpdateAppInfo | null> {
        return this.availableUpdateEvent.asObservable().pipe(
            filter((result: UpdateAppInfo | null) => !!result),
            tap(({ isAvailable, url, isRequiredUpdate }) =>
                this.openApplicationUpdateModal(isAvailable, url, isRequiredUpdate)
            )
        );
    }

    /**
     * Check exists new available update or not
     *
     * When result equal Promise<UpdateAppInfo> it means version is last or not
     * When result equal Promise<null> it means notification had been shown before
     *
     * @return Promise<UpdateAppInfo|null>
     */
    async isAvailableUpdate(): Promise<void> {
        if (isActiveOfflineMode()) {
            return;
        }

        await this.initVersionInfo();

        if (this.versionNumber && !this.deviceHelperService.isWeb) {
            if (this.deviceHelperService.isIOSPlatform) {
                await this.checkForIos();
            }

            if (this.deviceHelperService.isAndroidPlatform) {
                await this.checkForAndroid();
            }

            return;
        }

        this.nextEventPush(null, true);
    }

    async checkForIos(): Promise<void> {
        const {
            release_version: releaseVersion,
            apple_store_url: appleStoreUrl,
            testflight_url: testflightUrl
        } = await this.apollo
            .query({
                query: GetAppleStoreLastVersion,
                variables: {
                    envVersion: this.getEnvVersion()
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.appleStoreLastVersion))
            .toPromise();

        const wasNotified = get(this.versionsNotified, [this.versionNumber, releaseVersion], false);
        const isAvailableUpdate: boolean = releaseVersion && this.versionNumber < releaseVersion && !wasNotified;

        if (isAvailableUpdate) {
            await this.updateVersionsNotified(this.versionNumber, releaseVersion);
        }

        const isRequiredUpdate = this.isRequiredUpdate(this.versionNumber, releaseVersion);

        this.nextEventPush({
            isAvailable:
                isRequiredUpdate || (isAvailableUpdate && this.versionsNotified[this.versionNumber][releaseVersion]),
            url: this.is.production ? appleStoreUrl : testflightUrl,
            isRequiredUpdate
        });
    }

    async checkForAndroid(): Promise<void> {
        const { release_version: releaseVersion, google_play_url: googlePlayUrl } = await this.apollo
            .query({
                query: GetGooglePlayLastVersion,
                variables: {
                    envVersion: this.getEnvVersion()
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.googlePlayStoreLastVersion))
            .toPromise();

        if (!releaseVersion) {
            return;
        }

        const wasNotified = get(this.versionsNotified, [this.versionNumber, releaseVersion], false);
        const isAvailableUpdate: boolean = this.versionNumber < releaseVersion && !wasNotified;

        if (isAvailableUpdate) {
            await this.updateVersionsNotified(this.versionNumber, releaseVersion);
        }

        const isRequiredUpdate = this.isRequiredUpdate(this.versionNumber, releaseVersion);

        this.nextEventPush({
            isAvailable:
                isRequiredUpdate || (isAvailableUpdate && this.versionsNotified[this.versionNumber][releaseVersion]),
            url: googlePlayUrl,
            isRequiredUpdate
        });
    }

    /**
     * Get Application Name
     *
     * @return {Promise<string>}
     */
    async getAppName(): Promise<string> {
        await this.initVersionInfo();

        return this.appName;
    }

    /**
     * Get Package Name of application
     *
     * @return {Promise<string>}
     */
    async getPackageName(): Promise<string> {
        await this.initVersionInfo();

        return this.packageName;
    }

    /**
     * Get current version number of application
     * example: 0.1.1
     *
     * @return {Promise<string>}
     */
    async getVersionNumber(): Promise<string> {
        await this.initVersionInfo();

        return this.versionNumber;
    }

    /**
     * Check if current build
     * was done for Production server or not
     *
     * @return {boolean}
     */
    checkIsProduction(): boolean {
        return this.is.production;
    }

    /**
     * Check if current build
     * was done for Marvin or not
     *
     * @return {boolean}
     */
    checkIsMarvin(): boolean {
        return this.is.marvin;
    }

    /**
     * Check if current build
     * was done for Andersen or not
     *
     * @return {boolean}
     */
    checkIsAndersen(): boolean {
        return this.is.andersen;
    }

    /**
     * Check if current build
     * was done for LEI Genesis or not
     *
     * @return {boolean}
     */
    checkIsGenesis(): boolean {
        return this.is.genesis;
    }

    /**
     * Get One Of Environment Versions
     *
     * @return {"stage"|"prod"|"marvin_prod"|"andersen_prod"|"genesis_prod"|"revival_prod"}
     */
    getEnvVersion(): string {
        if (!this.is.production) {
            return 'stage';
        }

        if (this.is.andersen) {
            return 'andersen_prod';
        }

        if (this.is.marvin) {
            return 'marvin_prod';
        }

        if (this.is.genesis) {
            return 'genesis_prod';
        }

        if (this.is.revival) {
            return 'revival_prod';
        }

        return 'prod';
    }

    setLogoUrl(logoUrl: string): void {
        this.logoUrl = logoUrl;
    }

    async getLogoUrl(relativePrefix?: string): Promise<string> {
        if (this.logoUrl) {
            return this.logoUrl;
        }

        if (this.is.andersen) {
            return `${relativePrefix || ''}../../assets/img/andersen-logo.png`;
        }

        if (this.is.marvin) {
            return `${relativePrefix || ''}../../assets/img/marvin-logo.png`;
        }

        if (this.is.genesis) {
            return `${relativePrefix || ''}../../assets/img/genesis-logo.png`;
        }

        if (this.is.revival) {
            return `${relativePrefix || ''}../../assets/img/revival-logo.png`;
        }

        return (await this.storageService.get('logo_url')) || `${relativePrefix || ''}../../assets/img/Vendo-Logo.png`;
    }

    getTitle(): string {
        if (this.is.andersen) {
            return 'Andersen In Home Selling Tool';
        }

        if (this.is.marvin) {
            return 'Infinity Designer';
        }

        if (this.is.genesis) {
            return 'LEI Genesis';
        }

        return 'Paradigm Vendo';
    }

    async openApplicationUpdateModal(
        isAvailableNewVersion: boolean,
        url: string,
        isRequiredUpdate: boolean
    ): Promise<void> {
        if (!isAvailableNewVersion) {
            return;
        }

        const modal = await this.modalController.create({
            backdropDismiss: !isRequiredUpdate,
            component: ConfirmationModalComponent,
            componentProps: {
                confirmButtonName: 'Update Now',
                cancelButtonName: 'Okay',
                showCancelButton: !isRequiredUpdate,
                headerText: 'New update available'
            },
            cssClass: 'available-update-modal',
            showBackdrop: true
        });

        await modal.present();

        const { data } = await modal.onWillDismiss();

        if (!isRequiredUpdate) {
            this.nextEventPush(null, true);
        }

        if (data) {
            this.browser.create(url, '_system');
        }
    }

    private nextEventPush(data: UpdateAppInfo | null, done = false): void {
        if (!this.availableUpdateEvent.closed) {
            this.availableUpdateEvent.next(data);

            if (done) {
                this.availableUpdateEvent.complete();
            }
        }
    }

    private async updateVersionsNotified(currentVersion: string, nextVersion: string): Promise<void> {
        this.versionsNotified[currentVersion] = Object.assign(
            {
                [nextVersion]: true
            },
            this.versionsNotified[currentVersion]
        );

        await this.storageService.set(this.storageVersionControlKey, this.versionsNotified);
    }

    private isRequiredUpdate(currentVersion: string, nextVersion: string): boolean {
        if (!currentVersion || !nextVersion) {
            return false;
        }

        const currentVersionValue: number = this.transformVersionToNumber(
            currentVersion !== versions.appVersion ? versions.appVersion : currentVersion
        );
        const nextVersionValue: number = this.transformVersionToNumber(nextVersion);
        // update is required if the current version is 2 or more behind the new release
        const versionDiffValue = this.transformVersionToNumber('0.2.0');

        return nextVersionValue - currentVersionValue >= versionDiffValue;
    }

    /**
     * Transform string version string to number
     *
     * Example: args {ignorePatchVersion: true}, then "1.11.101" = 1 * 12000 + 11 * 1000 = 23000
     * Example: args {ignorePatchVersion: false}, then "1.11.101" = 1 * 12000 + 11 * 1000 + 101 = 23101
     *
     * @param {string} version
     * @param {boolean} ignorePatchVersion
     * @private
     */
    private transformVersionToNumber(version: string, ignorePatchVersion = true): number {
        const [majorVersion, minorVersion, patchVersion]: number[] = version.split('.').map(Number);

        return majorVersion * 12000 + minorVersion * 1000 + (ignorePatchVersion ? 0 : patchVersion);
    }
}

interface UpdateAppInfo {
    isAvailable: boolean;
    isRequiredUpdate: boolean;
    url: string;
}

import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { SQLite, SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { Apollo } from 'apollo-angular';

import { ModalController, Platform } from '@ionic/angular';

import { BehaviorSubject, firstValueFrom, lastValueFrom } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

import { RollbarErrorHandler } from '@core/handlers/rollbar-error-handler';
import { FilesService } from '@core/services/files.service';
import { UPLOAD_APPOINTMENTS } from '@core/services/offline/mutations/offline.mutations';
import { OFFLINE_TABLES, OFFLINE_DB_VERSION } from '@core/services/offline/offline-tables/tables';
import { StorageService } from '@core/services/storage.service';
import { OfflineTableConfig } from '@shared/enums/offline-table-config';
import { OfflineTableName } from '@shared/enums/offline-table-name';
import { LoadingModalComponent } from '@shared/modals/loading-modal/loading-modal.component';

import { environment } from '../../../../environments/environment';
import { UPLOAD_IMAGE } from '../../../main/appointments/mutations/appointment.mutations';

@Injectable({
    providedIn: 'root'
})
export class OfflineStorageService {
    hasUnsyncData = false;
    isDesktop = (window.location.host && window.location.host.includes('paradigmvendo')) || environment.local;
    private db: SQLiteObject;
    private setupPromise: any;
    private isInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private apollo: Apollo,
        private fileService: FilesService,
        private modalController: ModalController,
        private platform: Platform,
        private rollbarErrorHandler: RollbarErrorHandler,
        private storageService: StorageService,
        private sqlLite: SQLite
    ) {
        this.platform.ready().then(() => {
            if (this.isDesktop) {
                return;
            }
            this.setupPromise = this.makeQueryablePromise(
                this.sqlLite
                    .create({
                        name: 'offline_db',
                        location: 'default'
                    })
                    .then(async (db: SQLiteObject) => {
                        this.db = db;
                        const currentOfflineVersion = await this.storageService.get('offlineTablesVersion');

                        if (!currentOfflineVersion) {
                            await this.storageService.set('offlineTablesVersion', OFFLINE_DB_VERSION);
                        } else if (currentOfflineVersion !== OFFLINE_DB_VERSION) {
                            await this.dropTables();
                            await this.storageService.set('offlineTablesVersion', OFFLINE_DB_VERSION);
                        }

                        await this.createTables();

                        this.isInitialized$.next(true);
                    })
            );
        });
    }

    async updatedEntities(user): Promise<any> {
        const updatedAppointments = await this.read(
            `
                SELECT *
                FROM ${OfflineTableName.Appointments}
                WHERE seller_id = '${user.id}'
                  AND office_id = '${user.office.id}'
                  AND (updated = 1
                    OR created = 1)
            `,
            OfflineTableName.Appointments
        );
        const updatedOpeningAppointments = await this.read(
            `
                SELECT DISTINCT Appointments.id, openings
                FROM ${OfflineTableName.Appointments}
                WHERE seller_id = '${user.id}'
                  AND office_id = '${user.office.id}'
                  AND (openings LIKE '%\"created\":\"1\"%'
                    OR openings LIKE '%\"is_deleted\":\"1\"%'
                    OR openings LIKE '%\"updated\":\"1\"%')
            `,
            OfflineTableName.Appointments
        );

        const updatedOpenings = updatedOpeningAppointments.reduce((acc, appointment) => {
            return acc.concat(
                appointment.openings.filter(
                    (opening) => opening.updated === '1' && opening.created !== '1' && opening.is_deleted !== '1'
                )
            );
        }, []);

        const createdOpenings = updatedOpeningAppointments.reduce((acc, appointment) => {
            return acc.concat(appointment.openings.filter((opening) => opening.created === '1'));
        }, []);

        const deletedOpenings = updatedOpeningAppointments.reduce((acc, appointment) => {
            return acc.concat(appointment.openings.filter((opening) => opening.is_deleted === '1'));
        }, []);

        updatedAppointments.forEach((appointment) => {
            delete appointment.openings;
        });

        return {
            updatedAppointments,
            updatedOpeningAppointments,
            updatedOpenings,
            createdOpenings,
            deletedOpenings
        };
    }

    async syncData(updatedEntities: any): Promise<boolean> {
        const modal = await this.modalController.create({
            component: LoadingModalComponent,
            showBackdrop: true,
            backdropDismiss: false,
            cssClass: 'loading-modal',
            componentProps: {
                title: 'Syncing items',
                message: 'Your appointments are being updated with the most recent changes.'
            }
        });

        await modal.present();

        const allModifiedOpenings = updatedEntities.updatedOpenings.concat(updatedEntities.createdOpenings);
        const filesToDelete = [];

        for (const openingIndex in allModifiedOpenings) {
            const opening: any = allModifiedOpenings[openingIndex];

            if (opening.images?.length) {
                for (const imageIndex in opening.images) {
                    const imageSrc = opening.images[imageIndex];

                    if (imageSrc.__typename) {
                        delete opening.images[imageIndex].__typename;
                    }

                    if (imageSrc.appointment_type) {
                        delete opening.images[imageIndex].appointment_type;
                    }

                    if (imageSrc.opening_id) {
                        delete opening.images[imageIndex].opening_id;
                    }

                    if (!imageSrc.url || imageSrc.url.includes('https://')) {
                        continue;
                    }

                    const indexOfFilePath: number = imageSrc.url.indexOf('openingimages');

                    if (indexOfFilePath === -1) {
                        continue;
                    }

                    const localUrl: string = imageSrc.url.slice(indexOfFilePath);
                    const file: Blob = await this.fileService.getBlobFile(localUrl);
                    let tempUrl: string;

                    try {
                        tempUrl = await lastValueFrom(
                            this.apollo
                                .mutate({
                                    mutation: UPLOAD_IMAGE,
                                    variables: {
                                        file
                                    },
                                    context: {
                                        useMultipart: true,
                                        extensions: {
                                            background: true
                                        }
                                    }
                                })
                                .pipe(map((res: ApolloQueryResult<any>) => res.data.uploadImage))
                        );
                    } catch (e) {}

                    if (tempUrl) {
                        imageSrc.url = imageSrc.original_url = tempUrl;
                        filesToDelete.push(localUrl);
                    }
                }
            }
        }

        const syncData = {
            updated: {
                appointments: updatedEntities.updatedAppointments,
                openings: updatedEntities.updatedOpenings
            },
            created: {
                openings: updatedEntities.createdOpenings
            },
            deleted: {
                openings: updatedEntities.deletedOpenings.map((opening) => opening.id)
            }
        };

        let result;

        try {
            result = await lastValueFrom(
                this.apollo
                    .mutate({
                        mutation: UPLOAD_APPOINTMENTS,
                        variables: syncData,
                        context: {
                            extensions: {
                                background: true
                            }
                        }
                    })
                    .pipe(map((res: ApolloQueryResult<any>) => res?.data?.synchronizeAppointments))
            );

            if (result?.status) {
                this.rollbarErrorHandler.handleInfo(
                    `SynchronizeAppointments - ${result.status} - ${JSON.stringify(result)}`
                );
            }
        } catch (e) {
            this.rollbarErrorHandler.handleInfo(`Failed SynchronizeAppointments - ${JSON.stringify(e)}`);
        }

        if (filesToDelete?.length) {
            filesToDelete.forEach((file) => {
                this.fileService.deleteFile(file).catch();
            });
        }

        modal.dismiss();

        return (
            !!updatedEntities.updatedAppointments?.length ||
            !!updatedEntities.updatedOpenings?.length ||
            !!updatedEntities.createdOpenings?.length ||
            !!updatedEntities.deletedOpenings?.length
        );
    }

    async insertBatch(tableName: OfflineTableName, values: any[]): Promise<void> {
        await this.waitDBInitialization();

        const table: OfflineTableConfig = this.getOfflineTableConfig(tableName);

        if (!table) {
            return;
        }

        const query = `INSERT
        OR REPLACE INTO
        ${tableName}
        (
        ${table.fields.join(', ')}
        )
        VALUES
        (
        ${table.fields.map(() => '?').join(', ')}
        )`;
        const queryParams = values.map((value) =>
            table.fields.map((field: string) =>
                isArray(value[field]) || isObject(value[field]) ? JSON.stringify(value[field]) : (value[field] ?? null)
            )
        );

        try {
            await this.db.sqlBatch(queryParams.map((params) => [query, params]));
        } catch (e) {
            console.error('insertBatch SQL:', tableName, e);
        }
    }

    async insertOne(tableName: OfflineTableName, value: any): Promise<void> {
        await this.waitDBInitialization();

        const table: OfflineTableConfig = this.getOfflineTableConfig(tableName);

        if (!table) {
            return;
        }

        const query = `INSERT
        OR REPLACE INTO
        ${tableName}
        (
        ${table.fields.join(', ')}
        )
        VALUES
        (
        ${table.fields.map(() => '?').join(', ')}
        )`;
        const params = table.fields.map((field: string) =>
            isArray(value[field]) || isObject(value[field]) ? JSON.stringify(value[field]) : value[field]
        );

        try {
            await this.db.executeSql(query, params);
        } catch (e) {
            console.error('insertOne SQL:', tableName, e);
        }
    }

    async read(query: string, tableName: OfflineTableName): Promise<any[]> {
        await this.waitDBInitialization();

        const table: OfflineTableConfig = this.getOfflineTableConfig(tableName);

        if (!table) {
            return;
        }

        return this.db
            .executeSql(query, [])
            .then((res) =>
                Array.from({ length: res.rows.length }, (_, i) =>
                    this.convertJsonFields(table.columns_to_convert, res.rows.item(i))
                ).reverse()
            )
            .catch((e) => {
                console.error('read SQL:', tableName, e, query);
                return [];
            });
    }

    async findOne(query: string, tableName: OfflineTableName): Promise<any> {
        await this.waitDBInitialization();

        const table: OfflineTableConfig = this.getOfflineTableConfig(tableName);

        if (!table) {
            return;
        }

        return this.db
            .executeSql(query, [])
            .then((res) => this.convertJsonFields(table.columns_to_convert, res.rows.item(0)))
            .catch((e) => {
                console.error('findOne SQL:', tableName, e, query);
                return {};
            });
    }

    async deleteRecords(query: string): Promise<void> {
        await this.waitDBInitialization();

        try {
            await this.db.executeSql(query, []);
        } catch (e) {
            console.error('deleteRecords SQL:', e, query);
        }
    }

    private async dropTables(): Promise<void> {
        const queries: string[] = OFFLINE_TABLES.map(
            ({ table_name }: OfflineTableConfig) => `DROP TABLE IF EXISTS ${table_name}`
        );

        await this.db.sqlBatch(queries);
    }

    private async createTables(): Promise<void> {
        const queries: string[] = OFFLINE_TABLES.map((table: OfflineTableConfig) => {
            const fields: string[] = table.fields.map((field: string) => {
                let fieldData: string = field;

                if (table.column_types[field]) {
                    fieldData += ` ${table.column_types[field]}`;
                }

                if (table.primary_key === field) {
                    fieldData = `${fieldData} PRIMARY KEY`;
                }

                return fieldData;
            });

            return `CREATE TABLE IF NOT EXISTS ${table.table_name}
                    (
                        ${fields.join(', ')}
                    )`;
        });

        try {
            await this.db.sqlBatch(queries);
        } catch (e) {
            console.error('Offline tables do not created', e);
        }
    }

    private convertJsonFields(columnToConvert: string[], data: any): any {
        for (const key in data) {
            try {
                data[key] = columnToConvert.includes(key) ? JSON.parse(data[key]) : data[key];
            } catch (e) {}
        }

        return data;
    }

    private makeQueryablePromise(promise): any {
        // Don't modify any promise that has been already modified.
        if (promise.isFulfilled && promise.isFulfilled()) {
            return promise;
        }

        // Set initial state
        let isPending = true;
        let isRejected = false;
        let isFulfilled = false;

        // Observe the promise, saving the fulfillment in a closure scope.
        const result = promise.then(
            function (v) {
                isFulfilled = true;
                isPending = false;

                return v;
            },
            function (e) {
                isRejected = true;
                isPending = false;
                throw e;
            }
        );

        result.isFulfilled = function () {
            return isFulfilled;
        };
        result.isPending = function () {
            return isPending;
        };
        result.isRejected = function () {
            return isRejected;
        };

        return result;
    }

    private async waitDBInitialization(): Promise<void> {
        await firstValueFrom(this.isInitialized$.asObservable().pipe(filter((val: boolean) => val)));

        if (!this.setupPromise.isFulfilled()) {
            await this.setupPromise;
        }
    }

    private getOfflineTableConfig(tableName: OfflineTableName): OfflineTableConfig {
        return OFFLINE_TABLES.find(({ table_name }: OfflineTableConfig) => table_name === tableName);
    }
}

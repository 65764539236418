import { Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { BaseModal } from '@shared/modals/base-modal';

@Component({
    selector: 'vendo-loading-modal',
    templateUrl: './loading-modal.component.html',
    styleUrls: ['./loading-modal.component.scss']
})
export class LoadingModalComponent extends BaseModal {
    @Input() title = 'Loading...';
    @Input() message = '';

    constructor(public modalController: ModalController) {
        super(modalController);
    }
}

import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
    InfiniteScrollCustomEvent,
    IonContent,
    IonInfiniteScroll,
    NavController,
    PopoverController
} from '@ionic/angular';

import { EMPTY, Subscription } from 'rxjs';
import { expand, finalize, reduce, tap } from 'rxjs/operators';

import filter from 'lodash/filter';

import { DictionaryService } from '@core/services/dictionary.service';
import { HeaderService } from '@core/services/header.service';
import { PermissionsService } from '@core/services/permissions.service';
import { StepsService } from '@core/services/steps.service';
import { StorageService } from '@core/services/storage.service';
import { PERMISSIONS } from '@shared/constants/permissions';
import { AppointmentType } from '@shared/enums/appointment-type';
import { DictionaryType } from '@shared/enums/dictionary-type';
import { SaleStepType } from '@shared/enums/sale-step-type.enum';
import { Dictionary } from '@shared/interfaces/dictionary';
import { ProductSelection } from '@shared/interfaces/product-selection';

import { QuestionPopoverComponent } from '../../../main/appointments/configure/components/question-popover/question-popover.component';
import { ConfigureService } from '../../../main/appointments/configure/services/configure.service';
import { ProductSelectionService } from '../../../main/appointments/configure/services/product-selection.service';
import { MeasureService } from '../../../main/appointments/measure/services/measure.service';

@Component({
    selector: 'vendo-product-selection',
    templateUrl: './product-selection.component.html',
    styleUrls: ['./product-selection.component.scss']
})
export class ProductSelectionComponent implements OnInit, AfterViewInit {
    productSelection: ProductSelection;
    isExistAvailableTiles: boolean;
    steps: any[] = [];
    activeStep: any;
    height = '100%';
    searchString = '';

    @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
    @ViewChild(IonContent) contentComponent: IonContent;

    filtersItems: any[] = [];
    shiftIndex: number;
    moreItemsSelected = 0;
    readonly adderPermissions = {
        isCanCreateLine: this.permissionsService.hasPermissions(PERMISSIONS.ADDERS.CREATE_LINE),
        isCanSelectLine: this.permissionsService.hasPermissions(PERMISSIONS.ADDERS.SELECT_LINE)
    };
    private adderNameSetting: Dictionary;
    private quoteId: string;
    private openingId: string;
    private configurationType: number;
    private selectedAnswers: any[];
    private appointment: any;
    private queryParams: any;
    private pageIndex = 1;
    private pageSize = 20;
    private enableInfinityScroll = true;

    constructor(
        private cdr: ChangeDetectorRef,
        private configureService: ConfigureService,
        private dictionaryService: DictionaryService,
        private headerService: HeaderService,
        private measureService: MeasureService,
        private navController: NavController,
        private permissionsService: PermissionsService,
        private popoverController: PopoverController,
        private productSelectionService: ProductSelectionService,
        private route: ActivatedRoute,
        private stepsService: StepsService,
        private storageService: StorageService
    ) {}

    async ngOnInit(): Promise<void> {
        this.productSelection = this.route.snapshot.data.productSelection;
        this.setIsExistAvailableTiles();
        this.productSelection.ProductListQuestions = this.productSelection.ProductListQuestions.filter(
            ({ Hidden }) => !Hidden
        );
        this.shiftIndex = this.productSelection.ProductListQuestions.length > 6 ? 5 : 3;
        this.quoteId = this.route.snapshot.params.quoteId;
        this.openingId = this.route.snapshot.params.openingId;
        this.configurationType = this.route.snapshot.params.type;
        this.queryParams = this.route.snapshot.queryParams;
        this.filtersItems = this.productSelection.ProductListQuestions.map(() => ({
            selectedAnswers: 0,
            label: ''
        }));
        this.resetSelectedAnswers();
        this.appointment = await this.storageService.get('activeAppointment');
        this.adderNameSetting = await this.dictionaryService.getDictionaryName(DictionaryType.Adder);
        this.initSteps();
        const configureStepName: string = this.stepsService.getStepName(
            this.appointment.type,
            this.configurationType ? SaleStepType.CONFIGURE : SaleStepType.MEASURE
        );

        this.headerService.setHeaderInfo({
            title: `${this.appointment.family_name} ${configureStepName}`,
            subtitle: this.appointment.schedule_time
        });
    }

    ngAfterViewInit(): void {
        this.setAvailabilityForInfinityScroll();
        if (
            this.productSelection.Pagination.FirstPage !== this.productSelection.Pagination.TotalPages &&
            this.productSelection.AvailableTiles.filter(({ Hidden }) => Hidden).length
        ) {
            this.pageIndex++;
            this.fetchProductSelectionQuestions(false);
        }
    }

    loadNextPage(event: InfiniteScrollCustomEvent): void {
        if (!this.enableInfinityScroll) {
            event.target.complete();

            return;
        }

        this.pageIndex++;
        this.fetchProductSelectionQuestions(false, event);
    }

    saveAnswers(): void {
        this.resetSelectedAnswers();
        this.pageIndex = 1;
        this.enableInfinityScroll = true;
        this.fetchProductSelectionQuestions(true);
    }

    navigateToConfigurations(step: any): void {
        if (step.ID === 'product_selection') {
            return;
        }

        this.navigateToConfigurator();
    }

    selectProduct(product: any): void {
        if (this.selectedAnswers.findIndex((item: any) => item.QuestionBackendName === 'VendoProductType') === -1) {
            this.selectedAnswers.push({
                QuestionDisplayName: 'Product Type',
                QuestionBackendName: 'VendoProductType',
                AnswerBackendName: 'Unspecified'
            });
        }
        const productSelection = {
            SelectedProductListTileBackendName: product.BackendName,
            SelectedProductListTileBrandID: product.BrandID,
            ProductListQuestionSelections: this.selectedAnswers
        };

        if (this.configurationType) {
            if (this.productSelection.opening.temporary) {
                this.configureService
                    .fillTakeoffAnswers(this.quoteId, this.openingId, this.selectedAnswers)
                    .subscribe();
            }

            this.configureService
                .selectProduct(this.quoteId, this.openingId, productSelection, product.ImageURL)
                .subscribe(() => this.navigateToConfigurator());
        } else {
            this.measureService.productListSelections = productSelection;
            this.measureService.productImageUrl = product.ImageURL;
            this.navigateToConfigurator();
        }
    }

    async showAnswersPopover(event: Event, index: number): Promise<void> {
        event.stopPropagation();

        if (
            !this.productSelection ||
            !this.productSelection.ProductListQuestions ||
            !this.productSelection.ProductListQuestions[index]
        ) {
            return;
        }

        const question = this.productSelection.ProductListQuestions[index];

        const popover = await this.popoverController.create({
            component: QuestionPopoverComponent,
            event,
            translucent: true,
            mode: 'md',
            cssClass:
                question.DisplayName === 'Width' || question.DisplayName === 'Height'
                    ? 'question-checkbox-popover'
                    : 'question-general-popover',
            componentProps: {
                question,
                imgBaseUrl: this.productSelection.image_base_url
            }
        });

        const filterChanges: Subscription = this.productSelectionService.answerSelected$.subscribe(() =>
            this.saveAnswers()
        );

        await popover.present();

        await popover.onDidDismiss();
        filterChanges.unsubscribe();
    }

    setSearchString(searchString: string): void {
        this.searchString = searchString;
        this.enableInfinityScroll = true;
        this.pageIndex = 1;
        this.fetchProductSelectionQuestions(true);
    }

    fetchProductSelectionQuestions(isFullyReload: boolean, event?: InfiniteScrollCustomEvent): void {
        let count = 0;

        this.configureService
            .fetchProductSelectionQuestions(
                this.quoteId,
                this.openingId,
                this.selectedAnswers,
                true,
                { page_number: this.pageIndex, page_size: this.pageSize },
                this.searchString
            )
            .pipe(
                expand((res: ProductSelection) => {
                    if (
                        !res.AvailableTiles.length ||
                        res.Pagination.FirstPage === res.Pagination.TotalPages ||
                        count >= this.pageSize
                    ) {
                        this.enableInfinityScroll = false;

                        return EMPTY;
                    }

                    this.pageIndex++;

                    return this.configureService.fetchProductSelectionQuestions(
                        this.quoteId,
                        this.openingId,
                        this.selectedAnswers,
                        true,
                        { page_number: this.pageIndex, page_size: this.pageSize },
                        this.searchString
                    );
                }),
                tap((data: ProductSelection) => (count += data.AvailableTiles.filter(({ Hidden }) => !Hidden).length)),
                reduce((result: any, data: ProductSelection, index: number) => {
                    if (index === 0) {
                        return data;
                    } else {
                        result.AvailableTiles.push(...data.AvailableTiles);
                        result.Pagination = data.Pagination;

                        return result;
                    }
                }, {}),
                finalize(() => event?.target?.complete())
            )
            .subscribe((res: ProductSelection) => {
                this.productSelection.Pagination = res.Pagination;
                if (isFullyReload) {
                    this.productSelection.AvailableTiles = res.AvailableTiles;
                    this.contentComponent.scrollToTop();
                    setTimeout(() => {
                        this.setAvailabilityForInfinityScroll();
                        this.cdr.detectChanges();
                    });
                } else {
                    this.productSelection.AvailableTiles.push(...res.AvailableTiles);
                    this.setAvailabilityForInfinityScroll();
                }
                this.setIsExistAvailableTiles();
            });
    }

    private setAvailabilityForInfinityScroll(): void {
        if (!this.productSelection.AvailableTiles.length) {
            return;
        }
        this.enableInfinityScroll = this.pageIndex !== this.productSelection.Pagination.TotalPages;
    }

    private resetSelectedAnswers(): void {
        this.selectedAnswers = [];
        this.productSelection.ProductListQuestions.forEach((question, index: number) => {
            const questionAnswers = filter(question.AvailableAnswers, { IsSelected: true });

            this.filtersItems[index].selectedAnswers = questionAnswers.length;
            this.filtersItems[index].label =
                questionAnswers.length === 1 ? this.getButtonName(question, questionAnswers[0]) : question.DisplayName;

            questionAnswers.forEach((answer) => {
                this.selectedAnswers.push({
                    QuestionDisplayName: question.DisplayName,
                    QuestionBackendName: question.BackendName,
                    AnswerBackendName: answer.BackendName
                });
            });
        });

        const isCountMoreSelected =
            window.innerWidth >= 1280
                ? this.productSelection.ProductListQuestions.length > 6
                : this.productSelection.ProductListQuestions.length > 4;

        this.moreItemsSelected = isCountMoreSelected
            ? this.filtersItems.slice(this.shiftIndex).filter((item) => !!item.selectedAnswers).length
            : 0;
    }

    private getButtonName(question, questionAnswer): string {
        switch (question.DisplayName) {
            case 'Number Wide':
                return question.DisplayName.replace('Number', questionAnswer.DisplayName);
            case 'Width':
                return `W: ${questionAnswer.DisplayName}`;
            case 'Height':
                return `H: ${questionAnswer.DisplayName}`;
            default:
                return questionAnswer.DisplayName;
        }
    }

    private initSteps(): void {
        const hasSelectedProduct = !!this.productSelection.steps.length;

        if (!hasSelectedProduct) {
            this.productSelection.steps = [
                {
                    ID: -1,
                    Name: 'Layout',
                    Disabled: !hasSelectedProduct
                }
            ];
        }

        this.steps = [
            {
                ID: 'product_selection',
                Name: 'Product'
            },
            ...this.productSelection.steps,
            ...(this.adderPermissions.isCanCreateLine || this.adderPermissions.isCanSelectLine
                ? [
                      {
                          ID: 'adders',
                          Name: this.adderNameSetting.plural,
                          Disabled: !hasSelectedProduct
                      }
                  ]
                : [])
        ];

        this.steps = this.steps.filter((step) => !step.Hidden);
        this.activeStep = this.steps[0];
    }

    private navigateToConfigurator(): void {
        const stepPath: string = this.configurationType
            ? `${SaleStepType.CONFIGURE}/${this.configurationType}`
            : SaleStepType.MEASURE;
        const url = `/main/appointments/${this.appointment.id}/${stepPath}/${this.quoteId}/${this.openingId}/customize${
            this.appointment.type === AppointmentType.SecondMeasure ? '' : '/create'
        }`;

        this.navController.navigateRoot(url, {
            queryParams: this.queryParams,
            replaceUrl: true
        });
    }

    private setIsExistAvailableTiles(): void {
        this.isExistAvailableTiles = this.productSelection.AvailableTiles.some(({ Hidden }) => !Hidden);
    }
}

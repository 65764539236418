import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';

import { NavController } from '@ionic/angular';

import { Observable } from 'rxjs';

import { StorageService } from '@core/services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class LoginGuard {
    constructor(
        private navController: NavController,
        private storageService: StorageService
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.storageService.get('token').then((token) => {
            if (token) {
                this.navController.navigateRoot(next.queryParams.returnUrl || ['main']);
            }

            return !token;
        });
    }
}

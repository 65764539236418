import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

import { NgSelectComponent } from '@ng-select/ng-select';

import { AlertController } from '@ionic/angular';

import differenceBy from 'lodash/differenceBy';
import get from 'lodash/get';

import { Promotion } from '@shared/interfaces/promotion';

@Component({
    selector: 'vendo-discounts-dropdown',
    templateUrl: './discounts-dropdown.component.html',
    styleUrls: ['./discounts-dropdown.component.scss']
})
export class DiscountsDropdownComponent implements OnChanges {
    @Input() availableDiscountIds: number[];
    @Input() promotions: Promotion[];
    @Input() selectedPromotions: Promotion[];
    @Input() activeTabId: number;
    @Input() isCustomPromotionsAvailable: boolean;
    @Output() discountApplied: EventEmitter<string[]> = new EventEmitter<string[]>();
    @Output() createCustomPromotion: EventEmitter<void> = new EventEmitter<void>();
    @ViewChild('ngSelect') ngSelect: NgSelectComponent;

    activePromotions = [];

    private currentView = 'visible'; // another option is 'hidden'

    constructor(public alertController: AlertController) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.activeTabId && get(changes, 'activeTabId.currentValue')) {
            this.currentView = 'visible';
        }

        if (changes.selectedPromotions && get(changes, 'selectedPromotions.currentValue')) {
            this.setActivePromotions();
        }
    }

    apply(): void {
        const selectedPromotions: string[] = this.selectedPromotions
            .map((promotion: Promotion) => String(promotion.id))
            .filter(Boolean);

        this.discountApplied.emit(selectedPromotions);
    }

    changeView(): void {
        this.currentView = this.currentView === 'visible' ? 'hidden' : 'visible';
        this.setActivePromotions();
    }

    onItemRemove(): void {
        this.ngSelect.close();
    }

    async promotionsChanged(items: Promotion[]): Promise<void> {
        const deletedExpiredPromotions = differenceBy(this.selectedPromotions, items, 'id').filter(
            ({ id: deletedPromotionId }: Promotion) =>
                !this.promotions.find(({ id: promotionId }: Promotion) => promotionId === deletedPromotionId)
        );

        if (deletedExpiredPromotions.length) {
            this.selectedPromotions = Object.assign([], this.selectedPromotions);

            const isAllowToDeleteExpiredPromotions = await this.showExpiredAlertBeforeRemove();

            if (!isAllowToDeleteExpiredPromotions) {
                return;
            }
        }

        const hasDifference =
            differenceBy(this.selectedPromotions, items, 'id').length ||
            differenceBy(items, this.selectedPromotions, 'id').length;

        const addedDiscount = differenceBy(items, this.selectedPromotions, 'id');

        this.selectedPromotions = items;

        if (addedDiscount?.length) {
            const fullAddedDiscountInfo: any = this.promotions.find(
                (promotion) => promotion.id === addedDiscount[0].id
            );

            if (fullAddedDiscountInfo?.is_custom) {
                this.createCustomPromotion.emit();

                return;
            }
        }

        if (hasDifference) {
            this.setActivePromotions();
            this.apply();
        }
    }

    private setActivePromotions(): void {
        this.activePromotions = [];

        setTimeout(() => {
            this.activePromotions = this.promotions.filter((promotion: Promotion) =>
                this.currentView === 'visible'
                    ? promotion.visible && this.availableDiscountIds.includes(Number(promotion.id))
                    : !promotion.visible &&
                      (this.availableDiscountIds.includes(Number(promotion.id)) ||
                          (!promotion.id && this.isCustomPromotionsAvailable))
            );
        });
    }

    private async showExpiredAlertBeforeRemove(): Promise<boolean> {
        return new Promise((resolve) => {
            this.alertController
                .create({
                    header: 'Confirm your action',
                    message:
                        'You are about to delete an expired promotion.</br> Once this promotion is removed, it cannot be added again.</br>Would you like to continue?',
                    buttons: [
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => resolve(false)
                        },
                        {
                            text: 'Proceed',
                            handler: () => resolve(true)
                        }
                    ]
                })
                .then((alert) => alert.present());
        });
    }
}

<div class="product-selection">
    <div class="product-filter">
        <vendo-steps-nav class="steps"
                         [steps]="steps"
                         [activeStep]="activeStep"
                         (stepClicked)="navigateToConfigurations($event)"></vendo-steps-nav>

        <div class="section-questions">
            <div class="question-chips">
                <vendo-filter [list]="productSelection?.ProductListQuestions"
                              [filtersItems]="filtersItems"
                              [searchText]="productSelection.search"
                              [width]="165"
                              (valueChanged)="setSearchString($event)">
                    <ng-container *ngFor="let question of productSelection?.ProductListQuestions; let i = index;">
                        <ion-button *ngIf="!question.Hidden"
                                    fill="outline"
                                    [ngClass]="{'item-selected': filtersItems[i]?.selectedAnswers > 0}"
                                    (click)="showAnswersPopover($event, i)">
                            {{ filtersItems[i]?.label }}
                            <ng-container *ngIf="filtersItems[i]?.selectedAnswers > 1"> ({{ filtersItems[i]?.selectedAnswers }})</ng-container>
                        </ion-button>
                    </ng-container>
                </vendo-filter>
            </div>
        </div>
    </div>

    <ion-content class="main">
        <div class="section-products" [ngClass]="{'fixed-height': !isExistAvailableTiles}">
            <ng-container *ngIf="isExistAvailableTiles; else no_products">
                <ion-grid class="ion-no-padding">
                    <ion-row>
                        <ng-container *ngFor="let product of productSelection.AvailableTiles">
                            <ion-col *ngIf="!product.Hidden"
                                     size-sm="12"
                                     size-md="6"
                                     size-xl="4"
                                     (click)="selectProduct(product)">
                               <div class="product">
                                   <ion-img [src]="product.ImageURL | wcpDrawingUrl:productSelection.image_base_url"
                                            class="product-image"></ion-img>
                                   <div class="product-info">
                                       <ion-text class="product-title ion-text-left">{{ product.DisplayName }}</ion-text>
                                       <ion-text class="product-header ion-text-left" *ngIf="product.Header">{{ product.Header }}</ion-text>
                                       <ion-text class="product-description" *ngIf="product.Description">
                                           {{ product.Description && product.Description.length > 90 ? (product.Description | slice: 0:90) + '...' : product.Description }}
                                       </ion-text>
                                   </div>
                               </div>
                            </ion-col>
                        </ng-container>
                    </ion-row>
                </ion-grid>

                <ion-infinite-scroll threshold="100px" (ionInfinite)="loadNextPage($event)">
                     <ion-infinite-scroll-content [loadingSpinner]="null"></ion-infinite-scroll-content>
                </ion-infinite-scroll>
            </ng-container>
            <ng-template #no_products>
                <h6 class="no-products-label">
                    <i>No products found</i>
                </h6>
            </ng-template>
        </div>
    </ion-content>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';

import { ModalController } from '@ionic/angular';

import { takeUntil } from 'rxjs/operators';

import { FormHelperService } from '@core/services/form-helper.service';
import { PromotionAmountType } from '@shared/enums/promotion.enum';
import { BaseModal } from '@shared/modals/base-modal';

@Component({
    selector: 'vendo-custom-promotion-modal',
    templateUrl: './custom-promotion-modal.component.html',
    styleUrls: ['./custom-promotion-modal.component.scss']
})
export class CustomPromotionModalComponent extends BaseModal implements OnInit {
    @Input() maxAmount: number;
    charLimit = 70;
    form: UntypedFormGroup;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private formHelperService: FormHelperService,
        modalController: ModalController
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            name: ['', Validators.maxLength(this.charLimit)],
            amount: [null, this.getAmountValidators(true)],
            isAmountPromotion: true,
            amount_type: PromotionAmountType.Static
        });

        this.form
            .get('isAmountPromotion')
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: boolean) => {
                const control: AbstractControl = this.form.get('amount');

                if ((!value && control.value > 100) || (value && control.value > this.maxAmount)) {
                    control.setValue(null);
                }
                control.setValidators(this.getAmountValidators(value));
                control.updateValueAndValidity();
                this.form
                    .get('amount_type')
                    .setValue(value ? PromotionAmountType.Static : PromotionAmountType.Percentage);
            });
    }

    confirm(): void {
        const formData = this.form.getRawValue();

        delete formData.isAmountPromotion;
        this.dismiss(formData);
    }

    getMessages(errors): string[] {
        return this.formHelperService.getMessages(errors);
    }

    private getAmountValidators(isAmountPromotion: boolean): ValidatorFn[] {
        return [Validators.required, Validators.min(0), Validators.max(isAmountPromotion ? this.maxAmount : 100)];
    }
}

import { gql } from 'apollo-angular';

const FINISH_MEASURE_CONFIGURATOR = gql`
    mutation FinishMeasureConfigurator($quoteId: ID!, $openingId: ID!) {
        finishMeasureConfigurator(quote_id: $quoteId, opening_id: $openingId)
    }
`;

const UPDATE_MEASURE_CONFIGURATOR = gql`
    mutation UpdateMeasureQuestion($quoteId: ID!, $openingId: ID!, $stepId: ID!, $question: UpdateMeasureQuestion!) {
        updateMeasureQuestion(quote_id: $quoteId, opening_id: $openingId, step_id: $stepId, question: $question)
    }
`;

const SAVE_MEASURE_NOTES = gql`
    mutation SaveMeasureNotes($appointmentId: ID!, $measureNotes: String) {
        saveMeasureNotes(appointment_id: $appointmentId, measure_notes: $measureNotes)
    }
`;

const CONFIRM_MEASURE_LINES = gql`
    mutation confirmMeasureLines($appointmentId: ID!, $catalogId: ID!) {
        confirmMeasureLines(appointment_id: $appointmentId, catalog_id: $catalogId)
    }
`;

export { FINISH_MEASURE_CONFIGURATOR, SAVE_MEASURE_NOTES, UPDATE_MEASURE_CONFIGURATOR, CONFIRM_MEASURE_LINES };

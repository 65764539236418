export enum FieldType {
    Checkbox = 'checkbox',
    Datepicker = 'datepicker',
    Datetimepicker = 'datetimepicker',
    Dropdown = 'dropdown',
    Input = 'input',
    Label = 'label',
    Tags = 'tags',
    Textarea = 'textarea'
}

import { gql } from 'apollo-angular';

const IMPORT_PROVIA_ORDER = gql`
    mutation ImportProviaOrder(
        $quoteIds: [ID!]!
        $appointmentId: ID!
        $orderNumber: String!
        $userName: String!
        $password: String!
        $remember: Boolean
        $usedRemember: Boolean
    ) {
        importProviaOrder(
            quote_ids: $quoteIds
            appointment_id: $appointmentId
            order_number: $orderNumber
            user_name: $userName
            password: $password
            remember: $remember
            used_remember: $usedRemember
        )
    }
`;

const REMOVE_PROVIA_ORDER = gql`
    mutation RemoveProviaOrder($appointmentId: ID!) {
        removeProviaOrder(appointment_id: $appointmentId)
    }
`;

export { IMPORT_PROVIA_ORDER, REMOVE_PROVIA_ORDER };

import { gql } from 'apollo-angular';

const GET_MY_SIGNATURE_AND_INITIALS = gql`
    query GetMySignatureAndInitials {
        myActualSignature
        myActualInitials
    }
`;

const GET_SIGN_ABLE_DOCUMENTS = gql`
    query GetSignAbleDocuments($appointmentId: ID!) {
        mySignAbleDocuments(appointment_id: $appointmentId) {
            id
            name
            type
            is_sign_able
            has_project_description
            has_photos_glossary
            required
        }
    }
`;

const GET_SEND_ABLE_DOCUMENTS = gql`
    query GetSendAbleDocuments($appointmentId: ID!) {
        mySendAbleDocuments(appointment_id: $appointmentId) {
            id
            name
            type
            required
            quote {
                id
                package_name
            }
        }
    }
`;

const GET_QUOTES_DOCUMENTS = gql`
    query MyQuotesDocuments($appointmentId: ID!) {
        myQuotesDocuments(appointment_id: $appointmentId) {
            id
            status
            package_name
            quote_documents {
                id
                template_id
                status
                signers {
                    left_places_to_sign
                    signer_id
                    type
                }
                document_template {
                    name
                    is_sign_able
                }
            }
        }
    }
`;

const GET_QUOTE_DOCUMENTS = gql`
    query MyQuoteDocuments($appointmentId: ID!, $quoteId: ID!) {
        myQuoteDocuments(appointment_id: $appointmentId, quote_id: $quoteId) {
            id
            status
            package_name
            quote_documents {
                id
                template_id
                status
                signers {
                    left_places_to_sign
                    signature_key
                    signer_id
                    type
                }
                document_template {
                    name
                    is_sign_able
                }
            }
        }
    }
`;

const GET_DOCUMENTS_SUMMARY_AND_CUSTOMERS = gql`
    query documentsSummaryAndCustomers($appointmentId: ID!) {
        myDocumentCustomers(appointment_id: $appointmentId) {
            id
            first_name
            last_name
            is_signing
            documents_status
        }
        myDocumentsSummary(appointment_id: $appointmentId) {
            has_sign_able_documents
            first_quote_document {
                id
                quote_id
            }
        }
    }
`;

const GET_DOCUMENTS_SUMMARY = gql`
    query myDocumentsSummary($appointmentId: ID!) {
        myDocumentsSummary(appointment_id: $appointmentId) {
            has_sign_able_documents
            has_unsigned_documents
            first_quote_document {
                id
                quote_id
            }
        }
    }
`;

const GET_DOCUMENTS_USE_SUMMARY = gql`
    query myDocumentsUseSummary($appointmentId: ID!) {
        myDocumentsSummary(appointment_id: $appointmentId) {
            use_project_description
            use_photos_glossary
        }
    }
`;

const GET_DOCUMENT_CUSTOMERS = gql`
    query myDocumentCustomers($appointmentId: ID!) {
        myDocumentCustomers(appointment_id: $appointmentId) {
            id
            first_name
            last_name
            is_signing
            documents_status
            additional_info
            signature
            initials
        }
    }
`;

const GET_QUOTE_DOCUMENT = gql`
    query GetQuoteDocument($id: ID!) {
        myQuoteDocument(id: $id) {
            id
            signers {
                left_places_to_sign
                signature_key
                signer_id
                type
            }
            document_state
            status
            updated_at
        }
    }
`;

const GET_RESULT_ABLE_DOCUMENTS = gql`
    query GetResultAbleDocuments($appointmentId: ID!) {
        myResultAbleDocuments(appointment_id: $appointmentId) {
            id
            name
            signed
            type
            required
            quote {
                package_name
                id
            }
            categories
        }
    }
`;

const HAS_PENDING_DOCUMENTS = gql`
    query HasPendingDocuments($appointmentId: ID!) {
        myDocumentsSummary(appointment_id: $appointmentId) {
            has_pending_documents
        }
    }
`;

export {
    GET_SEND_ABLE_DOCUMENTS,
    GET_SIGN_ABLE_DOCUMENTS,
    GET_QUOTES_DOCUMENTS,
    GET_QUOTE_DOCUMENTS,
    GET_DOCUMENTS_SUMMARY_AND_CUSTOMERS,
    GET_DOCUMENTS_SUMMARY,
    GET_DOCUMENTS_USE_SUMMARY,
    GET_DOCUMENT_CUSTOMERS,
    GET_QUOTE_DOCUMENT,
    GET_RESULT_ABLE_DOCUMENTS,
    HAS_PENDING_DOCUMENTS,
    GET_MY_SIGNATURE_AND_INITIALS
};

import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, InjectionToken } from '@angular/core';

import Rollbar from 'rollbar';

import versions from '../../../environments/app-versions.js';
import { environment } from '../../../environments/environment';

const rollbarConfig = {
    accessToken: 'cc1c4da0b12d47a38e12ec2542af3cbf',
    addErrorContext: true,
    autoInstrument: {
        network: true,
        networkResponseHeaders: true,
        networkResponseBody: true,
        networkRequestBody: true,
        log: true,
        dom: true,
        navigation: true,
        connectivity: true,
        contentSecurityPolicy: true,
        errorOnContentSecurityPolicy: false
    },
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: environment.production ? 'production' : environment.stage ? 'stage' : 'development',
    enabled:
        (environment.production || environment.stage) &&
        !environment.lt &&
        window.localStorage.getItem('offline_mode') !== '1',
    transform: (payload) => {
        const trace = payload.body.trace;
        const locRegex = /^(https?):\/\/[a-zA-Z0-9._-]+\.paradigmvendo\.com(.*)/;

        if (trace && trace.frames) {
            for (let i = 0; i < trace.frames.length; i++) {
                const filename = trace.frames[i].filename;

                if (filename) {
                    if (filename.includes('stage')) {
                        return;
                    }

                    const m = filename.match(locRegex);

                    if (m) {
                        trace.frames[i].filename = m[1] + '://paradigmvendo.com' + m[2];
                    }
                }
            }
        }
    }
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable({
    providedIn: 'root'
})
export class RollbarErrorHandler implements ErrorHandler {
    private secretKeys: string[] = ['password', 'compare_password', 'token', 'secret', 'key'];

    constructor(@Inject(RollbarService) private rollbar: Rollbar) {
        this.rollbar.configure({
            codeVersion: versions.appVersion,
            code_version: versions.appVersion
        });
    }

    disable(): void {
        if (this.rollbar.options.enabled) {
            this.rollbar.configure({
                enabled: false
            });
        }
    }

    addRollbarPerson(user): void {
        this.rollbar.configure(
            this.getPayloadInfo({
                id: user.id,
                username: `${user.first_name} ${user.last_name}`,
                email: user.email
            })
        );
    }

    removeRollbarPerson(): void {
        this.rollbar.configure(this.getPayloadInfo());
    }

    handleError(err: any): void {
        console.error(err);

        this.rollbar.error(...this.sanitizedError(err.originalError || err));
    }

    handleInfo(data: string): void {
        this.rollbar.info(data);
    }

    captureEventFromTelemetry(metadata: any): void {
        this.hideSecrets(metadata);

        if (metadata) {
            this.rollbar.captureEvent(metadata, 'info');
        }
    }

    private sanitizedError(error: any): any[] {
        if (error instanceof Error || error instanceof String) {
            return [error];
        } else if (error instanceof HttpErrorResponse) {
            return [error.message, error];
        } else {
            return ['Error', error];
        }
    }

    private hideSecrets(data: any, seen = new WeakSet()): void {
        if (!data || typeof data !== 'object' || seen.has(data)) {
            return;
        }

        // Mark this object as seen to avoid circular references
        seen.add(data);

        if (Array.isArray(data)) {
            for (const subData of data) {
                this.hideSecrets(subData, seen);
            }
        } else {
            for (const key in data) {
                if (typeof data[key] === 'object') {
                    this.hideSecrets(data[key], seen);
                } else if (typeof data[key] === 'string' && data[key] && this.keyMustBeHidden(key)) {
                    data[key] = `${data[key].slice(0, 2)}*********${data[key].slice(-2)}`;
                }
            }
        }
    }

    private keyMustBeHidden(key: string): boolean {
        key = key.toLowerCase();

        return this.secretKeys.some((part: string) => key.endsWith(part));
    }

    private getPayloadInfo(person = null): any {
        return {
            payload: {
                person,
                environment: environment.production ? 'production' : environment.stage ? 'stage' : 'development',
                client: {
                    javascript: {
                        source_map_enabled: true,
                        code_version: versions.appVersion,
                        guess_uncaught_frames: true
                    }
                }
            }
        };
    }
}

export function rollbarFactory(): Rollbar {
    return new Rollbar(rollbarConfig);
}

<ng-container [formGroup]="form">
    <ion-select *ngIf="!useOnlyDatePicker"
                legacy="true"
                mode="md"
                interface="popover"
                [interfaceOptions]="{mode: 'md', alignment: 'start'}"
                placeholder="Select One"
                formControlName="expired_in">
        <ion-select-option *ngFor="let option of expirationDaysOptions"
                           [value]="option.value">
            {{ option.label }}
        </ion-select-option>
    </ion-select>

    <ion-item *ngIf="isShowDatePicker"
              lines="none"
              mode="ios"
              class="datepicker-field"
              id="open-date-input">
        <ion-input legacy="true" [value]="form.get('expired_date').value | localizedDate : 'MMMM dd, yyyy'"
                   readonly
                   placeholder="Choose expiration date"></ion-input>
        <ion-button fill="clear" slot="end">
            <ion-icon name="calendar-outline"></ion-icon>
        </ion-button>
        <ion-popover #popoverDatetime
                     class="popover-datepicker"
                     trigger="open-date-input"
                     show-backdrop="false"
                     mode="md">
            <ng-template>
                <ion-datetime #datetime
                              hour-cycle="h12"
                              presentation="date"
                              [min]="minStartsAt"
                              [max]="maxStartsAt"
                              mode="ios"
                              [showDefaultButtons]="true"
                              [value]="form.get('expired_date').value"
                              (ionChange)="setDateTime()"></ion-datetime>
            </ng-template>
        </ion-popover>
    </ion-item>
</ng-container>

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NavController } from '@ionic/angular';

import { from, Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import get from 'lodash/get';
import some from 'lodash/some';

import { RollbarErrorHandler } from '@core/handlers/rollbar-error-handler';
import { AuthService } from '@core/services/auth.service';
import { StorageService } from '@core/services/storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private navController: NavController,
        private rollbarErrorHandler: RollbarErrorHandler,
        private storageService: StorageService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.storageService.get('token')).pipe(
            tap((token: string) => {
                if (
                    token &&
                    !['/e-document/v2', '/offer-request'].some((item) => window.location.href.includes(item))
                ) {
                    req = req.clone({
                        headers: req.headers.set('x-token', token)
                    });
                }
            }),
            switchMap(() => next.handle(req)),
            catchError((response) => {
                const errors = get(response, 'error.errors');

                if (errors) {
                    const isAuthError = some(errors, (errObj) => {
                        return get(errObj, 'extensions.code') === 'UNAUTHENTICATED';
                    });

                    if (isAuthError && req.body.operationName !== 'logout') {
                        this.rollbarErrorHandler.handleInfo('Logout - User is not authenticated');
                        const returnUrl: string = this.authService.getReturnUrl();

                        this.navController.navigateRoot(
                            ['logout'],
                            returnUrl ? { queryParams: { returnUrl } } : undefined
                        );
                    }
                }

                return of(errors);
            })
        );
    }
}

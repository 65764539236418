<div class="warnings"
     *ngIf="configuratorData.LimitationWarningsCount">
    <div class="warnings-header">
        <ion-icon name="warning"></ion-icon>
        <ion-label>
            Warning
            <ng-container *ngIf="configuratorData.Limitations.length > 1">
                ({{ activeWarningSlideIndex + 1 }} of {{ configuratorData.Limitations.length }})
            </ng-container>
        </ion-label>
    </div>

    <div [ngClass]="{'p-10': configuratorData.Limitations.length === 1}">
	    <div class="swiper-container" #swiperErrorContainer>
		    <div class="swiper-wrapper">
			    <div class="swiper-slide ion-align-self-center warning-button"
				     *ngFor="let limitation of configuratorData.Limitations"
				     (click)="warmingClicked.emit(limitation)">
				        <ion-text>{{ limitation.Message }}</ion-text>
			    </div>
		    </div>

		    <ng-container *ngIf="configuratorData.Limitations.length > 1">
			    <button class="next-error">
				    <ion-icon name="chevron-forward-circle-outline"></ion-icon>
			    </button>
			    <button class="prev-error">
				    <ion-icon name="chevron-back-circle-outline"></ion-icon>
			    </button>
			    <div class="error-pagination"></div>
		    </ng-container>
	    </div>
    </div>
</div>

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isArray from 'lodash/isArray';

import { isActiveOfflineMode } from '@core/functions/is-active-offline-mode';
import { RollbarErrorHandler } from '@core/handlers/rollbar-error-handler';
import { ErrorsService } from '@core/services/errors.service';
import { NetworkService } from '@core/services/network.service';
import { SpinnerService } from '@core/services/spinner.service';
import { ToastService } from '@core/services/toast.service';
import { NetworkStatus } from '@shared/enums/network-status';

import { environment } from '../../../environments/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    private readonly isOffline: boolean = isActiveOfflineMode();

    constructor(
        private spinnerService: SpinnerService,
        private errorService: ErrorsService,
        private networkService: NetworkService,
        private rollbarErrorHandler: RollbarErrorHandler,
        private toastService: ToastService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.networkService.currentNetworkStatus === NetworkStatus.Offline && !this.isOffline) {
            this.networkService.showAlert();

            return of(null);
        }

        req = req.clone({
            url: `${environment.apiUrl}${req.url}`
        });

        const isSilent = req.url.indexOf('?background=true') !== -1;

        if (!isSilent) {
            this.spinnerService.changeActiveRequestsCount(1);
        }

        return next.handle(req).pipe(
            map((res: any) => {
                if (res.type === 4 || isArray(res)) {
                    let messages;

                    if (res.body?.errors) {
                        this.rollbarErrorHandler.captureEventFromTelemetry({
                            requestData: this.getRequestData(req),
                            errors: res.body?.errors
                        });
                        messages = res.body.errors.map((error) => error.message).join('\n');
                    }

                    if (isArray(res)) {
                        this.rollbarErrorHandler.captureEventFromTelemetry({
                            requestData: this.getRequestData(req),
                            errors: res
                        });
                        messages = res.map((error) => error.message).join('\n');
                    }

                    if (messages) {
                        this.toastService.showMessage(messages);
                        this.errorService.emitError();
                    }
                }

                return res;
            }),
            catchError((response) => {
                let errors = get(response, 'graphQLErrors');
                const networkErrors = get(response, 'networkErrors');

                this.rollbarErrorHandler.captureEventFromTelemetry({
                    requestData: this.getRequestData(req),
                    graphQLErrors: errors
                });

                if (networkErrors?.length) {
                    errors = errors.concat(networkErrors);
                    this.rollbarErrorHandler.captureEventFromTelemetry({
                        requestData: this.getRequestData(req),
                        networkErrors
                    });
                }

                return of(errors);
            }),
            finalize(() => {
                if (!isSilent) {
                    this.spinnerService.changeActiveRequestsCount(-1);
                }
            })
        );
    }

    private getRequestData(req: HttpRequest<any>): any {
        return {
            query: cloneDeep(req.body.query),
            variables: cloneDeep(req.body.variables)
        };
    }
}

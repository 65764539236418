import { Component, Input, OnInit } from '@angular/core';
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    Validators
} from '@angular/forms';

import { TagifySettings } from 'ngx-tagify';

import { ModalController } from '@ionic/angular';

import { startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

import get from 'lodash/get';

import { AuthService } from '@core/services/auth.service';
import { EmailService } from '@core/services/email.service';
import { FeedbackService } from '@core/services/feedback.service';
import { TagifyService } from '@core/services/helpers/tagify.service';
import { ResultingService } from '@core/services/resulting.service';
import { StorageService } from '@core/services/storage.service';
import { VoiceRecordingService } from '@core/services/voice-recording.service';
import { AppointmentPendingStatuses, AppointmentWonStatuses } from '@shared/constants/appointment-statuses';
import { AppointmentResultDisableStatus } from '@shared/enums/appointment-result-disable-status';
import { DocumentType } from '@shared/enums/document-type';
import { MailType } from '@shared/enums/mail-type.enum';
import { AppointmentMailSettings } from '@shared/interfaces/appointment';
import { AppointmentPossibleResults } from '@shared/interfaces/appointment-possible-results';
import { BaseModal } from '@shared/modals/base-modal';

import { ConfirmationModalComponent } from '../../../components/confirmation-modal/confirmation-modal.component';
import { AppointmentsService } from '../../../main/appointments/services/appointments.service';
import { FeedbackModalService } from '../../../main/components/feedback-modal/feedback-modal.service';

@Component({
    selector: 'vendo-resulting-modal',
    templateUrl: './resulting-modal.component.html',
    styleUrls: ['./resulting-modal.component.scss']
})
export class ResultingModalComponent extends BaseModal implements OnInit {
    @Input() availableResults: AppointmentPossibleResults;
    @Input() documents = [];
    @Input() appointment: any;
    @Input() showCloseIcon = true;
    tagifySettings: TagifySettings;
    alertMessage: string = null;
    countOfSelectedDocuments: number;
    form: UntypedFormGroup;
    isShowNotes: boolean;
    resultValueField = 'id';
    selectedStep = 1;
    totalSteps = 2;
    emailMessageProps = {
        isReadOnly: false,
        isSendable: true
    };
    resultEachCatalog: boolean;
    isI360Lightning = false;
    opportunityResults: any[] = [];
    readonly wonStatuses: string[] = AppointmentWonStatuses;

    get opportunityResultsFormArray(): UntypedFormArray {
        return this.form.get('opportunity_results') as UntypedFormArray;
    }

    constructor(
        private appointmentsService: AppointmentsService,
        private authService: AuthService,
        private feedbackModalService: FeedbackModalService,
        private feedbackService: FeedbackService,
        private formBuilder: UntypedFormBuilder,
        private emailService: EmailService,
        public modalController: ModalController,
        private storageService: StorageService,
        private resultingService: ResultingService,
        private tagifyService: TagifyService,
        private voiceRecordingService: VoiceRecordingService
    ) {
        super(modalController);
    }

    async ngOnInit(): Promise<void> {
        const user = this.authService.getUser();

        this.resultEachCatalog = user.office.product_filtering_settings?.result_each_catalog || false;
        this.isI360Lightning = user.office.crm_name === 'ImproveIt 360 Lightning';

        if (this.availableResults.results.length) {
            const isNullIdExist = this.availableResults.results.some(({ id }) => !id);
            const unsignedDocsResultNames: string[] = [];
            const requiredDocsResultNames: string[] = [];

            this.availableResults.results.forEach((result) => {
                if (isNullIdExist && !result.id) {
                    result.id = result.type;
                }

                if (result.disabled) {
                    switch (result.disabled_reason) {
                        case AppointmentResultDisableStatus.UnsignedDocuments:
                            unsignedDocsResultNames.push(result.name);
                            break;
                        case AppointmentResultDisableStatus.RequiredDocuments:
                            requiredDocsResultNames.push(result.name);
                            break;
                    }
                }
            });

            if (requiredDocsResultNames.length) {
                this.alertMessage = `Appointment cannot be resulted as ${requiredDocsResultNames.join()} until required documents are confirmed`;
            } else if (unsignedDocsResultNames.length) {
                this.alertMessage = `Appointment cannot be resulted as ${unsignedDocsResultNames.join()} until selected documents are signed`;
            }
        }

        if (!this.appointment) {
            this.appointment = await this.storageService.get('activeAppointment');
        }

        this.initForm();
        this.setSelectedDocuments();

        const email = get(this.appointment, 'customer.email');

        if (email) {
            this.form.get('emails_send_to').setValue([{ value: email }]);
        }

        const emailsAutocompleteItems = get(this.appointment, 'customer.additional_info.emails', []).map(
            (item) => item.email
        );

        this.tagifySettings = this.tagifyService.getEmailAutocompleteFieldSettings(emailsAutocompleteItems);
    }

    setResult(value: any): void {
        this.form.get('result').setValue(value);
    }

    opportunityResultsChanged(index: number, value: any): void {
        const catalogResultReasonControl: AbstractControl = this.opportunityResultsFormArray.get([
            index,
            'result_reason'
        ]);

        setTimeout(() => {
            catalogResultReasonControl.reset();
            catalogResultReasonControl.setValidators(
                AppointmentWonStatuses.includes(value.type) ? null : Validators.required
            );
            catalogResultReasonControl.updateValueAndValidity();
        });
    }

    toggleNotes(): void {
        this.isShowNotes = !this.isShowNotes;
        const control: AbstractControl = this.form.get('notes');

        this.isShowNotes ? control.setValidators(Validators.required) : control.clearValidators();
        control.updateValueAndValidity();
    }

    selectStep(counter: number): void {
        this.selectedStep += counter;
    }

    async finishResulting(): Promise<void> {
        if (this.form.invalid) {
            return;
        }

        const formData = this.form.getRawValue();

        if (
            formData.result?.type &&
            AppointmentWonStatuses.includes(formData.result.type) &&
            this.emailMessageProps.isSendable
        ) {
            const contractsAttached =
                formData.attachments?.length &&
                formData.attachments.some(
                    (attachment) =>
                        attachment.type === DocumentType.Proposal || attachment.type === DocumentType.Contract
                );

            if (!contractsAttached) {
                const modal = await this.modalController.create({
                    component: ConfirmationModalComponent,
                    componentProps: {
                        confirmButtonName: 'Proceed',
                        headerText: 'Please confirm',
                        message:
                            '<div class="result-appointment-confirm-text"><div>You have not selected any of the documents.</div><br/> Do you want to proceed without the contract?</div>'
                    },
                    cssClass: 'result-appointment-modal'
                });

                await modal.present();

                const { data } = await modal.onWillDismiss();

                if (!data) {
                    return;
                }
            }
        }

        formData.send_email = Boolean(formData.emails_send_to.length && formData.email_message);
        formData.emails_send_to = formData.emails_send_to.map(({ value }) => value);

        this.resultingService
            .resultAppointment(this.appointment.id, formData, false)
            .pipe(
                tap((appointmentResult) => {
                    if (appointmentResult.send_mail_details) {
                        this.emailService.openClient(appointmentResult.send_mail_details);
                    }
                }),
                switchMap(() => this.feedbackService.isAllowToShowFeedback())
            )
            .subscribe(async (isAllowedFeedback: boolean) => {
                this.dismiss(true);

                if (isAllowedFeedback) {
                    await this.feedbackModalService.openFeedbackModal();
                }

                const appointmentIdForRecording: string = await this.storageService.get(
                    'isRecordingStartedForAppointmentId'
                );

                if (appointmentIdForRecording === this.appointment.id.toString()) {
                    this.voiceRecordingService.stop();
                }
            });
    }

    private initForm(): void {
        const defaultResult =
            this.availableResults?.results?.length === 1
                ? this.availableResults.results[0]
                : this.availableResults.results.find((item) => AppointmentPendingStatuses.includes(item.type));

        this.form = this.formBuilder.group({
            result: [defaultResult, Validators.required],
            result_reason: '',
            email_message: '',
            emails_send_to: [[]],
            notes: null,
            attachments: [[]],
            opportunity_results: this.formBuilder.array([])
        });

        if (this.resultEachCatalog) {
            this.availableResults.catalogs.forEach(({ name }) =>
                this.opportunityResultsFormArray.push(this.getOpportunityResultFormGroup(name))
            );
        }

        this.form
            .get('result')
            .valueChanges.pipe(startWith(this.form.get('result').value), takeUntil(this.destroy$))
            .subscribe((result) => {
                if (result?.type) {
                    this.getEmailConfig(result.type);
                }

                this.handleOpportunitiesResults();

                const control: AbstractControl = this.form.get('result_reason');

                control.setValue('');
                if (result?.type && AppointmentWonStatuses.includes(result.type)) {
                    control.clearValidators();
                } else if (!this.isI360Lightning) {
                    control.setValidators(Validators.required);
                }
                control.updateValueAndValidity();
            });
    }

    setSelectedDocuments(documents?: any[]): void {
        if (documents) {
            this.documents = documents;
        }

        const attachments = this.documents.filter(
            (document) => document.selected || document.sign || document.required
        );

        this.form.get('attachments').setValue(attachments);
        this.countOfSelectedDocuments = attachments.length;
    }

    private emailConditionallyRequiredValidator(formGroup: UntypedFormGroup): ValidationErrors | null {
        if (
            (!formGroup.value.email_message &&
                !formGroup.value.emails_send_to.length &&
                !AppointmentWonStatuses.includes(formGroup.value.result?.type)) ||
            (formGroup.value.email_message && formGroup.value.emails_send_to.length)
        ) {
            return null;
        }

        return { required: true };
    }

    private getEmailConfig(mailType: MailType): void {
        this.appointmentsService
            .getAppointmentMail(this.appointment.id, mailType)
            .subscribe((value: AppointmentMailSettings) => {
                this.form.get('email_message').setValue(value.body);
                this.emailMessageProps.isReadOnly = value.is_readonly;
                this.emailMessageProps.isSendable = value.is_sendable;
                this.form.setValidators(
                    this.emailMessageProps.isSendable ? this.emailConditionallyRequiredValidator : null
                );
                this.form.updateValueAndValidity();
                this.handleSteps();
            });
    }

    private handleSteps(): void {
        if (this.resultEachCatalog) {
            this.totalSteps = this.emailMessageProps.isSendable ? 3 : 2;
        } else {
            this.totalSteps = this.emailMessageProps.isSendable ? 2 : 1;
        }
    }

    private getOpportunityResultFormGroup(catalogName: string): UntypedFormGroup {
        return this.formBuilder.group({
            catalog_name: catalogName,
            result: [null, Validators.required],
            result_reason: [null, Validators.required]
        });
    }

    private handleOpportunitiesResults(): void {
        if (!this.resultEachCatalog) {
            return;
        }

        this.opportunityResults = [];
        this.availableResults.opportunities_results.forEach(({ results }) => this.opportunityResults.push(...results));
        this.opportunityResultsFormArray.controls.forEach((group: AbstractControl) => {
            const catalogResultControl: AbstractControl = (group as UntypedFormGroup).get('result');
            const catalogResultReasonControl: AbstractControl = (group as UntypedFormGroup).get('result_reason');

            catalogResultControl.reset();
            catalogResultReasonControl.reset();
            catalogResultReasonControl.setValidators(Validators.required);
            catalogResultReasonControl.updateValueAndValidity();
        });
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SortablejsModule } from '@dustfoundation/ngx-sortablejs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ColorPickerModule } from 'ngx-color-picker';
import { TagifyModule } from 'ngx-tagify';

import { IonicModule } from '@ionic/angular';

import { CapitalizePipe } from './pipes/capitalize.pipe';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { InchSignPipe } from './pipes/inch-sign.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { LineBreaksPipe } from './pipes/line-breaks.pipe';
import { OfflineAndroidPipe } from './pipes/offline-android.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { SidingMeasurementTypePipe } from './pipes/siding-measurement-type.pipe';
import { WcpDrawingUrlPipe } from './pipes/wcp-drawing-url.pipe';
import { NewQuoteComparisonComponent } from '../main/appointments/quote/components/new-quote-comparison/new-quote-comparison.component';
import { AddersDetailsComponent } from '@shared/components/adders-details/adders-details.component';
import { AppUpdateComponent } from '@shared/components/app-update/app-update.component';
import { ChangeCustomTaxComponent } from '@shared/components/change-custom-tax/change-custom-tax.component';
import { ClickableImageComponent } from '@shared/components/clickable-image/clickable-image.component';
import { ConfiguratorWarningsComponent } from '@shared/components/configurator-warnings/configurator-warnings.component';
import { CustomizeQuestionComponent } from '@shared/components/customize-question/customize-question.component';
import { DiscountsDropdownComponent } from '@shared/components/discounts-dropdown/discounts-dropdown.component';
import { ErrorsFormComponent } from '@shared/components/errors-form/errors-form.component';
import { ExpiresDropdownComponent } from '@shared/components/expires-dropdown/expires-dropdown.component';
import { FabButtonComponent } from '@shared/components/fab-button/fab-button.component';
import { FileDropComponent } from '@shared/components/file-drop/file-drop.component';
import { FilterComponent } from '@shared/components/filter/filter.component';
import { FinancingComponent } from '@shared/components/financing-options/financing.component';
import { ImageAnnotationToolComponent } from '@shared/components/image-annotation-tool/image-annotation-tool.component';
import { ItemsListPopoverComponent } from '@shared/components/image-annotation-tool/items-list-popover/items-list-popover.component';
import { InstallationNotesComponent } from '@shared/components/installation-notes/installation-notes.component';
import { LineItemAddersComponent } from '@shared/components/line-adders/line-item-adders.component';
import { LineItemDetailsComponent } from '@shared/components/line-item-details/line-item-details.component';
import { LineItemMeasureDetailsComponent } from '@shared/components/line-item-measure-details/line-item-measure-details.component';
import { CenterMapControlComponent } from '@shared/components/map/controls/center-map-control/center-map-control.component';
import { MapComponent } from '@shared/components/map/map.component';
import { MaskedImageComponent } from '@shared/components/masked-image/masked-image.component';
import { NotificationsComponent } from '@shared/components/notifications/notifications.component';
import { NumberPickerComponent } from '@shared/components/number-picker/number-picker.component';
import { PackageSelectorComponent } from '@shared/components/package-selector/package-selector.component';
import { PageHeaderComponent } from '@shared/components/page-header/page-header.component';
import { PdfDocumentViewerModule } from '@shared/components/pdf-document-viewer/pdf-document-viewer.module';
import { PriceDetailsComponent } from '@shared/components/price-details/price-details.component';
import { ProductPreviewComponent } from '@shared/components/product-preview/product-preview.component';
import { ProductSelectionComponent } from '@shared/components/product-selection/product-selection.component';
import { ProjectAddersComponent } from '@shared/components/project-adders/project-adders.component';
import { QuoteActionsComponent } from '@shared/components/quote-actions/quote-actions.component';
import { QuoteNumbersComponent } from '@shared/components/quote-numbers/quote-numbers.component';
import { QuotePricingFieldsComponent } from '@shared/components/quote-pricing-fields/quote-pricing-fields.component';
import { QuoteSummaryComponent } from '@shared/components/quote-summary/quote-summary.component';
import { SelectDocumentsInputComponent } from '@shared/components/select-documents-input/select-documents-input.component';
import { SelectItemComponent } from '@shared/components/select-item/select-item.component';
import { SideExpandAreaComponent } from '@shared/components/side-expand-area/side-expand-area.component';
import { SignatureBarComponent } from '@shared/components/signature-bar/signature-bar.component';
import { SignaturePadComponent } from '@shared/components/signature-pad/signature-pad.component';
import { StartCustomerReviewComponent } from '@shared/components/start-customer-review/start-customer-review.component';
import { StepperProgressBarComponent } from '@shared/components/stepper-progress-bar/stepper-progress-bar.component';
import { StepsNavComponent } from '@shared/components/steps-nav/steps-nav.component';
import { TabsComponent } from '@shared/components/tabs/tabs.component';
import { ToolbarComponent } from '@shared/components/toolbar/toolbar.component';
import { YesNoRadioComponent } from '@shared/components/yes-no-radio/yes-no-radio.component';
import { ZoomPanPinchComponent } from '@shared/components/zoom-pan-pinch/zoom-pan-pinch.component';
import { RuleBuilderComponent } from '@shared/controls/rule-builder/rule-builder.component';
import { RuleRowComponent } from '@shared/controls/rule-builder/rule-row/rule-row.component';
import {
    ContentEditableDirective,
    HorizontalScrollDirective,
    IntegerInputDirective,
    PhoneInputMaskDirective,
    ScrollToMeDirective,
    SelectAllTextDirective
} from '@shared/directives';
import { AddersModalComponent } from '@shared/modals/adders-modal/adders-modal.component';
import { BluetoothDevicesModalComponent } from '@shared/modals/bluetooth-devices-modal/bluetooth-devices-modal.component';
import { ConfigurationInfoModalComponent } from '@shared/modals/configuration-info-modal/configuration-info-modal.component';
import { CopyOpeningModalComponent } from '@shared/modals/copy-opening-modal/copy-opening-modal.component';
import { CopyOpeningWithinQuoteModalComponent } from '@shared/modals/copy-opening-within-quote-modal/copy-opening-within-quote-modal.component';
import { CopyPackageModalComponent } from '@shared/modals/copy-package-modal/copy-package-modal.component';
import { CreateAdderModalComponent } from '@shared/modals/create-adder-modal/create-adder-modal.component';
import { CustomPromotionModalComponent } from '@shared/modals/custom-promotion-modal/custom-promotion-modal.component';
import { EmailResendModalComponent } from '@shared/modals/email-resend-modal/email-resend-modal.component';
import { FormModalComponent } from '@shared/modals/form-modal/form-modal.component';
import { GroupConfigurationModalComponent } from '@shared/modals/group-configuration-modal/group-configuration-modal.component';
import { HouseOnMapComponent } from '@shared/modals/house-on-map/house-on-map.component';
import { ImageAnnotationModalComponent } from '@shared/modals/image-annotation-modal/image-annotation-modal.component';
import { ListItemsModalComponent } from '@shared/modals/list-items-modal/list-items-modal.component';
import { ListPromotionItemsComponent } from '@shared/modals/list-promotion-items/list-promotion-items.component';
import { ListSelectModalComponent } from '@shared/modals/list-select-modal/list-select-modal.component';
import { LoadingModalComponent } from '@shared/modals/loading-modal/loading-modal.component';
import { LoginModalComponent } from '@shared/modals/login-modal/login-modal.component';
import { MeasurementReportDetailsModalComponent } from '@shared/modals/measurement-report-details-modal/measurement-report-details-modal.component';
import { MeasurementReportsModalComponent } from '@shared/modals/measurement-reports-modal/measurement-reports-modal.component';
import { NotesModalComponent } from '@shared/modals/notes-modal/notes-modal.component';
import { OpeningDrawingModalComponent } from '@shared/modals/opening-drawing-modal/opening-drawing-modal.component';
import { PaymentErrorModalComponent } from '@shared/modals/payment-error-modal/payment-error-modal.component';
import { PreferenceTypeModalComponent } from '@shared/modals/preference-type-modal/preference-type-modal.component';
import { PriceModifiersModalComponent } from '@shared/modals/price-modifiers-modal/price-modifiers-modal.component';
import { ProjectAnnotationModalComponent } from '@shared/modals/project-annotation-modal/project-annotation-modal.component';
import { PromotionsSummaryModalComponent } from '@shared/modals/promotions-summary-modal/promotions-summary-modal.component';
import { ProviaFormComponent } from '@shared/modals/provia-order-modal/provia-form/provia-form.component';
import { ProviaOrderModalComponent } from '@shared/modals/provia-order-modal/provia-order-modal.component';
import { ProviaOrdersListComponent } from '@shared/modals/provia-order-modal/provia-orders-list/provia-orders-list.component';
import { QuickInvoiceFormComponent } from '@shared/modals/quick-invoice-modal/quick-invoice-form/quick-invoice-form.component';
import { QuickInvoiceModalComponent } from '@shared/modals/quick-invoice-modal/quick-invoice-modal.component';
import { RadioGroupListModalComponent } from '@shared/modals/radio-group-list-modal/radio-group-list-modal.component';
import { RenamePackageComponent } from '@shared/modals/rename-package/rename-package.component';
import { RequestFinancingApplicationModalComponent } from '@shared/modals/request-financing-application-modal/request-financing-application-modal.component';
import { ResultingModalComponent } from '@shared/modals/resulting-modal/resulting-modal.component';
import { RoofingDimensionsModalComponent } from '@shared/modals/roofing-dimensions-modal/roofing-dimensions-modal.component';
import { SelectActionModalComponent } from '@shared/modals/select-action-modal/select-action-modal.component';
import { SelectDocumentsModalComponent } from '@shared/modals/select-documents-modal/select-documents-modal.component';
import { SelectPackageModalComponent } from '@shared/modals/select-package-modal/select-package-modal.component';
import { SelectPriceBookComponent } from '@shared/modals/select-price-book/select-price-book.component';
import { SelectPricingProfileModalComponent } from '@shared/modals/select-pricing-profile-modal/select-pricing-profile-modal.component';
import { SeriesChangesModalComponent } from '@shared/modals/series-changes-modal/series-changes-modal.component';
import { SetMonthlyInvestmentModalComponent } from '@shared/modals/set-monthly-investment-modal/set-monthly-investment-modal.component';
import { SetQuoteHeadersModalComponent } from '@shared/modals/set-quote-headers-modal/set-quote-headers-modal.component';
import { SignatureConfirmationModalComponent } from '@shared/modals/signature-confirmation-modal/signature-confirmation-modal.component';
import { SignatureModalComponent } from '@shared/modals/signature-modal/signature-modal.component';
import { StatusBarModalComponent } from '@shared/modals/status-bar-modal/status-bar-modal.component';
import { TagsModalComponent } from '@shared/modals/tags-modal/tags-modal.component';
import { TakeoffInfoModalComponent } from '@shared/modals/takeoff-info-modal/takeoff-info-modal.component';
import { UploadFilesModalComponent } from '@shared/modals/upload-files-modal/upload-files-modal.component';
import { UploadPhotosComponent } from '@shared/modals/upload-photos/upload-photos.component';
import { UploadPhotosModalComponent } from '@shared/modals/upload-photos-modal/upload-photos-modal.component';
import { UserPreferencesModalComponent } from '@shared/modals/user-preferences-modal/user-preferences-modal.component';
import { ViewMeasurementReportComponent } from '@shared/modals/view-measurement-report/view-measurement-report.component';
import { AppointmentFileNamePipe } from '@shared/pipes/appointment-file-name.pipe';
import { AppointmentStatusPipe } from '@shared/pipes/appointment-status.pipe';
import { FilterPipe } from '@shared/pipes/filter';
import { HashToLabelPipe } from '@shared/pipes/hash-to-label.pipe';
import { LocalizedDatePipe } from '@shared/pipes/localized-date.pipe';
import { OrdinaryNumberPipe } from '@shared/pipes/ordinary.number.pipe';
import { SortItemsByValuesPipe } from '@shared/pipes/sort-items-by-values.pipe';
import { StartCasePipe } from '@shared/pipes/start-case.pipe';
import { SumPipe } from '@shared/pipes/sum.pipe';
import { AddersListPopoverComponent } from '@shared/popovers/adders-list-popover/adders-list-popover.component';
import { DropdownPopoverComponent } from '@shared/popovers/dropdown-popover/dropdown-popover.component';
import { FinancingDropdownPopoverComponent } from '@shared/popovers/financing-dropdown-popover/financing-dropdown-popover.component';
import { FreeTextPopoverComponent } from '@shared/popovers/free-text-popover/free-text-popover.component';
import { MultiSelectPopoverComponent } from '@shared/popovers/multiselect-popover/multiselect-popover.component';
import { SelectionPopoverComponent } from '@shared/popovers/selection-popover/selection-popover.component';
import { TooltipPopoverComponent } from '@shared/popovers/tooltip-popover/tooltip-popover.component';

const components = [
    FabButtonComponent,
    PageHeaderComponent,
    TabsComponent,
    ToolbarComponent,
    FinancingComponent,
    MapComponent,
    YesNoRadioComponent,
    ClickableImageComponent,
    NumberPickerComponent,
    ExpiresDropdownComponent,
    CenterMapControlComponent,
    FileDropComponent,
    MaskedImageComponent,
    AppUpdateComponent,
    ImageAnnotationToolComponent,
    SideExpandAreaComponent,
    NotificationsComponent,
    ZoomPanPinchComponent,
    ProviaOrdersListComponent,
    ProviaFormComponent,
    QuickInvoiceFormComponent,
    StatusBarModalComponent,
    NewQuoteComparisonComponent,
    FinancingDropdownPopoverComponent,
    UploadPhotosComponent,
    StepperProgressBarComponent,
    ProjectAddersComponent,
    StepsNavComponent,
    CustomizeQuestionComponent,
    ProductPreviewComponent,
    LineItemAddersComponent,
    ConfiguratorWarningsComponent,
    ProductSelectionComponent,
    LineItemMeasureDetailsComponent,
    QuoteNumbersComponent,
    LineItemDetailsComponent,
    PriceDetailsComponent,
    AddersDetailsComponent,
    QuoteActionsComponent,
    QuotePricingFieldsComponent,
    DiscountsDropdownComponent,
    QuoteSummaryComponent,
    InstallationNotesComponent,
    SignatureBarComponent,
    StartCustomerReviewComponent,
    SignaturePadComponent
];

const controls = [RuleBuilderComponent, RuleRowComponent];

const modals = [
    NotesModalComponent,
    OpeningDrawingModalComponent,
    AddersModalComponent,
    ResultingModalComponent,
    RadioGroupListModalComponent,
    SignatureConfirmationModalComponent,
    PaymentErrorModalComponent,
    TagsModalComponent,
    ConfigurationInfoModalComponent,
    TakeoffInfoModalComponent,
    CopyOpeningModalComponent,
    CopyOpeningWithinQuoteModalComponent,
    CopyPackageModalComponent,
    RenamePackageComponent,
    ChangeCustomTaxComponent,
    ImageAnnotationModalComponent,
    CreateAdderModalComponent,
    HouseOnMapComponent,
    SignatureModalComponent,
    CustomPromotionModalComponent,
    SelectDocumentsInputComponent,
    SelectDocumentsModalComponent,
    UserPreferencesModalComponent,
    ProviaOrderModalComponent,
    GroupConfigurationModalComponent,
    SelectPricingProfileModalComponent,
    BluetoothDevicesModalComponent,
    PreferenceTypeModalComponent,
    QuickInvoiceModalComponent,
    SeriesChangesModalComponent,
    PromotionsSummaryModalComponent,
    LoadingModalComponent,
    ProjectAnnotationModalComponent,
    FormModalComponent,
    EmailResendModalComponent,
    LoginModalComponent,
    MeasurementReportsModalComponent,
    RoofingDimensionsModalComponent,
    ErrorsFormComponent,
    PackageSelectorComponent,
    SetMonthlyInvestmentModalComponent,
    ListItemsModalComponent,
    RequestFinancingApplicationModalComponent,
    SelectPriceBookComponent,
    SelectItemComponent,
    FilterComponent,
    SetQuoteHeadersModalComponent,
    SelectActionModalComponent,
    ListSelectModalComponent,
    PriceModifiersModalComponent,
    UploadPhotosModalComponent,
    MeasurementReportDetailsModalComponent,
    ViewMeasurementReportComponent,
    ListPromotionItemsComponent,
    UploadFilesModalComponent,
    SelectPackageModalComponent
];

const popovers = [
    AddersListPopoverComponent,
    DropdownPopoverComponent,
    FreeTextPopoverComponent,
    TooltipPopoverComponent,
    MultiSelectPopoverComponent,
    ItemsListPopoverComponent,
    SelectionPopoverComponent
];

const pipes = [
    FilterPipe,
    InchSignPipe,
    LocalizedDatePipe,
    OrdinaryNumberPipe,
    ReplacePipe,
    SortItemsByValuesPipe,
    SumPipe,
    PhonePipe,
    OfflineAndroidPipe,
    JoinPipe,
    StartCasePipe,
    SidingMeasurementTypePipe,
    LineBreaksPipe,
    CapitalizePipe,
    HashToLabelPipe,
    AppointmentStatusPipe,
    CustomCurrencyPipe,
    AppointmentFileNamePipe,
    WcpDrawingUrlPipe
];

const directives = [
    ContentEditableDirective,
    HorizontalScrollDirective,
    IntegerInputDirective,
    PhoneInputMaskDirective,
    ScrollToMeDirective,
    SelectAllTextDirective
];

@NgModule({
    declarations: [...components, ...controls, ...directives, ...modals, ...pipes, ...popovers],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        TagifyModule,
        SortablejsModule,
        ColorPickerModule,
        PdfDocumentViewerModule,
        NgSelectComponent
    ],
    exports: [
        CommonModule,
        IonicModule,
        FormsModule,
        TagifyModule,
        ReactiveFormsModule,
        SortablejsModule,
        PdfDocumentViewerModule,
        ...components,
        ...controls,
        ...directives,
        ...modals,
        ...popovers,
        ...pipes
    ],
    providers: [...pipes]
})
export class SharedModule {}

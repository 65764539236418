import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private isStorageInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isStorageInitialized$: Observable<boolean> = this.isStorageInitialized.asObservable();
    private _storage: Storage | null = null;

    constructor(private storage: Storage) {}

    async init(): Promise<void> {
        try {
            this._storage = await this.storage.create();
            this.isStorageInitialized.next(true);
        } catch (e) {
            console.error('Storage is not created', e);
        }
    }

    get(key: string): Promise<any> {
        return this._storage?.get(key);
    }

    async remove(key: string): Promise<void> {
        await this._storage?.remove(key);
    }

    async set(key: string, value: any): Promise<void> {
        await this._storage?.set(key, value);
    }
}

import { AlertController } from '@ionic/angular';

import { from, Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';

import cloneDeep from 'lodash/cloneDeep';

import { AuthService } from '@core/services/auth.service';
import { FilesService } from '@core/services/files.service';
import { OfflineStorageService } from '@core/services/offline/offline-storage.service';
import { SpinnerService } from '@core/services/spinner.service';
import { AppointmentImageType } from '@shared/enums/appointment-image-type.enum';
import { AppointmentType } from '@shared/enums/appointment-type';
import { OfflineTableName } from '@shared/enums/offline-table-name';
import { Appointment } from '@shared/interfaces/appointment';
import { AppointmentImage } from '@shared/interfaces/appointment-image';
import { AppointmentRequiredImages } from '@shared/interfaces/appointment-required-images';
import { SaveAppointmentImages } from '@shared/interfaces/save-appointment-images';

declare let moment: any;

export class AppointmentOffline {
    constructor(
        private authService: AuthService,
        private alertController: AlertController,
        private fileService: FilesService,
        private offlineStorageService: OfflineStorageService,
        private spinnerService: SpinnerService
    ) {}

    getAppointment(id: number): Observable<any> {
        const user = this.authService.getUser();
        const appointment = this.offlineStorageService.findOne(
            `
                SELECT *
                FROM ${OfflineTableName.Appointments}
                WHERE id = '${id}'
                  AND seller_id = '${user.id}'
                  AND office_id = '${user.office.id}'
            `,
            OfflineTableName.Appointments
        );

        return from(appointment);
    }

    getAppointmentCustomTax(id: number): Observable<any> {
        return of({
            accessibility: {
                change_tax: false
            }
        });
    }

    getAppointmentQuotes(appointmentId: string | number): Observable<any[]> {
        return of([]);
    }

    async getAppointments(date?: string): Promise<any> {
        const unixFromDate = moment.utc(date).unix();
        const unixToDate = moment.utc(date).add(1, 'd').unix();
        const user = this.authService.getUser();

        return this.offlineStorageService
            .read(
                `
                    SELECT *
                    FROM ${OfflineTableName.Appointments}
                    WHERE seller_id = '${user.id}'
                      AND office_id = '${user.office.id}'
                      AND schedule_time_timestamp >= ${unixFromDate}
                      AND schedule_time_timestamp < ${unixToDate}
                    ORDER BY schedule_time_timestamp DESC
                `,
                OfflineTableName.Appointments
            )
            .then((res) => {
                return res.map((appointment) => {
                    appointment.isExistViewItems = appointment.openings.some((opening) => !!opening.view_photo_id);

                    return appointment;
                });
            })
            .catch(() => []);
    }

    startAppointment(appointmentId: number): Observable<Appointment> {
        const user = this.authService.getUser();
        const promise = new Promise<Appointment>(async (resolve, reject) => {
            const appointment = await this.offlineStorageService.findOne(
                `
                    SELECT *
                    FROM ${OfflineTableName.Appointments}
                    WHERE id = '${appointmentId}'
                      AND seller_id = '${user.id}'
                      AND office_id = '${user.office.id}'
                `,
                OfflineTableName.Appointments
            );

            if (!appointment || appointment.status !== 'not_started') {
                reject(false);

                return;
            }

            appointment.status = 'pending';
            appointment.started_at = moment.utc().format('YYYY-MM-DD HH:mm:ss');
            appointment.updated = '1';

            await this.offlineStorageService.insertOne(OfflineTableName.Appointments, appointment);

            resolve(appointment);
        });

        return from(promise);
    }

    async uploadOfflineChanges(): Promise<boolean> {
        const user = cloneDeep(this.authService.getUser());

        if (!user) {
            return false;
        }

        const updatedEntities: any = await this.offlineStorageService.updatedEntities(user);

        if (
            !updatedEntities.updatedAppointments?.length &&
            !updatedEntities.updatedOpenings?.length &&
            !updatedEntities.createdOpenings?.length &&
            !updatedEntities.deletedOpenings?.length
        ) {
            return false;
        }

        const alert = await this.alertController.create({
            header: 'Offline items to sync',
            message:
                'Items created offline need to be synced up to keep your appointments up to date. Sync those items now?',
            backdropDismiss: false,
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel'
                },
                {
                    text: 'Sync Items',
                    handler: () => {
                        alert.dismiss(true);

                        return false;
                    }
                }
            ]
        });

        await alert.present();

        const { data } = await alert.onDidDismiss();

        if ((typeof data !== 'boolean' && !data.values) || !data) {
            this.offlineStorageService.hasUnsyncData = true;

            return false;
        }

        return this.offlineStorageService.syncData(updatedEntities);
    }

    getAppointmentImages(
        appointment_id: number,
        opening_id: number,
        types: AppointmentImageType[]
    ): Observable<AppointmentImage[]> {
        const user = this.authService.getUser();
        const promise: Promise<AppointmentImage[]> = new Promise<AppointmentImage[]>(async (resolve, reject) => {
            const appointment = await this.offlineStorageService.findOne(
                `
                    SELECT *
                    FROM ${OfflineTableName.Appointments}
                    WHERE id = '${appointment_id}'
                      AND seller_id = '${user.id}'
                      AND office_id = '${user.office.id}'
                `,
                OfflineTableName.Appointments
            );

            let photos: AppointmentImage[] = [];

            if (opening_id === null) {
                appointment.openings.forEach(({ images }) => {
                    const openingImages = images.filter(({ type }) => types.includes(type));

                    photos.push(...openingImages);
                });
            } else {
                const opening = appointment.openings.find(({ id }) => id === `${opening_id}`);

                if (opening) {
                    photos = opening.images.filter(({ type }) => types.includes(type));
                }
            }

            resolve(photos);
        });

        return from(promise);
    }

    saveAppointmentImages(
        appointment_id: number,
        type: AppointmentImageType,
        images_data: any[]
    ): Observable<SaveAppointmentImages> {
        const user = this.authService.getUser();
        const promise: Promise<SaveAppointmentImages> = new Promise<SaveAppointmentImages>(async (resolve, reject) => {
            const appointment = await this.offlineStorageService.findOne(
                `
                    SELECT *
                    FROM ${OfflineTableName.Appointments}
                    WHERE id = '${appointment_id}'
                      AND seller_id = '${user.id}'
                      AND office_id = '${user.office.id}'
                `,
                OfflineTableName.Appointments
            );

            const opening = appointment.openings.find(({ id }) => id === `${images_data[0].opening_id}`);

            images_data[0].images.forEach((image) => {
                if (!image.appointment_type) {
                    image.appointment_type = AppointmentType.Sales;
                }
            });
            opening.images = images_data[0].images;
            opening.updated = '1';

            await this.offlineStorageService.insertOne(OfflineTableName.Appointments, appointment);

            resolve({ saveAppointmentImages: true });
        });

        return from(promise);
    }

    getAppointmentRequiredImages(
        appointment_id: number,
        opening_id?: number,
        check_only_configured_openings?: boolean
    ): Observable<AppointmentRequiredImages> {
        const user = this.authService.getUser();
        const appointmentPromise = this.offlineStorageService.findOne(
            `SELECT *
             FROM ${OfflineTableName.Appointments}
             WHERE id = '${appointment_id}'
               AND seller_id = '${user.id}'
               AND office_id = '${user.office.id}'`,
            OfflineTableName.Appointments
        );
        const categoriesPromise = this.offlineStorageService.read(
            `SELECT *
             FROM ${OfflineTableName.Categories}
             WHERE user_id = '${user.id}'
               AND office_id = '${user.office.id}'
             ORDER BY position DESC`,
            OfflineTableName.Categories
        );

        const promise = Promise.all([appointmentPromise, categoriesPromise]).then(([appointment, categories]) => {
            const requiredImageNamesByCategoryType = {};

            for (const category of categories) {
                const salesImages = category.predefined_images.find(
                    ({ appointment_type }) => appointment_type === appointment.type
                );

                if (salesImages) {
                    requiredImageNamesByCategoryType[category.category.id] = salesImages.predefined_images
                        .filter((predefinedImage) => predefinedImage.is_required)
                        .map((predefinedImage) => predefinedImage.label);
                }
            }

            let isRequiredImagesUploaded = true;

            const openingImagesData = appointment.openings.map((openingData) => {
                const addedImageNames = new Set(openingData.images.map(({ name }) => name));

                const requiredImageNames = requiredImageNamesByCategoryType[openingData.category_id] || [];

                const requireCount = requiredImageNames.length;
                const addedCount = requiredImageNames.filter((requiredName) =>
                    addedImageNames.has(requiredName)
                ).length;

                if (isRequiredImagesUploaded && addedCount < requireCount) {
                    isRequiredImagesUploaded = false;
                }

                return {
                    opening_id: openingData.id,
                    addedCount,
                    requireCount
                };
            });

            return {
                is_required_images_uploaded: isRequiredImagesUploaded,
                opening_required_images: openingImagesData
            };
        });

        return from(promise);
    }

    uploadImage(file: File): Observable<any> {
        this.spinnerService.showSpinner();

        return from(
            this.fileService
                .saveBlobLocally(file, `openingimages${new Date().valueOf()}/`)
                .then((path) => this.fileService.getFileUrl(path))
        ).pipe(finalize(() => this.spinnerService.hideSpinner()));
    }
}

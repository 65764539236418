export const OFFLINE_CATEGORIES_FIELDS = [
    'category',
    'id',
    'name',
    'opening_conditions',
    'predefined_images',
    // custom added
    'user_id',
    'office_id',
    'position'
];

import { gql } from 'apollo-angular';

const SIGN_E_DOCUMENT = gql`
    mutation CustomerSignEDocument(
        $hash: String!
        $instructions: [DocumentInstruction!]!
        $applicationUrl: String!
        $updatedAt: String
    ) {
        customerSignEDocument(
            hash: $hash
            instructions: $instructions
            application_url: $applicationUrl
            updated_at: $updatedAt
        ) {
            document
            errors
        }
    }
`;

const UPLOAD_E_DOCUMENT_IMAGE = gql`
    mutation CustomerUploadImageEDocument($hash: String!, $type: CustomerImageTypeEnum!, $file: Upload!) {
        customerUploadImageEDocument(hash: $hash, type: $type, file: $file)
    }
`;

const DOWNLOAD_E_DOCUMENT_PDF = gql`
    mutation CustomerDownloadEDocument($hash: String!, $authToken: String) {
        customerDownloadEDocument(hash: $hash, auth_token: $authToken) {
            url
            name
            errors
        }
    }
`;

export { SIGN_E_DOCUMENT, UPLOAD_E_DOCUMENT_IMAGE, DOWNLOAD_E_DOCUMENT_PDF };

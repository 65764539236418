import { Component, Input, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { takeUntil } from 'rxjs/operators';

import { StatusBarService } from '@core/services/status-bar.service';
import { BaseModal } from '@shared/modals/base-modal';

@Component({
    selector: 'vendo-status-bar-modal',
    templateUrl: './status-bar-modal.component.html',
    styleUrls: ['./status-bar-modal.component.scss']
})
export class StatusBarModalComponent extends BaseModal implements OnInit {
    @Input() text: string;
    value = 0;

    constructor(
        public modalController: ModalController,
        private statusBarService: StatusBarService
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.statusBarService.statusBarPercentage$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
            this.value = value;

            if (this.value === 100) {
                this.dismiss(true);
                this.statusBarService.clearStatusBarModalDidDismiss();
            }
        });
    }
}

import { Injectable } from '@angular/core';
import { Route, UrlSegment } from '@angular/router';

import { NavController } from '@ionic/angular';

import { RollbarErrorHandler } from '@core/handlers/rollbar-error-handler';
import { StorageService } from '@core/services/storage.service';
import { ApplicationRole } from '@shared/enums/application-role.enum';

@Injectable()
export class AuthGuard {
    constructor(
        private navController: NavController,
        private rollbarErrorHandler: RollbarErrorHandler,
        private storageService: StorageService
    ) {}

    canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
        let search = '';

        if (window.location.search) {
            search = decodeURI(window.location.search);
        }

        return this.storageService.get('user').then((user) => {
            const parsedUser = JSON.parse(user);

            if (!parsedUser || parsedUser.application_role_hash === ApplicationRole.CapturePayment) {
                this.rollbarErrorHandler.handleInfo('Logout - Token not found in AuthGuard');
                this.navController.navigateRoot(['logout'], {
                    queryParams: {
                        returnUrl: `${decodeURI(segments.map((segment) => segment.path).join('/'))}${search}`
                    }
                });
            }

            return !!user;
        });
    }

    canActivate(): Promise<boolean> {
        return this.storageService.get('user').then((user) => {
            const parsedUser = JSON.parse(user);

            return !!parsedUser && parsedUser.application_role_hash !== ApplicationRole.CapturePayment;
        });
    }
}

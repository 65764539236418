<div class="modal-header">
    <ion-text class="title">{{ headerText }}</ion-text>
    <ion-searchbar debounce="500"
                   [placeholder]="placeholder"
                   [(ngModel)]="searchInput"
                   (ionInput)="handleSearch()"
                   mode="ios"></ion-searchbar>
</div>

<div class="modal-body">
    <ion-radio-group [(ngModel)]="selectedValue">
        <ion-grid>
            <ion-row [class.scroll-compensation]="isDesktop && items.length > 7">
                <ion-col *ngFor="let col of cols; let i = index"
                         [class.cursor-pointer]="i > 0"
                         [size]="col.size"
                         (click)="order(col)">
                    <ion-text>{{ col.label }}</ion-text>
                    <ng-container *ngIf="col.orderBy">
                        <ion-icon *ngIf="orderingColumn === col.orderBy"
                                  [name]="orderingOrientation === orderDirections.Desc ? 'arrow-down-outline' : 'arrow-up-outline'"></ion-icon>
                        <ng-container *ngIf="orderingColumn !== col.orderBy && items.length">
                            <ion-icon class="inactive" name="arrow-up-outline"></ion-icon>
                            <ion-icon class="inactive" name="arrow-down-outline"></ion-icon>
                        </ng-container>
                    </ng-container>
                </ion-col>
            </ion-row>

            <ion-content class="modal-body__content">
                <ion-grid>
                    <ion-row *ngFor="let item of items"
                             class="cursor-pointer"
                             (click)="setSelectedValue(item)">
                        <ion-col *ngFor="let col of cols" [size]="col.size">
                            <ion-radio legacy="true" *ngIf="col.type === 'radio'"
                                       [value]="item[col.value]"
                                       mode="md"></ion-radio>
                            <ion-text *ngIf="col.type === 'text'">{{ item[col.value] }}</ion-text>
                        </ion-col>
                    </ion-row>
                </ion-grid>

                <ion-infinite-scroll threshold="50px" (ionInfinite)="loadNextPage($event)">
                     <ion-infinite-scroll-content [loadingSpinner]="null"></ion-infinite-scroll-content>
                </ion-infinite-scroll>
            </ion-content>
        </ion-grid>
    </ion-radio-group>
</div>

<div class="modal-footer">
    <ion-button *ngIf="showCancelButton"
                class="classic-width outline"
                (click)="closeModal()">
        {{ cancelButtonName }}
    </ion-button>

    <ion-button class="classic-width"
                [disabled]="!items.length || !selectedValue || !selectedValueObj"
                (click)="confirm()">
        {{ confirmButtonName }}
    </ion-button>
</div>

<div class="modal-header">
    <ion-text class="title">Photo Annotation</ion-text>
    <ion-button fill="outline" color="dark" (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
</div>
<div class="modal-body">
    <vendo-image-annotation-tool
            *ngIf="lineTypes?.length"
            [imgUrl]="imageData.imgUrl"
            [feetPerPixel]="imageData.feet_per_pixel"
            [lineTypes]="lineTypes"
            [segments]="segments"
            [hideBackground]="hideBackground"
            [existingOpeningsCount]="existingOpeningsCount"
    ></vendo-image-annotation-tool>
</div>
<div class="modal-footer">
    <div>
        <ion-button class="classic-width" fill="outline" (click)="goToMap()">Back to Map</ion-button>
        <ion-button class="classic-width ion-align-self-start" color="danger" fill="outline" *ngIf="!imageFile"
                    (click)="deleteAnnotation()">Delete
        </ion-button>
    </div>
    <div>
        <ion-button class="classic-width outline" (click)="toggleBackground()">
            {{hideBackground ? 'Show' : 'Hide'}} Background
        </ion-button>
        <ion-button class="classic-width" (click)="done()">Done</ion-button>
    </div>
</div>

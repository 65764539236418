<div class="modal-header">
    <ion-text class="title">Configuration Groups</ion-text>
    <ion-button fill="outline" color="dark" (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
</div>

<div class="modal-body">
    <ion-content>
        <ion-grid>
            <ion-row class="group-row" *ngFor="let group of groups">
                <ion-col size="12">
                    <ion-item class="group" [detail]="false">
                        <ion-input legacy="true" type="text" [(ngModel)]="group.name"></ion-input>
                        <ion-button slot="end" color="danger" fill="outline" class="no-borders" *ngIf="group.touched"
                                    (click)="saveGroup(group)">
                            <ion-icon name="save-outline"></ion-icon>
                        </ion-button>

                        <ion-button slot="end"
                                    fill="outline"
                                    class="no-borders"
                                    (click)="group.id !== expandedGroup?.id ? expandGroup(group) : collapseGroup()">
                            <ion-icon [name]="group.id !== expandedGroup?.id ? 'chevron-forward-outline' : 'chevron-down-outline'"></ion-icon>
                        </ion-button>
                    </ion-item>

                    <ion-grid *ngIf="expandedGroup?.id === group.id && expandedGroup.openings">
                        <ion-row class="opening-row" *ngFor="let opening of expandedGroup.openings">
                            <ion-col size="1" class="opening-col">
                                <ion-checkbox legacy="true"
                                              mode="md"
                                              [checked]="opening.included"
                                              [disabled]="group.group_type && opening.group_type !== group.group_type"
                                              (ionChange)="selectionChanged(group, opening, $event.detail.checked)"></ion-checkbox>
                            </ion-col>
                            <ion-col class="opening-col">{{ opening.name }}</ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-content>
</div>

<div class="modal-footer">
    <ion-button fill="outline" (click)="addGroup()">
        Add group
    </ion-button>

    <ion-button (click)="dismiss(true)">
        Close
    </ion-button>
</div>

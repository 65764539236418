import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ModalController, NavController, PopoverController } from '@ionic/angular';

import { forkJoin, merge, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import isNil from 'lodash/isNil';
import round from 'lodash/round';
import toInteger from 'lodash/toInteger';

import { FeaturesService } from '@core/services/features.service';
import { QuoteService } from '@core/services/quote.service';
import { RoundingService } from '@core/services/rounding.service';
import { FEATURES } from '@shared/constants/features';
import { paradigmFinanceOfferId } from '@shared/constants/paradigm-finance-offer-id';
import { AppointmentType } from '@shared/enums/appointment-type';
import { DepositType } from '@shared/enums/deposit-type.enum';
import { PromotionStackableWithType } from '@shared/enums/promotion.enum';
import { QuotePricePresentationFieldType } from '@shared/enums/quote-price-presentation-field-type.enum';
import { SaleStepType } from '@shared/enums/sale-step-type.enum';
import { Unsubscriber } from '@shared/helpers/unsubscriber';
import { Adder } from '@shared/interfaces/adder';
import { Appointment } from '@shared/interfaces/appointment';
import { Discount } from '@shared/interfaces/discount';
import {
    DepositPriceFields,
    ExtraPriceFields,
    FinancingOptionPriceFields
} from '@shared/interfaces/extra-price-fields';
import { FinancingOption } from '@shared/interfaces/financing-option';
import { Promotion } from '@shared/interfaces/promotion';
import { Quote, QuoteLineItem } from '@shared/interfaces/quote';
import { QuotePricePresentationPackageViewSetting } from '@shared/interfaces/quote-price-presentation-package-view-setting';
import { CustomPromotionModalComponent } from '@shared/modals/custom-promotion-modal/custom-promotion-modal.component';
import { FinancingDropdownPopoverComponent } from '@shared/popovers/financing-dropdown-popover/financing-dropdown-popover.component';

import { ParadigmFinanceRateSheetSelectedService } from '../../../main/appointments/services/paradigm-finance-rate-sheet-selected.service';

@Component({
    selector: 'vendo-quote-pricing-fields',
    templateUrl: './quote-pricing-fields.component.html',
    styleUrls: ['./quote-pricing-fields.component.scss']
})
export class QuotePricingFieldsComponent extends Unsubscriber implements OnInit, OnChanges {
    @Input() quote: Quote;
    @Input() availableDiscountIds: number[];
    @Input() promotions: Promotion[];
    @Input() selectedPromotions: Promotion[];
    @Input() selectedPromotionIds: string[];
    @Input() activeTabId: number;
    @Input() pricePresentationSettings: QuotePricePresentationPackageViewSetting[] = [];
    @Input() appointment: Appointment;
    @Input() selectedOfferCode: any;
    @Output() selectedOfferCodeChange: EventEmitter<any> = new EventEmitter<any>();
    @Input() selectedFinancingOption: FinancingOption;
    @Output() selectedFinancingOptionChange: EventEmitter<FinancingOption> = new EventEmitter<FinancingOption>();
    @Input() allFinancingOptions: FinancingOption[] = [];
    @Input() allApprovedOffers: FinancingOption[] = [];
    @Input() offerCodeAllRateSheets: any[] = [];
    @Input() monthlyPayment: number;
    @Output() monthlyPaymentChange: EventEmitter<number> = new EventEmitter<number>();
    @Input() balance: number;
    @Output() balanceChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() customPromotionCreated: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Output() discountApplied: EventEmitter<any> = new EventEmitter<any>();
    @Output() isAmountDepositChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    approvedOffers: FinancingOption[] = [];
    financingOptions: FinancingOption[] = [];
    isParadigmFinanceUser: boolean;
    allRateSheets: any[] = [];
    amountFinancing: number;
    isAmountDeposit: boolean;
    isShowFinancingDropdown = false;
    isCaretUp = false;
    isCustomPromotionsAvailable: boolean;
    depositPricePresentation: QuotePricePresentationPackageViewSetting;
    financingOptionControl = this.formBuilder.control(null);
    depositControl = this.formBuilder.control(0);
    readonly paradigmFinanceName = 'Paradigm Finance';
    readonly hasParadigmFinanceFeature: boolean = this.featureService.hasFeature(
        [FEATURES.PARADIGM_FINANCE, FEATURES.PARADIGM_FINANCE_SANDBOX],
        false
    );
    private packageChanged$: Subject<void> = new Subject<void>();

    constructor(
        private cdr: ChangeDetectorRef,
        private featureService: FeaturesService,
        private formBuilder: UntypedFormBuilder,
        private modalController: ModalController,
        private navController: NavController,
        private paradigmFinanceRateSheetSelectedService: ParadigmFinanceRateSheetSelectedService,
        private popoverController: PopoverController,
        private roundingService: RoundingService,
        private route: ActivatedRoute,
        private quoteService: QuoteService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.quote?.currentValue) {
            const promotionStackableWith = [...Object.values(PromotionStackableWithType)];

            this.isCustomPromotionsAvailable =
                !this.quote.discount ||
                this.quote.discount_details.every((discount: Discount) =>
                    promotionStackableWith.every((type: PromotionStackableWithType) =>
                        discount.stackable_with.includes(type)
                    )
                );

            this.amountFinancing = this.roundingService.round(this.quote.final_price - this.quote.deposit);
            this.isAmountDeposit = this.quote.deposit_type === DepositType.STATIC;
            this.setDepositFieldSubscription();
            this.depositControl.setValue(this.quote.deposit_amount, { emitEvent: false });

            if (this.allFinancingOptions?.length || this.allApprovedOffers.length) {
                const financingName: string = this.selectedOfferCode?.name || this.selectedFinancingOption?.name;

                this.financingOptionControl.setValue(financingName || '');
                this.financingOptions = this.allFinancingOptions;
                this.approvedOffers = this.allApprovedOffers.filter(
                    (option: FinancingOption) => parseInt(option.quote_id, 10) === this.quote.quote_id
                );
            }

            if (this.quote.financing_options?.id !== paradigmFinanceOfferId) {
                this.paradigmFinanceRateSheetSelectedService.removeRateSheet(this.appointment.id, this.quote.quote_id);
            }
        }
    }

    ngOnInit(): void {
        this.isParadigmFinanceUser = !!this.route.snapshot.data.financingOptions.financingUsers.length;
        this.pricePresentationSettings.forEach((setting: QuotePricePresentationPackageViewSetting) => {
            switch (setting.hash) {
                case QuotePricePresentationFieldType.Deposit:
                    this.depositPricePresentation = setting;
                    break;
                case QuotePricePresentationFieldType.Balance:
                    this.isShowFinancingDropdown = !setting.fields.is_financing_dropdown_hidden;
                    break;
            }
        });

        if (this.isParadigmFinanceUser) {
            this.getFinancingOfferCodesAndRateSheets();
        }
    }

    async createCustomPromotion(): Promise<any> {
        const projectAddersNotDiscountableSum = this.getSumDiscountableFinalPrice(this.quote.adders);
        const lineAddersNotDiscountableSum = this.quote.line_items.reduce(
            (total: number, { adders }: QuoteLineItem) => total + this.getSumDiscountableFinalPrice(adders),
            0
        );
        const maxAmount: number = this.roundingService.round(
            this.quote.sub_final_price - projectAddersNotDiscountableSum - lineAddersNotDiscountableSum
        );

        const modal = await this.modalController.create({
            component: CustomPromotionModalComponent,
            cssClass: 'custom-promotion-modal',
            componentProps: {
                maxAmount
            }
        });

        await modal.present();

        const { data } = await modal.onWillDismiss();

        if (data) {
            this.quoteService
                .applyCustomPromotion(
                    this.appointment.id,
                    this.quote.quote_id,
                    data.name,
                    data.amount,
                    data.amount_type,
                    this.selectedPromotionIds
                )
                .subscribe((quotes: any[]) => this.customPromotionCreated.emit(quotes));
        } else {
            this.customPromotionCreated.emit();
        }
    }

    clearFinancingOption(): void {
        this.financingOptionControl.patchValue('');
        this.selectedOfferCode = null;
        this.selectedOfferCodeChange.emit(this.selectedOfferCode);
        this.financingOptionChanged();
    }

    financingOptionChanged(financingOption?: FinancingOption): void {
        this.selectedFinancingOption = financingOption
            ? [...this.allFinancingOptions, ...this.approvedOffers].find(
                  ({ id }: FinancingOption) => id === financingOption.id
              )
            : null;

        if (this.selectedOfferCode && !this.selectedFinancingOption) {
            this.selectedFinancingOption = {
                id: paradigmFinanceOfferId,
                type: this.quoteService.financingType,
                name: this.paradigmFinanceName,
                interest_rate: 0,
                months: 0
            };
        }
        this.selectedFinancingOptionChange.emit(this.selectedFinancingOption);

        const financingData = this.getFinancingData();

        this.getCalculations(financingData);
        this.saveFinancingOption(this.quote.quote_id, financingData?.id);
    }

    navigateToFinancingPage(): void {
        this.navController.navigateRoot(
            `/main/appointments/${this.appointment.id}/${
                this.appointment.type === AppointmentType.SecondMeasure ? SaleStepType.REVIEW : SaleStepType.QUOTE
            }/${this.quote.quote_id}/financing`
        );
    }

    async selectFinancingOption(event?: any): Promise<any> {
        const standardOffers = this.financingOptions;
        let dropdownOptions: any[] = standardOffers;

        this.isCaretUp = !this.isCaretUp;

        if (this.hasParadigmFinanceFeature && this.isParadigmFinanceUser) {
            const itemsWithRange = [];

            this.offerCodeAllRateSheets.forEach((item) => {
                if (
                    !(
                        (item.min_price_to_apply !== null && item.min_price_to_apply > this.amountFinancing) ||
                        (item.max_price_to_apply !== null && item.max_price_to_apply < this.amountFinancing)
                    )
                ) {
                    itemsWithRange.push(item);
                }
            });

            const offerCodeRateSheets: any[] = itemsWithRange
                .map((rateSheet) => ({
                    parent_id: rateSheet.parent_id,
                    offerCodeName: rateSheet.offerCodeName,
                    name: rateSheet.product_term_structure.short_description,
                    hash: rateSheet.id,
                    product_category: rateSheet.product_category,
                    interest_rate: rateSheet.product_term_structure.apr_range.includes('to')
                        ? parseFloat(rateSheet.product_term_structure.apr_range.split(' ')[0])
                        : parseFloat(rateSheet.product_term_structure.apr_range),
                    months: rateSheet.product_term_structure.term_range.includes('to')
                        ? parseFloat(rateSheet.product_term_structure.term_range.split(' ')[0])
                        : parseFloat(rateSheet.product_term_structure.term_range)
                }))
                .filter((rateSheet) => rateSheet.product_category);

            const allRateSheets = cloneDeep(this.allRateSheets);
            const uniqRateSheets = [];

            allRateSheets.forEach((rateSheetItem) => {
                const itemExistsInOfferCode = offerCodeRateSheets.some(
                    (item) =>
                        item.name === rateSheetItem.product_name &&
                        item.product_category === rateSheetItem.product_category
                );

                if (!itemExistsInOfferCode) {
                    delete rateSheetItem.id;

                    uniqRateSheets.push(rateSheetItem);
                }
            });

            if (this.approvedOffers.length) {
                offerCodeRateSheets.unshift(...this.approvedOffers);
            }

            dropdownOptions = standardOffers.length
                ? [
                      {
                          name: this.paradigmFinanceName,
                          hash: paradigmFinanceOfferId,
                          children: [...offerCodeRateSheets, ...uniqRateSheets]
                      },
                      {
                          name: 'Standard Offers',
                          hash: 'standard_offers',
                          children: standardOffers
                      }
                  ]
                : [...offerCodeRateSheets, ...uniqRateSheets];
        }

        const popover = await this.popoverController.create({
            component: FinancingDropdownPopoverComponent,
            event,
            translucent: true,
            backdropDismiss: true,
            showBackdrop: false,
            mode: 'md',
            cssClass: 'dropdown-popover financing-options',
            componentProps: {
                items: dropdownOptions,
                isUseCollapse: this.hasParadigmFinanceFeature && this.isParadigmFinanceUser && standardOffers.length,
                selectedRateSheetId: this.selectedOfferCode?.id,
                selectedFinancingOptionId:
                    this.selectedFinancingOption?.id !== paradigmFinanceOfferId
                        ? this.selectedFinancingOption?.id
                        : null
            }
        });

        await popover.present();

        const result = await popover.onWillDismiss();

        this.isCaretUp = !this.isCaretUp;

        if (
            !result?.data ||
            (result.data.id
                ? result.data.id === this.selectedFinancingOption?.id
                : result.data.hash === this.selectedOfferCode?.id)
        ) {
            return;
        }

        const financingOption: FinancingOption = [...this.financingOptions, ...this.approvedOffers].find(
            (option) => option.id === result.data.id
        );

        this.financingOptionControl.patchValue(result.data.name);

        const allRateSheets = [...this.allRateSheets, ...this.offerCodeAllRateSheets];

        this.selectedOfferCode = result.data.id ? null : allRateSheets.find(({ id }) => id === result.data.hash);
        this.selectedOfferCodeChange.emit(this.selectedOfferCode);

        if (this.selectedOfferCode) {
            this.paradigmFinanceRateSheetSelectedService.setRateSheet(
                this.appointment.id,
                this.quote.quote_id,
                this.selectedOfferCode.id
            );
        } else {
            this.paradigmFinanceRateSheetSelectedService.removeRateSheet(this.appointment.id, this.quote.quote_id);
        }
        this.financingOptionChanged(financingOption);
    }

    toggleDepositType(): void {
        this.isAmountDeposit = !this.isAmountDeposit;

        if (
            (!this.isAmountDeposit && this.depositControl.value > 100) ||
            (this.isAmountDeposit && this.depositControl.value > this.quote.final_price)
        ) {
            this.depositControl.setValue(0);
        } else {
            this.getCalculations();
            this.saveDeposit();
        }
    }

    getCalculations(financing?): void {
        const depositData: DepositPriceFields = {
            deposit_amount: this.depositControl.value || 0,
            deposit_type: this.isAmountDeposit ? DepositType.STATIC : DepositType.PERCENT
        };

        let financingData: FinancingOptionPriceFields;

        if (!financing) {
            financing = this.getFinancingData();
        }

        if (financing && financing.id !== paradigmFinanceOfferId) {
            financingData = {
                id: financing.id,
                interest_rate: financing.interest_rate,
                payment_factor: financing.payment_factor,
                months: financing.months
            };
        }

        this.quoteService
            .getExtraPriceFields(this.quote.final_price, depositData, financingData)
            .subscribe((data: ExtraPriceFields) => {
                this.balance = data.balance;
                this.balanceChange.emit(this.balance);
                this.quote.deposit_amount = depositData.deposit_amount;
                this.quote.deposit_type = depositData.deposit_type;
                this.quote.deposit = data.deposit;
                this.monthlyPayment = financing ? data.monthly_payment : null;
                this.monthlyPaymentChange.emit(this.monthlyPayment);
            });
    }

    private getFinancingData(): any {
        if (!this.selectedFinancingOption) {
            return undefined;
        }

        return {
            id:
                this.selectedFinancingOption.id === paradigmFinanceOfferId
                    ? this.selectedFinancingOption.id
                    : toInteger(this.selectedFinancingOption.id),
            interest_rate: this.selectedFinancingOption.interest_rate,
            payment_factor: this.selectedFinancingOption.payment_factor,
            months: this.selectedFinancingOption.months,
            ...(this.monthlyPayment !== null && { monthly_payment: this.monthlyPayment })
        };
    }

    private setDepositFieldSubscription(): void {
        if (this.packageChanged$) {
            this.packageChanged$.next();
            this.packageChanged$.complete();
        }

        this.packageChanged$ = new Subject<void>();
        this.depositControl.valueChanges
            .pipe(
                debounceTime(500),
                filter(() => this.activeTabId !== null),
                map((value: number) => {
                    let currentValue: number = value;

                    if (value < 0) {
                        currentValue = 0;
                    }

                    if (!this.isAmountDeposit && value > 100) {
                        currentValue = 100;
                    }

                    if (this.isAmountDeposit && value > this.quote.final_price) {
                        currentValue = this.quote.final_price;
                    }

                    const roundedValue: number = currentValue === null ? 0 : round(currentValue, 2);

                    this.depositControl.setValue(roundedValue, { emitEvent: false });

                    return roundedValue;
                }),
                distinctUntilChanged((prev: number, next: number) => prev === next),
                takeUntil(merge(this.destroy$, this.packageChanged$))
            )
            .subscribe(() => {
                this.getCalculations();
                this.saveDeposit();
            });
    }

    private saveDeposit(): void {
        this.quoteService
            .saveDeposit(this.quote.quote_id, this.isAmountDeposit, this.depositControl.value)
            .subscribe(() => {
                const financingData = this.getFinancingData();

                if (financingData) {
                    this.saveFinancingOption(this.quote.quote_id, financingData.id);
                }
            });
    }

    private saveFinancingOption(quoteId: number, financingOptionId: number): void {
        this.quoteService
            .setFinancingOption(quoteId, financingOptionId)
            .subscribe(() => (this.quote.financing_options = this.selectedFinancingOption));
    }

    private getFinancingOfferCodesAndRateSheets(): void {
        forkJoin([this.quoteService.getFinancingOfferCodes(), this.quoteService.getFinancingRateSheets()]).subscribe(
            async ([offerCodes, rateSheets]) => {
                const groupedRateSheetsList = groupBy(rateSheets, 'product_category');

                for (const key in groupedRateSheetsList) {
                    const rateSheet = groupedRateSheetsList[key].map((item) => ({
                        id: `${key}-${item.product_name}`,
                        name: item.short_description,
                        hash: `${key}-${item.product_name}`,
                        ...item
                    }));

                    this.allRateSheets.push(...rateSheet);
                }

                this.offerCodeAllRateSheets = offerCodes.reduce(
                    (acc, offerCode) =>
                        acc.concat(
                            offerCode.rate_sheet_merchant_product_prices.map((rateSheet) => ({
                                ...rateSheet,
                                offerCodeName: offerCode.name,
                                parent_id: offerCode.id,
                                max_price_to_apply: offerCode.max_price_to_apply,
                                min_price_to_apply: offerCode.min_price_to_apply
                            }))
                        ),
                    []
                );

                const selectedRateSheetId = await this.paradigmFinanceRateSheetSelectedService.getRateSheet(
                    this.appointment.id,
                    this.quote.quote_id
                );

                this.selectedOfferCode = [...this.allRateSheets, ...this.offerCodeAllRateSheets].find(
                    ({ id }) => id === selectedRateSheetId
                );
                this.selectedOfferCodeChange.emit(this.selectedOfferCode);

                const financingName: string = this.selectedOfferCode?.name || this.selectedFinancingOption?.name;

                if (this.allRateSheets.length && isNil(this.financingOptionControl.value) && !isNil(financingName)) {
                    this.financingOptionControl.setValue(this.paradigmFinanceName);
                    this.cdr.detectChanges();
                }
            }
        );
    }

    private getSumDiscountableFinalPrice(adders: Adder[]): number {
        const total: number = adders
            .filter(({ discountable }: Adder) => !discountable)
            .reduce((acc: number, { final_price }: Adder) => acc + final_price, 0);

        return this.roundingService.round(total);
    }
}

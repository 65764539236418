<div class="resources-filter" [style.width]="'calc(100vw - ' + width + 'px)'">
  <div #container="tqMHorizontalScroll" tqMHorizontalScroll (overflowed)="isShowNavButtons = $event">
    <ion-searchbar mode="ios"
                   [placeholder]="!list.length || isSearchFocused ? 'Search' : ''"
                   [class.searchbar-focused]="!list.length || isSearchFocused || searchFormControl.value"
                   [style.max-width]="list.length ? '250px' : 'auto'"
                   (ionFocus)="toggleSearchField(true)"
                   (ionBlur)="toggleSearchField(false)"
                   [formControl]="searchFormControl"></ion-searchbar>
    <ng-content></ng-content>
  </div>

  <ng-container *ngIf="isShowNavButtons && list.length > 1">
    <ion-button class="icon-only nav-btn"
                fill="outline"
                expand="full"
                [disabled]="!container.canScrollStart"
                (click)="container.scrollLeft()">
      <ion-icon name="chevron-back"></ion-icon>
    </ion-button>

    <ion-button class="icon-only nav-btn"
                fill="outline"
                expand="full"
                [disabled]="!container.canScrollEnd"
                (click)="container.scrollRight()">
      <ion-icon name="chevron-forward"></ion-icon>
    </ion-button>
  </ng-container>
</div>

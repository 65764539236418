import { Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { BaseModal } from '@shared/modals/base-modal';

@Component({
    selector: 'vendo-select-action-modal',
    templateUrl: './select-action-modal.component.html',
    styleUrls: ['./select-action-modal.component.scss']
})
export class SelectActionModalComponent extends BaseModal {
    @Input() actions: any[] = [];
    @Input() headerText = 'Please confirm';
    @Input() message = '';

    constructor(modalController: ModalController) {
        super(modalController);
    }

    action(value: any): void {
        this.dismiss(value);
    }
}

import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';

import interact from 'interactjs';

import findIndex from 'lodash/findIndex';
import last from 'lodash/last';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';

import { SpinnerService } from '@core/services/spinner.service';
import { ZoomPanPinchComponent } from '@shared/components/zoom-pan-pinch/zoom-pan-pinch.component';
import { CategoryType } from '@shared/enums/category-type';

@Component({
    selector: 'vendo-masked-image',
    templateUrl: './masked-image.component.html',
    styleUrls: ['./masked-image.component.scss']
})
export class MaskedImageComponent implements OnInit, OnChanges, OnDestroy {
    @Input() openings;
    @Input() imageUrl;
    @Input() disableEdit = false;
    @Input() activeOpening: any;
    @Output() openingClicked = new EventEmitter<any>();
    @Output() editingStarted = new EventEmitter<void>();
    @Output() editingFinished = new EventEmitter<void>();
    @Output() coordinatesModified = new EventEmitter<any[]>();

    handles: { x: number; y: number }[];
    handleSize = 4;
    editMode = false;
    imageRatio;
    width = '100%';
    displayImage = false;
    activePoint: any;
    activePointId: number;
    activePointIndex: number;

    readonly categoryTypes: typeof CategoryType = CategoryType;

    private interact: any;

    @ViewChild('houseImg', { static: false }) houseImage: ElementRef;
    @ViewChild(ZoomPanPinchComponent, { static: false }) zoomComponent: ZoomPanPinchComponent;

    constructor(
        private cdr: ChangeDetectorRef,
        private spinnerService: SpinnerService
    ) {}

    ngOnInit(): void {
        this.spinnerService.changeActiveRequestsCount(1);
        this.editMode = !!this.activeOpening;

        if (this.activeOpening) {
            setTimeout(() => {
                this.editOpening(this.activeOpening, 0, true);
                this.displayImage = true;
                this.spinnerService.changeActiveRequestsCount(-1);
            }, 300);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.openings &&
            changes.openings.previousValue &&
            changes.openings.previousValue.length < changes.openings.currentValue.length
        ) {
            const newOpening: any = last(changes.openings.currentValue);

            if (!newOpening?.id && !newOpening?.uuid) {
                this.editOpening(newOpening, changes.openings.currentValue.length - 1);
                this.zoomComponent.zoomToFit(
                    newOpening.view_coordinates,
                    this.houseImage.nativeElement.width,
                    this.houseImage.nativeElement.height,
                    1
                );
            }
        }

        if (changes.width && changes.width.previousValue && changes.width.previousValue !== changes.width) {
            setTimeout(() => {
                this.calcRatio();

                if (this.activeOpening?.id) {
                    this.zoomComponent.zoomToFit(
                        this.activeOpening.view_coordinates,
                        this.houseImage.nativeElement.width,
                        this.houseImage.nativeElement.height
                    );
                }
            });
        }
    }

    public resetOpeningCoordinates(coordinates: any[], activePointIndex?: number): void {
        this.activeOpening.view_coordinates = [].concat(...coordinates);
        this.activePointIndex = activePointIndex;

        this.calcRatio(true, activePointIndex);
    }

    public zoomToPoint(coordinates: { x: number; y: number }, pointIndex: number): void {
        const correctedCoordinates = {
            x: coordinates.x * this.imageRatio,
            y: coordinates.y * this.imageRatio
        };

        this.zoomComponent.disableZooming();

        this.activePointIndex = pointIndex;

        this.activePoint = correctedCoordinates;
        this.activePoint.id = new Date().valueOf();
        this.activePointId = this.activePoint.id;

        this.zoomComponent.zoomToFit(
            [correctedCoordinates],
            this.houseImage.nativeElement.width,
            this.houseImage.nativeElement.height
        );
    }

    public zoomToActiveOpening(): void {
        this.activePoint = null;
        this.activePointId = null;
        this.activePointIndex = null;
        this.calcRatio();
        this.zoomComponent.enableZooming();
        this.zoomComponent.zoomToFit(
            this.activeOpening.view_coordinates,
            this.houseImage.nativeElement.width,
            this.houseImage.nativeElement.height
        );
    }

    movePoint(handle: any, dx: number, dy: number): void {
        const correctedDx = dx * this.imageRatio;
        const correctedDy = dy * this.imageRatio;

        this.activePoint.x += correctedDx;
        this.activePoint.y += correctedDy;
        handle.x += correctedDx;
        handle.y += correctedDy;

        Object.assign(this.activeOpening.view_coordinates[this.activePointIndex], this.activePoint);

        this.onInteractEnd(null);
    }

    removeActiveOpening(): void {
        const activeOpeningIndex = findIndex(this.openings, { uuid: this.activeOpening.uuid });

        this.openings.splice(activeOpeningIndex, 1);

        this.calcRatio();

        this.stopEditing();
    }

    onImageLoaded(): void {
        setTimeout(() => {
            this.calcRatio();

            this.cdr.detectChanges();

            this.resetZoom();

            if (this.activeOpening?.id) {
                setTimeout(() => {
                    this.zoomComponent.zoomToFit(
                        this.activeOpening.view_coordinates,
                        this.houseImage.nativeElement.width,
                        this.houseImage.nativeElement.height
                    );
                });
            }

            this.displayImage = true;
            this.spinnerService.changeActiveRequestsCount(-1);
        }, 400);
    }

    calcRatio(useRealRatio = false, activePointIndex?: number): void {
        setTimeout(() => {
            const oldRatio = this.imageRatio;

            this.imageRatio = this.houseImage.nativeElement.width / this.houseImage.nativeElement.naturalWidth || 1;
            const newRatio = oldRatio ? this.imageRatio / oldRatio : this.imageRatio;

            if (useRealRatio) {
                this.resetPoints(this.imageRatio);
            } else {
                this.resetPoints(newRatio);
            }
            this.cdr.detectChanges();

            if (this.editMode) {
                this.initHandles(activePointIndex);
            }

            this.cdr.detectChanges();
        });
    }

    editOpening(opening: any, index?: number, resetZoom = false): void {
        if (opening === this.activeOpening && !resetZoom) {
            return;
        }
        this.openingClicked.emit(opening);
        this.enterEditMode(opening, index);
    }

    private enterEditMode(opening: any, index: number): void {
        this.activeOpening = opening;

        if (!index && index !== 0) {
            index = findIndex(this.openings, { uuid: this.activeOpening.uuid });
        }

        this.calcRatio();

        if (!this.disableEdit) {
            this.editMode = true;
            this.interact = interact(`#view-drawing-edit-${index}`);
            this.setInteractions();
            this.editingStarted.emit(this.activeOpening);
        }

        setTimeout(() => {
            if (this.activeOpening?.id) {
                this.zoomComponent.zoomToFit(
                    this.activeOpening.view_coordinates,
                    this.houseImage.nativeElement.width,
                    this.houseImage.nativeElement.height
                );
            }
        });
    }

    stopEditing(): void {
        this.editMode = false;
        this.activeOpening = null;
        this.cdr.detectChanges();
        this.openingClicked.emit(null);

        this.resetZoom();
    }

    setInteractions(): void {
        this.interact.draggable({
            onmove: (e): void => {
                this.onOpeningMove(e);
            },
            onend: (e): void => {
                this.onInteractEnd(e);
            },
            modifiers: [
                interact.modifiers.restrict({
                    restriction: 'parent',
                    elementRect: { top: 0, left: 0, bottom: 1, right: 1 }
                })
            ]
        });

        this.interact.resizable({
            onmove: (e) => {
                this.onOpeningResize(e);
                this.cdr.detectChanges();
            },
            onend: (e) => {
                this.onInteractEnd(e);
            },
            edges: {
                top: '.handle-1',
                bottom: '.handle-5',
                right: '.handle-3',
                left: '.handle-7'
            },
            modifiers: [
                interact.modifiers.restrictEdges({
                    outer: 'parent'
                }),

                interact.modifiers.restrictSize({
                    min: { width: this.handleSize * 2, height: this.handleSize * 2 }
                })
            ]
        });
    }

    onOpeningMove(event: any): void {
        const group = event.target;
        const scale = this.zoomComponent.getZoomLevel();

        const dx = event.dx / scale;
        const dy = event.dy / scale;

        Array.from(group.children).forEach((child) => {
            if (child instanceof SVGPolygonElement) {
                // Move all points for a polygon
                for (let i = 0, len = child.points.numberOfItems; i < len; i++) {
                    const point = child.points.getItem(i);

                    point.x += dx;
                    point.y += dy;

                    this.activeOpening.view_coordinates[i].x = point.x;
                    this.activeOpening.view_coordinates[i].y = point.y;
                }
                this.initHandles();
            } else if (child instanceof SVGRectElement) {
                // Move the drag handles
                child.x.baseVal.value += dx;
                child.y.baseVal.value += dy;
            }
        });
    }

    onOpeningResize(event: any): void {
        const scale = this.zoomComponent.getZoomLevel();

        const edges = event.edges;
        const dx = event.dx / scale;
        const dy = event.dy / scale;

        const polygon: any = document.getElementById('opening-polygon');

        // Index out of the polygon's points array should match index in getPolygonPoints().
        const upperLeft = polygon.points.getItem(0);
        const upperRight = polygon.points.getItem(1);
        const lowerRight = polygon.points.getItem(2);
        const lowerLeft = polygon.points.getItem(3);

        // If two edges move it's a corner and needs to move x,y of one point.
        // If only one edge moves it's a side and only moves one direction on the corner points.

        if (edges.top && edges.left) {
            upperLeft.x += dx;
            upperLeft.y += dy;
        } else if (edges.top && edges.right) {
            upperRight.x += dx;
            upperRight.y += dy;
        } else if (edges.bottom && edges.right) {
            lowerRight.x += dx;
            lowerRight.y += dy;
        } else if (edges.bottom && edges.left) {
            lowerLeft.x += dx;
            lowerLeft.y += dy;
        } else if (edges.top) {
            upperLeft.y += dy;
            upperRight.y += dy;
        } else if (edges.right) {
            upperRight.x += dx;
            lowerRight.x += dx;
        } else if (edges.left) {
            upperLeft.x += dx;
            lowerLeft.x += dx;
        } else if (edges.bottom) {
            lowerLeft.y += dy;
            lowerRight.y += dy;
        }

        this.activeOpening.view_coordinates = [
            {
                x: upperLeft.x,
                y: upperLeft.y
            },
            {
                x: upperRight.x,
                y: upperRight.y
            },
            {
                x: lowerRight.x,
                y: lowerRight.y
            },
            {
                x: lowerLeft.x,
                y: lowerLeft.y
            }
        ];

        this.initHandles();
    }

    onInteractEnd(e: any): void {
        const viewCoordinates = this.activeOpening.view_coordinates.map((coordinate) => {
            return {
                x: coordinate.x / this.imageRatio,
                y: coordinate.y / this.imageRatio
            };
        });

        this.coordinatesModified.emit(viewCoordinates);
    }

    getHandleClass(index: number): string {
        if (index % 2 === 0) {
            let indexBefore = index - 1;

            if (indexBefore < 0) {
                indexBefore = 7;
            }

            return `handle-${index % 2 === 0 ? indexBefore : index} handle-${index + 1}`;
        }

        return `handle-${index}`;
    }

    getMaskCoordinates(index: number): string {
        return this.openings[index].view_coordinates.map((point) => `${point.x} ${point.y}`).join(' ');
    }

    private resetZoom(): void {
        setTimeout(() => {
            this.zoomComponent.setPosition(0, 0, 1);
        });
    }

    private initHandles(activePointIndex?: number): void {
        const handles = [];

        this.activeOpening.view_coordinates.forEach((point: { x: number; y: number }, index: number) => {
            const handlePosition = {
                x: point.x,
                y: point.y
            };

            handles.push({
                x: handlePosition.x - this.handleSize / 2,
                y: handlePosition.y - this.handleSize / 2
            });

            if (activePointIndex === index) {
                this.activePoint = handlePosition;
                this.activePoint.id = new Date().valueOf();
                this.activePointId = this.activePoint.id;
            }

            const nextPoint = this.activeOpening.view_coordinates[index + 1]
                ? this.activeOpening.view_coordinates[index + 1]
                : this.activeOpening.view_coordinates[0];

            handles.push({
                x: (point.x + nextPoint.x) / 2 - this.handleSize / 2,
                y: (point.y + nextPoint.y) / 2 - this.handleSize / 2
            });
        });

        this.handles = handles;
    }

    private resetPoints(imageRatio = 1): any {
        this.openings.map((opening) => {
            opening.view_coordinates = opening.view_coordinates.map((point) => {
                return {
                    x: point.x * imageRatio,
                    y: point.y * imageRatio
                };
            });

            const topLeftX = opening.view_coordinates[0].x;
            const topLeftY = opening.view_coordinates[0].y;
            const bottomRightX = opening.view_coordinates[2].x;
            const bottomRightY = opening.view_coordinates[2].y;
            const maxX = maxBy(opening.view_coordinates, 'x').x;
            const minX = minBy(opening.view_coordinates, 'x').x;
            const maxY = maxBy(opening.view_coordinates, 'y').y;
            const minY = minBy(opening.view_coordinates, 'y').y;

            opening.center = {
                x: (topLeftX - bottomRightX) / 2 + bottomRightX - 16 / 2,
                y: (topLeftY - bottomRightY) / 2 + bottomRightY + 16 / 2
            };

            opening.pixelWidth = maxX - minX;
            opening.pixelHeight = maxY - minY;
            opening.minX = minX;
            opening.minY = minY;

            return opening;
        });
    }

    public resetOpeningPoints(opening: any): any {
        opening.view_coordinates = opening.view_coordinates.map((point) => {
            return {
                x: point.x / this.imageRatio,
                y: point.y / this.imageRatio
            };
        });

        return opening;
    }

    ngOnDestroy(): void {
        if (this.activeOpening) {
            this.activeOpening.view_coordinates = this.activeOpening.view_coordinates.map((coordinate) => {
                return {
                    x: coordinate.x / this.imageRatio,
                    y: coordinate.y / this.imageRatio
                };
            });
        }

        this.interact?.unset();
    }
}

import { Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { BaseModal } from '@shared/modals/base-modal';

@Component({
    selector: 'vendo-terms-and-conditions-modal',
    templateUrl: './terms-and-conditions-modal.component.html',
    styleUrls: ['./terms-and-conditions-modal.component.scss']
})
export class TermsAndConditionsModalComponent extends BaseModal {
    @Input() isNeedToConfirmUpdatedPrivacyPolicy = false;

    constructor(modalController: ModalController) {
        super(modalController);
    }

    accept(): void {
        this.dismiss(true);
    }
}

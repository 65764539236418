<div class="modal-header">
  <ion-text class="title">Set preference type</ion-text>
</div>

<div class="modal-body">
  <div class="answer">
    <img *ngIf="question.questionType === questionTypes.MultiChoice"
         [src]="answer.ImageURL | wcpDrawingUrl:imgBaseUrl"
         class="answer-image"/>
    <ion-text>
      <span>{{ question.QuestionText }}:</span>&nbsp;<span>{{ answer.Text }}</span>
    </ion-text>
  </div>

  <ion-list [formGroup]="form">
    <ion-radio-group>
      <ng-container *ngFor="let item of wcpPreferences">
        <ion-item lines="none">
          <ion-label>{{ item.label }}</ion-label>
          <ion-checkbox legacy="true" slot="start" mode="md" [formControlName]="item.value"></ion-checkbox>
        </ion-item>
        <ion-text>{{ item.description }}</ion-text>
      </ng-container>
    </ion-radio-group>
  </ion-list>
</div>

<div class="modal-footer">
  <ion-button class="classic-width outline" (click)="closeModal()">Cancel</ion-button>

  <ion-button class="classic-width" (click)="apply()">Set</ion-button>
</div>

import { AfterContentInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

import SignaturePad from 'signature_pad';

import { SignaturePadOptions } from '@shared/interfaces/signature-pad-options';

@Component({
    selector: 'vendo-signature-pad',
    templateUrl: './signature-pad.component.html',
    styleUrls: ['./signature-pad.component.scss']
})
export class SignaturePadComponent implements AfterContentInit {
    @Input() options: SignaturePadOptions;
    @Output() drawStart: EventEmitter<MouseEvent | Touch> = new EventEmitter<MouseEvent | Touch>();
    signaturePad!: SignaturePad;
    private penColor = '#000080';

    constructor(private elementRef: ElementRef) {}

    ngAfterContentInit(): void {
        const canvas: HTMLCanvasElement = this.initCanvas(this.options);

        this.initSignaturePad(canvas);
        this.clear();
    }

    clear(): void {
        this.signaturePad.clear();
    }

    set(option: string, value: any): void {
        if (option === 'canvasHeight' || option === 'canvasWidth') {
            const canvas: HTMLCanvasElement = this.getCanvas();
            const canvasOption = option.replace('canvas', '').toLowerCase();

            if (canvas[canvasOption] === value) {
                return;
            }

            canvas[canvasOption] = value;
            this.clear();
        } else {
            if (this.signaturePad[option] === value) {
                return;
            }

            this.signaturePad[option] = value;
        }
    }

    toDataURL(imageType?: string, quality?: number): string {
        return this.signaturePad.toDataURL(imageType, quality); // save image as data URL
    }

    fromDataURL(dataURL: string, options: { ratio?: number; width?: number; height?: number } = {}): Promise<void> {
        if (!options.hasOwnProperty('height') && this.options.canvasHeight) {
            options.height = this.options.canvasHeight;
        }
        if (!options.hasOwnProperty('width') && this.options.canvasWidth) {
            options.width = this.options.canvasWidth;
        }
        return this.signaturePad.fromDataURL(dataURL, options);
    }

    private initCanvas(options: SignaturePadOptions): HTMLCanvasElement {
        const canvas: HTMLCanvasElement = this.getCanvas();

        if (this.options.canvasHeight) {
            canvas.height = options.canvasHeight;
        }

        if (this.options.canvasWidth) {
            canvas.width = options.canvasWidth;
        }

        if (this.options.canvasBackground) {
            canvas.style.background = options.canvasBackground;
        }

        return canvas;
    }

    private initSignaturePad(canvas: HTMLCanvasElement): void {
        this.signaturePad = new SignaturePad(canvas, { penColor: this.penColor });
        this.signaturePad.addEventListener('beginStroke', (event: CustomEvent) => this.drawStart.emit(event.detail));
    }

    private getCanvas(): HTMLCanvasElement {
        return this.elementRef.nativeElement.querySelector('canvas');
    }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { AppService } from '@core/services/app.service';
import { AuthService } from '@core/services/auth.service';

import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'vendo-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss', '../../auth.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit {
    emailSent = false;
    form = new UntypedFormGroup({
        email: new UntypedFormControl('', [Validators.required, Validators.email])
    });
    currentYear = new Date().getFullYear();
    logoUrl: string;
    isHigherImage: boolean = this.appService.checkIsAndersen() || this.appService.checkIsGenesis();

    constructor(
        private appService: AppService,
        private authService: AuthService
    ) {}

    async ngOnInit(): Promise<void> {
        this.logoUrl = await this.appService.getLogoUrl('../../');
    }

    sendForgotPasswordEmail(): void {
        this.form.markAllAsTouched();

        if (!this.form.valid) {
            return;
        }

        const baseResetPasswordUrl = environment.siteUrl + '/reset-password/';

        this.authService.forgotPassword(this.form.value.email, baseResetPasswordUrl).subscribe((status) => {
            this.emailSent = status;
        });
    }
}

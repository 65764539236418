<div class="modal-header">
    <ion-text class="title">Select Existing {{ adderNameSetting?.plural }}</ion-text>
    <ion-button fill="outline" color="dark" (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
</div>

<div class="modal-body">
    <div class="tabs">
        <vendo-tabs [tabs]="tabs"
                    [activeTabId]="activeTab?.id"
                    (tabSelected)="selectTab($event)"></vendo-tabs>

        <ion-content class="tab-content" (swipeleft)="changeTab(1)" (swiperight)="changeTab(-1)">
            <form [formGroup]="form" *ngIf="addersCategories.length; else noAdders">
                <ng-container formArrayName="addersCategories">
                    <ng-container [formGroupName]="activeTabIndex">
                        <ion-item class="header-row"
                                  lines="full">
                            <ion-grid>
                                <ion-row>
                                    <ion-col class="ion-text-center" size="0.75">
                                        <!-- ngFor: Value don't change on ui when switching between tabs -->
                                        <ion-checkbox legacy="true" *ngFor="let all of [activeTabIndex]"
                                                      mode="md"
                                                      class="package"
                                                      formControlName="isSelectAll"></ion-checkbox>
                                    </ion-col>
                                    <ion-col>
                                        {{ adderNameSetting?.single }} Name
                                    </ion-col>
                                    <ion-col class="amount-col ion-text-right" size="3">
                                        Amount
                                    </ion-col>
                                </ion-row>
                            </ion-grid>
                        </ion-item>

                        <ng-container formArrayName="adders">
                            <ion-item *ngFor="let adder of activeTab?.adders; let j = index"
                                      [formGroupName]="j"
                                      (click)="selectAdder(j)">
                                <ion-grid>
                                    <ion-row>
                                        <ng-container formArrayName="packages">
                                            <ion-col class="ion-text-center" size="0.75">
                                                <ion-checkbox legacy="true" mode="md"
                                                              class="package"
                                                              formControlName="0"
                                                              (click)="$event.stopPropagation()"></ion-checkbox>
                                            </ion-col>
                                        </ng-container>

                                        <ion-col>
                                            <ion-text>{{ adder.name }}</ion-text>
                                        </ion-col>

                                        <ion-col class="amount-col" size="3">
                                            <ng-container *ngIf="adder.variable">
                                                <ion-text *ngIf="adder.amount_type === amountTypes.STATIC">$</ion-text>
                                                <ion-input legacy="true" [ngClass]="{
                                                            'ion-text-left': adder.amount_type === amountTypes.STATIC,
                                                            'ion-text-right': [amountTypes.PERCENTAGE_OF_LINE_ITEM, amountTypes.PERCENTAGE_OF_SUBTOTAL, amountTypes.PERCENTAGE_PACKAGE].includes(adder.amount_type)
                                                           }"
                                                           type="number"
                                                           inputmode="numeric"
                                                           formControlName="amount"
                                                           tabindex="-1"
                                                           placeholder="Amount"
                                                           autocomplete="off"
                                                           (click)="$event.stopPropagation()"></ion-input>
                                                <ion-text *ngIf="[amountTypes.PERCENTAGE_OF_LINE_ITEM, amountTypes.PERCENTAGE_OF_SUBTOTAL, amountTypes.PERCENTAGE_PACKAGE].includes(adder.amount_type)">%</ion-text>
                                            </ng-container>

                                            <ng-container *ngIf="isShowPrice && !adder.variable">
                                                <ion-text>
                                                    <ng-container [ngSwitch]="adder.amount_type">
                                                        <ng-container *ngSwitchCase="[amountTypes.PERCENTAGE_OF_LINE_ITEM, amountTypes.PERCENTAGE_OF_SUBTOTAL, amountTypes.PERCENTAGE_PACKAGE].includes(adder.amount_type) ? adder.amount_type : ''">
                                                            {{ adder.amount }}%
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="amountTypes.STATIC">
                                                            ${{ adder.amount }}
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="amountTypes.QUESTION_MULTIPLIER">
                                                            ${{ adder.amount }} (Per {{isProjectAdders ? (adder.additional_info.question_for_multiplier | startCase) : adder.additional_info.question_for_multiplier }})
                                                        </ng-container>
                                                    </ng-container>
                                                </ion-text>
                                            </ng-container>
                                        </ion-col>
                                    </ion-row>
                                </ion-grid>
                            </ion-item>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </form>

            <ng-template #noAdders>
                <ion-text class="no-adders">No Available {{ adderNameSetting?.plural }}</ion-text>
            </ng-template>
        </ion-content>
    </div>
</div>

<div class="modal-footer">
    <ion-button fill="outline"
                (click)="closeModal()">
        Cancel
    </ion-button>
    <ion-button *ngIf="addersCategories.length" (click)="save()">
        Done
    </ion-button>
</div>

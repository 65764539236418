import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { AuthService } from '@core/services/auth.service';
import { RoundingService } from '@core/services/rounding.service';

@Pipe({
    name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {
    constructor(
        private authService: AuthService,
        private roundingService: RoundingService
    ) {}
    transform(
        value: number,
        digitsInfo: string | boolean = '1.2-2',
        display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol',
        currencyCode = 'USD',
        locale = 'en-US'
    ): string | null {
        if (typeof digitsInfo === 'boolean' && digitsInfo) {
            const user = this.authService.getUser();
            const digitsCount: number = user.office.price_configuration.base_settings.round_to_decimals;

            if (value) {
                value = this.roundingService.round(value);
            }

            digitsInfo = `1.${digitsCount}-${digitsCount}`;
        }

        return new CurrencyPipe(locale).transform(value, currencyCode, display, digitsInfo as string);
    }
}

import { gql } from 'apollo-angular';

const SYNCHRONIZE_APPOINTMENTS = gql`
    query SynchronizeAppointments($synchronized_at: String, $last_schedule_time: String) {
        synchronizeAppointments(synchronized_at: $synchronized_at, last_schedule_time: $last_schedule_time) {
            date_range
            appointments {
                id
                seller_id
                schedule_time
                scheduled_to
                status
                type
                lock_type
                notes
                result_type
                is_archived
                crm_results
                products_interests
                activities {
                    hash
                    status
                    view_history
                }
                deeplinks {
                    is_show_confirmation
                    is_paste_data_to_device
                    items {
                        name
                        hash
                        url
                    }
                }
                customer {
                    id
                    first_name
                    last_name
                    phone_number
                    email
                    additional_info
                }
                second_customer {
                    id
                    first_name
                }
                family_name
                house {
                    address1
                    address2
                    city
                    country
                    state
                    postal_code
                    additional_info
                    properties
                }
                second_house {
                    address1
                    address2
                    city
                }
                openings {
                    id
                    name
                    catalog_id
                    category_id
                    appointment_id
                    notes
                    quantity
                    temporary
                    view_coordinates
                    opening_details {
                        question_id
                        answer
                    }
                    opening_inclusions {
                        id
                        width
                        height
                        hash
                        excluded
                    }
                    opening_configurations {
                        wcp_item_id
                        wcp_config_id
                    }
                    additional_info
                    mulled
                    view_photo_id
                    images {
                        appointment_type
                        opening_id
                        name
                        note
                        type
                        url
                    }
                }
                assessment {
                    answers {
                        question
                        answer
                    }
                }
            }
        }
        commonItems(types: ["customer_phone_number_tags", "customer_email_tags"])
        myUserPreferences(
            types: [
                "augment_reality_measurement"
                "show_pricing"
                "default_deposit_as_percent"
                "bosch_measurement"
                "demo_resources_order"
                "quick_takeoff"
                "show_price_modifiers"
            ]
        ) {
            value
            type
        }
        offlineCategories {
            id
            name
            category {
                id
                name
                allow_takeoff
                category_type
                catalog_type
                all_questions {
                    default_answer
                    depends_on
                    field_type
                    hash
                    id
                    order
                    required
                    title
                    available_answers
                    show_if
                }
                questions {
                    default_answer
                    depends_on
                    field_type
                    hash
                    id
                    order
                    required
                    title
                    available_answers
                    show_if
                    display_on_grid
                }
                attributes {
                    title
                }
            }
            predefined_images {
                appointment_type
                category_type
                predefined_images {
                    label
                    is_required
                }
            }
            opening_conditions {
                name
                id
            }
        }
        myDemoResources {
            id
            name
            icon
            text_color
            widget_color
            resource_type
            resource_src
            badge {
                src
                type
            }
            categories {
                id
                name
            }
        }
        myNeedsAssessments {
            appointment_type
            questions {
                title
                field_type
                multiple
                required
                answers
                default_answer
                quote_header_question
            }
        }
    }
`;

export { SYNCHRONIZE_APPOINTMENTS };

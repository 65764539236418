import { ModalController } from '@ionic/angular';

import { Unsubscriber } from '@shared/helpers/unsubscriber';

export class BaseModal extends Unsubscriber {
    constructor(public modalController: ModalController) {
        super();
    }

    closeModal(): any {
        this.dismiss(false);
    }

    dismiss(data): void {
        this.modalController.getTop().then((modal) => {
            modal.dismiss(data);
        });
    }
}

export enum DocumentType {
    AppointmentFile = 'appointment_file',
    Attachment = 'attachment',
    Contract = 'contract',
    DemoResource = 'demo_resource',
    InspectionReport = 'inspection_report',
    MeasurementReport = 'measurement_report',
    PriceConditioning = 'price_conditioning',
    PromoCardContract = 'promo_card_contract',
    Proposal = 'proposal'
}

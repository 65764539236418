import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';

import { ModalController } from '@ionic/angular';

import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import every from 'lodash/every';
import startCase from 'lodash/startCase';

import { AuthService } from '@core/services/auth.service';
import { FilesService } from '@core/services/files.service';
import { SignaturePadComponent } from '@shared/components/signature-pad/signature-pad.component';
import { SignatureTabHash } from '@shared/enums/signature-tab-hash.constants';
import { SignatureType } from '@shared/enums/signature-type';
import { BaseModal } from '@shared/modals/base-modal';

import { EDocumentService } from '../../../e-document-v2/services/e-document.service';
import { DocumentsService } from '../../../main/appointments/documents/services/documents.service';

@Component({
    selector: 'vendo-signature-modal',
    templateUrl: './signature-modal.component.html',
    styleUrls: ['./signature-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignatureModalComponent extends BaseModal implements OnInit, AfterViewInit {
    @ViewChild('signaturePad', { static: true }) signaturePad: SignaturePadComponent;
    @ViewChild('modalBody', { static: true }) modalBody;
    @Input() signatureImages;
    @Input() signatureOwner; // possible values customer, seller
    @Input() customer;
    @Input() documentHash: string;
    @Input() signatureEntities: any;
    tabs: any[] = [];
    signaturePadOptions;
    activeTab: any;
    isLastTabSelected = false;
    signerInitials: string;
    signatures: any = {};
    canvas: HTMLCanvasElement;
    isNeedToUpload = {
        [SignatureTabHash.CUSTOMER_SIGNATURE]: false,
        [SignatureTabHash.CUSTOMER_INITIALS]: false,
        [SignatureTabHash.SELLER_SIGNATURE]: false,
        [SignatureTabHash.SELLER_INITIALS]: false
    };
    signatureExist = {
        [SignatureTabHash.CUSTOMER_SIGNATURE]: false,
        [SignatureTabHash.CUSTOMER_INITIALS]: false,
        [SignatureTabHash.SELLER_SIGNATURE]: false,
        [SignatureTabHash.SELLER_INITIALS]: false
    };
    seller: any;
    private canvasWidth = 480;
    private canvasHeight = 196;

    constructor(
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
        private documentsService: DocumentsService,
        private eDocumentService: EDocumentService,
        private fileService: FilesService,
        modalController: ModalController
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.setTabs();
        this.signaturePadOptions = {
            canvasWidth: this.canvasWidth,
            canvasHeight: this.canvasHeight,
            backgroundColor: '#FFFFFF'
        };

        if (this.signatureOwner === 'seller') {
            this.seller = this.authService.getUser();
        }

        if (this.signatureImages.initials) {
            this.signerInitials = this.signatureImages.initials;
        } else {
            const signer = this.customer || this.seller;

            this.signerInitials = `${signer.first_name[0]}.${signer.last_name[0]}.`;
        }

        Object.assign(this.signatureExist, this.signatureImages);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            const newWidth = this.modalBody.nativeElement.clientWidth - 40;

            if (newWidth < this.canvasWidth && newWidth > 0) {
                this.signaturePad.set('canvasWidth', newWidth);
            }

            this.canvas = document.querySelector('canvas');
            const ctx = this.canvas.getContext('2d');

            ctx.font = this.getFont();
            ctx.textBaseline = 'top';
            this.setActiveTab(0);
        });
    }

    setActiveTab(tabIndex: number): void {
        this.isLastTabSelected = this.tabs.length - 1 === tabIndex;
        this.activeTab = this.tabs[tabIndex];
        this.clear();

        if (this.signatureImages && this.signatureImages[this.activeTab.hash]) {
            this.signaturePad.fromDataURL(this.signatureImages[this.activeTab.hash]);
            this.signatureExist[this.activeTab.hash] = true;
        } else if (this.activeTab.isInitials) {
            this.redrawInitials();
        } else {
            this.signatureExist[this.activeTab.hash] = false;
        }

        this.cdr.detectChanges();
    }

    redrawInitials(): void {
        this.clear();
        let fontSize = this.canvasWidth / (this.signerInitials.length / 2);

        if (fontSize > this.canvasHeight / 2) {
            fontSize = this.canvasHeight;
        }

        const ctx = this.canvas.getContext('2d');

        ctx.font = this.getFont(fontSize);
        ctx.fillText(this.signerInitials, 15, (this.canvasHeight - fontSize) / 2 + 15);
        this.signatureExist[this.activeTab.hash] = this.isNeedToUpload[this.activeTab.hash] = true;
    }

    drawingStarted(): void {
        this.signatureExist[this.activeTab.hash] = this.isNeedToUpload[this.activeTab.hash] = true;
    }

    clear(): void {
        this.signatureExist[this.activeTab.hash] = false;
        this.signaturePad.clear();
    }

    goToTab(index: number): void {
        this.setSignature();
        this.setActiveTab(index);
    }

    done(): void {
        this.setSignature();

        forkJoin(
            Object.entries(this.signatures).map(([key, value]) => {
                if (!this.isNeedToUpload[key]) {
                    return of([key, this.signatureImages[key]]);
                }

                const blob: any = this.fileService.base64ToBlob(value, 'image/png', 512, 'key.png');
                const type: SignatureType = key.split('_')[1] as SignatureType;

                return this.saveSignatureImage(type, blob).pipe(map((url: string) => [key, url]));
            })
        ).subscribe((data) => {
            this.signatures = Object.fromEntries(data);
            this.dismiss(this.signatures);
        });
    }

    isDoneEnabled(): boolean {
        return every(this.tabs, (tab) => this.signatureExist[tab.hash]);
    }

    private setSignature(): void {
        this.signatures[this.activeTab.hash] = this.signaturePad.toDataURL();
    }

    private saveSignatureImage(type: SignatureType, file: File): Observable<string> {
        if (this.signatureOwner === 'seller') {
            return this.documentsService.uploadSellerSignatureImage(type, file);
        } else if (this.documentHash) {
            return this.eDocumentService.uploadCustomerSignatureImage(this.documentHash, type, file);
        }

        return this.documentsService.uploadCustomerSignatureImage(this.customer.id, type, file);
    }

    private setTabs(): void {
        if (this.signatureEntities.customerInitials) {
            this.tabs.push({
                name: startCase(SignatureTabHash.CUSTOMER_INITIALS),
                hash: SignatureTabHash.CUSTOMER_INITIALS,
                isInitials: true
            });
        }

        if (this.signatureEntities.customerSignatures) {
            this.tabs.push({
                name: startCase(SignatureTabHash.CUSTOMER_SIGNATURE),
                hash: SignatureTabHash.CUSTOMER_SIGNATURE
            });
        }

        if (this.signatureEntities.sellerInitials) {
            this.tabs.push({
                name: startCase(SignatureTabHash.SELLER_INITIALS),
                hash: SignatureTabHash.SELLER_INITIALS,
                isInitials: true
            });
        }

        if (this.signatureEntities.sellerSignatures) {
            this.tabs.push({
                name: startCase(SignatureTabHash.SELLER_SIGNATURE),
                hash: SignatureTabHash.SELLER_SIGNATURE
            });
        }
    }

    private getFont(fontSize: number = 40): string {
        return `${fontSize}px \'Covered By Your Grace\', cursive`;
    }
}

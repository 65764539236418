import { Component, Input, OnInit } from '@angular/core';

import { ModalController, PopoverController } from '@ionic/angular';

import uniq from 'lodash/uniq';

import { SelectDocument, SelectGroupOfDocuments } from './select-documents-modal.interfaces';
import { DocumentType } from '@shared/enums/document-type';
import { BaseModal } from '@shared/modals/base-modal';
import { NotesModalComponent } from '@shared/modals/notes-modal/notes-modal.component';
import { TooltipPopoverComponent } from '@shared/popovers/tooltip-popover/tooltip-popover.component';

@Component({
    selector: 'vendo-select-documents-modal',
    templateUrl: './select-documents-modal.component.html',
    styleUrls: ['./select-documents-modal.component.scss']
})
export class SelectDocumentsModalComponent extends BaseModal implements OnInit {
    @Input() documents = [];
    @Input() isOnlyAttachments = false;
    title: string;
    groupedDocuments: SelectGroupOfDocuments[] = [];
    documentTypes: typeof DocumentType = DocumentType;
    allOpenedGroups: string[] = [];

    constructor(
        public modalController: ModalController,
        private popoverController: PopoverController
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.title = `Select ${this.isOnlyAttachments ? 'Attachments' : 'Documents'} To Send`;
        this.setGroupedDocuments();
    }

    done(): void {
        this.dismiss(this.documents);
    }

    async setDocumentContext(document: any): Promise<void> {
        if (document.type === DocumentType.DemoResource && document.categories.length) {
            this.groupedDocuments[this.groupedDocuments.length - 1].groupedDocuments.forEach((groupedDocument) => {
                groupedDocument.documents.forEach((resource) => {
                    if (
                        resource.categories?.find((name: string) => name === groupedDocument.name) &&
                        resource.id === document.id
                    ) {
                        resource.selected = !resource.selected;
                    }
                });
            });

            return;
        }

        if (document.type !== 'proposal') {
            return;
        }

        const modal: HTMLIonModalElement = await this.modalController.create({
            component: NotesModalComponent,
            componentProps: {
                headerText: 'Description of Work'
            },
            cssClass: 'result-appointment-modal'
        });

        await modal.present();

        const { data } = await modal.onWillDismiss();

        document.context = {
            description_of_work: data
        };
    }

    async presentPopover(document, event: any): Promise<void> {
        if (!document.signed) {
            return;
        }

        const popover: HTMLIonPopoverElement = await this.popoverController.create({
            component: TooltipPopoverComponent,
            cssClass: 'tooltip-popover ion-text-nowrap ion-padding',
            event,
            translucent: true,
            mode: 'md',
            showBackdrop: false,
            componentProps: {
                message: 'Document is signed and can not be unchecked'
            }
        });

        await popover.present();
    }

    private setGroupedDocuments(): void {
        const packageNames: { [key: string]: string } = {};
        const packagesDocumentsMap: Map<string, SelectDocument[]> = new Map();
        const additionalDocuments: SelectDocument[] = [];
        const demoResources: SelectDocument[] = [];
        const demoResourceCategories: string[] = [];

        for (const document of this.documents) {
            if (document.required) {
                document.selected = true;
            }

            if (document.type === DocumentType.DemoResource) {
                demoResources.push(document);

                if (document.categories.length) {
                    demoResourceCategories.push(...document.categories.map((name: string) => name));
                }

                continue;
            }

            if (!document.quote?.id) {
                additionalDocuments.push(document);
                continue;
            }

            const packageId = String(document.quote.id);

            if (packagesDocumentsMap.has(packageId)) {
                packagesDocumentsMap.get(packageId).push(document);
            } else {
                packagesDocumentsMap.set(packageId, [document]);
            }

            if (document.quote.package_name) {
                packageNames[packageId] = document.quote.package_name;
            }
        }

        const groupedDocuments: SelectGroupOfDocuments[] = Array.from(packagesDocumentsMap.keys())
            .sort()
            .map((packageId) => ({
                name: packageNames[packageId],
                documents: packagesDocumentsMap.get(packageId)
            }));

        if (additionalDocuments.length) {
            groupedDocuments.push({
                name: 'Additional Attachments',
                documents: additionalDocuments
            });
        }

        if (demoResources.length) {
            const groupedDemoResourcesMap: Map<string, SelectDocument[]> = new Map();
            const ungroupedDemoResources: SelectDocument[] = [];
            const uniqDemoResourceCategories: string[] = uniq(demoResourceCategories).sort();

            uniqDemoResourceCategories.map((name) => groupedDemoResourcesMap.set(name, []));
            demoResources.forEach((demoResource: SelectDocument) => {
                if (demoResource.categories.length) {
                    demoResource.categories.forEach((name: string) => {
                        groupedDemoResourcesMap.get(name).push(demoResource);
                    });
                } else {
                    ungroupedDemoResources.push(demoResource);
                }
            });

            const groupedDemoResources = Array.from(groupedDemoResourcesMap.keys())
                .sort()
                .map((name) => ({
                    name,
                    documents: groupedDemoResourcesMap.get(name)
                }));

            if (groupedDemoResources.length && ungroupedDemoResources.length) {
                groupedDemoResources.push({
                    name: 'General',
                    documents: ungroupedDemoResources
                });
            }

            groupedDocuments.push({
                name: 'Demo Resources',
                documents: groupedDemoResources.length ? [] : demoResources,
                groupedDocuments: groupedDemoResources.length ? groupedDemoResources : null
            });
        }

        this.allOpenedGroups = groupedDocuments.map((_, index) => `group-accordion-${index}`);
        this.groupedDocuments = groupedDocuments;
    }
}

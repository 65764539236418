<ng-container *ngIf="question?.questionType === questionTypes.Input || question?.questionType === questionTypes.Textarea || question?.Answers?.length || question.AllowsDifferentAnswers">
    <div *ngIf="!question.Hidden || question.StopType === 2"
         class="question-block"
         [ngClass]="{
            'has-error': question.StopType === 2,
            'free-entry': question.questionType === questionTypes.Input || question.questionType === questionTypes.Dropdown
         }">
        <div class="question-header">
            <div class="question-container">
                <div class="question-title">
                    {{ question.QuestionText }}
                    <span *ngIf="question.QuestionSubText" class="question-sub-title">{{ question.QuestionSubText }}</span>
                    <ion-icon *ngIf="question.Locked && !question.UsingDifferentAnswers"
                              class="locked-icon"
                              name="lock-closed"></ion-icon>
                </div>

                <div *ngIf="question.Errors || (hardStopErrors && hardStopErrors[question.ID])" class="errors-wrapper">
                    <ion-icon name="alert-circle"></ion-icon>
                    <div *ngIf="question.Errors" class="messages">
                        <ion-text *ngFor="let error of question.Errors">
                            {{ error.message }}
                        </ion-text>
                    </div>
                </div>
            </div>

            <ion-button *ngIf="question.InfoLinkURL"
                        size="small"
                        color="primary"
                        (click)="openLink(question.InfoLinkURL)">
                <ion-icon name="log-out-outline" slot="start"></ion-icon>
                Help me choose
            </ion-button>
        </div>

        <div class="answers"
             [class.locked]="question.Locked && !question.UsingDifferentAnswers"
             [class.free-text]="question.questionType === questionTypes.Input || question.questionType === questionTypes.Dropdown">
            <p *ngIf="question.MarketingText">{{ question.MarketingText }}</p>

            <ion-item *ngIf="question.AllowsDifferentAnswers" lines="none" class="toggle">
                <ion-label class="left-label" [attr.data-active]="!question.UsingDifferentAnswers">Set for all</ion-label>
                <ion-toggle mode="md"
                            [disabled]="isSecondMeasureValidate && !question.SelectedAnswerID && !question.questions?.length"
                            [(ngModel)]="question.UsingDifferentAnswers"
                            (ngModelChange)="toggleAllowsDifferentAnswers(question)"></ion-toggle>
                <ion-label class="right-label" [attr.data-active]="question.UsingDifferentAnswers">Set per unit</ion-label>
            </ion-item>

            <ng-container *ngIf="question.UsingDifferentAnswers && question.questions?.length; else answerTemplate">
                <div class="sub-question"
                     *ngFor="let subQuestion of question.questions; let i = index; trackBy: trackById"
                     [ngClass]="{
                        'has-error': subQuestion.StopType === 2,
                        'free-entry': subQuestion.questionType === questionTypes.Input || question.questionType === questionTypes.Dropdown
                     }">
                    <div class="question-header">
                        <div class="question-container">
                            <div class="question-title">
                                {{ subQuestion.QuestionText }}
                                <span *ngIf="subQuestion.QuestionSubText" class="question-sub-title">{{ subQuestion.QuestionSubText }}</span>
                                <ion-icon *ngIf="subQuestion.Locked" class="locked-icon" name="lock-closed"></ion-icon>
                            </div>

                            <div *ngIf="subQuestion.Errors || (hardStopErrors && hardStopErrors[subQuestion.ID])" class="errors-wrapper">
                                <ion-icon name="alert-circle"></ion-icon>
                                <div *ngIf="subQuestion.Errors" class="messages">
                                    <ion-text *ngFor="let error of subQuestion.Errors">
                                        {{ error.message }}
                                    </ion-text>
                                </div>
                            </div>
                        </div>

                        <ion-button *ngIf="question.InfoLinkURL"
                                    size="small"
                                    color="primary"
                                    (click)="openLink(subQuestion.InfoLinkURL)">
                            <ion-icon name="log-out-outline" slot="start"></ion-icon>
                            Help me choose
                        </ion-button>
                    </div>

                    <p *ngIf="subQuestion.MarketingText" class="sub-question-marketing-text">
                        {{ subQuestion.MarketingText }}
                    </p>

                    <div class="sub-answers"
                         [class.locked]="subQuestion.Locked"
                         [ngSwitch]="subQuestion.questionType">
                        <ion-input legacy="true" *ngSwitchCase="questionTypes.Input"
                                   [disabled]="subQuestion.Locked"
                                   type="text"
                                   [inputmode]="subQuestion.IsNumeric ? 'tel' : 'text'"
                                   [placeholder]="subQuestion.QuestionText"
                                   [(ngModel)]="subQuestion.SelectedAnswerID"
                                   [id]="'subquestion' + subQuestion.ID"
                                   (ionFocus)="focusedInputId.emit('subquestion' + subQuestion.ID)"
                                   (ionBlur)="focusedInputId.emit(null)"
                                   (change)="saveTextAnswer(subQuestion, initialQuestion.questions[i].SelectedAnswerID)"
                                   (click)="openPicker(subQuestion, initialQuestion.questions[i].SelectedAnswerID)"
                                   [readonly]="wheelSelectorSettings && subQuestion.IsNumeric && !subQuestion.Locked"
                                   #inputElement></ion-input>

                        <ng-container *ngSwitchCase="questionTypes.Dropdown">
                            <div class="flex-center">
                                <ion-select legacy="true"
                                            mode="md"
                                            interface="popover"
                                            class="w-100"
                                            [interfaceOptions]="{mode: 'md', alignment: 'start', cssClass: 'wrap-select-option-text'}"
                                            [value]="subQuestion.SelectedAnswerID"
                                            [disabled]="subQuestion.Locked"
                                            (click)="setPopoverWidth($event, subQuestion.Locked)"
                                            (ionChange)="setAnswer(subQuestion, $event.detail.value, i)">
                                    <ng-container *ngFor="let answer of subQuestion.Answers">
                                        <ion-select-option *ngIf="!answer.Hidden" [value]="answer.ID">
                                            {{ answer.Text }}
                                        </ion-select-option>
                                    </ng-container>
                                </ion-select>

                                <i *ngIf="selectedAnswer?.HoverText"
                                   class="material-icons info-icon"
                                   (click)="infoIconOnClick($event, selectedAnswer.HoverText, subQuestion.InfoLinkURL)">
                                    info
                                </i>

                                <i *ngIf="isWcpPreferencesFeatureEnabled && selectedSubAnswers[i]?.IsPreferenceAllowed"
                                   class="material-icons"
                                   [class.selected]="selectedSubAnswers[i].IsPreference"
                                   (click)="setPreferenceType(subQuestion, selectedSubAnswers[i].ID)">
                                    {{ selectedSubAnswers[i].IsPreference ? 'star' : 'star_outline' }}
                                </i>
                            </div>
                        </ng-container>

                        <ion-list *ngSwitchCase="questionTypes.RadioButtons">
                            <ion-radio-group (ionChange)="setAnswer(subQuestion, $event.detail.value)"
                                             [value]="subQuestion.SelectedAnswerID">
                                <ng-container *ngFor="let answer of subQuestion.Answers; trackBy: trackById">
                                    <div  *ngIf="!answer.Hidden" class="radio-item ion-text-center">
                                        <ion-radio legacy="true" mode="md"
                                                   [value]="answer.ID"
                                                   [disabled]="subQuestion.Locked"></ion-radio>
                                        <ion-label>{{ answer.Text }}</ion-label>

                                        <i *ngIf="answer.HoverText"
                                           class="material-icons info-icon"
                                           (click)="infoIconOnClick($event, answer.HoverText, answer.InfoLinkURL)">
                                            info
                                        </i>

                                        <ng-container *ngIf="isWcpPreferencesFeatureEnabled && answer.IsPreferenceAllowed">
                                            <hr class="divider"/>
                                            <div class="favorite-answer">
                                                <i class="material-icons"
                                                   [class.selected]="answer.IsPreference"
                                                   (click)="setPreferenceType(subQuestion, answer.ID)">
                                                    {{ answer.IsPreference ? 'star' : 'star_outline' }}
                                                </i>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </ion-radio-group>
                        </ion-list>

                        <ion-grid *ngSwitchDefault>
                            <ion-row>
                                <ng-container *ngFor="let answer of subQuestion.Answers; trackBy: trackById">
                                    <ion-col *ngIf="!answer.Hidden"
                                             [ngClass]="{'selected': answer.ID === subQuestion.SelectedAnswerID, 'with-icon': answer.HoverText}"
                                             (click)="setAnswer(subQuestion, answer.ID)"
                                             size="3"
                                             class="answer">
                                        <img [src]="answer.ImageURL | wcpDrawingUrl:imgBaseUrl"
                                             class="answer-image"/>
                                        <ion-text>{{ answer.Text }}</ion-text>

                                        <i *ngIf="answer.HoverText"
                                           class="material-icons info-icon"
                                           (click)="infoIconOnClick($event, answer.HoverText, answer.InfoLinkURL)">
                                            info
                                        </i>

                                        <ng-container *ngIf="isWcpPreferencesFeatureEnabled && answer.IsPreferenceAllowed">
                                            <hr class="divider"/>
                                            <div class="favorite-answer">
                                                <i class="material-icons"
                                                   [class.selected]="answer.IsPreference"
                                                   (click)="setPreferenceType(subQuestion, answer.ID, $event)">
                                                    {{ answer.IsPreference ? 'star' : 'star_outline' }}
                                                </i>
                                            </div>
                                        </ng-container>

                                        <ion-icon *ngIf="answer.ID === subQuestion.SelectedAnswerID"
                                                  name="checkmark"
                                                  class="selected-icon"></ion-icon>
                                    </ion-col>
                                </ng-container>
                            </ion-row>
                        </ion-grid>
                        <ng-container *ngIf="hardStopErrors && hardStopErrors[subQuestion.ID]">
                            <div *ngFor="let subQuestion of hardStopErrors[subQuestion.ID]" class="hard-stop-error">{{ subQuestion }}</div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>

            <ng-template #answerTemplate>
                <ng-container [ngSwitch]="question.questionType">
                    <div *ngSwitchCase="questionTypes.Input" class="flex-center">
                        <ion-input legacy="true" [disabled]="question.Locked"
                                   [placeholder]="question.QuestionText"
                                   [(ngModel)]="question.SelectedAnswerID"
                                   type="text"
                                   [inputmode]="question.IsNumeric ? 'tel' : 'text'"
                                   [id]="'question' + question.ID"
                                   (ionFocus)="focusedInputId.emit('question' + question.ID)"
                                   (ionBlur)="focusedInputId.emit(null)"
                                   (change)="saveTextAnswer(question, initialQuestion.SelectedAnswerID)"
                                   (click)="openPicker(question, initialQuestion.SelectedAnswerID)"
                                   [readonly]="wheelSelectorSettings && question.IsNumeric && !question.Locked"
                                   #inputElement></ion-input>

                        <i (click)="infoIconOnClick($event, question.MarketingText, question.InfoLinkURL)"
                           *ngIf="question.MarketingText" class="material-icons info-icon">
                            info
                        </i>
                    </div>

                    <div *ngSwitchCase="questionTypes.Textarea" class="flex-center">
                        <ion-textarea #inputField
                                      rows="3"
                                      [disabled]="question.Locked"
                                      [placeholder]="question.QuestionText"
                                      [(ngModel)]="question.SelectedAnswerID"
                                      [id]="'question' + question.ID"
                                      (ionFocus)="focusedInputId.emit('question' + question.ID)"
                                      (ionBlur)="focusedInputId.emit(null)"
                                      (change)="saveTextAnswer(question, initialQuestion.SelectedAnswerID)"></ion-textarea>
                    </div>

                    <div *ngSwitchCase="questionTypes.Dropdown" class="flex-center">
                        <ion-select legacy="true"
                                    mode="md"
                                    interface="popover"
                                    class="w-100"
                                    [interfaceOptions]="{mode: 'md', alignment: 'start', cssClass: 'wrap-select-option-text'}"
                                    [ngClass]="{
                                        'preference-allowed' : isWcpPreferencesFeatureEnabled && selectedAnswer?.IsPreferenceAllowed,
                                        'hover-text' : selectedAnswer?.HoverText
                                    }"
                                    [value]="question.SelectedAnswerID"
                                    [disabled]="question.Locked"
                                    (click)="setPopoverWidth($event, question.Locked)"
                                    (ionChange)="setAnswer(question, $event.detail.value)">
                            <ng-container *ngFor="let answer of question.Answers">
                                <ion-select-option *ngIf="!answer.Hidden" [value]="answer.ID">
                                    {{ answer.Text }}
                                </ion-select-option>
                            </ng-container>
                        </ion-select>

                        <i *ngIf="selectedAnswer?.HoverText"
                           class="material-icons info-icon"
                           (click)="infoIconOnClick($event, selectedAnswer.HoverText, selectedAnswer.InfoLinkURL)">
                            info
                        </i>

                        <i *ngIf="isWcpPreferencesFeatureEnabled && selectedAnswer?.IsPreferenceAllowed"
                           class="material-icons"
                           [class.selected]="selectedAnswer.IsPreference"
                           (click)="setPreferenceType(question, selectedAnswer.ID)">
                            {{ selectedAnswer.IsPreference ? 'star' : 'star_outline' }}
                        </i>
                    </div>

                    <ion-list *ngSwitchCase="questionTypes.RadioButtons">
                        <ion-radio-group (ionChange)="setAnswer(question, $event.detail.value)"
                                         [value]="question.SelectedAnswerID">
                            <ng-container *ngFor="let answer of question.Answers; trackBy: trackById">
                                <div *ngIf="!answer.Hidden" class="radio-item ion-text-center">
                                    <ion-radio legacy="true" mode="md"
                                               [value]="answer.ID"
                                               [disabled]="question.Locked"></ion-radio>
                                    <div class="flex-center label-container">
                                        <ion-label>{{ answer.Text }}</ion-label>

                                        <i *ngIf="answer.HoverText"
                                           class="material-icons info-icon"
                                           (click)="infoIconOnClick($event, answer.HoverText, answer.InfoLinkURL)">
                                            info
                                        </i>
                                    </div>

                                    <ng-container *ngIf="isWcpPreferencesFeatureEnabled && answer.IsPreferenceAllowed">
                                        <hr class="divider"/>
                                        <div class="favorite-answer">
                                            <i class="material-icons"
                                               [class.selected]="answer.IsPreference"
                                               (click)="setPreferenceType(question, answer.ID)">
                                                {{ answer.IsPreference ? 'star' : 'star_outline' }}
                                            </i>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </ion-radio-group>
                    </ion-list>

                    <ion-grid *ngSwitchDefault>
                        <ion-row>
                            <ng-container *ngFor="let answer of question.Answers; trackBy: trackById">
                                <ion-col *ngIf="!answer.Hidden"
                                         [ngClass]="{'selected': answer.ID === question.SelectedAnswerID, 'with-icon': answer.HoverText}"
                                         (click)="setAnswer(question, answer.ID)"
                                         size="3"
                                         class="answer">
                                    <img [src]="answer.ImageURL | wcpDrawingUrl:imgBaseUrl"
                                         class="answer-image"/>

                                    <div class="flex-center">
                                        <ion-text>{{ answer.Text }}</ion-text>

                                        <i *ngIf="answer.HoverText"
                                           class="material-icons info-icon"
                                           (click)="infoIconOnClick($event, answer.HoverText, answer.InfoLinkURL)">
                                            info
                                        </i>
                                    </div>

                                    <ng-container *ngIf="isWcpPreferencesFeatureEnabled && answer.IsPreferenceAllowed">
                                        <hr class="divider"/>
                                        <div class="favorite-answer">
                                            <i class="material-icons"
                                               [class.selected]="answer.IsPreference"
                                               (click)="setPreferenceType(question, answer.ID, $event)">
                                                {{ answer.IsPreference ? 'star' : 'star_outline' }}
                                            </i>
                                        </div>
                                    </ng-container>
                                    <ion-icon *ngIf="answer.ID === question.SelectedAnswerID"
                                              name="checkmark"
                                              class="selected-icon"></ion-icon>
                                </ion-col>
                            </ng-container>
                        </ion-row>
                    </ion-grid>
                </ng-container>
            </ng-template>
            <ng-container *ngIf="hardStopErrors && hardStopErrors[question.ID]">
                <div *ngFor="let question of hardStopErrors[question.ID]" class="hard-stop-error">{{ question }}</div>
            </ng-container>
        </div>
    </div>
</ng-container>

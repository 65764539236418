<div class="modal-header">
    <ion-text class="title">{{quote.package_name}}</ion-text>
    <ion-button fill="outline" color="dark" (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
</div>

<div class="modal-body">
    <ion-item>
        <ion-input legacy="true" #inputField
                   class="package-name"
                   [(ngModel)]="inputText"
                   [maxlength]="charLimit"
                   [placeholder]="quote.package_name"
                   type="text"></ion-input>
    </ion-item>
</div>

<div class="modal-footer">
    <ion-text class="char-limit">{{charLimit - inputText.length}} Characters remaining</ion-text>
    <ion-button class="classic-width" (click)="save()">Done</ion-button>
</div>

const domain = 'api.stage.paradigmvendo.com';

export const environment = {
  production: false,
  stage: true,
  marvin: false,
  andersen: false,
  genesis: false,
  revival: false,
  local: false,
  lt: false,
  wsUrl: `wss://${domain}/subscription`,
  apiUrl: `https://${domain}`,
  siteUrl: 'https://stage.paradigmvendo.com',
  auth0: {
    tenant: 'dev-dia12oy6.auth0.com',
    client_id: 'yoTB67ZaY5bmvzif9vvZFsoXidhYXOen'
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoicGFyYWRpZ212ZW5kbyIsImEiOiJjazZqa3VyNnQwYnZqM2ptcDNjMnFzeGx2In0.xxzq4MLuppbjMf5Ged19rQ'
  },
  ionic: {
    channel_name: 'Stage',
    app_id: '8ba34b60'
  },
  intercomId: '',
  pendoApiKeys: {
    web: '4181b33a-d3c6-4782-4475-6c42776c4e98',
    ios: '00b721c2-5d73-4bb2-5432-0c495776a9f2',
    android: '95270a4d-5e61-43b9-5689-b239352b7f7a'
  },
  staticResourceUrl: 'tqcdn.azureedge.net'
};


<div class="modal-header">
    <ion-text class="title">Quick Invoice</ion-text>
    <ion-button fill="outline" color="dark" (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
</div>

<div class="modal-body">
    <ion-content>
        <h6 *ngIf="quickInvoices?.length">Existing invoices</h6>
        <div class="invoices-container" *ngIf="quickInvoices?.length">
            <ion-grid class="quick-invoices">
                <ion-row class="header-row">
                    <ion-col size="1">#</ion-col>
                    <ion-col size="4">Status</ion-col>
                    <ion-col size="4">Due Date</ion-col>
                    <ion-col size="3" class="ion-text-right">Amount</ion-col>
                </ion-row>
                <ion-grid class="table-body">
                    <ion-row *ngFor="let invoice of quickInvoices; let i = index" class="table-row">
                        <ion-col size="1">{{ i + 1 }}</ion-col>
                        <ion-col size="4"
                                 [class.expired]="invoice.payment_status === 'Failed'"
                                 [class.completed]="invoice.payment_status === 'Completed'">
                            {{ invoice.payment_status }}
                        </ion-col>
                        <ion-col size="4" [class.expired]="invoice.is_expired">
                            {{ invoice.due_date | localizedDate: 'MM/dd/yyyy' }}
                        </ion-col>
                        <ion-col size="3" class="ion-text-right">
                            {{ invoice.amount | customCurrency }}
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-grid>
        </div>

        <vendo-quick-invoice-form [remainingBalance]="remainingBalance"
                                  [appointment]="appointment"
                                  (balanceAdjusted)="adjustedRemainingBalance = $event"></vendo-quick-invoice-form>
    </ion-content>
</div>

<div class="modal-footer">
    <div class="balance">
        <h5>Remaining Balance:</h5>
        <h4>{{ adjustedRemainingBalance | customCurrency }}</h4>
    </div>
    <ion-button class="classic-width"
                (click)="sendInvoice()">
        Send
    </ion-button>
</div>

<div class="modal-header">
  <ion-text class="title">Pricing profiles</ion-text>
  <ion-button fill="outline" color="dark" (click)="closeModal()">
    <ion-icon slot="icon-only" name="close"></ion-icon>
  </ion-button>
</div>

<div class="modal-body">
  <ion-radio-group *ngIf="profiles.length && isLineItemsExist"
                   [(ngModel)]="isUpdateExistingLines">
    <ion-item *ngFor="let option of applyOptions"
              lines="none">
      <ion-label>{{ option.label }}</ion-label>
      <ion-radio legacy="true" slot="start" mode="md" [value]="option.value"></ion-radio>
    </ion-item>
  </ion-radio-group>

  <vendo-select-item [(selectedValue)]="selectedProfileId"
                    [items]="profiles"
                    labelProperty="ProfileName"
                    valueProperty="ProfileId"
                    [isAllowUnselect]="true"
                    noDataText="No available profiles"></vendo-select-item>
</div>

<div class="modal-footer">
  <ion-button fill="outline" (click)="closeModal()">
    Cancel
  </ion-button>
  <ion-button *ngIf="profiles.length" (click)="confirm()">
    Save
  </ion-button>
</div>


import { gql } from 'apollo-angular';

const IMPORT_MEASUREMENT_SYSTEM_REPORT = gql`
    mutation importMeasurementSystemReport(
        $id: ID
        $report_id: ID
        $type: MeasurementSystemTypeEnum!
        $appointment_id: ID!
        $category_types: [String!]
        $takeoff_details: JSON
        $credentials: JSON
        $remember: Boolean
        $used_remember: Boolean
        $geolocation: JSON
    ) {
        importMeasurementSystemReport(
            id: $id
            report_id: $report_id
            type: $type
            appointment_id: $appointment_id
            category_types: $category_types
            takeoff_details: $takeoff_details
            credentials: $credentials
            remember: $remember
            used_remember: $used_remember
            geolocation: $geolocation
        )
    }
`;

const MODIFY_MEASUREMENT_SYSTEM_REPORT = gql`
    mutation modifyMeasurementSystemReport(
        $id: ID!
        $type: MeasurementSystemTypeEnum!
        $appointment_id: ID!
        $category_types: [String!]!
        $takeoff_details: JSON
        $geolocation: JSON
    ) {
        modifyMeasurementSystemReport(
            id: $id
            type: $type
            appointment_id: $appointment_id
            category_types: $category_types
            takeoff_details: $takeoff_details
            geolocation: $geolocation
        )
    }
`;

const ORDER_MEASUREMENT_SYSTEM_REPORT = gql`
    mutation orderMeasurementSystemReport(
        $type: MeasurementSystemTypeEnum!
        $appointment_id: ID!
        $owner_details: InputMeasurementSystemOwnerDetails
        $address_details: InputMeasurementSystemAddress!
        $order_details: JSON
        $credentials: JSON
        $remember: Boolean
        $used_remember: Boolean
    ) {
        orderMeasurementSystemReport(
            type: $type
            appointment_id: $appointment_id
            owner_details: $owner_details
            address_details: $address_details
            order_details: $order_details
            credentials: $credentials
            remember: $remember
            used_remember: $used_remember
        )
    }
`;

const REMOVE_MEASUREMENT_SYSTEM_REPORT = gql`
    mutation removeMeasurementSystemReport($id: ID!, $appointmentId: ID!) {
        removeMeasurementSystemReport(id: $id, appointment_id: $appointmentId)
    }
`;

export {
    IMPORT_MEASUREMENT_SYSTEM_REPORT,
    MODIFY_MEASUREMENT_SYSTEM_REPORT,
    ORDER_MEASUREMENT_SYSTEM_REPORT,
    REMOVE_MEASUREMENT_SYSTEM_REPORT
};

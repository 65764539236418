<div class="promotion-dropdown flex-center" (swipeleft)="changeView()">
	<ng-select #ngSelect
			   bindLabel="name"
			   notFoundText="No Promotions available"
			   placeholder="Promotion"
			   [multiple]="true"
			   [searchable]="false"
			   [clearable]="false"
			   [closeOnSelect]="true"
			   [markFirst]="false"
			   [deselectOnClick]="true"
			   [items]="activePromotions"
			   [ngModel]="selectedPromotions"
			   (remove)="onItemRemove()"
			   (ngModelChange)="promotionsChanged($event)">
	</ng-select>
</div>

import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import Swiper from 'swiper';

import { ModalController } from '@ionic/angular';

import get from 'lodash/get';

import { SwiperService } from '@core/services/helpers/swiper.service';
import { BaseModal } from '@shared/modals/base-modal';

@Component({
    selector: 'vendo-takeoff-info-modal',
    templateUrl: './takeoff-info-modal.component.html',
    styleUrls: ['./takeoff-info-modal.component.scss']
})
export class TakeoffInfoModalComponent extends BaseModal implements OnInit, AfterViewInit, OnDestroy {
    @Input() openingData: any;
    @Input() activeSlideIndex: number;
    @Input() takeoffDetailsTitle: string;
    tabs = [];
    activeTab = this.tabs[0];
    countImages: number;

    @ViewChild('swiperContainer', { static: false }) swiperContainer: ElementRef;
    private swiperInstance: Swiper;

    constructor(
        modalController: ModalController,
        private swiperService: SwiperService
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.countImages = get(this.openingData, 'images.length') || 0;
        this.tabs = [
            {
                label: 'Photos',
                hash: 'photos',
                hidden: !this.countImages
            }
        ];

        if (this.takeoffDetailsTitle) {
            this.tabs.push({
                label: this.takeoffDetailsTitle,
                hash: 'details'
            });
        }

        const tabIndex = Number(!this.activeSlideIndex);

        this.activeTab = this.tabs[this.takeoffDetailsTitle ? tabIndex : 0];
    }

    ngAfterViewInit(): void {
        this.initSwiper();
    }

    ngOnDestroy(): void {
        this.destroySwiper();
    }

    setActiveTab(tab: any): void {
        this.activeTab = tab;

        if (this.activeTab.hash === 'photos') {
            this.initSwiper();
        }
    }

    private initSwiper(): void {
        const initialSlide = this.activeSlideIndex ? this.activeSlideIndex - 1 : 0;

        this.destroySwiper();

        setTimeout(() => {
            if (this.swiperContainer) {
                this.swiperInstance = this.swiperService.initSwiper(
                    this.swiperContainer.nativeElement,
                    false,
                    400,
                    initialSlide,
                    false
                );
            }
        }, 50);
    }

    private destroySwiper(): void {
        if (this.swiperInstance) {
            this.swiperInstance.destroy(true, true);
        }
    }
}

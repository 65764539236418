<div class="modal-header">
    <ion-text class="title">Feedback</ion-text>
    <ion-button *ngIf="!isSubmittedFeedback"
                fill="outline"
                color="dark"
                (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
</div>

<div class="modal-body" [class.success-message]="isSubmittedFeedback">
    <ng-container *ngIf="!isSubmittedFeedback; else message" [formGroup]="form">
        <div class="mb-15">
            As a sales representative, how likely are you to recommend this in-home selling tool to others in the
            industry?
        </div>
        <vendo-rating formControlName="rating"></vendo-rating>

        <div *ngIf="form.get('rating').value !== null">
            <div class="mb-5">
                <ng-container [ngSwitch]="true">
                    <ng-container *ngSwitchCase="form.get('rating').value >= 9">
                        Which features of this software do you value/use the most?
                    </ng-container>
                    <ng-container *ngSwitchCase="form.get('rating').value <= 6">
                        What is missing or disappointing with your experience with this software?
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        How can we improve your experience with this software?
                    </ng-container>
                </ng-container>
            </div>
            <ion-textarea formControlName="description"
                          [maxlength]="charLimit"
                          rows="3"></ion-textarea>
            <ion-text class="char-limit">
                {{ charLimit - form.get('description').value.length }} Characters remaining
            </ion-text>
        </div>
    </ng-container>

    <ng-template #message>
        <ng-container [ngSwitch]="true">
            <ng-container *ngSwitchCase="form.get('rating').value >= 9">
                Thanks for your feedback. It’s great to hear that you’re a fan of the product. Your feedback helps us
                discover new opportunities to improve our product and make sure you have the best possible experience.
            </ng-container>
            <ng-container *ngSwitchCase="form.get('rating').value <= 6">
                Thanks for your feedback. We highly value all ideas and suggestions from our customers, whether they’re
                positive or critical.
            </ng-container>
            <ng-container *ngSwitchDefault>
                Thanks for your feedback. Our goal is to create the best possible product, and your thoughts, ideas, and
                suggestions play a major role in helping us identify opportunities to improve.
            </ng-container>
        </ng-container>
    </ng-template>
</div>

<div class="modal-footer">
    <ion-button *ngIf="!isSubmittedFeedback; else okButton"
                class="classic-width"
                [disabled]="form.invalid"
                (click)="save()">
        Submit
    </ion-button>

    <ng-template #okButton>
        <ion-button class="classic-width"
                    (click)="dismiss(isSubmittedFeedback)">
            Ok
        </ion-button>
    </ng-template>
</div>

import { Injectable } from '@angular/core';

import Swiper from 'swiper';

import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SwiperService {
    private swiperInited: Subject<void> = new Subject<void>();
    swiperInited$: Observable<void> = this.swiperInited.asObservable();

    initSwiper(
        nativeElement: HTMLElement,
        loop: boolean,
        speed: number,
        initialSlide: number,
        useNavigation: boolean,
        nextEl = '.swiper-button-next',
        prevEl = '.swiper-button-prev',
        paginationClass = '.swiper-pagination'
    ): Swiper {
        return new Swiper(nativeElement, {
            loop: loop,
            ...(speed && { speed }),
            ...(initialSlide && { initialSlide }),
            ...(useNavigation && {
                navigation: {
                    nextEl,
                    prevEl
                }
            }),
            pagination: {
                el: paginationClass,
                clickable: true
            },
            on: {
                init: () => this.swiperInited.next()
            }
        });
    }
}

import { Injectable } from '@angular/core';

import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { DocumentViewer } from '@awesome-cordova-plugins/document-viewer/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { FileTransfer, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { BIOMETRIC_TYPE, FingerprintAIO } from '@awesome-cordova-plugins/fingerprint-aio/ngx';
import { InAppBrowser, InAppBrowserObject } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { saveAs } from 'file-saver';
import * as printJS from 'print-js';

import { NavController, Platform } from '@ionic/angular';

import { SpinnerService } from '@core/services/spinner.service';
import { Appointment } from '@shared/interfaces/appointment';
import { DeviceInfo } from '@shared/interfaces/user-feedback';

@Injectable()
export class DeviceHelperService {
    private isAndroid: boolean;
    private isIOS: boolean;
    private isMobileWeb: boolean;
    private isDesktop: boolean;
    private isTablet: boolean;
    private isBrowser: boolean;
    private deviceInfo: DeviceInfo;
    private deviceUUID: string;
    private devicePlatform: string;
    private androidLocalFolder = 'Download/';

    constructor(
        private browser: InAppBrowser,
        private callNumber: CallNumber,
        private clipboard: Clipboard,
        private device: Device,
        private documentViewer: DocumentViewer,
        private faio: FingerprintAIO,
        private file: File,
        private fileOpener: FileOpener,
        private launchNavigator: LaunchNavigator,
        private navController: NavController,
        private screenOrientation: ScreenOrientation,
        private platform: Platform,
        private spinnerService: SpinnerService,
        private transfer: FileTransfer
    ) {
        this.platform.ready().then(() => {
            this.isAndroid = this.platform.is('android');
            this.isIOS = this.platform.is('ios');
            // ionic does not correct detect 'mobileweb' platform
            this.isMobileWeb =
                this.platform.is('mobileweb') || !this.device.platform || this.device.platform === 'browser';
            this.isBrowser = this.device.platform === 'browser';
            this.isDesktop = this.platform.is('desktop');
            this.isTablet = this.platform.is('tablet');
            this.deviceInfo = {
                device_model: this.device.model,
                device_operating_system_version: this.device.version
            };
            this.deviceUUID = this.device.uuid;
            this.devicePlatform = this.device.platform || 'browser';
        });
    }

    get isAndroidPlatform(): boolean {
        return this.isAndroid;
    }

    get isIOSPlatform(): boolean {
        return this.isIOS;
    }

    get isBrowserPlatform(): boolean {
        return this.isBrowser;
    }

    get isDesktopWeb(): boolean {
        return this.isDesktop;
    }

    get isWeb(): boolean {
        return this.isDesktop || this.isMobileWeb;
    }

    get isTabletWeb(): boolean {
        return this.isTablet && this.isMobileWeb;
    }

    get isPhone(): boolean {
        return (this.isIOS || this.isAndroid) && !this.isTablet;
    }

    get getDeviceInfo(): DeviceInfo {
        return this.deviceInfo;
    }

    get getDeviceUUID(): string {
        return this.deviceUUID;
    }

    get getDevicePlatform(): string {
        return this.devicePlatform;
    }

    get isLandscape(): boolean {
        return [
            this.screenOrientation.ORIENTATIONS.LANDSCAPE,
            this.screenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY,
            this.screenOrientation.ORIENTATIONS.LANDSCAPE_SECONDARY
        ].includes(this.screenOrientation.type);
    }

    get isPortrait(): boolean {
        return [
            this.screenOrientation.ORIENTATIONS.PORTRAIT,
            this.screenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY,
            this.screenOrientation.ORIENTATIONS.PORTRAIT_SECONDARY
        ].includes(this.screenOrientation.type);
    }

    isFingerPrintAvailable(): Promise<boolean> {
        if (!this.isAndroid && !this.isIOS) {
            return Promise.resolve(false);
        }

        return this.faio
            .isAvailable()
            .then((result: BIOMETRIC_TYPE) => !!result)
            .catch(() => false);
    }
    showFingerPrintDialog(): Promise<void> {
        return this.faio.show({ disableBackup: true, confirmationRequired: false });
    }

    async copyToClipboard(data: any, prefix?: string): Promise<void> {
        let text: string = JSON.stringify(data, null, 2);

        if (prefix) {
            text = `${prefix}
            ${text}`;
        }

        await this.clipboard.copy(text);
    }

    openCallApp(phone: string): void {
        this.callNumber.callNumber(phone, false);
    }

    openEmailApp(email: string): void {
        this.browser.create(`mailto:${email}`, '_system');
    }

    openMaps(appointment: Appointment, houseAddress: any): void {
        if (this.isWeb) {
            const url = `https://maps.google.com/?q=${houseAddress.address1} ${
                houseAddress.address2 ? houseAddress.address2 : ''
            } ${houseAddress.city} ${houseAddress.state} ${houseAddress.postal_code}`;

            window.open(url, '_system');

            return;
        }

        const address: string =
            appointment.house.properties.hasOwnProperty('address') && appointment.house.properties.address.oneLine
                ? appointment.house.properties.address.oneLine
                : `${appointment.house.address1}, ${appointment.house.city}, ${appointment.house.state}, ${appointment.house.country}`;

        this.launchNavigator.navigate(address);
    }

    async openUrl(url: string, target: '_blank' | '_system' = '_blank'): Promise<InAppBrowserObject> {
        if (this.isWeb) {
            window.open(url, '_system');

            return;
        }

        await this.platform.ready();

        return this.browser.create(url, target, {
            clearcache: 'yes',
            clearsessioncache: 'yes'
        });
    }

    openIngageApp(url: string): void {
        let openUrl = url;
        let queryParamValue;
        let openUrlTarget;
        const ingageUrl = new URL(url);

        if (this.isIOSPlatform) {
            for (const [key, value] of (ingageUrl.searchParams as any).entries()) {
                ingageUrl.searchParams.set(key, value);
            }
            openUrl = ingageUrl.toString(); // will auto encode symbols
            queryParamValue = this.isMobileWeb ? window.location.href : 'paradigmvendo://';
            openUrlTarget = '_system';
        } else if (this.isAndroidPlatform) {
            ingageUrl.host = 'web.ingage.io';
            ingageUrl.protocol = 'https:';

            if (!ingageUrl.pathname.endsWith('/')) {
                ingageUrl.pathname += '/';
            }

            openUrl = ingageUrl.toString();
        } else {
            queryParamValue = window.location.href;
        }

        if (queryParamValue) {
            const urlSeparator = url.includes('?') ? '&' : '?';

            openUrl += `${urlSeparator}callback-url=${queryParamValue}`;
        }

        this.openUrl(openUrl, openUrlTarget);
    }

    openPdf(appointmentId: string | number, url: string, fileName: string): void {
        this.navController.navigateRoot(
            `main/appointments/${appointmentId}/pdf-preview/${this.encryptAndEncode(url)}/${this.encryptAndEncode(
                fileName
            )}`
        );
    }

    private encryptAndEncode(value: string): string {
        return encodeURIComponent(btoa(value));
    }

    async printDocument(url: string, fileName: string): Promise<void> {
        if (this.isMobileWeb && this.isIOS) {
            await this.openPdfInBrowserIOS(url, fileName);
        } else if (this.isDesktop) {
            printJS({
                printable: url,
                type: 'pdf',
                style: '@page { size: A4 portrait; }'
            });
        } else if (this.isMobileWeb) {
            // it is for android because does not correct detect android in browser
            this.browser.create(url, '_blank', {
                clearcache: 'yes',
                clearsessioncache: 'yes',
                location: 'no'
            });
        } else if (this.isIOS || this.isAndroid) {
            this.spinnerService.changeActiveRequestsCount(1);
            const entryUrl: string = await this.download(url, fileName, false);
            const directoryPath: string = entryUrl.slice(0, entryUrl.lastIndexOf('/'));
            const base64: string = await this.file.readAsDataURL(directoryPath, fileName);

            this.spinnerService.changeActiveRequestsCount(-1);
            window['plugins'].PrintPDF.print({
                data: base64.replace('data:application/pdf;base64,', '')
            });
        }
    }

    async download(url: string, fileName: string, isOpen = true): Promise<string> {
        if (this.isMobileWeb && this.isIOS) {
            await this.openPdfInBrowserIOS(url, fileName);
        } else if (this.isWeb) {
            const fileBlob = await this.convertPdfUrlToBlob(url);

            saveAs(fileBlob, fileName);
        } else if (this.isIOS || this.isAndroid) {
            return this.downloadDocument(url, fileName, isOpen);
        }
    }

    private async downloadDocument(url: string, fileName: string, isOpen: boolean): Promise<string> {
        const fileTransfer: FileTransferObject = this.transfer.create();
        const directoryPath: string = this.isIOS
            ? this.file.documentsDirectory
            : `${this.file.externalDataDirectory}${this.androidLocalFolder}`;

        this.spinnerService.changeActiveRequestsCount(1);
        if (this.isAndroid) {
            try {
                await this.file.checkDir(this.file.externalDataDirectory, this.androidLocalFolder);
            } catch (e) {
                await this.file.createDir(this.file.externalDataDirectory, this.androidLocalFolder, false);
            }
        }

        const entry = await fileTransfer.download(url, `${directoryPath}${fileName}`);
        const entryUrl = this.isAndroid ? entry.nativeURL : entry.toURL();

        this.spinnerService.changeActiveRequestsCount(-1);

        if (isOpen) {
            if (this.isIOS) {
                this.documentViewer.viewDocument(entryUrl, 'application/pdf', {
                    title: fileName,
                    print: { enabled: true },
                    openWith: { enabled: true }
                });
            } else {
                this.fileOpener.showOpenWithDialog(entryUrl, 'application/pdf');
            }
        }

        return entryUrl;
    }

    private async openPdfInBrowserIOS(url: string, fileName: string): Promise<void> {
        const fileBlob = await this.convertPdfUrlToBlob(url);
        const downloadLink = document.createElement('a');

        downloadLink.download = fileName;
        downloadLink.target = '_blank';
        const winUrl = window.URL || window.webkitURL;

        downloadLink.href = winUrl.createObjectURL(fileBlob);
        downloadLink.click();
    }

    private async convertPdfUrlToBlob(url: string): Promise<Blob> {
        const data = await fetch(url);
        const arrayBuffer = await data.arrayBuffer();

        return new Blob([arrayBuffer], { type: 'application/pdf' });
    }
}

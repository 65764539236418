import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import { SwUpdate, VersionEvent } from '@angular/service-worker';

import { DeeplinkMatch, Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { register } from 'swiper/element/bundle';

import { NavController, Platform } from '@ionic/angular';

import { filter } from 'rxjs/operators';

import { isActiveOfflineMode } from '@core/functions/is-active-offline-mode';
import { RollbarErrorHandler } from '@core/handlers/rollbar-error-handler';
import { ThemeService } from '@core/modules/theme/services/theme.service';
import { AppUpdatesService } from '@core/services/app-updates.service';
import { AppService } from '@core/services/app.service';
import { AuthService } from '@core/services/auth.service';
import { Auth0Service } from '@core/services/auth0.service';
import { AutoCloseOverlaysService } from '@core/services/auto-close-overlays.service';
import { DeviceHelperService } from '@core/services/device-helper.service';
import { FilesService } from '@core/services/files.service';
import { IntercomService } from '@core/services/intercom.service';
import { KeyboardService } from '@core/services/keyboard.service';
import { NetworkService } from '@core/services/network.service';
import { PendoService } from '@core/services/pendo.service';
import { RequestCancelService } from '@core/services/request-cancel.service';
import { SecureStorageService } from '@core/services/secure-storage.service';
import { SpinnerService } from '@core/services/spinner.service';

register();

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
    @HostListener('document:visibilitychange', [])
    onVisibilityChange(): void {
        if (this.deviceHelperService.isWeb && document.visibilityState === 'visible' && this.swUpdate.isEnabled) {
            this.swUpdate.checkForUpdate();
        }
    }

    isIPad = false;
    isIPhone = false;
    isPortrait: boolean;
    isKeyboardOpened = false;
    updatesInProgress = false;
    showSpinner;
    private readonly isOffline: boolean = isActiveOfflineMode();

    constructor(
        private platform: Platform,
        private statusBar: StatusBar,
        private screenOrientation: ScreenOrientation,
        private themeService: ThemeService,
        private authService: AuthService,
        private spinnerService: SpinnerService,
        private networkService: NetworkService,
        private cdr: ChangeDetectorRef,
        private deeplinks: Deeplinks,
        private navController: NavController,
        private appService: AppService,
        private titleService: Title,
        private router: Router,
        private autoCloseOverlaysService: AutoCloseOverlaysService,
        private keyboard: Keyboard,
        private appUpdatesService: AppUpdatesService,
        private deviceHelperService: DeviceHelperService,
        private swUpdate: SwUpdate,
        private fileService: FilesService,
        private intercomService: IntercomService,
        private requestCancelService: RequestCancelService,
        private keyboardService: KeyboardService,
        private rollbarErrorHandler: RollbarErrorHandler,
        @Inject(DOCUMENT) private document: Document,
        private pendoService: PendoService,
        private auth0Service: Auth0Service,
        private secureStorageService: SecureStorageService
    ) {
        this.initializeApp();
        this.handleNavigationStart();
    }

    async ngOnInit(): Promise<void> {
        this.intercomService.init();

        await this.platform.ready();

        if (!this.deviceHelperService.isWeb) {
            this.secureStorageService.init();
            this.isIPad = this.platform.is('ipad');
            this.isIPhone = this.platform.is('iphone');

            if (this.isIPhone) {
                this.screenOrientation.onChange().subscribe(() => this.setIsPortrait());
            }
        } else if (this.swUpdate.isEnabled) {
            this.swUpdateProcess();
        }
    }

    private setIsPortrait(): void {
        this.isPortrait = this.deviceHelperService.isPortrait;
    }

    private initializeApp(): void {
        this.titleService.setTitle(this.appService.getTitle());
        this.appUpdatesService.updatingProcess.subscribe((updates) => {
            this.updatesInProgress = updates.in_progress;
        });
        this.authService.signedIn.subscribe((isLoggedIn) => {
            if (isLoggedIn && !this.isOffline) {
                this.appUpdatesService.performLiveUpdates();
            }
        });
        this.platform.ready().then(() => {
            this.auth0Service.init();
            this.pendoService.init();
            this.initSpinner();

            this.networkService.initializeNetworkEvents();

            if (this.deviceHelperService.isAndroidPlatform) {
                this.statusBar.overlaysWebView(false);
                this.cdr.markForCheck();
            }
            this.statusBar.backgroundColorByHexString(this.themeService.getActiveThemeColor('--primary'));

            if (this.deviceHelperService.isPhone) {
                this.setIsPortrait();
                this.screenOrientation.unlock();
            } else if (!this.deviceHelperService.isWeb) {
                this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
            }

            this.handleDeeplinks();
            this.handleIOSKeyboard();
            this.handleOfflineIOSImages();
        });
    }

    private initSpinner(): void {
        this.spinnerService.spinnerShown.subscribe((value: boolean) => {
            this.showSpinner = value;
            this.cdr.detectChanges();
        });
    }

    private handleDeeplinks(): void {
        if (this.deviceHelperService.isWeb) {
            return;
        }

        this.deeplinks
            .route({
                '/main/appointments': {
                    queryParams: {}
                },
                '/main/appointments/:appointmentId': {
                    queryParams: {
                        openResulting: true
                    }
                },
                '/main/appointments/:appointmentId/final/:quoteId': {
                    queryParams: {
                        paymentResult: true
                    }
                },
                '/reset-password/:token': {
                    queryParams: {}
                },
                '/e-document/v2/:hash': {
                    queryParams: {}
                },
                '/offer-request/:hash': {
                    queryParams: {}
                },
                '/deeplink/:hash': {
                    queryParams: {}
                },
                '/login': {
                    queryParams: {}
                },
                '/redirect-callback': {
                    queryParams: {}
                }
            })
            .subscribe(
                (match: DeeplinkMatch) => {
                    if (match.$link.path.includes('deeplink')) {
                        this.authService.isLoginViaDeeplink = true;
                    }

                    this.navController.navigateRoot(match.$link.path, {
                        queryParams: {
                            ...match.$route.queryParams,
                            ...(match.$route.queryParams.paymentResult && match.$args)
                        }
                    });
                },
                (nomatch) => {
                    console.error("Got a deeplink that didn't match", nomatch);
                }
            );
    }

    private handleIOSKeyboard(): void {
        /**
         * Tricks for issue "IOS Keyboard Covers Input "
         */
        if (this.deviceHelperService.isIOSPlatform) {
            const appEl = this.document.getElementsByTagName('ION-APP')[0] as HTMLElement;

            this.keyboard.disableScroll(true);

            window.addEventListener('keyboardDidShow', (event) => {
                const activeElement = this.document.activeElement;
                const isExistActiveElement: boolean =
                    activeElement && activeElement.nodeName.toLowerCase() !== 'iframe';
                const isActiveElementHasFocusMethod = !!(activeElement as HTMLInputElement).focus;

                if (this.keyboardService.getIsSkipHandleIOSKeyboard() || this.isKeyboardOpened) {
                    if (isExistActiveElement && isActiveElementHasFocusMethod) {
                        (activeElement as HTMLInputElement).focus();
                    }

                    return;
                }

                const appElHeight = appEl.clientHeight;

                this.isKeyboardOpened = true;
                appEl.style.height = appElHeight - (event as any).keyboardHeight + 'px';

                if (isExistActiveElement) {
                    activeElement.scrollIntoView(false);
                    if (isActiveElementHasFocusMethod) {
                        (activeElement as HTMLInputElement).focus();
                    }
                    this.cdr.detectChanges();
                }
            });

            window.addEventListener('keyboardDidHide', () => {
                if (this.keyboardService.getIsSkipHandleIOSKeyboard()) {
                    return;
                }
                this.isKeyboardOpened = false;
                appEl.style.height = '100%';
                this.cdr.detectChanges();
            });
        }
    }

    private handleNavigationStart(): void {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => {
                if (event.navigationTrigger === 'popstate' || event.url.startsWith('/logout')) {
                    this.autoCloseOverlaysService.trigger();
                }

                this.requestCancelService.cancelPendingRequests();
            });
    }

    private handleOfflineIOSImages(): void {
        if (this.isOffline && this.deviceHelperService.isIOSPlatform) {
            this.fileService.startLocalServerAndSetLocalServerUrl();
        }
    }

    private swUpdateProcess(): void {
        this.swUpdate.versionUpdates.subscribe((event: VersionEvent) => {
            // eslint-disable-next-line no-console
            console.log(event);
            switch (event.type) {
                case 'VERSION_DETECTED':
                    this.spinnerService.changeActiveRequestsCount(1);
                    break;
                case 'VERSION_READY':
                    this.spinnerService.changeActiveRequestsCount(-1);
                    this.rollbarErrorHandler.handleInfo(`Reload - ${JSON.stringify(event)}`);
                    window.location.reload();
                    break;
            }
        });

        this.swUpdate.checkForUpdate();
    }
}

import { Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { BaseModal } from '@shared/modals/base-modal';

import { TakeoffService } from '../../../main/appointments/takeoff/services/takeoff.service';

@Component({
    selector: 'vendo-copy-opening-modal',
    templateUrl: './copy-opening-modal.component.html',
    styleUrls: ['./copy-opening-modal.component.scss']
})
export class CopyOpeningModalComponent extends BaseModal {
    @Input() opening: any;
    @Input() disclosure: string;
    copyCount = 1;

    constructor(
        modalController: ModalController,
        private takeoffService: TakeoffService
    ) {
        super(modalController);
    }

    save(): void {
        this.takeoffService.copyOpening(this.opening.id, this.copyCount).subscribe((res) => {
            this.dismiss(true);
        });
    }
}

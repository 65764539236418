import { Component, Input, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { QuoteService } from '@core/services/quote.service';
import { BaseModal } from '@shared/modals/base-modal';

@Component({
    selector: 'vendo-copy-package-modal',
    templateUrl: './copy-package-modal.component.html',
    styleUrls: ['./copy-package-modal.component.scss']
})
export class CopyPackageModalComponent extends BaseModal implements OnInit {
    @Input() packages: any;
    @Input() copyingPackage: any;
    copyToList = [];
    canDoCopy = false;

    constructor(
        modalController: ModalController,
        private quoteService: QuoteService
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.packages.forEach(({ quoteId, name }) => {
            if (quoteId !== this.copyingPackage.quoteId) {
                this.copyToList.push({
                    quoteId,
                    name,
                    isChecked: false
                });
            }
        });
    }

    checkHandler(): void {
        this.canDoCopy = this.copyToList.some(({ isChecked }) => isChecked);
    }

    copy(): void {
        const copyToQuoteIds = [];

        this.copyToList.forEach((item) => {
            if (item.isChecked) {
                copyToQuoteIds.push(item.quoteId);
            }
        });

        this.quoteService
            .copyQuotePackage(this.copyingPackage.quoteId, copyToQuoteIds)
            .subscribe(() => this.dismiss(true));
    }
}

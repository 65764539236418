import { Injectable } from '@angular/core';

import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';

@Injectable({
    providedIn: 'root'
})
export class GeolocationService {
    constructor(
        private geolocation: Geolocation,
        private diagnostic: Diagnostic
    ) {}

    async getGeolocation(): Promise<any> {
        try {
            const isLocationAvailable = await this.diagnostic.isLocationEnabled();

            if (isLocationAvailable) {
                const geolocation = await this.geolocation.getCurrentPosition({ enableHighAccuracy: true });

                return {
                    lat: geolocation.coords.latitude,
                    long: geolocation.coords.longitude
                };
            }
        } catch (e) {
            return {};
        }

        return {};
    }
}

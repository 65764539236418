import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'join'
})
export class JoinPipe implements PipeTransform {
    transform(values: string[], separator = ', ', propertyName: string): unknown {
        if (!values || !values.length) {
            return '';
        }

        if (propertyName) {
            const objectValues = values.map((value) => value[propertyName]);

            return objectValues.join(separator);
        }

        return values.join(separator);
    }
}

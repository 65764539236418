import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';

import { ModalController } from '@ionic/angular';

import { fromEvent, Subscription } from 'rxjs';
import { filter, map, pairwise, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

import forEach from 'lodash/forEach';

import { ImageService } from '@core/services/image.service';
import { BaseModal } from '@shared/modals/base-modal';

@Component({
    selector: 'vendo-image-annotation-modal',
    templateUrl: './image-annotation-modal.component.html',
    styleUrls: ['./image-annotation-modal.component.scss']
})
export class ImageAnnotationModalComponent extends BaseModal implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('inputCanvas') canvas: ElementRef;
    @ViewChild('bgCanvas') bgCanvas: ElementRef;

    @Input() imgUrl = '';
    @Input() fileName = 'photo.jpg';
    @Input() index;
    isSketch: boolean;
    tools = [];
    selectedTool;
    actionsHistory = [];
    color = '#ff0000';
    isUseGrid = true;

    private cx: CanvasRenderingContext2D;
    private cxBg: CanvasRenderingContext2D;
    private img: HTMLImageElement;
    private activeSubscriptions = [];
    private preventAddingNewElement = false;
    private startPos;
    private previousText: string;
    private displayNoneStyle = 'display: none';

    constructor(
        private cdr: ChangeDetectorRef,
        public modalController: ModalController,
        private imageService: ImageService
    ) {
        super(modalController);
    }

    private widthRatio: number;

    ngOnInit(): void {
        this.isSketch = this.fileName === 'sketch.jpeg';
        this.tools = [
            ...(this.isSketch
                ? [
                      {
                          name: 'Grid',
                          hash: 'grid',
                          icon: 'grid_on'
                      }
                  ]
                : []),
            {
                name: 'Draw',
                hash: 'draw',
                icon: 'brush'
            },
            {
                name: 'Erase',
                hash: 'erase',
                icon: 'edit'
            },
            {
                name: 'Line',
                hash: 'line',
                icon: 'timeline'
            },
            {
                name: 'Text',
                hash: 'text',
                icon: 'text_fields'
            }
        ];
        this.selectedTool = this.tools[this.isSketch ? 1 : 0];
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.initCanvas(), 100);
    }

    ngOnDestroy(): void {
        this.clearSubscriptions();
    }

    private initCanvas(): void {
        const modals = document.getElementsByClassName('modal-body');
        const modal = document.getElementsByClassName('modal-body')[modals.length - 1] as any;

        modal.setAttribute('style', `min-height: ${modal.clientHeight}px`);
        const modalWidth = modal.clientWidth - 40 - 80;
        const modalHeight = modal.clientHeight - 40;
        const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
        const bgCanvasEl: HTMLCanvasElement = this.bgCanvas.nativeElement;

        this.imageService.getImage(this.imgUrl).subscribe((res) => {
            this.cx = canvasEl.getContext('2d');
            this.cxBg = bgCanvasEl.getContext('2d');
            this.img = new Image();
            this.img.onload = () => {
                let canvasStyleHeight = null;
                let canvasStyleWidth = null;

                if (this.img.height > modalHeight) {
                    const resizeRatio = modalHeight / this.img.height;

                    canvasStyleHeight = modalHeight;
                    canvasStyleWidth = this.img.width * resizeRatio;
                }

                if (canvasStyleWidth > modalWidth) {
                    const resizeRatio = modalWidth / (canvasStyleWidth || modalWidth);

                    canvasStyleWidth = modalWidth;
                    canvasStyleHeight = (canvasStyleHeight || modalHeight) * resizeRatio;
                }

                canvasEl.height = this.img.height;
                canvasEl.width = this.img.width;
                bgCanvasEl.height = this.img.height;
                bgCanvasEl.width = this.img.width;

                if (this.img.height >= this.img.width) {
                    const canvasHeight = canvasStyleHeight || modalHeight;

                    canvasEl.setAttribute(
                        'style',
                        `height: ${canvasHeight}px; background-image: url(${this.img.src}); background-size: contain`
                    );
                    bgCanvasEl.setAttribute('style', `height: ${canvasHeight}px`);
                    this.widthRatio = canvasEl.height / canvasHeight;

                    if (canvasStyleWidth) {
                        canvasEl.setAttribute(
                            'style',
                            `width: ${canvasStyleWidth}px; background-image: url(${this.img.src}); background-size: contain`
                        );
                        bgCanvasEl.setAttribute('style', `width: ${canvasStyleWidth}px`);
                    }
                } else {
                    const canvasWidth =
                        (canvasStyleWidth || modalWidth) > this.img.width
                            ? this.img.width
                            : canvasStyleWidth || modalWidth;

                    canvasEl.setAttribute(
                        'style',
                        `width: ${canvasWidth}px; height: ${
                            canvasStyleHeight || canvasEl.height
                        }px; background-image: url(${this.img.src}); background-size: contain`
                    );
                    bgCanvasEl.setAttribute(
                        'style',
                        `width: ${canvasWidth}px; height: ${canvasStyleHeight || canvasEl.height}px`
                    );
                    this.widthRatio = canvasEl.width / canvasWidth;
                }

                this.drawImage(bgCanvasEl);
            };
            this.img.src = res;
            this.cdr.detectChanges();
        });
        this.captureEvents(canvasEl);
    }

    undo(): void {
        const lastAction = this.actionsHistory.pop();

        if (!lastAction) {
            return;
        }

        if (lastAction.type === 'text') {
            if (lastAction.styles) {
                lastAction.node.setAttribute('style', lastAction.styles);
            } else if (lastAction.hasOwnProperty('text')) {
                lastAction.node.innerHTML = lastAction.text;
            } else {
                lastAction.node.remove();
            }

            return;
        }

        if (lastAction.type === 'line') {
            if (this.startPos) {
                this.startPos = null;
            }

            this.clearSubscriptions();
            this.captureEvents(this.canvas.nativeElement);
        }

        if (this.actionsHistory.length) {
            this.cx.putImageData(lastAction.imageData, 0, 0);
        } else {
            this.cx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
        }
    }

    changeTool(tool: any): void {
        this.preventAddingNewElement = false;
        if (this.startPos) {
            this.startPos = null;
        }

        if (tool.hash === 'grid') {
            this.isUseGrid = !this.isUseGrid;
            let styles: string[] = this.canvas.nativeElement.getAttribute('style').split(';');

            if (this.isUseGrid) {
                this.tools[0].icon = 'grid_on';
                styles.push(`background-image: url(${this.img.src})`);
                styles.push('background-size: contain');
            } else {
                this.tools[0].icon = 'grid_off';
                styles = styles.filter(
                    (style: string) => !style.includes('background-image') && !style.includes('background-size')
                );
            }
            this.canvas.nativeElement.setAttribute('style', styles.join(';'));

            return;
        }

        this.selectedTool = tool;
        this.clearSubscriptions();
        this.captureEvents(this.canvas.nativeElement);
    }

    setColor(color: string): void {
        this.color = color;
        this.startPos = null;
    }

    save(): void {
        this.saveTextToCanvas();

        if (!this.isUseGrid) {
            this.cxBg.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
            this.cxBg.fillStyle = '#ffffff';
            this.cxBg.fillRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
        }

        this.cxBg.drawImage(this.canvas.nativeElement, 0, 0);
        this.bgCanvas.nativeElement.toBlob(
            (blob) => {
                blob.lastModifiedDate = new Date();
                blob.name = this.isSketch ? `photo${this.index === undefined ? '' : this.index}.jpg` : this.fileName;
                this.dismiss(blob);
            },
            'image/jpeg',
            0.9
        );
    }

    private clearSubscriptions(): void {
        this.activeSubscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    /**
     * Handle mouse and touch events on canvas
     *
     * @param {HTMLCanvasElement} canvasEl
     */
    private captureEvents(canvasEl: HTMLCanvasElement): void {
        switch (this.selectedTool.hash) {
            case 'draw':
            case 'erase':
                this.activeSubscriptions = this.initDrawEvents(canvasEl);
                break;
            case 'line':
            case 'text':
                this.activeSubscriptions = this.initClickEvent(canvasEl);
                break;
        }
    }

    private updateHistoryActions(textAction?: { node: HTMLDivElement; actionType?: 'moved' | 'textChanged' }): void {
        if (!this.cx) {
            return;
        }

        switch (this.selectedTool.hash) {
            case 'draw':
            case 'erase':
            case 'line':
                const imageData = this.cx.getImageData(
                    0,
                    0,
                    this.canvas.nativeElement.width,
                    this.canvas.nativeElement.height
                );

                this.actionsHistory.push({
                    type: this.selectedTool.hash,
                    imageData
                });
                break;
            case 'text':
                this.actionsHistory.push({
                    type: 'text',
                    node: textAction.node,
                    ...(textAction?.actionType === 'moved' && { styles: textAction.node.getAttribute('style') }),
                    ...(textAction?.actionType === 'textChanged' && { text: this.previousText })
                });
                break;
        }
    }

    private initDrawEvents(canvasEl: HTMLCanvasElement): Subscription[] {
        return [
            fromEvent(canvasEl, 'mousedown')
                .pipe(
                    filter(() => !!this.cx),
                    tap(() => this.updateHistoryActions()),
                    switchMap(() => {
                        return fromEvent(canvasEl, 'mousemove').pipe(
                            takeUntil(fromEvent(canvasEl, 'mouseup')),
                            takeUntil(fromEvent(canvasEl, 'mouseleave')),
                            pairwise()
                        );
                    })
                )
                .subscribe((res: [MouseEvent, MouseEvent]) => this.drawLine(res)),
            fromEvent(canvasEl, 'touchstart')
                .pipe(
                    filter(() => !!this.cx),
                    tap(() => this.updateHistoryActions()),
                    switchMap((e: Event) => {
                        e.preventDefault();

                        return fromEvent(canvasEl, 'touchmove').pipe(
                            takeUntil(fromEvent(canvasEl, 'touchend')),
                            takeUntil(fromEvent(canvasEl, 'touchcancel')),
                            pairwise()
                        );
                    })
                )
                .subscribe((res: [TouchEvent, TouchEvent]) =>
                    this.drawLine([res[0].changedTouches[0], res[1].changedTouches[0]])
                )
        ];
    }

    private initMoveEvent(el: HTMLDivElement): Subscription[] {
        return [
            fromEvent(el, 'mousedown')
                .pipe(
                    tap(() => this.updateHistoryActions({ node: el, actionType: 'moved' })),
                    switchMap(() => {
                        return fromEvent(document, 'mousemove').pipe(
                            map((move: MouseEvent) => {
                                move.preventDefault();

                                return move;
                            }),
                            filter(
                                (move: MouseEvent) =>
                                    (move.target as HTMLDivElement).getAttribute('contenteditable') === 'false'
                            ),
                            takeUntil(fromEvent(el, 'mouseup')),
                            takeUntil(fromEvent(el, 'mouseleave')),
                            pairwise()
                        );
                    }),
                    map((res: [MouseEvent, MouseEvent]) => [res[1].clientX, res[1].clientY])
                )
                .subscribe(([clientX, clientY]: number[]) => this.setElementPositions(el, clientX, clientY)),
            fromEvent(el, 'touchstart')
                .pipe(
                    tap(() => this.updateHistoryActions({ node: el, actionType: 'moved' })),
                    switchMap((e: TouchEvent) => {
                        e.preventDefault();

                        return fromEvent(el, 'touchmove').pipe(
                            filter(
                                (move: TouchEvent) =>
                                    (move.target as HTMLDivElement).getAttribute('contenteditable') === 'false'
                            ),
                            takeUntil(fromEvent(el, 'touchend')),
                            takeUntil(fromEvent(el, 'touchcancel')),
                            pairwise()
                        );
                    }),
                    map((res: [TouchEvent, TouchEvent]) => [
                        res[1].changedTouches[0].clientX,
                        res[1].changedTouches[0].clientY
                    ])
                )
                .subscribe(([clientX, clientY]: number[]) => this.setElementPositions(el, clientX, clientY))
        ];
    }

    private setElementPositions(el: HTMLDivElement, moveToX: number, moveToY: number): void {
        const rect = this.canvas.nativeElement.getBoundingClientRect();
        const cantMoveX = moveToX < rect.left || moveToX + el.clientWidth / 2 > rect.right;
        const cantMoveY = moveToY < rect.top || moveToY + el.clientHeight / 2 > rect.bottom;

        if (cantMoveX || cantMoveY) {
            const styles: string[] = el.getAttribute('style').split(';');
            const displayStyleIndex: number = styles.findIndex((item: string) => item.includes('display'));

            if (displayStyleIndex > -1) {
                styles.splice(displayStyleIndex, 1, this.displayNoneStyle);
            } else {
                styles.push(this.displayNoneStyle);
            }
            el.setAttribute('style', styles.join(';'));

            return;
        }

        const positionLeft: number = moveToX - rect.left - el.clientWidth / 2;
        const positionTop: number = moveToY - rect.top - el.clientHeight / 2;

        el.style.left = `${positionLeft}px`;
        el.style.top = `${positionTop}px`;
    }

    private initClickEvent(canvasEl: HTMLCanvasElement): Subscription[] {
        return [
            fromEvent(canvasEl, 'click')
                .pipe(
                    startWith(null),
                    pairwise(),
                    filter(() => !this.preventAddingNewElement),
                    map(([prevEvent, currEvent]: [MouseEvent, MouseEvent]) => [
                        this.startPos ? prevEvent : null,
                        currEvent
                    ])
                )
                .subscribe((res: [MouseEvent, MouseEvent]) => {
                    switch (this.selectedTool.hash) {
                        case 'text':
                            this.addTextElement(res[1]);
                            break;
                        case 'line':
                            this.drawSegment(res);
                            break;
                    }
                })
        ];
    }

    private initBlurEvent(element: any): void {
        element.onblur = (e) => {
            this.preventAddingNewElement = true;
            element.setAttribute('contenteditable', 'false');

            setTimeout(() => {
                this.preventAddingNewElement = false;

                if (this.previousText !== element.innerHTML) {
                    this.updateHistoryActions({ node: e.target, actionType: 'textChanged' });
                }
            }, 100);
        };
    }

    /**
     * Draw source image on canvas
     *
     * @param {HTMLCanvasElement} canvasEl
     */
    private drawImage(canvasEl: HTMLCanvasElement): void {
        this.cxBg.imageSmoothingEnabled = false;
        this.cxBg.drawImage(this.img, 0, 0, this.img.width, this.img.height, 0, 0, canvasEl.width, canvasEl.height);
    }

    /**
     * Handle mouse/touch move events on a canvas
     *
     * @param prevPos
     * @param currentPos
     * @param lineWidth
     */
    private drawOnCanvas(
        prevPos: { x: number; y: number },
        currentPos: { x: number; y: number },
        lineWidth: number
    ): void {
        this.cx.beginPath();
        this.cx.moveTo(prevPos.x, prevPos.y);
        this.cx.lineTo(currentPos.x, currentPos.y);
        this.cx.lineWidth = lineWidth * this.widthRatio;
        this.cx.stroke();
    }

    private drawLine(res: any, useStartPosAsCurrent?: boolean): void {
        if (this.selectedTool.hash === 'draw' || this.selectedTool.hash === 'line') {
            this.cx.globalCompositeOperation = 'source-over';
            this.cx.strokeStyle = this.color;
            this.cx.lineWidth = 2;
        } else if (this.selectedTool.hash === 'erase') {
            this.cx.globalCompositeOperation = 'destination-out';
            this.cx.lineWidth = 25;
        }
        this.cx.lineCap = 'round';
        this.cx.setLineDash([]);
        const rect = this.canvas.nativeElement.getBoundingClientRect();
        const prevPos = this.getPosition(res[0], rect);
        const currentPos =
            this.selectedTool.hash === 'line' && useStartPosAsCurrent ? this.startPos : this.getPosition(res[1], rect);

        this.drawOnCanvas(prevPos, currentPos, this.selectedTool.hash === 'erase' ? 25 : 2);
    }

    private getPosition(event: any, rect: ClientRect): any {
        return {
            x: (event.clientX - rect.left) * this.widthRatio,
            y: (event.clientY - rect.top) * this.widthRatio
        };
    }

    private drawPoint(res: any): void {
        const rect = this.canvas.nativeElement.getBoundingClientRect();
        const pos = this.getPosition(res, rect);
        const point = new Path2D();

        point.arc(pos.x, pos.y, 6, 0, 2 * Math.PI);
        if (!this.startPos) {
            this.startPos = pos;
        }
        this.cx.globalCompositeOperation = 'source-over';
        this.cx.fillStyle = this.color;
        this.cx.fill(point);
    }

    private drawSegment(res: any): void {
        if (!res[0]) {
            this.updateHistoryActions();
        }
        const deltaX: number = this.startPos?.x - res[1].offsetX * this.widthRatio;
        const deltaY: number = this.startPos?.y - res[1].offsetY * this.widthRatio;
        const useStartPosAsCurrent: boolean = Math.abs(deltaX) < 10 && Math.abs(deltaY) < 10;

        if (!this.startPos || !useStartPosAsCurrent) {
            this.drawPoint(res[1]);
        }

        if (res[0]) {
            this.drawLine(res, useStartPosAsCurrent);
        }

        if (useStartPosAsCurrent) {
            this.startPos = null;
        }
    }

    @HostListener('document:keydown.escape', ['$event'])
    onKeydownHandler(event: KeyboardEvent): void {
        if (this.selectedTool.hash === 'line' && event.keyCode === 27) {
            event.preventDefault();
            event.stopPropagation();
            this.startPos = null;
        }
    }

    private addTextElement(position: any): void {
        const rect: ClientRect = this.canvas.nativeElement.getBoundingClientRect();
        // substract 30px and 12px to centering of element according click event
        const positionLeft: number = position.clientX - rect.left - 30;
        const positionTop: number = position.clientY - rect.top - 12;
        const input: HTMLDivElement = document.createElement('div');

        input.setAttribute('contenteditable', 'true');

        input.className = 'custom-text';
        const defaultStyles = `
                        position: absolute;
                        background: rgba(255, 255, 255, .75);
                        border: 2px dashed;
                        min-width: 60px;
                        line-height: 16px;
                        font-size: 16px;
                        font-style: sans-serif;
                        resize: horizontal;
                        padding: 10px;
                        max-width: ${this.canvas.nativeElement.offsetWidth - positionLeft}px;
        `;

        input.setAttribute('style', `${defaultStyles} left: ${positionLeft}px; top: ${positionTop}px`);
        this.setElementTouchTime(input, 0);

        this.initMoveEvent(input);
        this.initBlurEvent(input);
        this.initDblClickEvent(input);
        this.initKeydownEvent(input);

        document.querySelector('.relative').appendChild(input);
        input.focus();
        this.previousText = input.innerHTML;
        setTimeout(() => input.scrollIntoView(), 300);

        this.updateHistoryActions({ node: input });
    }

    saveTextToCanvas(): void {
        const inputs = document.querySelectorAll('.custom-text');

        if (inputs.length) {
            forEach(inputs, (input) => {
                const styles: string[] = input.getAttribute('style').split(';');
                const displayNoneStyle: string = styles.find((item: string) => item.includes(this.displayNoneStyle));

                if (!displayNoneStyle) {
                    const fontSize = 16;
                    const borderSize = 2;
                    const paddingSize = 10;
                    const rectangleHeight = (input.clientHeight + borderSize) * this.widthRatio;
                    const rectangleWidth = (input.clientWidth + borderSize) * this.widthRatio;
                    const x: number = input.offsetLeft * this.widthRatio;
                    const y: number = input.offsetTop * this.widthRatio;

                    // draw background
                    this.cx.fillStyle = 'rgba(255, 255, 255, .75)';
                    this.cx.fillRect(x, y, rectangleWidth, rectangleHeight);

                    this.cx.globalCompositeOperation = 'source-over';
                    this.drawText(
                        input.innerText,
                        fontSize,
                        rectangleWidth,
                        (input.offsetTop + paddingSize) * this.widthRatio,
                        (input.offsetLeft + paddingSize) * this.widthRatio
                    );

                    // draw background border
                    this.cx.setLineDash([5 * this.widthRatio, 3 * this.widthRatio]);
                    this.cx.lineWidth = 2 * this.widthRatio;
                    this.cx.strokeStyle = 'black';
                    this.cx.strokeRect(x, y, rectangleWidth, rectangleHeight);
                }

                input.remove();
            });
        }
    }

    drawText(text: string, fontSize: number, width: number, initialOffsetTop: number, offsetLeft: number): void {
        let lines = [];

        this.cx.fillStyle = 'black';
        this.cx.font = `${fontSize * this.widthRatio}px Montserrat, "Helvetica Neue", sans-serif`;
        this.canvas.nativeElement.style.letterSpacing = '0px';
        this.cx.textAlign = 'left';
        this.cx.textBaseline = 'middle';
        this.cx.lineWidth = this.widthRatio;
        initialOffsetTop = initialOffsetTop + 10 * this.widthRatio;

        lines = this.getLines(this.cx, text, width);
        // Visually output text
        for (let i = 0, len = lines.length; i < len; i++) {
            const offsetTop = initialOffsetTop + i * (fontSize * this.widthRatio);

            this.cx.fillText(lines[i], offsetLeft, offsetTop);
        }
    }

    private initDblClickEvent(el: any): void {
        el.ondblclick = (e) => {
            el.setAttribute('contenteditable', 'true');
            el.focus();
            this.previousText = el.innerHTML;
            // remove 2 mousedown actions
            this.actionsHistory.splice(this.actionsHistory.length - 2, 2);
        };

        el.addEventListener(
            'touchstart',
            (e) => {
                e.stopPropagation();
                const touchTime = el.getAttribute('data-touchtime');

                if (touchTime == 0) {
                    // set first click
                    this.setElementTouchTime(el);
                } else {
                    // compare first click to this click and see if they occurred within double click threshold
                    if (new Date().valueOf() - touchTime < 400) {
                        this.setElementTouchTime(el, 0);
                        el.setAttribute('contenteditable', 'true');
                        el.focus();
                        this.previousText = el.innerHTML;
                        // remove 2 touchstart actions
                        this.actionsHistory.splice(this.actionsHistory.length - 2, 2);
                    } else {
                        // not a double click so set as a new first click
                        this.setElementTouchTime(el);
                    }
                }
            },
            false
        );
    }

    private getLines(ctx, text, maxWidth): any[] {
        const initialLines = text.split('\n');
        const lines = [];

        initialLines.forEach((line) => {
            const words = line.split(' ');
            let currentLine = words[0];

            for (let i = 1; i < words.length; i++) {
                const word = words[i];
                const width = ctx.measureText(currentLine + ' ' + word).width;

                if (width < maxWidth) {
                    currentLine += ' ' + word;
                } else {
                    lines.push(currentLine);
                    currentLine = word;
                }
            }
            lines.push(currentLine);
        });

        return lines;
    }

    private initKeydownEvent(el: any): void {
        el.onkeydown = (e) => {
            // trap the return key being pressed
            if (e.keyCode === 13) {
                // insert 2 br tags (if only one br tag is inserted the cursor won't go to the next line)
                document.execCommand('insertHTML', false, '<br><br>');

                // prevent the default behaviour of return key pressed
                return false;
            }
        };
    }

    private setElementTouchTime(el: HTMLDivElement, time?: any): void {
        if (time === undefined) {
            time = new Date().valueOf();
        }
        el.setAttribute('data-touchtime', time.toString());
    }
}

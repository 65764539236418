import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';

import { AlertController, ModalController, NavController } from '@ionic/angular';

import { Observable, of } from 'rxjs';

import isArray from 'lodash/isArray';

import { AuthService } from '@core/services/auth.service';
import { DataCompareHelperService } from '@core/services/data-compare-helper.service';
import { FilesService } from '@core/services/files.service';
import { AppointmentOffline } from '@core/services/offline/appointment/appointment';
import { CommonOffline } from '@core/services/offline/common/common';
import { DemoResourcesOffline } from '@core/services/offline/demo-resources/demo-resources-offline';
import { NeedsAssessmentOffline } from '@core/services/offline/needs-assessment/needs-assessment';
import { OfflineStorageService } from '@core/services/offline/offline-storage.service';
import { QuoteOffline } from '@core/services/offline/quote/quote';
import { StepsOffline } from '@core/services/offline/steps/steps';
import { TakeoffOffline } from '@core/services/offline/takeoff/takeoff';
import { UserPreferencesOffline } from '@core/services/offline/user-preferences/user-preferences';
import { SpinnerService } from '@core/services/spinner.service';
import { OfflineTableName } from '@shared/enums/offline-table-name';
import { SaleStepType } from '@shared/enums/sale-step-type.enum';

declare let moment: any;

@Injectable({
    providedIn: 'root'
})
export class OfflineService {
    // all services that used in Offline services must import here
    constructor(
        protected alertController: AlertController,
        protected apollo: Apollo,
        protected authService: AuthService,
        protected dataCompareHelperService: DataCompareHelperService,
        protected fileService: FilesService,
        protected modalController: ModalController,
        protected navController: NavController,
        protected offlineStorageService: OfflineStorageService,
        protected spinnerService: SpinnerService
    ) {}

    isAvailableUpdateObservable(): Observable<{ isAvailable: boolean; url: any } | null> {
        return of({ isAvailable: true, url: '' });
    }

    getVersionNumber(): Promise<string> {
        return of('100500').toPromise();
    }

    uploadOfflineChanges(): Promise<boolean> {
        return Promise.resolve(false);
    }

    async addViewHistory(
        hash: SaleStepType,
        appointmentId: string,
        historyData: any,
        geolocation = {},
        originAppointment?: any
    ): Promise<void> {
        const user = this.authService.getUser();
        const appointmentActivityConst = {
            STEP_IN_PROGRESS: 'in_progress',
            STEP_COMPLETED: 'completed',
            STEP_SKIPPED: 'skipped'
        };
        const expectedHashes = [SaleStepType.TAKE_OFF, SaleStepType.DEMO, SaleStepType.CONFIGURE, SaleStepType.QUOTE];
        let appointment;

        if (expectedHashes.indexOf(hash) === -1 || typeof historyData !== 'object' || !appointmentId) {
            return;
        }

        if (originAppointment) {
            appointment = originAppointment;
        } else {
            appointment = await this.offlineStorageService.findOne(
                `
                    SELECT *
                    FROM ${OfflineTableName.Appointments}
                    WHERE id = '${appointmentId}'
                      AND seller_id = '${user.id}'
                `,
                OfflineTableName.Appointments
            );
        }

        if (!appointment) {
            return;
        }

        let appointmentActivity;
        let viewHistory: any = {};

        if (isArray(appointment.activities)) {
            appointmentActivity = appointment.activities.find((activity) => activity.hash === hash);
        }

        if (appointmentActivity) {
            viewHistory = appointmentActivity.view_history;
        } else {
            viewHistory = {
                started_at: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                geolocation
            };
        }

        if (hash === SaleStepType.DEMO) {
            /*
             *   Expected data
             *   historyData = {
             *       resource_id: Number,
             *       name: String
             *   };
             */
            if (!viewHistory?.hasOwnProperty('resources')) {
                viewHistory.resources = [];
            }

            const resourceIndex = viewHistory.resources.findIndex((x) => x.id === historyData.resource_id);

            if (resourceIndex !== -1) {
                viewHistory.resources[resourceIndex].viewed_count += 1;
            } else {
                const resourceData = {
                    id: historyData.resource_id,
                    name: historyData.name,
                    viewed_count: 1,
                    timeline: []
                };

                viewHistory.resources.push(resourceData);
            }

            viewHistory.last_viewed_at = moment.utc().format('YYYY-MM-DD HH:mm:ss');
        }

        if (viewHistory?.hasOwnProperty('completed_at')) {
            viewHistory.completed_at = moment.utc().format('YYYY-MM-DD HH:mm:ss');
        }

        if (appointmentActivity) {
            Object.assign(appointmentActivity, { view_history: viewHistory });
        } else {
            if (isArray(appointment.activities)) {
                appointment.activities.push({
                    hash,
                    appointment_id: appointment.id,
                    view_history: viewHistory,
                    status: appointmentActivityConst.STEP_IN_PROGRESS
                });
            } else {
                appointment.activities = [
                    {
                        hash,
                        appointment_id: appointment.id,
                        view_history: viewHistory,
                        status: appointmentActivityConst.STEP_IN_PROGRESS
                    }
                ];
            }
        }

        appointment.updated = '1';

        if (originAppointment) {
            return appointment;
        } else {
            await this.offlineStorageService.insertOne(OfflineTableName.Appointments, appointment);
        }
    }
}

function applyMixins(derivedCtor: any, constructors: any[]): void {
    constructors.forEach((baseCtor) => {
        Object.getOwnPropertyNames(baseCtor.prototype).forEach((name) => {
            Object.defineProperty(
                derivedCtor.prototype,
                name,
                Object.getOwnPropertyDescriptor(baseCtor.prototype, name) || Object.create(null)
            );
        });
    });
}

applyMixins(OfflineService, [
    AppointmentOffline,
    CommonOffline,
    TakeoffOffline,
    UserPreferencesOffline,
    QuoteOffline,
    DemoResourcesOffline,
    StepsOffline,
    NeedsAssessmentOffline
]);

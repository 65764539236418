import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, UntypedFormControl } from '@angular/forms';

import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { KeyboardService } from '@core/services/keyboard.service';
import { Unsubscriber } from '@shared/helpers/unsubscriber';

@Component({
    selector: 'vendo-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent extends Unsubscriber implements OnInit {
    isSearchFocused = false;
    searchFormControl: UntypedFormControl;
    isShowNavButtons = false;

    @Input() list: any[] = [];
    @Input() filtersItems: any[] = [];
    @Input() width;
    @Input() searchText: string;

    @Output() valueChanged = new EventEmitter<string>();

    constructor(
        private formBuilder: FormBuilder,
        private keyboardService: KeyboardService
    ) {
        super();
    }

    ngOnInit(): void {
        this.searchFormControl = this.formBuilder.control(this.searchText || '');
        this.searchFormControl.valueChanges
            .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
            .subscribe((searchString) => {
                this.valueChanged.emit(searchString);
            });
    }

    toggleSearchField(isFocused: boolean): void {
        this.keyboardService.setIsSkipHandleIOSKeyboard(isFocused);
        if (!this.list.length) {
            return;
        }
        this.isSearchFocused = isFocused;
        setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
    }
}

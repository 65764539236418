import { Injectable } from '@angular/core';

import { NavController } from '@ionic/angular';

import { isActiveOfflineMode } from '@core/functions/is-active-offline-mode';

@Injectable()
export class OfflineModeGuard {
    constructor(private navController: NavController) {}

    canLoad(): boolean {
        return this.handleOfflineMode();
    }

    canActivate(): boolean {
        return this.handleOfflineMode();
    }

    private handleOfflineMode(): boolean {
        if (isActiveOfflineMode()) {
            this.navController.navigateRoot('main/appointments');

            return false;
        }

        return true;
    }
}

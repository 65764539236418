import { Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { BaseModal } from '@shared/modals/base-modal';

@Component({
    selector: 'vendo-list-select-modal',
    templateUrl: './list-select-modal.component.html',
    styleUrls: ['./list-select-modal.component.scss']
})
export class ListSelectModalComponent extends BaseModal {
    @Input() resources: any[] = [];
    @Input() propToShow: string;
    @Input() title: string;
    @Input() notice: string;
    constructor(public modalController: ModalController) {
        super(modalController);
    }

    selectSource(resource: any): void {
        this.dismiss(resource);
    }
}

import { gql } from 'apollo-angular';

const GET_E_DOCUMENT = gql`
    query CustomerEDocument($hash: String!, $authToken: String) {
        customerEDocument(hash: $hash, auth_token: $authToken) {
            document
            errors
            customer {
                id
                first_name
                last_name
                signature
                initials
            }
            quote_document {
                signers {
                    initials
                    left_places_to_sign
                    signature
                    signature_key
                    signer_id
                    type
                }
                updated_at
            }
            office {
                id
                name
                logo_url
                colors
                website
                timezone
            }
        }
    }
`;

export { GET_E_DOCUMENT };

<ng-container *ngIf="addersList?.length">
    <div class="title">
        Additional Project Details
    </div>

    <ion-grid class="options">
        <ng-container *ngFor="let adder of addersList; let i = index;">
            <ion-row class="adder-item">
                <ion-col>
                    <ion-text class="adder-name">
                        {{ adder.name }}
                        <ion-icon *ngIf="adder.description"
                                  class="info-icon"
                                  name="information-circle-outline"
                                  (click)="showFullDescriptionPopover($event, adder.description)"></ion-icon>
                    </ion-text>
                    <ion-text class="adder-desc" [innerHTML]="adder.short_description | lineBreaks"></ion-text>
                </ion-col>

                <ion-col class="adder-amount-column" [class.is-show-price]="showPrice">
                    <ion-text>Qty {{ adder.quantity }}</ion-text>
                    <div *ngIf="showPrice" class="adder-amount">
                        <div class="value" [class.value-with-discount]="adder.display_price !== adder.total_price">
                            <ion-icon *ngIf="isPromotionApplied && !adder.discountable"
                                      name="md-custom-no-discount-icon"></ion-icon>
                            {{ adder.display_price | customCurrency: true }}
                        </div>
                        <div *ngIf="adder.display_price !== adder.total_price" class="value">
                            {{ adder.total_price | customCurrency: true }}
                        </div>
                    </div>
                </ion-col>
            </ion-row>

            <ng-container *ngIf="adder.changes?.length">
                <ion-row class="detail"
                         [ngClass]="{'expanded': isExpanded[i]}"
                         (click)="toggleDetail(i)">
                    <ion-col size="12">
                        <ion-button class="icon-only" color="primary">
                            <ion-icon [name]="isExpanded[i] ? 'remove-outline' : 'add-outline'"></ion-icon>
                        </ion-button>
                        <ion-text>Change Details</ion-text>
                    </ion-col>
                </ion-row>

                <ng-container *ngIf="isExpanded[i]">
                    <ion-row class="changes">
                        <ion-col size="4"></ion-col>
                        <ion-col size="4">
                            Original
                        </ion-col>
                        <ion-col size="4">
                            New
                        </ion-col>
                    </ion-row>

                    <ion-row *ngFor="let change of adder.changes" class="changes">
                        <ion-col size="4">
                            {{ change.label }}
                        </ion-col>
                        <ion-col size="4">
                            {{ change.old }}
                        </ion-col>
                        <ion-col size="4">
                            {{ change.new }}
                        </ion-col>
                    </ion-row>
                </ng-container>
            </ng-container>
        </ng-container>
    </ion-grid>
</ng-container>

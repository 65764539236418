import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { IonDatetime, ModalController } from '@ionic/angular';

import find from 'lodash/find';

import { QuoteService } from '@core/services/quote.service';
import { BaseModal } from '@shared/modals/base-modal';
import { LocalizedDatePipe } from '@shared/pipes/localized-date.pipe';

declare let moment: any;

@Component({
    selector: 'vendo-set-quote-headers-modal',
    templateUrl: './set-quote-headers-modal.component.html',
    styleUrls: ['./set-quote-headers-modal.component.scss']
})
export class SetQuoteHeadersModalComponent extends BaseModal implements OnInit {
    @Input() fields: any;
    @Input() quoteId: string;
    @Input() title: string;

    @ViewChild(IonDatetime) popoverDatetime: IonDatetime;

    form: UntypedFormGroup;
    maxDate: string = moment().add(30, 'days').endOf('day').format();

    constructor(
        private formBuilder: UntypedFormBuilder,
        private localizedDatePipe: LocalizedDatePipe,
        modalController: ModalController,
        private quoteService: QuoteService
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.initForm();
    }

    apply(): void {
        const formInputs = Object.entries(this.form.value);

        for (const [key, value] of formInputs) {
            const field = find(this.fields, { FieldId: key });

            field.FieldValue =
                field.FieldType === 'Date' ? this.localizedDatePipe.transform(value, 'MM/dd/yyyy') : value;
        }

        this.quoteService.setQuoteFields(this.quoteId, this.fields).subscribe(() => this.dismiss(true));
    }

    async setDateTime(controlName: string, value: string): Promise<void> {
        this.form.get(controlName).setValue(value);
    }

    private initForm(): void {
        const configurationsFormObject = {};

        this.fields.forEach((field) => {
            if (!field.Hidden) {
                configurationsFormObject[field.FieldId] = [
                    { value: field.FieldValue, disabled: field.readOnly },
                    field.Required === 1 ? Validators.required : null
                ];
            }
        });

        this.form = this.formBuilder.group(configurationsFormObject);
    }
}

import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    Validators
} from '@angular/forms';

import { debounceTime, distinctUntilChanged, filter, pairwise, startWith, takeUntil } from 'rxjs/operators';

import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import pickBy from 'lodash/pickBy';

import { FieldType } from '@shared/enums/field-type.enum';
import { FieldValue } from '@shared/enums/field-value.enum';
import { AbstractForm } from '@shared/helpers/abstract-form';
import { FieldSetting } from '@shared/interfaces/form-fields-settings';

@Component({
    selector: 'vendo-rule-row',
    templateUrl: './rule-row.component.html',
    styleUrls: ['./rule-row.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RuleRowComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => RuleRowComponent),
            multi: true
        }
    ]
})
export class RuleRowComponent extends AbstractForm implements ControlValueAccessor, OnInit {
    @Input() initialRequiredValues: any; // used only to handle disabling fields
    @Input() isHandleDisablingDependsOn = true;
    @Input() isApplyDefaultValueOnlyOnFirstField = true;
    @Input() fieldsConfigs: FieldSetting[];
    @Output() blur: EventEmitter<void> = new EventEmitter<void>();
    formGroupRow: UntypedFormGroup;
    isOldLabel = true;
    readonly fieldTypes: typeof FieldType = FieldType;
    readonly fieldValues: typeof FieldValue = FieldValue;

    constructor(private formBuilder: UntypedFormBuilder) {
        super();
    }

    ngOnInit(): void {
        this.fieldsConfigs = cloneDeep(this.fieldsConfigs);
        this.formGroupRow = this.formBuilder.group({});
        this.fieldsConfigs.forEach((field: FieldSetting) => {
            const disabled: boolean = this.isHandleDisablingDependsOn && field.hasOwnProperty('depends_on');
            const control: AbstractControl = this.formBuilder.control(
                { value: null, disabled },
                field.required !== false ? Validators.required : null
            );

            this.formGroupRow.addControl(field.key, control);

            if (disabled) {
                this.formGroupRow
                    .get(field.depends_on)
                    .valueChanges.pipe(
                        filter((value: any) => !isNil(value) && value !== ''),
                        takeUntil(this.destroy$)
                    )
                    .subscribe(() => control.enable());
            }
        });

        this.formGroupRow.valueChanges
            .pipe(debounceTime(100), distinctUntilChanged(), takeUntil(this.destroy$))
            .subscribe(() => this.onChange(this.formGroupRow.getRawValue()));

        this.formGroupRow.valueChanges
            .pipe(
                startWith(this.formGroupRow.value),
                distinctUntilChanged(isEqual),
                pairwise(),
                takeUntil(this.destroy$)
            )
            .subscribe(([prev, curr]) => this.handleFields(prev, curr));
    }

    registerOnChange(fn: (T) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.formGroupRow.disable() : this.formGroupRow.enable();
    }

    writeValue(values: any): void {
        const isApply: boolean = values && isObject(values);
        const needToDisable: string[] = [];

        this.fieldsConfigs.forEach((field: FieldSetting, index: number) => {
            if (values.hasOwnProperty(field.key)) {
                this.updateField(field, isApply ? values[field.key] : null);

                if (
                    this.initialRequiredValues &&
                    isObject(this.initialRequiredValues[field.key]) &&
                    this.initialRequiredValues[field.key].disabled
                ) {
                    needToDisable.push(field.key);
                }
            } else if (
                isApply &&
                !Object.keys(values).length &&
                (!this.isApplyDefaultValueOnlyOnFirstField || index === 0) &&
                field.defaultValue
            ) {
                this.updateField(field);
            }
        });

        if (this.initialRequiredValues && needToDisable.length) {
            const exceptFields: string[] = difference(Object.keys(values), needToDisable);

            setTimeout(() => this.disableFormControls(this.formGroupRow, exceptFields));
        }
    }

    onChange(val: any): void {}

    onTouched(): void {}

    validate(control: UntypedFormControl): ValidationErrors {
        if (control && this.formGroupRow.invalid) {
            return { required: true };
        }

        return null;
    }

    markFieldsAsUntouched(): void {
        this.markAllFieldsAsUntouched(this.formGroupRow);
    }

    markFieldsAsTouched(): void {
        this.markAllFieldsAsTouched(this.formGroupRow);
    }

    markAsTouched(): void {
        this.onTouched();
        this.blur.emit();
    }

    private handleFields(prev, curr): void {
        const changed = pickBy(curr, (v, k) => !isEqual(prev[k], v));

        this.fieldsConfigs.forEach((field: FieldSetting) => {
            if (
                !field.options ||
                (field?.depends_on_fields
                    ? !field.depends_on_fields.includes(Object.keys(changed)[0])
                    : field.depends_on !== Object.keys(changed)[0])
            ) {
                return;
            }

            let isMatchCondition = false;

            for (const option of field.options) {
                if (!option.matches?.length) {
                    continue;
                }

                for (const condition of option.matches) {
                    isMatchCondition = Object.keys(condition).reduce(
                        (acc: boolean, key: string) => acc && curr[key] === condition[key],
                        true
                    );

                    if (isMatchCondition) {
                        Object.entries(option).forEach(([key, value]) => {
                            if (key === 'matches') {
                                return;
                            }

                            field[key] = value;
                        });
                        this.updateField(field, undefined, option.disabled);
                        break;
                    }
                }

                if (isMatchCondition) {
                    break;
                } else if (field.defaultSettings) {
                    Object.entries(field.defaultSettings).forEach(([key, value]) => (field[key] = value));
                    this.updateField(field);
                }
            }
        });
    }

    private updateField(field: FieldSetting, value?: any, isDisable?: boolean): void {
        const control: AbstractControl = this.formGroupRow.get(field.key);

        control.setValue(value ?? field.defaultValue ?? (field.fieldType === FieldType.Checkbox ? false : null));

        if (!this.isHandleDisablingDependsOn && isDisable !== undefined) {
            setTimeout(() => (isDisable ? control.disable() : control.enable()));
        }

        if (field.valueType === FieldValue.Hidden) {
            control.clearValidators();
            control.updateValueAndValidity();
        } else if (field.required !== false && !control.hasValidator(Validators.required)) {
            control.setValidators(Validators.required);
            control.updateValueAndValidity();
        }
    }
}

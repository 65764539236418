<div class="center-block">
    <div class="title-section">
        <ion-icon name="phone-portrait-outline"></ion-icon>
        <div class="title">Please hand your device to {{ activeSigner?.first_name }}</div>
        <div class="description">
            Protect yourself and others following health safety measures such as disinfecting surfaces like this device before and after using it.
        </div>
    </div>

    <div class="actions-section">
        <ion-button color="primary"
                    class="classic-width"
                    (click)="proceed.emit()">
            Start to review
        </ion-button>

        <ion-button color="primary"
                    class="classic-width no-borders"
                    fill="outline"
                    (click)="cancel.emit()">
            Cancel
        </ion-button>
    </div>
</div>

import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { PopoverController } from '@ionic/angular';

import find from 'lodash/find';
import findLastIndex from 'lodash/findLastIndex';
import last from 'lodash/last';
import round from 'lodash/round';

import { LINE_COLORS } from '@shared/components/image-annotation-tool/image-annotation-tool.constants';
import { ItemsListPopoverComponent } from '@shared/components/image-annotation-tool/items-list-popover/items-list-popover.component';
import { DropdownPopoverComponent } from '@shared/popovers/dropdown-popover/dropdown-popover.component';

@Component({
    selector: 'vendo-image-annotation-tool',
    templateUrl: './image-annotation-tool.component.html',
    styleUrls: ['./image-annotation-tool.component.scss']
})
export class ImageAnnotationToolComponent implements OnInit {
    private _lineTypes: any[];

    @Input() segments = [];
    @Input() imgUrl: string;
    @Input() feetPerPixel: number;
    @Input() existingOpeningsCount: any = {};
    @Input() hideBackground = false;

    @Input() set lineTypes(value: any[]) {
        this._lineTypes = value.map((item: string, index) => {
            return {
                name: item,
                hash: item.toLowerCase(),
                color: LINE_COLORS[index]
            };
        });
    }

    get lineTypes(): any[] {
        return this._lineTypes;
    }

    actionsHistory: any[] = [];
    drawingInProgress = false;
    askAboutMeasurements = true;

    activeSegment: any;
    activePoint: any;
    activePolyLine: any;
    activeTextElement: any;
    allowTextEditing = false;
    viewBox: string;
    widthRatio = 1;
    heightRatio = 1;
    lineStrokeWidth = 1;
    circleStrokeWidth = 2;
    radius = 5;
    fontSize = 16;
    legendPosition: any;
    doneButtonPosition: any;
    legendItems = [];
    textEditPosition = '';
    tools = [
        {
            name: 'Line',
            hash: 'line',
            icon: 'timeline',
            types: []
        },
        {
            name: 'Polygon',
            hash: 'polygon',
            icon: 'crop_free',
            type: {
                name: 'Facet',
                color: 'blue',
                hash: 'facet'
            }
        }
    ];
    selectedTool: any;

    offsetX = 0;
    offsetY = 0;
    hiddenItems: any = {};
    highlightedItem: any;

    @ViewChild('image') imageElement;
    @ViewChild('imageContainer') imageContainerElement;

    constructor(private popoverController: PopoverController) {}

    ngOnInit(): void {
        this.tools[0].types = this.lineTypes;
        const image = new Image();

        image.src = this.imgUrl;

        image.onload = () => {
            this.viewBox = `0 0 ${image.width} ${image.height}`;
            setTimeout(() => {
                const imageBoundingRect = this.imageElement.nativeElement.getBoundingClientRect();

                this.widthRatio = imageBoundingRect.width / image.width;
                this.heightRatio = imageBoundingRect.height / image.height;
                const imageContainerBoundingRect = this.imageContainerElement.nativeElement.getBoundingClientRect();

                this.offsetX = imageBoundingRect.x - imageContainerBoundingRect.x;
                this.offsetY = imageBoundingRect.y - imageContainerBoundingRect.y;
                this.lineStrokeWidth = round(this.lineStrokeWidth / this.widthRatio, 0);
                this.circleStrokeWidth = round(this.circleStrokeWidth / this.widthRatio, 0);
                this.radius = round(this.radius / this.widthRatio, 0);
                this.fontSize = round(this.fontSize / this.heightRatio, 0);
                this.legendPosition = {
                    x: 0,
                    y: image.height - 100 / this.heightRatio,
                    width: 350 / this.widthRatio,
                    height: 100 / this.heightRatio,
                    fontSize: round(14 / this.heightRatio, 0)
                };
                this.doneButtonPosition = {
                    x: 0,
                    y: image.height - 80 / this.heightRatio,
                    width: 80 / this.widthRatio,
                    height: 80 / this.heightRatio
                };

                if (this.segments?.length) {
                    this.rebuildLegend();
                }
            }, 100);
        };
    }

    textElementClicked(textElement: any, event: any, type?: string): void {
        this.selectedTool = find(this.tools, { hash: 'text' });
        this.allowTextEditing = true;
        this.setTextFieldPosition(textElement.x, textElement.y);
        this.activeTextElement = textElement;
        this.activeTextElement.type = type;

        if (type === 'measurements') {
            this.activeTextElement.oldValue = this.activeTextElement.title;
        }
    }

    pointClicked(event: any, point: any): void {
        if (this.activePoint) {
            this.drawSegment(event, point);
            this.activePoint = point;
        } else {
            this.startDrawing(event, point);
        }
    }

    startDrawing(event: any, startingPoint?: any): void {
        if (!this.selectedTool) {
            return;
        }

        if (!this.drawingInProgress) {
            this.actionsHistory.push({
                type: 'start_drawing'
            });
        }

        this.drawSegment(event, startingPoint);
    }

    changeTool(tool: any, type?: any): void {
        this.selectedTool = tool;
        this.selectedTool.type = type;
        this.stopDrawing();
    }

    async showDropdownPopover(event: Event, tool: any): Promise<any> {
        event.stopPropagation();
        const popover = await this.popoverController.create({
            component: DropdownPopoverComponent,
            event,
            translucent: true,
            mode: 'md',
            cssClass: 'dropdown-popover',
            componentProps: {
                items: tool.types
            }
        });

        await popover.present();

        return popover.onDidDismiss().then((result: any) => {
            const toolType = tool.types.find((type) => type.hash === result.data);

            this.changeTool(tool, toolType);
        });
    }

    getPolylinePoints(segment: any): string {
        const points = segment.points;

        return `${points.map((point) => `${point.x},${point.y}`).join(' ')}`;
    }

    async showItemsListPopover(event: any, itemType: string): Promise<any> {
        event.stopPropagation();
        const segments = this.segments.filter((segment) => {
            return segment.type.hash === itemType && (segment.lines?.length || segment.polylines?.length);
        });

        const popover = await this.popoverController.create({
            component: ItemsListPopoverComponent,
            event,
            translucent: true,
            cssClass: 'items-list-popover',
            mode: 'md',
            componentProps: {
                items: segments,
                visibilityMap: this.hiddenItems,
                propagateActiveItem: this.highlightItem.bind(this),
                askAboutMeasurements: this.askAboutMeasurements
            },
            backdropDismiss: false
        });

        await popover.present();

        const { data } = await popover.onDidDismiss();

        if (data.modified) {
            this.askAboutMeasurements = data.askAboutMeasurements;
            const modifiedIds = [];

            data.modified.forEach((item) => {
                const segmentToModify = this.segments.find((segment) => segment.id === item.id);

                if (segmentToModify) {
                    segmentToModify.name = item.name;
                    segmentToModify.distance = item.distance;
                    modifiedIds.push(segmentToModify.id);
                }
            });

            if (data.distanceRatio) {
                this.segments.forEach((segment) => {
                    if (modifiedIds.includes(segment.id)) {
                        return;
                    }

                    segment.distance = Math.ceil(segment.distance * data.distanceRatio * 100) / 100;
                });
            }
        }

        if (data.deleted) {
            data.deleted.forEach((item) => {
                this.segments = this.segments.filter((segment) => segment.id !== item.id);
            });
        }

        this.rebuildLegend();

        this.highlightedItem = null;
    }

    highlightItem(item: any): void {
        this.highlightedItem = item;
    }

    undo(): void {
        const lastAction = this.actionsHistory.pop();

        if (lastAction) {
            if (this.drawingInProgress && this.selectedTool.hash === 'line') {
                const index: number = findLastIndex(this.actionsHistory, { type: 'segment_added' });

                if (index > -1) {
                    this.activeSegment = this.actionsHistory[index].item;
                    this.activePoint = this.actionsHistory[index].item.points[1];
                }
            }

            if (last(this.actionsHistory)?.type === 'start_drawing') {
                this.actionsHistory.pop();
                this.stopDrawing();
            }

            this.segments.pop();
        }

        this.rebuildLegend();
    }

    stopDrawing(): void {
        this.activeSegment = null;
        this.activePoint = null;
        this.activePolyLine = null;
        this.drawingInProgress = false;
    }

    doneDrawing(): void {
        if (this.activeSegment && !this.activeSegment.lines.length && !this.activeSegment.polylines.length) {
            this.undo();
        }
        this.stopDrawing();
    }

    public getSegments(): any[] {
        return this.segments;
    }

    private drawSegment(event: any, startingPoint?: any): void {
        if (!this.selectedTool.type) {
            return;
        }

        const x = event.layerX / this.widthRatio;
        const y = event.layerY / this.heightRatio - this.offsetY;

        const newPoint = {
            x,
            y,
            color: this.selectedTool.type.color,
            type: this.selectedTool.type.hash
        };

        if (startingPoint) {
            newPoint.x = startingPoint.x;
            newPoint.y = startingPoint.y;
        }

        let existingTypesCount = 0;

        if (this.selectedTool.type.hash === 'facet') {
            existingTypesCount = this.segments.filter((segment) => segment.polylines?.length && segment.is_new).length;
        } else {
            this.segments.reduce((acc, item) => {
                return (existingTypesCount += item.lines.filter(
                    (line) => line.type.hash === this.selectedTool.type.hash && item.is_new
                ).length
                    ? 1
                    : 0);
            }, existingTypesCount);
        }

        if (!this.activeSegment || (this.selectedTool.hash === 'line' && this.activeSegment.lines.length)) {
            const points = [newPoint];

            if (this.activeSegment) {
                points.unshift(last(this.activeSegment.points));
                if (this.activeSegment.points.length < 2) {
                    this.activeSegment.points.push(newPoint);
                }
            }
            this.activeSegment = {
                is_new: true,
                points,
                lines: [],
                polylines: [],
                id: new Date().valueOf(),
                name: `${this.selectedTool.type.name} ${
                    existingTypesCount + (this.existingOpeningsCount[this.selectedTool.type.name] || 0) + 1
                }`,
                type: this.selectedTool.type
            };
            this.drawingInProgress = true;

            this.segments.push(this.activeSegment);

            this.actionsHistory.push({
                type: 'segment_added',
                item: this.activeSegment
            });
        } else {
            this.activeSegment.points.push(newPoint);
        }

        if (this.activeSegment.points.length > 1 && this.drawingInProgress) {
            if (this.selectedTool.hash === 'polygon') {
                this.drawPolyLine();
            } else {
                this.drawLine(newPoint, this.activePoint);
            }
        }

        this.activePoint = newPoint;
    }

    private drawLine(from: any, to: any): void {
        const a = from.x - to.x;
        const b = from.y - to.y;
        const newLine = {
            from,
            to,
            type: this.selectedTool.type
        };

        this.activeSegment.distance = this.feetPerPixel ? Math.floor(Math.sqrt(a * a + b * b) * this.feetPerPixel) : 0;

        this.activeSegment.lines.push(newLine);

        this.fillLegend(this.selectedTool.type);
    }

    private drawPolyLine(): void {
        const newPolyLine = {
            type: this.selectedTool.type
        };

        if (!this.activePolyLine) {
            this.activePolyLine = newPolyLine;
            this.activeSegment.polylines.push(newPolyLine);
            this.fillLegend(this.selectedTool.type);
        }

        this.activeSegment.distance = this.calculatePolygonArea(this.activeSegment.points);
    }

    private calculatePolygonArea(points: any[]): number {
        let p1;
        let p2;
        let area;
        let len;
        let i;

        for (area = 0, len = points.length, i = 0; i < len; ++i) {
            p1 = points[i];
            p2 = points[(i - 1 + len) % len]; // Previous point, with wraparound
            area += (p2.x + p1.x) * this.feetPerPixel * ((p2.y - p1.y) * this.feetPerPixel);
        }

        return Math.floor(Math.abs(area / 2));
    }

    private fillLegend(type: any): void {
        const existingType = this.legendItems.find((item) => item.hash === type.hash);

        if (existingType) {
            return;
        }

        const legendItemsCount = this.legendItems.length;
        let yIndex = legendItemsCount;

        if (legendItemsCount >= 6) {
            yIndex -= 6;
        } else if (legendItemsCount >= 3) {
            yIndex -= 3;
        }

        const x: number = (Math.floor(legendItemsCount / 3) * 160 + 20) / this.heightRatio;
        const y: number = this.legendPosition.y + (20 + yIndex * 30) / this.widthRatio;

        this.legendItems.push({
            ...type,
            x: x + 50 / this.widthRatio,
            y,
            x1: x,
            y1: y - 4 / this.heightRatio,
            x2: x + 40 / this.widthRatio,
            y2: y - 4 / this.heightRatio
        });

        if (this.legendItems.length > 6) {
            this.legendPosition.width = 500 / this.widthRatio;
        } else {
            this.legendPosition.width = 350 / this.widthRatio;
        }
    }

    private rebuildLegend(): void {
        const uniqueLegendItemTypes = [];

        this.legendItems = [];

        this.segments.forEach((segment) => {
            segment.lines?.forEach((line) => {
                if (!uniqueLegendItemTypes.includes(line.type.hash)) {
                    uniqueLegendItemTypes.push(line.type.hash);
                    this.fillLegend(line.type);
                }
            });

            if (segment.polylines?.length && !uniqueLegendItemTypes.includes('facet')) {
                uniqueLegendItemTypes.push('facet');
                this.fillLegend(segment.polylines[0].type);
            }
        });
    }

    private setTextFieldPosition(x: number, y: number): void {
        this.textEditPosition = `left: ${x * this.widthRatio - 5}px; top: ${y * this.heightRatio - 20}px`;
    }
}

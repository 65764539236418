import { from, Observable, of } from 'rxjs';

import { AuthService } from '@core/services/auth.service';
import { OfflineStorageService } from '@core/services/offline/offline-storage.service';
import { OfflineTableName } from '@shared/enums/offline-table-name';

export class CommonOffline {
    constructor(
        private authService: AuthService,
        private offlineStorageService: OfflineStorageService
    ) {}

    getCommonItem(type: string): Observable<any> {
        return of({
            values: {
                auth0: false,
                pendo: false,
                rollbar: false
            }
        });
    }

    getCommonItems(types?: string[], silentRequest = false): Observable<any> {
        const promise = this.offlineStorageService
            .read(
                `SELECT * FROM ${OfflineTableName.Common} where id in ("${types.join('", "')}")`,
                OfflineTableName.Common
            )
            .then((res) =>
                res.reduce((acc, item) => {
                    acc[item.id] = item.value;

                    return acc;
                }, {})
            );

        return from(promise);
    }

    getOptionList(): Observable<any> {
        const user = this.authService.getUser();

        return of([
            {
                value: user.office.id,
                label: user.office.name
            }
        ]);
    }
}

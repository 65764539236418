import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

import { ModalController } from '@ionic/angular';

import round from 'lodash/round';

import { AppService } from '@core/services/app.service';
import { DeviceHelperService } from '@core/services/device-helper.service';
import { FeedbackService } from '@core/services/feedback.service';
import { UserFeedbackDevice } from '@shared/enums/user-feedback-device.enum';
import { UserFeedbackInput } from '@shared/interfaces/user-feedback';
import { BaseModal } from '@shared/modals/base-modal';

@Component({
    selector: 'vendo-feedback-modal',
    templateUrl: './feedback-modal.component.html',
    styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent extends BaseModal implements OnInit {
    readonly charLimit = 1000;
    form: UntypedFormGroup;
    appointmentId: number;
    isSubmittedFeedback: boolean;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private feedbackService: FeedbackService,
        modalController: ModalController,
        private route: ActivatedRoute,
        private appService: AppService,
        private deviceHelperService: DeviceHelperService
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.appointmentId = this.getAppointmentId(this.route.snapshot);
        this.form = this.formBuilder.group({
            rating: [null, Validators.required],
            description: ['', Validators.maxLength(this.charLimit)]
        });
    }

    async save(): Promise<void> {
        if (this.form.invalid) {
            return;
        }

        const data: UserFeedbackInput = this.form.getRawValue();
        // convert rating in percentage

        data.rating = round((data.rating / 10) * 100, 2);

        const app_version: string = await this.appService.getVersionNumber();

        data.additional_info = {
            ...(this.appointmentId && { appointment_id: this.appointmentId }),
            device: this.getDevice(),
            ...this.deviceHelperService.getDeviceInfo,
            app_version
        };

        this.feedbackService.saveFeedback(data).subscribe(() => (this.isSubmittedFeedback = true));
    }

    private getAppointmentId(snapshot: ActivatedRouteSnapshot): number {
        const appointmentId: string = snapshot.paramMap.get('appointmentId');

        if (appointmentId) {
            return Number(appointmentId);
        }

        if (snapshot.children.length) {
            return this.getAppointmentId(snapshot.children[0]);
        }

        return null;
    }

    private getDevice(): UserFeedbackDevice {
        switch (true) {
            case this.deviceHelperService.isAndroidPlatform:
                return UserFeedbackDevice.Android;
            case this.deviceHelperService.isIOSPlatform:
                return UserFeedbackDevice.IOS;
            default:
                return UserFeedbackDevice.Browser;
        }
    }
}

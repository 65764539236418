import { Component, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { forkJoin, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ConfigurationGroupsService } from '@core/services/configuration-groups.service';
import { ToastService } from '@core/services/toast.service';
import { maxQuantity } from '@shared/constants/form-validation-constants';
import { BaseModal } from '@shared/modals/base-modal';

import { ConfigureService } from '../../../main/appointments/configure/services/configure.service';
import { TakeoffService } from '../../../main/appointments/takeoff/services/takeoff.service';

declare let moment: any;

@Component({
    selector: 'vendo-group-configuration-modal',
    templateUrl: './group-configuration-modal.component.html',
    styleUrls: ['./group-configuration-modal.component.scss']
})
export class GroupConfigurationModalComponent extends BaseModal implements OnInit {
    appointmentId: number;
    catalogId: number;
    groups: any[];
    expandedGroup: any;
    openings: any[];

    private category: any;

    constructor(
        private configureService: ConfigureService,
        modalController: ModalController,
        private groupsService: ConfigurationGroupsService,
        private takeoffService: TakeoffService,
        private toastService: ToastService
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        forkJoin([
            this.groupsService.getGroups(this.appointmentId, this.catalogId),
            this.groupsService.getNotConfiguredOpenings(this.appointmentId, this.catalogId),
            this.takeoffService.getCategoryWithQuestions(this.catalogId, this.appointmentId)
        ]).subscribe(([groups, openings, category]) => {
            const measurementTypeQuestion = category.questions.find(({ hash }) => hash === 'measurement_type');

            this.category = category;
            this.groups = groups;

            const questionsForGrouping = this.category.all_questions.filter(
                ({ used_for_grouping }) => used_for_grouping
            );

            this.openings = openings.map((opening) => {
                opening.included = !!opening.group_id;
                opening.measurement_type = opening.opening_details.find(
                    ({ question_id }) => question_id === measurementTypeQuestion.id
                )?.answer;

                const answersForGrouping = {};

                questionsForGrouping.forEach((question) => {
                    answersForGrouping[question.hash] = opening.opening_details.find(
                        ({ question_id }) => question_id === question.id
                    )?.answer;
                });
                opening.group_type = Object.keys(answersForGrouping)
                    .sort()
                    .map((key: string) => answersForGrouping[key])
                    .join('_');

                return opening;
            });
            this.initGroupingAvailability();
        });
    }

    expandGroup(group: any): void {
        group.openings = [];
        this.openings.forEach((opening) => {
            if (!opening.included || opening.group_id === group.id) {
                group.openings.push(opening);
            }
        });
        this.expandedGroup = group;
    }

    collapseGroup(): void {
        this.expandedGroup = null;
    }

    addGroup(): void {
        const newGroup = {
            name: `Group ${this.groups.length + 1}`,
            openings: this.openings.filter((opening) => !opening.group_id),
            isNew: true,
            id: moment.now()
        };

        this.groups.push(newGroup);
        this.expandGroup(newGroup);
    }

    saveGroup(group: any): void {
        const opening_ids = [];
        let quantity = 0;

        this.openings.forEach((opening) => {
            if (opening.group_id === group.id) {
                opening_ids.push(opening.id);
                quantity += opening.quantity;
            }
        });

        let message: string;

        if (!opening_ids?.length) {
            message = 'Can not create a group with no openings';
        }

        if (quantity > maxQuantity) {
            message = `Current quantity ${quantity} of the ${group.name} exceeds the limit of ${maxQuantity}!`;
        }

        if (quantity < 1) {
            message = `Current quantity ${quantity} of the ${group.name} must be greater or equal 1`;
        }

        if (message) {
            this.toastService.showMessage(message);

            return;
        }
        (group.isNew ? of(true) : this.configureService.updateOpeningQuantity(this.appointmentId, group.id, quantity))
            .pipe(
                switchMap(() =>
                    this.groupsService.saveGroup(group.name, opening_ids, group.isNew ? undefined : group.id)
                )
            )
            .subscribe((updatedGroup) => {
                const oldGroupId = group.id;

                group.id = updatedGroup.id;
                group.isNew = false;
                group.touched = false;

                this.openings.forEach((opening) => {
                    if (opening.group_id === oldGroupId) {
                        opening.group_id = group.id;
                    }
                });
            });
    }

    selectionChanged(group: any, opening: any, checked: boolean): void {
        group.touched = true;
        opening.included = checked;

        const baseOpening = this.openings.find((baseOpening) => baseOpening.id === opening.id);

        if (baseOpening) {
            baseOpening.included = checked;
            baseOpening.group_id = baseOpening.included ? group.id : null;
        }

        this.initGroupingAvailability();
    }

    private initGroupingAvailability(): void {
        this.groups = this.groups.map((group) => {
            const groupedOpening = this.openings.find((opening) => opening.group_id === group.id);

            group.group_type = groupedOpening?.group_type || null;

            return group;
        });

        if (this.expandedGroup) {
            const group = this.groups.find((group) => group.id === this.expandedGroup.id);

            this.expandGroup(group);
        }
    }
}

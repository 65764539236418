import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { BluetoothService } from '@core/services/bluetooth.service';
import { BaseModal } from '@shared/modals/base-modal';

@Component({
    selector: 'vendo-bluetooth-devices-modal',
    templateUrl: './bluetooth-devices-modal.component.html',
    styleUrls: ['./bluetooth-devices-modal.component.scss']
})
export class BluetoothDevicesModalComponent extends BaseModal implements OnInit, OnDestroy {
    devices = [];
    isSearching: boolean;
    private searchTimeout: any;

    constructor(
        modalController: ModalController,
        private bluetoothService: BluetoothService,
        private cdr: ChangeDetectorRef
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.searchDevices();
    }

    searchDevices(): void {
        this.isSearching = true;
        this.searchTimeout = setTimeout(() => {
            this.isSearching = false;
        }, 25000);
        this.bluetoothService.scanDevices().subscribe((device) => {
            if (!device.name) {
                return;
            }

            this.devices.push(device);

            this.cdr.detectChanges();
        });
    }

    selectDevice(device: any): void {
        this.dismiss(device);
    }

    ngOnDestroy(): void {
        this.bluetoothService.stopScan();
        this.isSearching = false;
        clearTimeout(this.searchTimeout);
    }
}

import { UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import map from 'lodash/map';

export function ValidateItemUnique(label = 'items', childKey?: string): ValidatorFn {
    return (control: any) => {
        let values = [];

        if (control instanceof UntypedFormControl) {
            values = Array.isArray(control.value) ? control.value : [];
        } else if (control.controls) {
            values = map(control.controls, (form) => {
                let value = form instanceof UntypedFormGroup ? form.get(childKey || 'active').value : form.value;

                if (typeof value === 'string') {
                    value = value.trim();
                } else if (isObject(value)) {
                    value = isEmpty(value) || !value.hasOwnProperty(childKey) ? null : value[childKey];
                }

                return value;
            });
        }

        const duplicates = values.filter(
            (item, index, self) => !isNil(item) && item !== '' && self.indexOf(item) !== index
        );

        if (duplicates.length) {
            return { unique: { label }, ...(childKey && { [childKey]: { label, duplicates } }) };
        }

        return null;
    };
}

import { gql } from 'apollo-angular';

export const MeFragment = gql`
    fragment MeFragment on User {
        id
        first_name
        last_name
        email
        application_role_hash
        organization {
            id
            name
            colors
            logo_url
            background_url
            application_name
            use_office_contact_for_help
            contact_for_help {
                name
                phone_number
                email
            }
            appointment_list_settings
            promo_card_settings
            other_settings
        }
        office {
            name
            id
            country
            colors
            logo_url
            timezone
            currency
            is_use_detect_tax
            use_organization_colors
            contact_for_help {
                name
                phone_number
                email
            }
            payment_system
            voice_recording
            use_organization_appointment_list_settings
            appointment_list_settings
            use_organization_promo_card_settings
            promo_card_settings
            use_organization_other_settings
            other_settings
            product_filtering_settings
            customer_settings
            house_data_fields
            crm_name
            dictionary_settings {
                appointment_type
                status
                items {
                    type
                    single
                    plural
                }
            }
            steps {
                appointment_type
                items {
                    hash
                    name
                    second_name
                    color
                    icon
                    dependencies
                    is_hidden
                    required_viewing
                }
            }
            price_configuration {
                base_settings {
                    rounding_type
                    round_to_decimals
                }
            }
        }
        role {
            name
            hash
            role_permissions {
                permission_hash
                permissions
            }
        }
        organization_features
        privacy_policy_updated
    }
`;

const LoginMutation = gql`
    mutation LoginMutation(
        $userName: String!
        $password: String!
        $device_id: String
        $device: String
        $use_touch: Boolean
        $org_hash: String
    ) {
        mobileSignIn(
            login: $userName
            password: $password
            device_id: $device_id
            device: $device
            use_touch: $use_touch
            org_hash: $org_hash
        ) {
            token
            reset_password
            me {
                ...MeFragment
            }
        }
    }
`;

// todo move to queries
const GetMe = gql`
    query GetMe {
        me {
            ...MeFragment
        }
    }
`;

// todo move to queries
const CheckAuthTypes = gql`
    query CheckEmail($email: String!, $login_to: String) {
        checkAuthTypes(email: $email, login_to: $login_to)
    }
`;
const SSO_SIGN_IN = gql`
    mutation SSOSignIn(
        $code: String!
        $code_verifier: String!
        $redirect_url: String!
        $device_id: String
        $device: String
        $org_hash: String
    ) {
        ssoMobileSignIn(
            code: $code
            code_verifier: $code_verifier
            redirect_url: $redirect_url
            device_id: $device_id
            device: $device
            org_hash: $org_hash
        ) {
            token
            reset_password
            me {
                ...MeFragment
            }
        }
    }
`;

const BiometricLoginMutation = gql`
    mutation BiometricLoginMutation($userName: String!, $t_id_hash: String!, $device_id: String!, $org_hash: String) {
        biometricSignIn(login: $userName, t_id_hash: $t_id_hash, device_id: $device_id, org_hash: $org_hash) {
            token
            me {
                ...MeFragment
            }
        }
    }
`;

const RefreshToken = gql`
    mutation RefreshToken($officeId: ID) {
        refreshToken(office_id: $officeId) {
            token
            me {
                ...MeFragment
            }
        }
    }
`;

const ForgotPassword = gql`
    mutation forgotPassword($email: String!, $reset_password_url: String!, $org_hash: String) {
        forgotPassword(email: $email, reset_password_url: $reset_password_url, org_hash: $org_hash)
    }
`;

const ResetPasswordLogin = gql`
    mutation resetPasswordLogin(
        $hash: String!
        $password: String!
        $compare_password: String!
        $login_to: String
        $accept_terms_and_conditions: Boolean
    ) {
        resetPasswordLogin(
            hash: $hash
            password: $password
            compare_password: $compare_password
            login_to: $login_to
            accept_terms_and_conditions: $accept_terms_and_conditions
        ) {
            token
            me {
                ...MeFragment
            }
        }
    }
`;

const AcceptUpdatedPrivacyPolicy = gql`
    mutation acceptUpdatedPrivacyPolicy {
        acceptUpdatedPrivacyPolicy
    }
`;

const LogoutMutation = gql`
    mutation logout {
        logout
    }
`;

const AppointmentDeeplink = gql`
    mutation AppointmentDeeplink($hash: ID!) {
        appointmentDeeplink(hash: $hash) {
            appointment_id
            auth {
                token
                me {
                    ...MeFragment
                }
            }
        }
    }
`;

export {
    LoginMutation,
    LogoutMutation,
    ForgotPassword,
    BiometricLoginMutation,
    ResetPasswordLogin,
    AcceptUpdatedPrivacyPolicy,
    CheckAuthTypes,
    SSO_SIGN_IN,
    GetMe,
    RefreshToken,
    AppointmentDeeplink
};

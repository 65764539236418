import { Injectable } from '@angular/core';

import { ModalController, PopoverController } from '@ionic/angular';

import isEmpty from 'lodash/isEmpty';

import { CategoryHelperService } from '@core/services/helpers/category-helper.service';
import { MeasurementSystemService } from '@core/services/measurement-system.service';
import { ToastService } from '@core/services/toast.service';
import { Images } from '@shared/constants/images';
import { CategoryType } from '@shared/enums/category-type';
import { MeasurementSystemType } from '@shared/enums/measurement-system-type';
import { FormFieldsSettings } from '@shared/interfaces/form-fields-settings';
import {
    MeasurementSystem,
    MeasurementSystemReport,
    MeasurementSystemReportDetails,
    MeasurementSystemUserDetails
} from '@shared/interfaces/measurement-system';
import { PaginationItems } from '@shared/interfaces/pagination-items';
import { LoginModalComponent } from '@shared/modals/login-modal/login-modal.component';
import { MeasurementReportDetailsModalComponent } from '@shared/modals/measurement-report-details-modal/measurement-report-details-modal.component';
import { MeasurementReportsModalComponent } from '@shared/modals/measurement-reports-modal/measurement-reports-modal.component';
import { ViewMeasurementReportComponent } from '@shared/modals/view-measurement-report/view-measurement-report.component';
import { SelectionPopoverComponent } from '@shared/popovers/selection-popover/selection-popover.component';

import { ConfirmationModalComponent } from '../../components/confirmation-modal/confirmation-modal.component';
import { TakeoffService } from '../../main/appointments/takeoff/services/takeoff.service';

@Injectable({
    providedIn: 'root'
})
export class MeasurementReportService {
    constructor(
        private categoryHelperService: CategoryHelperService,
        private measurementSystemService: MeasurementSystemService,
        private modalController: ModalController,
        private popoverController: PopoverController,
        private takeoffService: TakeoffService,
        private toastService: ToastService
    ) {}

    async openMeasurementSystemsDialog(
        appointment,
        measurementSystems: MeasurementSystem[],
        categoryType?: CategoryType
    ): Promise<any> {
        const measurementSystemTypeEntries = Object.entries(MeasurementSystemType);
        const popover = await this.popoverController.create({
            component: SelectionPopoverComponent,
            translucent: true,
            mode: 'md',
            cssClass: 'selection-popover',
            componentProps: {
                items: measurementSystems
                    .filter(
                        ({ is_enabled, category_types }) =>
                            is_enabled && (!categoryType || category_types.includes(categoryType))
                    )
                    .map(({ hash }) => ({
                        name: measurementSystemTypeEntries.find(([key, value]) => value === hash)[0],
                        hash,
                        imageUrl: Images[hash]
                    }))
            }
        });

        await popover.present();

        const { data } = await popover.onWillDismiss();

        if (!data) {
            return;
        }

        const measurementSystem: MeasurementSystem = measurementSystems.find(
            (item: MeasurementSystem) => item.hash === data.hash
        );

        if (data.hash === MeasurementSystemType.Hover) {
            return this.openMeasurementReportsModal(appointment, measurementSystem);
        }

        const rememberData = await this.measurementSystemService.getRemember(data.hash).toPromise();

        if (isEmpty(rememberData)) {
            return this.openLoginDialog(appointment, measurementSystem);
        } else {
            return this.openMeasurementReportsModal(appointment, measurementSystem, {}, rememberData);
        }
    }

    private async openLoginDialog(appointment, measurementSystem: MeasurementSystem): Promise<any> {
        const modal = await this.modalController.create({
            backdropDismiss: false,
            component: LoginModalComponent,
            componentProps: {
                allowUseOfficeCredential: measurementSystem.allow_skip_credentials,
                integrationHash: measurementSystem.hash
            },
            cssClass: 'login-modal'
        });

        await modal.present();

        const { data } = await modal.onDidDismiss();

        if (!data) {
            return;
        }

        return this.openMeasurementReportsModal(appointment, measurementSystem, data);
    }

    private async openMeasurementReportsModal(
        appointment,
        measurementSystem: MeasurementSystem,
        loginFormData: any = {},
        rememberData?
    ): Promise<any> {
        const credentials =
            loginFormData.username && loginFormData.password
                ? {
                      username: loginFormData.username,
                      password: loginFormData.password
                  }
                : rememberData
                  ? {
                        username: rememberData.username,
                        password: rememberData.username
                    }
                  : undefined;
        const userDetails: MeasurementSystemUserDetails = await this.measurementSystemService
            .getMeasurementSystemUserDetails(measurementSystem.hash, appointment.id, credentials)
            .toPromise();

        let initialReportsData: PaginationItems<MeasurementSystemReport>;
        let orderFieldsForm: FormFieldsSettings;
        const userData = rememberData?.username
            ? {
                  username: rememberData.username,
                  password: rememberData.username, // prefill password by user name
                  remember: true,
                  used_remember: true
              }
            : {
                  ...loginFormData,
                  used_remember: false
              };

        // to check if credentials are valid
        try {
            [initialReportsData, orderFieldsForm] = await Promise.all([
                this.measurementSystemService
                    .searchReport(
                        measurementSystem.hash,
                        appointment.id,
                        appointment.house.address1,
                        userData.username && userData.password
                            ? {
                                  username: userData.username,
                                  password: userData.password
                              }
                            : undefined,
                        userData.remember,
                        userData.used_remember
                    )
                    .toPromise(),
                this.measurementSystemService
                    .getMeasurementSystemOrderFieldsForm(measurementSystem.hash, appointment.id, credentials)
                    .toPromise()
            ]);
        } catch (e) {
            if (measurementSystem.hash === MeasurementSystemType.EagleView) {
                return this.openLoginDialog(appointment, measurementSystem);
            }

            return;
        }

        const modal = await this.modalController.create({
            backdropDismiss: false,
            component: MeasurementReportsModalComponent,
            componentProps: {
                measurementSystem,
                userData,
                appointment,
                initialReportsData,
                userDetails,
                orderFieldsForm
            },
            cssClass: `measurement-reports-modal ${
                userData?.used_remember || userData?.remember || userDetails ? 'with-logged-as' : ''
            }`
        });

        await modal.present();

        const { data } = await modal.onDidDismiss();

        if (data) {
            if (measurementSystem.hash === MeasurementSystemType.EagleView && data === 'relogin') {
                return this.openLoginDialog(appointment, measurementSystem);
            }

            return data;
        }
    }

    async openMeasurementSystemReportDetailsDialog(
        measurementSystem: MeasurementSystem,
        appointment: any,
        report: any,
        credentials: any,
        remember?: boolean,
        used_remember?: boolean
    ): Promise<any> {
        let details: MeasurementSystemReportDetails;
        let categories;

        try {
            [details, categories] = await Promise.all([
                this.measurementSystemService
                    .getMeasurementSystemReportDetails(
                        measurementSystem.hash,
                        appointment.id,
                        report,
                        credentials,
                        remember,
                        used_remember
                    )
                    .toPromise(),
                this.categoryHelperService.isFilterByProductInterest(appointment.products_interests)
                    ? this.takeoffService.getCatalogsByProductInterests(appointment.id).toPromise()
                    : null
            ]);
        } catch (e) {
            this.toastService.showMessage(e);

            return;
        }

        const modal = await this.modalController.create({
            backdropDismiss: false,
            component: MeasurementReportDetailsModalComponent,
            componentProps: {
                measurementSystem,
                appointment,
                report,
                credentials,
                details,
                remember,
                used_remember,
                categories
            },
            cssClass: 'measurement-report-details-modal'
        });

        await modal.present();

        const { data } = await modal.onDidDismiss();

        return data;
    }

    async openViewMeasurementReportDialog(
        measurementSystem: MeasurementSystem,
        appointment: any,
        report: any
    ): Promise<void> {
        const modal = await this.modalController.create({
            backdropDismiss: false,
            component: ViewMeasurementReportComponent,
            componentProps: {
                measurementSystem,
                appointment,
                report
            },
            cssClass: 'full-screen'
        });

        await modal.present();
    }

    async removeReport(id: string, appointmentId): Promise<any> {
        const modal = await this.modalController.create({
            backdropDismiss: false,
            component: ConfirmationModalComponent,
            componentProps: {
                headerText: 'Warning',
                message: 'Are you sure you want to delete the imported report?',
                confirmButtonName: 'Yes',
                cancelButtonName: 'No'
            },
            cssClass: 'auto-width-and-height'
        });

        await modal.present();

        const { data } = await modal.onDidDismiss();

        if (data) {
            try {
                return this.measurementSystemService.removeReport(id, appointmentId).toPromise();
            } catch (e) {
                this.toastService.showMessage(e);
            }
        }
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';

import { NavController, Platform } from '@ionic/angular';

import { RollbarErrorHandler } from '@core/handlers/rollbar-error-handler';
import { AuthService } from '@core/services/auth.service';
import { DeviceHelperService } from '@core/services/device-helper.service';
import { StorageService } from '@core/services/storage.service';

@Component({
    selector: 'vendo-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, OnDestroy {
    private routeSub: any;

    constructor(
        private authService: AuthService,
        private deviceHelper: DeviceHelperService,
        private navController: NavController,
        private platform: Platform,
        private rollbarErrorHandler: RollbarErrorHandler,
        private router: Router,
        private splashScreen: SplashScreen,
        private storageService: StorageService
    ) {}

    ngOnInit(): void {
        let redirectTimeout;

        if ((this.deviceHelper.isAndroidPlatform || this.deviceHelper.isIOSPlatform) && !this.deviceHelper.isWeb) {
            this.splashScreen.show();
        }
        this.platform.ready().then(() => {
            redirectTimeout = setTimeout(() => {
                this.storageService.get('token').then((token) => {
                    if (!token) {
                        this.rollbarErrorHandler.handleInfo('Logout - Token not found in HomePageComponent');
                        const returnUrl: string = this.authService.getReturnUrl();

                        this.navController.navigateRoot(
                            ['logout'],
                            returnUrl ? { queryParams: { returnUrl } } : undefined
                        );
                    } else {
                        this.navController.navigateRoot(['main']);
                    }
                });
            }, 1000);
        });

        this.routeSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                clearTimeout(redirectTimeout);
            }
        });
    }

    ngOnDestroy(): void {
        this.splashScreen.hide();
        this.routeSub.unsubscribe();
    }
}

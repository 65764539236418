import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';

import { ModalController } from '@ionic/angular';

import round from 'lodash/round';
import sortBy from 'lodash/sortBy';

import { DeviceHelperService } from '@core/services/device-helper.service';
import { DocumentViewMode } from '@shared/enums/document-view-mode';
import { SignatureTabHash } from '@shared/enums/signature-tab-hash.constants';
import { SignatureFieldData, SortedSignatureFieldData } from '@shared/interfaces/signature-field-data';
import { SignatureConfirmationModalComponent } from '@shared/modals/signature-confirmation-modal/signature-confirmation-modal.component';
import { SignatureModalComponent } from '@shared/modals/signature-modal/signature-modal.component';

import { ConfirmationModalComponent } from '../../../components/confirmation-modal/confirmation-modal.component';
import { SIGNATURE_SELECTORS } from '../../constants/signature-selectors.constants';

@Component({
    selector: 'vendo-signature-bar',
    templateUrl: './signature-bar.component.html',
    styleUrls: ['./signature-bar.component.scss']
})
export class SignatureBarComponent implements OnInit, OnDestroy {
    @Input() showConsent = true;
    @Input() customer: any;
    @Input() documentHash: string;
    @Input() viewMode: DocumentViewMode;
    @Input() predefinedSignature: string;
    @Input() predefinedInitials: string;
    @Input() initialScale: number;
    @Input() @HostBinding('class.ion-justify-content-center') isSigning: boolean;
    @Output() isSigningChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() signed: EventEmitter<any> = new EventEmitter<any>();
    readonly documentViewModes: typeof DocumentViewMode = DocumentViewMode;
    customerSignatureDone = false;
    activeFieldIndex: number;
    notSignedFields: SignatureFieldData[] = [];
    isDisableActions: boolean;
    isShowResume: boolean;
    private confirmedSignatures = {};
    private documentContainer: Element;
    private scrolledContainer: HTMLElement | Element;
    private isAutoScroll: boolean;
    private observer: IntersectionObserver;

    constructor(
        private deviceHelperService: DeviceHelperService,
        private modalController: ModalController,
        private renderer2: Renderer2
    ) {}

    ngOnInit(): void {
        this.documentContainer = document.querySelector(this.documentHash ? '.document-wrapper' : '.content-holder');
        this.observer = new IntersectionObserver(
            (entries: IntersectionObserverEntry[]) => {
                for (const entry of entries) {
                    if (this.isSigning) {
                        if (entry.isIntersecting && entry.intersectionRatio === 1) {
                            this.isShowResume = this.isAutoScroll = false;
                        } else if (!entry.isIntersecting && entry.intersectionRatio === 0 && !this.isAutoScroll) {
                            this.isShowResume = true;
                        }
                    }
                }
            },
            { threshold: [0, 1] }
        );
    }

    ngOnDestroy(): void {
        this.observer.disconnect();
    }

    async showDisclosure(): Promise<any> {
        const modal = await this.modalController.create({
            component: ConfirmationModalComponent,
            componentProps: {
                showCancelButton: false,
                confirmButtonName: 'Done',
                headerText: 'E-Signature disclosure',
                message:
                    'The parties agree that this agreement may be electronically signed. The parties agree that the electronic signatures appearing on this agreement are the same as handwritten signatures for the purposes of validity, enforceability, and admissibility. A fully-executed digital document will be sent to and retained by all signing parties.'
            }
        });

        await modal.present();
    }

    async showAgreement(): Promise<void> {
        if (this.showConsent) {
            const modal = await this.modalController.create({
                component: SignatureConfirmationModalComponent,
                cssClass: `allow-zoom${
                    this.deviceHelperService.isPhone && this.deviceHelperService.isLandscape ? ' full-screen' : ''
                }`
            });

            await modal.present();

            const { data } = await modal.onDidDismiss();

            if (!data) {
                return;
            }
        }

        await this.showSignatureModal();
    }

    async showSignatureModal(): Promise<void> {
        const signaturePlaces = document.querySelectorAll(SIGNATURE_SELECTORS[this.viewMode].signature_not_signed);
        const initialsPlaces = document.querySelectorAll(SIGNATURE_SELECTORS[this.viewMode].initials_not_signed);
        const signatureImages = {};

        if (!signaturePlaces?.length && !initialsPlaces?.length) {
            return;
        }

        if (this.predefinedSignature) {
            signatureImages[SignatureTabHash.CUSTOMER_SIGNATURE] = this.predefinedSignature;
        }

        if (this.predefinedInitials) {
            signatureImages[SignatureTabHash.CUSTOMER_INITIALS] = this.predefinedInitials;
        }

        const modal = await this.modalController.create({
            component: SignatureModalComponent,
            componentProps: {
                signatureEntities: {
                    customerInitials: !!initialsPlaces?.length,
                    customerSignatures: !!signaturePlaces?.length
                },
                signatureImages,
                signatureOwner: 'customer',
                customer: this.customer,
                documentHash: this.documentHash
            },
            cssClass: `allow-zoom ${
                this.deviceHelperService.isPhone && this.deviceHelperService.isLandscape
                    ? 'full-screen'
                    : 'signature-modal'
            }`
        });

        await modal.present();

        const { data } = await modal.onDidDismiss();

        if (!data) {
            return;
        }

        if (this.isPageScaled() && this.deviceHelperService.isIOSPlatform) {
            // fix problem with popover position when page is zoom in
            const confirmModal = await this.modalController.create({
                component: ConfirmationModalComponent,
                cssClass: 'allow-zoom',
                componentProps: {
                    headerText: 'Warning',
                    message: 'Please zoom out to proceed with signing document',
                    confirmButtonName: 'Ok',
                    showCancelButton: false
                }
            });

            await confirmModal.present();

            const result = await confirmModal.onDidDismiss();

            if (this.isPageScaled() || !result.data) {
                return;
            }
        }

        this.confirmedSignatures = data;
        const selector: string = Object.keys(data)
            .map((key: SignatureTabHash) =>
                key === SignatureTabHash.CUSTOMER_SIGNATURE
                    ? SIGNATURE_SELECTORS[this.viewMode].signature_not_signed
                    : SIGNATURE_SELECTORS[this.viewMode].initials_not_signed
            )
            .join(',');
        const pages = document.querySelectorAll('.pf[id^="pf"]');

        if (pages.length) {
            this.scrolledContainer = document.getElementById('page-container');
            this.notSignedFields = [];
            pages.forEach((page: Element) => {
                this.notSignedFields.push(...this.getSortedFields(selector, page, this.documentHash ? 235 : 275));
            });
        } else {
            this.scrolledContainer = document.querySelector(
                this.documentHash ? '.document-wrapper' : '.proposal-wrapper'
            );
            this.notSignedFields = this.getSortedFields(
                selector,
                this.scrolledContainer,
                this.documentHash ? 237 : 320
            );
        }

        if (this.notSignedFields.length) {
            this.notSignedFields.forEach(({ element }: SignatureFieldData) => {
                element.onclick = () => {
                    if (this.isSigning && !element.classList.contains('signed')) {
                        if (element.classList.contains('active')) {
                            this.confirm();
                        } else {
                            const activeFieldIndex: number = this.notSignedFields.findIndex(
                                (item: SignatureFieldData) => element === item.element
                            );

                            if (activeFieldIndex > -1 && !this.notSignedFields[activeFieldIndex].isSigned) {
                                this.unSelectActiveField();
                                this.activeFieldIndex = activeFieldIndex;
                                this.setActiveField(this.notSignedFields[this.activeFieldIndex].element);
                            }
                        }
                    }
                };
            });
            this.renderer2.addClass(this.documentContainer, 'signing');
            this.setSigning(true);
            this.activeFieldIndex = 0;
            this.goToNextNotSignedField();
        }
    }

    skip(): void {
        this.notSignedFields[this.activeFieldIndex].element.innerHTML = '';
        this.renderer2.removeClass(this.notSignedFields[this.activeFieldIndex].element, 'signed');
        this.goToNextField(true);
    }

    confirm(): void {
        const signatureImage: string =
            this.confirmedSignatures[
                this.notSignedFields[this.activeFieldIndex].isInitials
                    ? SignatureTabHash.CUSTOMER_INITIALS
                    : SignatureTabHash.CUSTOMER_SIGNATURE
            ];

        this.notSignedFields[this.activeFieldIndex].element.innerHTML =
            `<img src="${signatureImage}" style="max-height: 100%; max-width: 100%"/>`;
        this.renderer2.addClass(this.notSignedFields[this.activeFieldIndex].element, 'signed');
        this.notSignedFields[this.activeFieldIndex].isSigned = true;
        this.goToNextField();
    }

    scrollToField(): void {
        this.isAutoScroll = true;
        this.scrolledContainer.scrollTo({
            top: this.notSignedFields[this.activeFieldIndex].offsetTop,
            behavior: 'smooth'
        });
    }

    private goToNextField(isSkipped?: boolean): void {
        this.unSelectActiveField();
        const isAllSigned: boolean = !this.notSignedFields.filter(({ isSigned }: SignatureFieldData) => !isSigned)
            .length;

        if (isAllSigned) {
            this.finishSigning();
        } else {
            this.goToNextNotSignedField(isSkipped);
        }
    }

    private goToNextNotSignedField(isSkipped?: boolean): void {
        for (let i = this.activeFieldIndex; i < this.notSignedFields.length + this.activeFieldIndex; i++) {
            if (isSkipped && i === this.activeFieldIndex) {
                continue;
            }

            const el = this.notSignedFields[i % this.notSignedFields.length];

            if (!el.isSigned) {
                this.activeFieldIndex = this.notSignedFields.findIndex(
                    ({ element }: SignatureFieldData) => element === el.element
                );
                break;
            }
        }

        if (this.isFullyVisible(this.notSignedFields[this.activeFieldIndex].element)) {
            this.setActiveField(this.notSignedFields[this.activeFieldIndex].element);
            return;
        }

        this.isDisableActions = true;
        setTimeout(() => {
            this.scrollToField();
            this.setActiveField(this.notSignedFields[this.activeFieldIndex].element);
            this.isDisableActions = false;
        }, 500);
    }

    private isFullyVisible(element: HTMLElement): boolean {
        const rect: DOMRect = element.getBoundingClientRect();
        const containerRect: DOMRect = this.scrolledContainer.getBoundingClientRect();

        return (
            rect.top >= containerRect.top &&
            rect.left >= containerRect.left &&
            rect.bottom <= containerRect.bottom &&
            rect.right <= containerRect.right
        );
    }

    private setActiveField(element: Element): void {
        this.observer.observe(element);
        this.renderer2.addClass(element, 'active');
    }

    private unSelectActiveField(): void {
        this.observer.unobserve(this.notSignedFields[this.activeFieldIndex].element);
        this.renderer2.removeClass(this.notSignedFields[this.activeFieldIndex].element, 'active');
    }

    private finishSigning(): void {
        this.renderer2.removeClass(this.documentContainer, 'signing');
        this.setSigning(false);
        this.customerSignatureDone = !document.querySelectorAll(
            `${SIGNATURE_SELECTORS[this.viewMode].signature_not_signed}, ${
                SIGNATURE_SELECTORS[this.viewMode].initials_not_signed
            }`
        ).length;
        this.signed.emit(this.customerSignatureDone ? this.confirmedSignatures : null);
    }

    private setSigning(value: boolean): void {
        this.isSigning = value;
        this.isSigningChange.emit(value);
    }

    private getAbsolutePosition(element: HTMLElement): { top: number; left: number } {
        let top = 0;
        let left = 0;

        while (element) {
            top += element.offsetTop;
            left += element.offsetLeft;
            element = element.offsetParent as HTMLElement;
        }
        return { top, left };
    }

    private getSortedFields(selector: string, container: Element, shiftPosition: number): any[] {
        const elements: SortedSignatureFieldData[] = Array.from(container.querySelectorAll(selector)).map(
            (element: Element) => ({
                element: element as HTMLElement,
                top: this.getAbsolutePosition(element as HTMLElement).top,
                left: this.getAbsolutePosition(element as HTMLElement).left
            })
        );
        const sortedElements: SortedSignatureFieldData[] = sortBy(elements, [
            ({ top }: SortedSignatureFieldData) => top,
            ({ left }: SortedSignatureFieldData) => left
        ]);

        return sortedElements.map(({ element, top }: SortedSignatureFieldData) => {
            return {
                element,
                // needs offset 180px and 90px(only phones) from vendo-actions-header
                offsetTop: top - shiftPosition + (this.deviceHelperService.isPhone ? 90 : 0),
                isSigned: false,
                isInitials: element.getAttribute('data-name')?.endsWith('initials')
            };
        });
    }

    private isPageScaled(): boolean {
        return round(window.visualViewport.scale, 3) !== round(this.initialScale || 1, 3);
    }
}

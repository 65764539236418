import { gql } from 'apollo-angular';

const ALLOW_DISPLAY_LIST_PRICE_STATUS = gql`
    query AllowDisplayListPriceStatus {
        allowDisplayListPriceStatus
    }
`;

const ALLOW_DISPLAY_PRICE_MODIFIERS_STATUS = gql`
    query AllowDisplayPriceModifiersStatus {
        allowDisplayPriceModifiersStatus
    }
`;

export { ALLOW_DISPLAY_LIST_PRICE_STATUS, ALLOW_DISPLAY_PRICE_MODIFIERS_STATUS };

<div class="question-block">
    <div class="question-title">{{ question.DisplayName }}</div>
    <div class="answers">
        <ion-grid *ngIf="question.DisplayName !== 'Width' && question.DisplayName !== 'Height'; else list">
            <ion-row class="questions-row ion-nowrap">
                <ng-container *ngFor="let answer of question.AvailableAnswers">
                    <ion-col *ngIf="!answer.Hidden"
                             [class.selected]="answer.IsSelected"
                             (click)="toggleAnswer(answer)"
                             size="3"
                             class="answer">
                        <img *ngIf="answer.ImageURL"
                             class="answer-image"
                             [src]="answer.ImageURL | wcpDrawingUrl:imgBaseUrl"/>
                        <ion-text>{{ answer.DisplayName }}</ion-text>
                        <ion-icon *ngIf="answer.IsSelected" name="checkmark" class="selected-icon"></ion-icon>
                    </ion-col>
                </ng-container>
            </ion-row>
        </ion-grid>

        <ng-template #list>
            <ion-list lines="none">
                <ng-container *ngFor="let answer of question.AvailableAnswers">
                    <ion-item *ngIf="!answer.Hidden">
                        <ion-label>{{ answer.DisplayName }}</ion-label>
                        <ion-checkbox legacy="true" slot="start"
                                      [checked]="answer.IsSelected"
                                      (ionChange)="toggleAnswer(answer)"
                                      mode="md"></ion-checkbox>
                    </ion-item>
                </ng-container>
            </ion-list>
        </ng-template>
    </div>

    <div class="errors-wrapper" *ngIf="question.Errors">
        <ion-icon name="warning"></ion-icon>
        <div class="messages" *ngFor="let error of question.Errors">
            {{ error.message }}
        </div>
    </div>
</div>

<div class="modal-header">
    <ion-button fill="outline" (click)="closeModal()">
        <ion-icon name="arrow-back-outline"></ion-icon>
    </ion-button>

    <ion-text class="title">{{ title }}</ion-text>
</div>

<div class="modal-body">
    <ion-content>
        <ion-accordion-group *ngFor="let groupedDocument of groupedDocuments; let j = index"
                             [multiple]="true"
                             [value]="allOpenedGroups">
            <ion-accordion toggleIconSlot="start" value="group-accordion-{{ j }}">
                <ion-item slot="header" color="light">
                    <ion-label class="group-label">{{ groupedDocument.name }}</ion-label>
                </ion-item>
                <div slot="content">
                    <ng-container *ngIf="groupedDocument.documents">
                        <ng-container *ngFor="let document of groupedDocument.documents; let i = index">
                            <ion-item lines="none"
                                      class="ml-26"
                                      (click)="presentPopover(document, $event)">
                                <ion-label>
                                    {{ document.name }}
                                    <ng-container [ngSwitch]="document.type">
                                        <ng-container *ngSwitchCase="documentTypes.PriceConditioning">
                                          (Energy Savings)
                                        </ng-container>
                                        <ng-container *ngSwitchCase="documentTypes.AppointmentFile">
                                            (Upload)
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                            ({{ document.type | startCase }})
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="isOnlyAttachments && document.package_name">
                                        - {{ document.package_name }}
                                    </ng-container>
                                </ion-label>
                                <ion-checkbox legacy="true" slot="start"
                                              mode="md"
                                              (click)="$event.stopPropagation()"
                                              [disabled]="document.signed || document.required"
                                              (ngModelChange)="$event ? setDocumentContext(document) : ''"
                                              [(ngModel)]="document.selected"></ion-checkbox>
                            </ion-item>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="groupedDocument.groupedDocuments">
                        <ion-accordion-group *ngFor="let groupedDocument of groupedDocument.groupedDocuments"
                                             class="ml-26"
                                             [multiple]="true">
                            <ion-accordion toggleIconSlot="start">
                                <ion-item slot="header" color="light">
                                    <ion-label class="group-label">{{ groupedDocument.name }}</ion-label>
                                </ion-item>
                                <div slot="content">
                                    <ng-container *ngFor="let document of groupedDocument.documents; let i = index">
                                        <ion-item lines="none"
                                                  class="ml-26"
                                                  (click)="presentPopover(document, $event)">
                                            <ion-label>{{ document.name }}</ion-label>
                                            <ion-checkbox legacy="true" slot="start"
                                                          mode="md"
                                                          [disabled]="document.signed"
                                                          (click)="$event.stopPropagation()"
                                                          (ngModelChange)="$event ? setDocumentContext(document) : ''"
                                                          [(ngModel)]="document.selected"></ion-checkbox>
                                        </ion-item>
                                    </ng-container>
                                </div>
                            </ion-accordion>
                        </ion-accordion-group>
                    </ng-container>
                </div>
            </ion-accordion>
        </ion-accordion-group>
    </ion-content>
</div>

<div class="modal-footer flex-end">
    <ion-button class="classic-width" (click)="done()">
        Done
    </ion-button>
</div>

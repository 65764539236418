<div class="modal-header">
    <ion-text class="title">{{ activeTab?.name }}</ion-text>

    <ion-button fill="outline"
                color="dark"
                (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
</div>

<div class="modal-body" #modalBody>
    <span class="font-preload">a</span>
    <ion-content>
        <ion-input legacy="true" *ngIf="activeTab?.isInitials"
                   [(ngModel)]="signerInitials"
                   (ngModelChange)="redrawInitials()"></ion-input>
        <vendo-signature-pad #signaturePad
                             class="signature-pad"
                             [options]="signaturePadOptions"
                             (drawStart)="drawingStarted()"></vendo-signature-pad>
    </ion-content>
</div>

<div class="modal-footer">
    <ion-button class="classic-width"
                color="secondary"
                (click)="clear()">
        Clear
    </ion-button>

    <ion-button *ngIf="tabs.length > 1"
                class="classic-width"
                fill="outline"
                [disabled]="!signatureExist[activeTab?.hash] && !isLastTabSelected"
                (click)="goToTab(isLastTabSelected ? 0 : 1)">
        {{ isLastTabSelected ? 'Back' : 'Confirm & Next' }}
    </ion-button>

    <ion-button *ngIf="isLastTabSelected"
                class="classic-width"
                [disabled]="!isDoneEnabled()"
                (click)="done()">
        Done
    </ion-button>
</div>

<div class="modal-header">
  <ion-text class="title">{{headerText}}</ion-text>
  <ion-button fill="outline" color="dark" (click)="closeModal()">
    <ion-icon slot="icon-only" name="close"></ion-icon>
  </ion-button>
</div>

<div class="modal-body">
  <ion-textarea #inputField
                [(ngModel)]="inputText"
                minlength="1"
                [maxlength]="charLimit"
                rows="3"></ion-textarea>
</div>

<div class="modal-footer">
  <ion-text class="char-limit">{{charLimit - inputText.length}} Characters remaining</ion-text>
  <ion-button class="classic-width" (click)="save()">Save</ion-button>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AvailableBoundingBox } from '@shared/interfaces/mulling-item';

@Component({
    selector: 'vendo-clickable-image',
    templateUrl: './clickable-image.component.html',
    styleUrls: ['./clickable-image.component.scss']
})
export class ClickableImageComponent {
    @Input() imgSrc: string;
    @Input() areas: AvailableBoundingBox[] = [];

    @Output() areaClicked = new EventEmitter<AvailableBoundingBox>();

    selectArea(area: AvailableBoundingBox): void {
        this.areaClicked.emit(area);
    }
}

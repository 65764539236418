<div class="modal-header">
    <ion-text class="title">Result the appointment with {{ appointment?.family_name }}</ion-text>
    <ion-button fill="outline" color="dark" (click)="closeModal()" *ngIf="showCloseIcon">
        <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
</div>

<div class="modal-body">
    <vendo-stepper-progress-bar [class.hidden]="totalSteps === 1"
                                [totalSteps]="totalSteps"
                                [selectedStep]="selectedStep">
    </vendo-stepper-progress-bar>

    <ion-content>
        <form *ngIf="form" [formGroup]="form" class="modal-body__form" autocomplete="off">
            <ng-container *ngIf="selectedStep === 1">
                <div *ngIf="availableResults.results.length !== 1" class="field-block support-scroll-x">
                    <ion-label class="required">How did the appointment go?</ion-label>
                    <ion-content [scrollX]="true" [scrollY]="false">
                        <div *ngIf="!availableResults.results.length; else reasons">No available reasons</div>
                        <ng-template #reasons>
                          <ng-container *ngFor="let result of availableResults.results">
                            <ion-button class="outline"
                                        [ngClass]="{'active-reason': form.get('result').value && form.get('result').value[resultValueField] === result[resultValueField]}"
                                        [disabled]="result.disabled"
                                        (click)="setResult(result)">
                              <span>{{ result.name | appointmentStatus }}</span>
                            </ion-button>
                          </ng-container>
                        </ng-template>
                    </ion-content>
                </div>

                <div *ngIf="alertMessage" class="alert-message">
                  <ion-icon name="information-circle"></ion-icon>
                  {{ alertMessage }}
                </div>

                <div *ngIf="(form.get('result').value && !wonStatuses.includes(form.get('result').value.type)) && !isI360Lightning"
                     class="field-block">
                    <ion-label class="required">What was the reason?</ion-label>
                    <ng-container *ngIf="form.get('result').value?.reasons?.length; else inputTemplate">
                        <ion-radio-group formControlName="result_reason">
                            <ion-item *ngFor="let reason of form.get('result').value?.reasons"
                                      lines="none">
                                <ion-label>{{ reason.name }}</ion-label>
                                <ion-radio legacy="true" slot="start" mode="md" [value]="reason"></ion-radio>
                            </ion-item>
                        </ion-radio-group>
                    </ng-container>
                    <ng-template #inputTemplate>
                        <ion-input legacy="true" placeholder="Explain more here" formControlName="result_reason"></ion-input>
                    </ng-template>
                </div>

                <div class="field-block" *ngIf="emailMessageProps.isSendable">
                    <vendo-select-documents-input [countOfSelectedDocuments]="countOfSelectedDocuments"
                                                  [documents]="documents"
                                                  (selectedDocuments)="setSelectedDocuments($event)"
                                                  placeholder="Select documents"
                                                  label="Documents for homeowner"></vendo-select-documents-input>
                </div>

                <div class="field-block notes">
                    <ion-item lines="none">
                        <ion-label [class.required]="isShowNotes">
                            Include final installation/appointment notes
                        </ion-label>
                        <ion-checkbox legacy="true" mode="md"
                                      slot="start"
                                      [ngModel]="isShowNotes"
                                      (ngModelChange)="toggleNotes()"
                                      [ngModelOptions]="{standalone: true}"></ion-checkbox>
                    </ion-item>

                    <ion-textarea *ngIf="isShowNotes"
                                  rows="2"
                                  formControlName="notes"></ion-textarea>
                </div>
            </ng-container>

            <ng-container *ngIf="selectedStep === 2 && resultEachCatalog" formArrayName="opportunity_results">
                <div class="opportunities-step-label">Sales Opportunities</div>

                <div class="opportunities flex-center"
                     *ngFor="let opportunitiesResultFormGroup of opportunityResultsFormArray.controls; let i = index;"
                     [formGroupName]="i">
                    <div class="catalog-name flex-center">
                        <i class="material-icons">window</i>
                        <span class="text-ellipsis">{{ opportunitiesResultFormGroup.get('catalog_name').value }}</span>
                    </div>

                    <div class="field-block">
                        <ion-label class="required">Result</ion-label>
                        <ion-select legacy="true"
                                    mode="md"
                                    interface="popover"
                                    [interfaceOptions]="{mode: 'md', alignment: 'start'}"
                                    placeholder="Result"
                                    required
                                    (ionChange)="opportunityResultsChanged(i, $event.detail.value)"
                                    formControlName="result">
                            <ion-select-option *ngFor="let option of opportunityResults"
                                               [value]="option">
                                {{ option.name }}
                            </ion-select-option>
                        </ion-select>
                    </div>

                    <div class="field-block"
                         [ngStyle]="{ 'visibility': wonStatuses.includes(opportunityResultsFormArray.get([i, 'result']).value?.type) ? 'hidden' : 'visible' }">
                        <ion-label class="required">Result Detail</ion-label>
                        <ion-select *ngIf="opportunityResultsFormArray.get([i, 'result']).value?.reasons?.length"
                                    legacy="true"
                                    mode="md"
                                    interface="popover"
                                    [interfaceOptions]="{mode: 'md', alignment: 'start'}"
                                    placeholder="Result Detail"
                                    [required]="!wonStatuses.includes(opportunityResultsFormArray.get([i, 'result']).value?.type)"
                                    formControlName="result_reason">
                            <ion-select-option *ngFor="let option of opportunityResultsFormArray.get([i, 'result']).value?.reasons"
                                               [value]="option">
                                {{ option.name }}
                            </ion-select-option>
                        </ion-select>
                        <ion-input legacy="true" *ngIf="!opportunityResultsFormArray.get([i, 'result']).value?.reasons?.length"
                                   placeholder="Result Detail"
                                   formControlName="result_reason"></ion-input>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="selectedStep === totalSteps && emailMessageProps.isSendable">
                <div class="field-block">
                    <ion-label [class.required]="wonStatuses.includes(form.get('result').value?.type) || form.get('email_message').value || form.get('emails_send_to').value?.length">
                        Homeowner email(s)
                    </ion-label>
                    <tagify formControlName="emails_send_to"
                            [settings]="tagifySettings"></tagify>
                    <ion-text class="email-hint">
                        Separate multiple e-mail addresses using a comma or a space between them
                    </ion-text>
                </div>

                <div class="field-block">
                    <ion-label [class.required]="wonStatuses.includes(form.get('result').value?.type) || form.get('email_message').value || form.get('emails_send_to').value?.length">
                        Message for the homeowner
                    </ion-label>
                    <ion-textarea rows="13"
                                  [readonly]="emailMessageProps.isReadOnly"
                                  placeholder="Write a message for the homeowner with instructions to close the deal"
                                  formControlName="email_message"></ion-textarea>
                </div>
            </ng-container>
        </form>
    </ion-content>
</div>

<div class="modal-footer flex-end">
    <ion-button class="classic-width"
                fill="outline"
                (click)="selectedStep === 1 ? closeModal() : selectStep(-1)">
        {{ selectedStep === 1 ? 'Cancel' : 'Back' }}
    </ion-button>
    <ion-button class="classic-width"
                [disabled]="form?.invalid && selectedStep === totalSteps"
                (click)="selectedStep === totalSteps ? finishResulting() : selectStep(1)">
        {{ selectedStep === totalSteps ? 'Finish' : 'Next' }}
    </ion-button>
</div>

import { Injectable, NgZone } from '@angular/core';

import { File } from '@awesome-cordova-plugins/file/ngx';
import { HTMLElement, parse } from 'node-html-parser';

import { Platform } from '@ionic/angular';

import { environment } from '../../../environments/environment';

declare let cordova: any;

export interface HttpdOptions {
    www_root?: string;
    port?: number;
    localhost_only?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class FilesService {
    private storageDirectory: string;
    private options: HttpdOptions;
    private localServerUrl: string;

    constructor(
        private file: File,
        private platform: Platform,
        private ngZone: NgZone
    ) {
        this.platform.ready().then(() => {
            if ((window.location.host && window.location.host.includes('paradigmvendo')) || environment.local) {
                return;
            }
            if (this.platform.is('ios')) {
                this.storageDirectory = this.file.documentsDirectory;
            } else if (this.platform.is('android')) {
                this.storageDirectory = this.file.externalDataDirectory;
            }

            this.options = {
                www_root: this.storageDirectory.replace('file://', ''),
                port: 9763, // Port less than 1024 will throw error: Permission denied
                localhost_only: true // if you want to create multiple localhost then false
            };
        });
    }

    async saveResourceLocally(fileUrl: string, localFolder: string, fileName?: string): Promise<any> {
        if (!this.storageDirectory || !fileUrl) {
            return;
        }

        if (!fileName) {
            const filePathOnly: string = new URL(fileUrl).pathname;

            fileName = filePathOnly.split('/').pop();
        }

        return new Promise((resolve, reject) => {
            this.file
                .checkFile(this.storageDirectory + localFolder, fileName)
                .then((exist) => {
                    if (!exist) {
                        this.createDirectory(resolve, reject, localFolder, fileUrl, fileName);
                    } else {
                        resolve(localFolder + fileName);
                    }
                })
                .catch(() => {
                    this.createDirectory(resolve, reject, localFolder, fileUrl, fileName);
                });
        });
    }

    async getFreeDiskSpace(measurement = 'gb'): Promise<number> {
        const spaceInKb: number = await this.file.getFreeDiskSpace();

        switch (measurement) {
            case 'kb':
                return spaceInKb;
            case 'mb':
                return spaceInKb / 1024;
            case 'gb':
                return spaceInKb / 1024 / 1024;
        }
    }

    async saveBlobLocally(file: any, localFolder: string): Promise<any> {
        if (!this.storageDirectory) {
            return;
        }

        const fileName = file.name;

        return new Promise((resolve, reject) => {
            this.file
                .checkFile(this.storageDirectory + localFolder, fileName)
                .then((exist) => {
                    if (!exist) {
                        this.createDirectory(resolve, reject, localFolder, undefined, fileName, file);
                    } else {
                        resolve(localFolder + fileName);
                    }
                })
                .catch(() => this.createDirectory(resolve, reject, localFolder, undefined, fileName, file));
        });
    }

    private createDirectory(
        resolve: any,
        reject: any,
        localFolder: string,
        fileUrl: string,
        fileName: string,
        file?: any
    ): void {
        this.file
            .checkDir(this.storageDirectory, localFolder)
            .then((_) => {
                if (!file) {
                    this.downloadAndSave(resolve, reject, localFolder, fileUrl, fileName);
                } else {
                    this.saveFile(file, localFolder, fileName, resolve, reject);
                }
            })
            .catch(() => {
                this.file
                    .createDir(this.storageDirectory, localFolder, true)
                    .then((_) => {
                        if (!file) {
                            this.downloadAndSave(resolve, reject, localFolder, fileUrl, fileName);
                        } else {
                            this.saveFile(file, localFolder, fileName, resolve, reject);
                        }
                    })
                    .catch(() => {
                        reject();
                    });
            });
    }

    downloadAndSave(resolve: any, reject: any, localFolder: string, fileUrl: string, fileName: string): void {
        const Http = new XMLHttpRequest();

        Http.open('GET', fileUrl);
        Http.responseType = 'blob';

        Http.send();

        Http.onload = () => {
            if (Http.status === 200) {
                const blob = new Blob([Http.response]);

                this.saveFile(blob, localFolder, fileName, resolve, reject);
            }
        };

        Http.onerror = () => reject();
    }

    saveFile(blob, localFolder, fileName, resolve, reject): Promise<any> {
        return this.file
            .writeFile(this.storageDirectory + localFolder, fileName, blob, { replace: true })
            .then(() => resolve(localFolder + fileName))
            .catch(() => {
                reject();
            });
    }

    startLocalServer(): Promise<string> {
        return new Promise((resolve, reject) => {
            // Server is live http://127.0.0.0.1:[PORT]
            cordova.plugins.CorHttpd.startServer(
                this.options,
                (url: string) => resolve(url),
                (err) => {
                    if (err === 'server is already up') {
                        cordova.plugins.CorHttpd.getURL(
                            (url) => resolve(url),
                            () => reject()
                        );
                    } else {
                        reject();
                    }
                }
            );
        });
    }

    startLocalServerAndSetLocalServerUrl(): void {
        this.startLocalServer().then((url: string) => this.ngZone.run(() => (this.localServerUrl = url)));
    }

    getFileUrl(filePath: string): string {
        if (this.platform.is('ios')) {
            return `${this.localServerUrl}${filePath}`;
        } else if (this.platform.is('android')) {
            const win: any = window; // hack compilator

            return win.Ionic.WebView.convertFileSrc(this.storageDirectory + filePath);
        }
    }

    async getBase64File(filePath: string): Promise<any> {
        return this.file.readAsDataURL(this.storageDirectory, filePath);
    }

    async getTextFile(filePath: string): Promise<string> {
        return this.file.readAsText(this.storageDirectory, filePath);
    }

    async getBlobFile(filePath: string): Promise<Blob> {
        const directoryPath: string = filePath.slice(0, filePath.lastIndexOf('/'));
        const fileName: string = filePath.slice(filePath.lastIndexOf('/') + 1);
        const base64Data: string = await this.file.readAsDataURL(`${this.storageDirectory}/${directoryPath}`, fileName);

        if (base64Data) {
            const endIndex: number = base64Data.indexOf(';base64');
            const contentType: string = base64Data.substring('data:'.length, endIndex);
            const extension: string = base64Data.substring('data:image/'.length, endIndex);

            return this.base64ToBlob(base64Data, contentType, 512, `blob.${extension}`);
        }

        return Promise.reject('File not found');
    }
    base64ToBlob(b64Data, contentType = '', sliceSize = 512, fileName = 'photo.jpg'): Blob {
        b64Data = b64Data.replace(/data\:image\/(jpeg|jpg|png)\;base64\,/gi, '');
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType }) as any;

        blob.name = fileName;

        return blob;
    }

    async deleteFile(filePath: string): Promise<any> {
        return this.file.removeFile(this.storageDirectory, filePath);
    }

    async saveAndReplaceLinksInHTML(path: string): Promise<void> {
        const html: string = await this.getTextFile(path);
        const root: HTMLElement = parse(html);

        const linkElements: HTMLElement[] = root.getElementsByTagName('link');
        const links: string[] = linkElements
            .map((el: HTMLElement) => el.getAttribute('href'))
            .filter((link: string) => link.startsWith('https'));

        let temporaryFolder = 'temporary/';

        if (links.length) {
            const parts: string[] = links[0].split('/');

            temporaryFolder = `${parts[parts.length - 2]}/`;
        }

        const linkPromises = links.map((link: string) =>
            this.saveResourceLocally(link, temporaryFolder).then(async (filePath: string) => {
                const styles: string = await this.getTextFile(filePath);

                return { filePath, styles };
            })
        );
        const linkResults: Array<{ filePath; styles }> = await Promise.all(linkPromises).catch(() => []);

        linkResults.forEach(({ filePath, styles }) => {
            const styleTag = parse(`<style>${styles}</style>`);

            root.appendChild(styleTag);
            this.deleteFile(filePath).catch();
        });

        linkElements.forEach((el: HTMLElement) => el.remove());

        const imageElements: HTMLElement[] = root
            .getElementsByTagName('img')
            .filter((el: HTMLElement) => el.getAttribute('src').startsWith('https'));

        const imageFilePathPromises = imageElements.map((el: HTMLElement) =>
            this.saveResourceLocally(el.getAttribute('src'), temporaryFolder).then((imageFilePath: string) =>
                this.getFileUrl(imageFilePath)
            )
        );
        const imageFilePaths: string[] = await Promise.all(imageFilePathPromises).catch(() => []);

        imageFilePaths.forEach((imagePath: string, index: number) =>
            imageElements[index].setAttribute('src', imagePath)
        );

        const [folder, fileName]: string[] = path.split('/');

        if (links.length || imageElements.length) {
            await this.file.writeFile(this.storageDirectory + folder, fileName, root.toString(), { replace: true });
        }
    }

    fixIOSLinksInPDFResource(content: string): string {
        return content.replaceAll('src="undefined', `src="${this.localServerUrl}`);
    }
}

import { Directive, ElementRef, HostListener } from '@angular/core';

import { DeviceHelperService } from '@core/services/device-helper.service';

@Directive({
    selector: 'ion-input[select-all-text]'
})
export class SelectAllTextDirective {
    constructor(
        private el: ElementRef,
        private deviceHelperService: DeviceHelperService
    ) {}

    @HostListener('click')
    selectAll(): void {
        const nativeEl: HTMLInputElement = this.el.nativeElement.querySelector('input');

        if (nativeEl) {
            nativeEl.select();
        }
    }

    @HostListener('contextmenu', ['$event'])
    onContextMenuOpen(event): void {
        if (this.deviceHelperService.isAndroidPlatform) {
            event.preventDefault();
        }
    }
}

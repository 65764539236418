import { Component, Input, ViewChild } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { PaymentService } from '@core/services/payment.service';
import { BaseModal } from '@shared/modals/base-modal';
import { QuickInvoiceFormComponent } from '@shared/modals/quick-invoice-modal/quick-invoice-form/quick-invoice-form.component';

@Component({
    selector: 'vendo-quick-invoice-modal',
    templateUrl: './quick-invoice-modal.component.html',
    styleUrls: ['./quick-invoice-modal.component.scss']
})
export class QuickInvoiceModalComponent extends BaseModal {
    @Input() appointment: any;
    @Input() remainingBalance: number;
    @Input() quickInvoices: any[];

    adjustedRemainingBalance = 0;

    @ViewChild(QuickInvoiceFormComponent) formComponent: QuickInvoiceFormComponent;

    constructor(
        public modalController: ModalController,
        private paymentService: PaymentService
    ) {
        super(modalController);
    }

    async sendInvoice(): Promise<any> {
        const formData = await this.formComponent.getFormValues();

        if (!formData) {
            return;
        }

        this.paymentService.sendQuickInvoice(this.appointment.id, formData).subscribe(() => this.closeModal());
    }
}

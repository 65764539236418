import { UntypedFormControl, ValidatorFn } from '@angular/forms';

export function ValidateArrayMaxLength(maxLength: number, msg = 'List must contain less items.'): ValidatorFn {
    return (control: UntypedFormControl) => {
        const arrayLength = Array.isArray(control.value) ? control.value.length : 0;

        if (arrayLength > maxLength) {
            return { matchPattern: { msg } };
        }

        return null;
    };
}

import { Injectable } from '@angular/core';

import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    constructor(private toastController: ToastController) {}

    async showMessage(
        message: string,
        duration = 2500,
        position: 'bottom' | 'top' | 'middle' = 'bottom',
        cssClass = '',
        buttons = []
    ): Promise<{ data?: any; role?: string }> {
        const toast: HTMLIonToastElement = await this.toastController.create({
            message,
            duration,
            position,
            cssClass,
            buttons
        });

        await toast.present();

        return toast.onDidDismiss();
    }

    async dismissActiveMessage(): Promise<void> {
        const active: HTMLIonToastElement = await this.toastController.getTop();

        if (active) {
            await active.dismiss();
        }
    }
}

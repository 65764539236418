import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { lastValueFrom } from 'rxjs';

import cloneDeep from 'lodash/cloneDeep';

import { RollbarErrorHandler } from '@core/handlers/rollbar-error-handler';
import { AuthService } from '@core/services/auth.service';
import { FilesService } from '@core/services/files.service';
import { OfflineStorageService } from '@core/services/offline/offline-storage.service';
import { SYNCHRONIZE_APPOINTMENTS } from '@core/services/offline/queries/offline.queries';
import { StorageService } from '@core/services/storage.service';
import { GRID_IMAGE_URL } from '@shared/constants/grid-image-url';
import { OfflineTableName } from '@shared/enums/offline-table-name';
import { ResourceType } from '@shared/enums/resource-type.enum';

declare let moment: any;

@Injectable({
    providedIn: 'root'
})
export class OfflineSyncService {
    constructor(
        private apollo: Apollo,
        private authService: AuthService,
        private fileService: FilesService,
        private offlineStorageService: OfflineStorageService,
        private rollbarErrorHandler: RollbarErrorHandler,
        private storageService: StorageService
    ) {}

    async syncAppointments(background = true): Promise<void> {
        const user = cloneDeep(this.authService.getUser());

        if (!user) {
            this.rollbarErrorHandler.handleInfo('syncAppointments - user does not exist');

            return;
        }

        const newSyncTime = moment.utc().format('YYYY-MM-DD HH:mm:ss');
        const lastSyncData = await this.offlineStorageService.findOne(
            `SELECT *
             FROM ${OfflineTableName.OfflineSyncs}
             WHERE user_id = '${user.id}'
               AND office_id = '${user.office.id}'`,
            OfflineTableName.OfflineSyncs
        );
        let response: ApolloQueryResult<any>;

        try {
            response = await lastValueFrom(
                this.apollo.query({
                    query: SYNCHRONIZE_APPOINTMENTS,
                    variables: {
                        synchronized_at: lastSyncData?.synchronized_at,
                        last_schedule_time: lastSyncData?.last_schedule_time
                    },
                    context: {
                        extensions: {
                            background
                        }
                    }
                })
            );
        } catch (e) {
            this.logError('syncAppointments', e);
        }

        if (response.data?.synchronizeAppointments?.appointments?.length) {
            await this.saveAppointments(response.data.synchronizeAppointments.appointments, user);

            const lastAppointment = await this.offlineStorageService.findOne(
                `SELECT MAX(schedule_time_timestamp) as max_schedule_time, schedule_time
                 FROM ${OfflineTableName.Appointments}
                 WHERE seller_id = '${user.id}'
                   AND office_id = '${user.office.id}'`,
                OfflineTableName.Appointments
            );

            await this.offlineStorageService.insertOne(OfflineTableName.OfflineSyncs, {
                user_id: user.id,
                office_id: user.office.id,
                synchronized_at: newSyncTime,
                last_schedule_time: lastAppointment?.schedule_time
            });
        }

        if (response.data?.myNeedsAssessments) {
            await this.saveNeedsAssessments(response.data.myNeedsAssessments, user);
        }

        if (response.data?.commonItems) {
            await this.saveCommonItems(response.data.commonItems);
        }

        if (response.data?.myUserPreferences) {
            await this.saveUserPreferences(response.data.myUserPreferences, user);
        }

        if (response.data?.offlineCategories) {
            await this.saveCategories(response.data.offlineCategories, user);
        }

        if (response.data?.myDemoResources) {
            await this.saveDemoResources(response.data.myDemoResources, user);
        }

        if (response.data?.synchronizeAppointments?.date_range) {
            this.cleanupAppointments(
                response.data.synchronizeAppointments.date_range.from_timestamp,
                response.data.synchronizeAppointments.date_range.to_timestamp,
                user
            );
        }

        const localGridImageUrl: string = await this.fileService
            .saveResourceLocally(GRID_IMAGE_URL, 'images/')
            .catch(() => '');

        window.localStorage.setItem('localGridImageUrl', localGridImageUrl);

        const logoUrl: string = await this.storageService.get('logo_url');

        if (logoUrl.startsWith('http')) {
            const logoPath: string = await this.fileService.saveResourceLocally(logoUrl, 'images/').catch(() => '');

            window.localStorage.setItem(`logo_user_id_${user.id}`, logoPath);
        }
    }

    private async saveAppointments(appointments: any[], user: any): Promise<void> {
        for (const appointmentIndex in appointments) {
            const appointment = appointments[appointmentIndex];

            appointment.schedule_time_timestamp = moment.utc(appointment.schedule_time).unix();
            appointment.office_id = user.office.id;

            if (appointment.house.properties.hasOwnProperty('address')) {
                appointment.house.properties = {
                    address: appointment.house.properties.address
                };
            }
        }

        try {
            await this.offlineStorageService.insertBatch(OfflineTableName.Appointments, appointments);
        } catch (e) {
            this.logError('saveAppointments', e);
        }
    }

    private async saveNeedsAssessments(needsAssessments: any[], user: any): Promise<void> {
        await this.offlineStorageService.insertOne(OfflineTableName.NeedsAssessments, {
            user_id: user.id,
            office_id: user.office.id,
            needs_assessment: needsAssessments
        });
    }

    private async saveCommonItems(commonItems: any): Promise<void> {
        await this.offlineStorageService.insertBatch(OfflineTableName.Common, [
            {
                id: 'customer_phone_number_tags',
                value: commonItems.customer_phone_number_tags
            },
            {
                id: 'customer_email_tags',
                value: commonItems.customer_email_tags
            }
        ]);
    }

    private async saveUserPreferences(preferences: any[], user: any): Promise<void> {
        await this.offlineStorageService.insertOne(OfflineTableName.UserPreferences, {
            user_id: user.id,
            office_id: user.office.id,
            preferences
        });
    }

    private async saveCategories(categories: any[], user: any): Promise<void> {
        categories.forEach((category, index) => {
            category.user_id = user.id;
            category.office_id = user.office.id;
            category.position = index;
        });

        try {
            await this.offlineStorageService.insertBatch(OfflineTableName.Categories, categories);
        } catch (e) {
            this.logError('saveCategories', e);
        }
    }

    private async saveDemoResources(demoResources: any[], user: any): Promise<void> {
        const myDemoResources = [];
        const allowTypes: ResourceType[] = [ResourceType.Pdf, ResourceType.Video, ResourceType.Ingage];

        demoResources.forEach((resource) => {
            if (!allowTypes.includes(resource.resource_type)) {
                return;
            }

            resource.user_id = user.id;
            resource.office_id = user.office.id;

            myDemoResources.push(resource);
        });

        if (myDemoResources.length) {
            for (const resource of myDemoResources) {
                if (resource.badge.src.startsWith('https')) {
                    try {
                        resource.badge.src = await this.fileService.saveResourceLocally(resource.badge.src, 'badges/');
                    } catch (e) {
                        this.logError(`saveResourceLocally Badge ${JSON.stringify(resource)}`, e);
                    }
                }

                if (resource.resource_type === ResourceType.Ingage) {
                    continue;
                }

                try {
                    let fileName: string;

                    if (resource.resource_type === ResourceType.Pdf) {
                        const filePathOnly: string = new URL(resource.resource_src).pathname;
                        const paths: string[] = filePathOnly.split('/');

                        fileName = paths.slice(-2).join('-');
                    }

                    resource.resource_src = await this.fileService.saveResourceLocally(
                        resource.resource_src,
                        'demo_resources/',
                        fileName
                    );
                } catch (e) {
                    this.logError(`saveResourceLocally ${JSON.stringify(resource)}`, e);
                }

                if (resource.resource_type === ResourceType.Pdf) {
                    try {
                        await this.fileService.saveAndReplaceLinksInHTML(resource.resource_src);
                    } catch (e) {
                        this.logError(`saveAndReplaceLinksInHTML ${JSON.stringify(resource)}`, e);
                    }
                }
            }

            await this.offlineStorageService.insertBatch(OfflineTableName.DemoResources, myDemoResources);
        }

        this.cleanupDemoResources(myDemoResources, user);
    }

    private async cleanupDemoResources(resources: any[], user: any): Promise<void> {
        const savedResources = await this.offlineStorageService.read(
            `SELECT *
             FROM ${OfflineTableName.DemoResources}
             WHERE user_id = '${user.id}'
               AND office_id = '${user.office.id}'
            `,
            OfflineTableName.DemoResources
        );
        const deletedResourceIds: string[] = [];

        savedResources.forEach(({ id, resource_src, resource_type }) => {
            if (!resources.find((resource) => resource.id == id)) {
                deletedResourceIds.push(id);

                if (resource_type !== ResourceType.Ingage) {
                    this.fileService.deleteFile(resource_src).catch();
                }
            }
        });

        await this.offlineStorageService.deleteRecords(
            `
                SELECT *
                FROM DemoResources
                WHERE user_id = '${user.id}'
                  AND office_id = '${user.office.id}'
                  AND id IN (${deletedResourceIds.join(', ')})
            `
        );
    }

    private async cleanupAppointments(dateFrom: number, dateTo: number, user: any): Promise<void> {
        await this.offlineStorageService.deleteRecords(
            `
                SELECT *
                FROM Appointments
                WHERE seller_id = '${user.id}'
                  AND (schedule_time_timestamp >= '${dateTo}'
                    OR schedule_time_timestamp < '${dateFrom}')
            `
        );
    }

    private logError(message: string, e: any): void {
        const text = `${message} - Error - ${JSON.stringify(e)}`;

        this.rollbarErrorHandler.handleInfo(text);
        // eslint-disable-next-line no-console
        console.log(text);
        console.error(e);
    }
}

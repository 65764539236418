import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { ModalController } from '@ionic/angular';

import { forkJoin, from, merge, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import round from 'lodash/round';
import uniq from 'lodash/uniq';

import { PermissionsService } from '@core/services/permissions.service';
import { StepsService } from '@core/services/steps.service';
import { StorageService } from '@core/services/storage.service';
import { UserPreferencesService } from '@core/services/user-preferences.service';
import { maxQuantity } from '@shared/constants/form-validation-constants';
import { PERMISSIONS } from '@shared/constants/permissions';
import { AdderAmountType } from '@shared/enums/adder-amount-type.enum';
import { AdderAppliedType, AdderType } from '@shared/enums/adder-type.enum';
import { AppointmentType } from '@shared/enums/appointment-type';
import { ConfigurationAdderCreatedFromType } from '@shared/enums/configuration-adder-created-from-type';
import { ConfigurationType } from '@shared/enums/configuration-type.enum';
import { SalesStepRequiredViewing, SaleStepType } from '@shared/enums/sale-step-type.enum';
import { UserPreference } from '@shared/enums/user-preference.enum';
import { Unsubscriber } from '@shared/helpers/unsubscriber';
import { Appointment } from '@shared/interfaces/appointment';
import { Dictionary } from '@shared/interfaces/dictionary';
import { AddersModalComponent } from '@shared/modals/adders-modal/adders-modal.component';
import { CreateAdderModalComponent } from '@shared/modals/create-adder-modal/create-adder-modal.component';

import { ConfirmationModalComponent } from '../../../components/confirmation-modal/confirmation-modal.component';
import { ConfigureService } from '../../../main/appointments/configure/services/configure.service';

declare let moment: any;

@Component({
    selector: 'vendo-project-adders',
    templateUrl: './project-adders.component.html',
    styleUrls: ['./project-adders.component.scss']
})
export class ProjectAddersComponent extends Unsubscriber implements OnInit, OnChanges {
    @Input() quotesWithAdders: any[] = [];
    @Input() appointment: Appointment;
    @Input() packageNames: string[] = [];
    @Input() countOfAdders: number;
    @Input() isTabVisited: boolean;
    @Input() configureType: ConfigurationType;
    @Input() adderNameSetting: Dictionary;

    @Output() countOfAddersChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() renamePackage: EventEmitter<number> = new EventEmitter<number>();
    @Output() newPackage: EventEmitter<void> = new EventEmitter<void>();
    @Output() isTabVisitedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    mappedAdders: any[] = [];
    readonly isCanViewPrice: boolean = this.permissionsService.hasPermissions(PERMISSIONS.SECOND_MEASURE.VIEW_PRICE);
    showPrices = false;
    form: UntypedFormGroup;

    isShowDetails: { [key: number]: boolean } = {};
    readonly adderTypes: typeof AdderType = AdderType;
    readonly adderAppliedTypes: typeof AdderAppliedType = AdderAppliedType;
    readonly amountTypes: typeof AdderAmountType = AdderAmountType;
    readonly configurationAdderCreatedFromTypes: typeof ConfigurationAdderCreatedFromType =
        ConfigurationAdderCreatedFromType;
    readonly configureTypes: typeof ConfigurationType = ConfigurationType;

    constructor(
        private configureService: ConfigureService,
        private formBuilder: UntypedFormBuilder,
        private modalController: ModalController,
        private permissionsService: PermissionsService,
        private stepsService: StepsService,
        private storageService: StorageService,
        private userPreferenceService: UserPreferencesService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.quotesWithAdders && get(changes, 'quotesWithAdders.currentValue')) {
            this.reInitTable();
        }
    }

    ngOnInit(): void {
        if (this.appointment.type !== AppointmentType.SecondMeasure || this.isCanViewPrice) {
            this.initShowPrices();

            this.userPreferenceService
                .getUserPreferenceByType(UserPreference.ShowPricing)
                .pipe(takeUntil(this.destroy$))
                .subscribe((preference) => (this.showPrices = preference.value));
        }

        if (!this.isTabVisited && this.appointment.type === AppointmentType.Sales) {
            forkJoin([
                this.stepsService.childVisitStep(
                    this.appointment.id,
                    SaleStepType.CONFIGURE,
                    this.quotesWithAdders[0].id,
                    {
                        type: SalesStepRequiredViewing.ProjectAdders,
                        started_at: new Date()
                    }
                ),
                from(this.storageService.get('activeAppointment'))
            ]).subscribe(([_, appointment]: [any, Appointment]) => {
                this.isTabVisited = true;
                this.isTabVisitedChange.emit(this.isTabVisited);
                const configureStep = appointment.activities.find(
                    (activity) => activity.hash === SaleStepType.CONFIGURE
                );

                configureStep.view_history = configureStep.view_history || {};
                configureStep.view_history[SalesStepRequiredViewing.ProjectAdders] = {
                    started_at: moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                };

                this.storageService.set('activeAppointment', appointment);
            });
        }
    }

    preventClick(event): void {
        event.stopPropagation();
    }

    toggleDetails(adder): void {
        if (this.quotesWithAdders.length > 1) {
            this.isShowDetails[adder.id] = !this.isShowDetails[adder.id];
        }
    }

    addNewPackage(): void {
        this.newPackage.emit();
    }

    rename(event: Event, index): void {
        event.stopPropagation();

        this.renamePackage.emit(index);
    }

    showAddersList(): void {
        this.configureService.getAvailableAdders('project').subscribe(async (addersCategories) => {
            const modal: HTMLIonModalElement = await this.modalController.create({
                component: AddersModalComponent,
                componentProps: {
                    addersCategories,
                    selectedAdders: this.mappedAdders.filter(
                        (adder) => adder.created_from !== ConfigurationAdderCreatedFromType.Wcp
                    ),
                    countOfColumns: this.quotesWithAdders.length,
                    packageNames: this.packageNames,
                    isProjectAdders: true,
                    isShowPrice: this.showPrices
                },
                cssClass: 'adders-modal'
            });

            await modal.present();
            const { data } = await modal.onWillDismiss();

            if (data) {
                this.saveAdders(data.adderIds, data.addersConfigs);
            }
        });
    }

    async openAdderModal(adder?): Promise<any> {
        const modal: HTMLIonModalElement = await this.modalController.create({
            component: CreateAdderModalComponent,
            componentProps: {
                adder,
                countOfPackages: this.quotesWithAdders.length,
                packageNames: this.packageNames,
                isProjectAdder: true
            },
            cssClass: 'create-adder-modal',
            backdropDismiss: false
        });

        await modal.present();

        const { data } = await modal.onWillDismiss();

        if (data) {
            const { selectedIds, configs } = this.getSelectedAdders(
                undefined,
                data.slice(0, this.quotesWithAdders.length),
                !!adder
            );

            this.saveAdders(selectedIds, configs);
        }
    }

    removeAdder(adderId): void {
        const { selectedIds, configs } = this.getSelectedAdders(adderId);

        this.saveAdders(selectedIds, configs);
    }

    get addersForm(): UntypedFormArray {
        return this.form.get('adders') as UntypedFormArray;
    }

    private initShowPrices(): void {
        this.userPreferenceService.showPricingEnabled().subscribe((res: boolean) => (this.showPrices = res));
    }

    private initForm(): void {
        this.destroy$ = new Subject<void>();
        this.form = this.formBuilder.group({
            adders: this.formBuilder.array(
                this.mappedAdders.map((adder) => {
                    return this.formBuilder.group({
                        packages: this.formBuilder.array(
                            adder.packages.map((value: boolean) =>
                                this.formBuilder.control({
                                    value,
                                    disabled:
                                        adder.created_from === ConfigurationAdderCreatedFromType.Wcp ||
                                        adder.applied_type === AdderAppliedType.Auto
                                })
                            )
                        ),
                        quantity: this.formBuilder.control(
                            {
                                value: adder.quantity,
                                disabled:
                                    adder.created_from === ConfigurationAdderCreatedFromType.Wcp ||
                                    adder.applied_type === AdderAppliedType.Auto
                            },
                            { validators: [Validators.min(1), Validators.max(maxQuantity)], updateOn: 'blur' }
                        )
                    });
                })
            )
        });

        merge(
            ...this.addersForm.controls.map((group: AbstractControl, index: number) =>
                this.handleQuantityControl(group as UntypedFormGroup, index)
            )
        )
            .pipe(takeUntil(this.destroy$))
            .subscribe(({ value, rowIndex }) => this.changeAdderQuantity(value, rowIndex));

        merge(
            ...this.addersForm.controls.map((group: AbstractControl, index: number) =>
                this.handlePackagesForm(group as UntypedFormGroup, index)
            )
        )
            .pipe(takeUntil(this.destroy$))
            .subscribe(async ({ checked, rowIndex, colIndex }) => {
                const packagesForm: UntypedFormArray = this.addersForm.at(rowIndex).get('packages') as UntypedFormArray;
                const packages: boolean[] = packagesForm.getRawValue();
                const isLastUnchecked: boolean =
                    !checked &&
                    packages
                        .filter((value: boolean, index: number) => index !== colIndex)
                        .every((value: boolean) => !value);

                if (isLastUnchecked && this.quotesWithAdders.length > 1) {
                    const modal = await this.modalController.create({
                        component: ConfirmationModalComponent,
                        componentProps: {
                            headerText: 'Warning',
                            confirmButtonName: 'Proceed',
                            message: `You have deselected this ${this.adderNameSetting.single} from all packages. If you continue, this will delete this ${this.adderNameSetting.single} from the project. Would you like to continue?`
                        }
                    });

                    await modal.present();

                    const { data } = await modal.onWillDismiss();

                    if (data) {
                        const { selectedIds, configs } = this.getSelectedAdders();

                        this.saveAddersForOneQuote(
                            this.quotesWithAdders[colIndex],
                            selectedIds[colIndex],
                            configs[colIndex]
                        );
                    } else {
                        packagesForm.at(colIndex).setValue(true, { emitEvent: false });
                    }
                } else {
                    const { selectedIds, configs } = this.getSelectedAdders();

                    this.saveAddersForOneQuote(
                        this.quotesWithAdders[colIndex],
                        selectedIds[colIndex],
                        configs[colIndex]
                    );
                }
            });
    }

    private handlePackagesForm(group: UntypedFormGroup, rowIndex: number): Observable<any> {
        return merge(
            ...(group.get('packages') as UntypedFormArray).controls.map((control: AbstractControl, colIndex: number) =>
                control.valueChanges.pipe(map((checked) => ({ checked, rowIndex, colIndex })))
            )
        );
    }

    private handleQuantityControl(group: UntypedFormGroup, rowIndex: number): Observable<any> {
        const control: AbstractControl = group.get('quantity');

        return control.valueChanges.pipe(
            filter(() => {
                if (control.valid) {
                    return true;
                } else {
                    control.setValue(this.mappedAdders[rowIndex].quantity, { emitEvent: false });
                }
            }),
            map((value: number) => ({ value, rowIndex }))
        );
    }

    private getSelectedAdders(
        excludedId?: string,
        customIds?: Array<string[]>,
        isEditCustomAdder?: boolean
    ): { selectedIds: Array<number[]>; configs: any[] } {
        const selectedIds: Array<number[]> = this.quotesWithAdders.map(() => []);
        const configs: any[] = this.quotesWithAdders.map(() => []);
        const addersForms: UntypedFormGroup[] = this.addersForm.controls as UntypedFormGroup[];
        const customId: string =
            customIds &&
            Array.isArray(customIds) &&
            customIds.map((ids: string[]) => ids[0]).find((id: string) => !!id);
        let countOfExcludedAdders = 0;

        addersForms.forEach((groupControl: UntypedFormGroup, index: number) => {
            const adder: any = this.mappedAdders[index];

            if (
                adder.created_from === ConfigurationAdderCreatedFromType.Wcp ||
                (excludedId && adder.id === excludedId)
            ) {
                countOfExcludedAdders++;

                return;
            }

            (groupControl.get('packages') as UntypedFormArray).controls.forEach(
                (control: AbstractControl, colIndex: number) => {
                    if (isEditCustomAdder && customId && Number(adder.id) === Number(customId)) {
                        customIds[colIndex].forEach((adder_id: string, rowIndex: number) => {
                            selectedIds[colIndex].push(Number(adder_id));
                            configs[colIndex].push(
                                ...customIds[colIndex].map(() => ({
                                    adder_id,
                                    position: addersForms.length
                                        ? this.mappedAdders.length - countOfExcludedAdders + rowIndex
                                        : rowIndex
                                }))
                            );
                        });

                        return;
                    }

                    if (control.value) {
                        selectedIds[colIndex].push(Number(adder.id));
                        configs[colIndex].push({
                            adder_id: adder.id,
                            ...(adder.variable && { amount: adder.amount }),
                            position: index - countOfExcludedAdders
                        });
                    }
                }
            );
        });

        if (!isEditCustomAdder && customIds && Array.isArray(customIds)) {
            customIds.forEach((ids: string[], colIndex: number) => {
                ids.forEach((adder_id: string, index: number) => {
                    selectedIds[colIndex].push(Number(adder_id));
                    configs[colIndex].push(
                        ...ids.map(() => ({
                            adder_id,
                            position: addersForms.length
                                ? this.mappedAdders.length - countOfExcludedAdders + index
                                : index
                        }))
                    );
                });
            });
        }

        return { selectedIds, configs };
    }

    private mapProjectAdders(): void {
        this.mappedAdders = [];
        const quotesWithAdders = cloneDeep(this.quotesWithAdders);
        const quoteIds = this.quotesWithAdders.map((quote) => quote.id);
        const keys: any = {};
        const ids = [];
        const mappedAdders = [];

        quotesWithAdders.forEach((quote, index: number) => {
            quote.config_adders.forEach((adder) => {
                if (typeof keys[adder.id] !== 'undefined') {
                    mappedAdders[keys[adder.id]].packages[index] = true;
                    mappedAdders[keys[adder.id]].amounts[index] = adder.amount;

                    if (adder.applied_type === AdderAppliedType.Auto) {
                        mappedAdders[keys[adder.id]].applied_type = adder.applied_type;
                        mappedAdders[keys[adder.id]].position = adder.position;
                    }
                } else {
                    keys[adder.id] = mappedAdders.length;
                    let newPosition: number;

                    if (!ids[adder.position]) {
                        ids[adder.position] = adder.id;
                    } else if (ids[adder.position] !== adder.id) {
                        newPosition = adder.position;
                        while (ids[newPosition]) {
                            newPosition++;
                        }
                        ids[newPosition] = adder.id;
                    }
                    mappedAdders.push({
                        ...adder,
                        ...(newPosition && { position: newPosition }),
                        quoteIds: [...quoteIds],
                        packages: new Array(this.quotesWithAdders.length)
                            .fill(false)
                            .map((value, i: number) => i === index),
                        amounts: new Array(this.quotesWithAdders.length)
                            .fill(null)
                            .map((value, i: number) => (i === index ? adder.amount : null))
                    });
                }
            });
        });

        const charges = [];
        const adders = [];

        mappedAdders.forEach((adder) => {
            if (uniq(adder.amounts.filter((val: number) => val !== null)).length === 1) {
                adder.amounts = [];
            }

            if (adder.created_from === ConfigurationAdderCreatedFromType.Wcp) {
                charges.push(adder);
            } else {
                adders.push(adder);
            }
        });
        this.mappedAdders = [
            ...charges,
            ...adders
                .sort((a, b) => (a.position > b.position ? 1 : a.position < b.position ? -1 : 0))
                .filter((adder) => adder.visible)
        ];

        this.countOfAddersChange.emit(this.mappedAdders.length);
    }

    private saveAdders(adderIds: Array<number[]>, configs: any[]): void {
        if (this.quotesWithAdders.length > 1) {
            this.saveAddersForMultiQuote(adderIds, configs);
        } else {
            this.saveAddersForOneQuote(this.quotesWithAdders[0], adderIds[0], configs[0]);
        }
    }

    private saveAddersForMultiQuote(selected: Array<number[]>, adders_config: Array<any[]>): void {
        const input: any[] = this.quotesWithAdders.map((quote, index: number) => ({
            adders: selected[index],
            adders_config: adders_config[index],
            applies_to: 'project',
            quote_id: quote.id
        }));

        this.configureService.saveMultiAdders(input).subscribe((res: any[]) => {
            this.quotesWithAdders.forEach((quote, index: number) => (quote.config_adders = res[index].adders));
            this.reInitTable();
        });
    }

    private saveAddersForOneQuote(quote: any, adderIds: number[], addersConfigs: any[]): void {
        this.configureService.saveAdders(quote.id, adderIds, addersConfigs, 'project').subscribe((res) => {
            quote.config_adders = res;
            this.reInitTable();
        });
    }

    private reInitTable(): void {
        this.mapProjectAdders();
        this.destroy$.next();
        this.destroy$.complete();
        this.initForm();
    }

    private changeAdderQuantity(qty: number, rowIndex: number): void {
        qty = round(qty);

        const adderId: number = parseInt(this.mappedAdders[rowIndex].id, 10);

        this.configureService.changeProjectAdderQuantity(this.appointment.id, adderId, qty).subscribe(() => {
            this.mappedAdders[rowIndex].quantity = qty;

            this.addersForm.at(rowIndex).get('quantity').setValue(qty, { emitEvent: false });

            this.quotesWithAdders.forEach((quote) => {
                const foundAdder = quote.config_adders.find((adder) => parseInt(adder.id, 10) === adderId);

                if (foundAdder) {
                    foundAdder.quantity = qty;
                }
            });
        });
    }
}

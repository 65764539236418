<ng-container *ngIf="form" [formGroup]="form">
    <div class="rules"
         formArrayName="rules">
        <div class="rule-row"
             *ngFor="let ruleGroup of rulesFormArray.controls; let i = index">
            <vendo-rule-row class="w-100"
                            [initialRequiredValues]="i < initialRequiredValues.length ? initialRequiredValues[i] : null"
                            [isHandleDisablingDependsOn]="isHandleDisablingDependsOn"
                            [fieldsConfigs]="config"
                            [formControlName]="i"
                            (blur)="markAsTouched()"></vendo-rule-row>

            <ion-button class="icon-only"
                        *ngIf="(!initialRequiredValues || i >= initialRequiredValues.length) && addButtonName && isCanAddOrEdit"
                        fill="clear"
                        [disabled]="form.disabled"
                        (click)="removeRow(i)">
                <i slot="icon-only" class="material-icons">clear</i>
            </ion-button>
        </div>
    </div>

    <div *ngFor="let error of getMessages(form.get(['rules'])?.errors)"
         class="general-error"
         [class.mt-20]="!rulesFormArray.controls.length">
        {{ error }}
    </div>

    <div class="button-wrapper" [ngClass]="buttonParams?.position">
        <ion-button *ngIf="addButtonName && isCanAddOrEdit"
                    [ngStyle]="{ 'width': buttonParams ? buttonParams.width + 'px' : '' }"
                    color="primary"
                    class="w-100 mt-20"
                    [disabled]="form.disabled || rulesFormArray.controls.length >= maxRows"
                    (click)="addRow()">
            <ion-icon name="add"></ion-icon>
            <span>{{ addButtonName }}</span>
        </ion-button>
    </div>
</ng-container>

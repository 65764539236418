import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'vendo-start-customer-review',
    templateUrl: './start-customer-review.component.html',
    styleUrls: ['./start-customer-review.component.scss']
})
export class StartCustomerReviewComponent {
    @Input() activeSigner: any;
    @Output() proceed = new EventEmitter<void>();
    @Output() cancel = new EventEmitter<void>();
}

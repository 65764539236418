import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { ModalController } from '@ionic/angular';

import { BaseModal } from '@shared/modals/base-modal';

@Component({
    selector: 'vendo-list-items-modal',
    templateUrl: './list-items-modal.component.html',
    styleUrls: ['./list-items-modal.component.scss']
})
export class ListItemsModalComponent extends BaseModal implements OnInit {
    @Input() isMultiSelect: boolean;
    @Input() title: string;
    @Input() applyButtonName = 'Apply';
    @Input() items: any[] = [];
    @Input() valueProperty = 'id';
    @Input() subNameProperty: string;
    form: UntypedFormGroup;

    constructor(
        private formBuilder: UntypedFormBuilder,
        public modalController: ModalController
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            ...(this.isMultiSelect
                ? {
                      items: this.formBuilder.array(
                          this.items.map(({ isSelected }) => this.formBuilder.control(isSelected))
                      )
                  }
                : {
                      item: [null, Validators.required]
                  })
        });
    }

    apply(): void {
        if (this.form.invalid) {
            return;
        }

        if (this.isMultiSelect) {
            const values: boolean[] = this.form.get('items').value;

            values.forEach((value: boolean, index: number) => {
                this.items[index].isSelected = value;
            });
            this.dismiss(this.items);
        } else {
            const value = this.form.get('item').value;
            const result = this.items.find(({ id }) => id === value);

            this.dismiss(result);
        }
    }
}

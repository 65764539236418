import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

import { InfiniteScrollCustomEvent, IonContent, IonInfiniteScroll, ModalController } from '@ionic/angular';

import { finalize, tap } from 'rxjs/operators';

import cloneDeep from 'lodash/cloneDeep';

import { DeviceHelperService } from '@core/services/device-helper.service';
import { OrderDirection } from '@shared/enums/order-direction.enum';
import { PaginationItems } from '@shared/interfaces/pagination-items';
import { BaseModal } from '@shared/modals/base-modal';
import { ColSetting } from '@shared/modals/radio-group-list-modal/radio-group-list-modal.interface';

@Component({
    selector: 'vendo-radio-group-list-modal',
    templateUrl: './radio-group-list-modal.component.html',
    styleUrls: ['./radio-group-list-modal.component.scss']
})
export class RadioGroupListModalComponent<T extends PaginationItems>
    extends BaseModal
    implements OnInit, AfterViewInit
{
    @Input() headerText = 'Select one item from the list';
    @Input() placeholder: string;
    @Input() showCancelButton = true;
    @Input() cancelButtonName = 'Cancel';
    @Input() confirmButtonName = 'Confirm';
    @Input() cols: ColSetting[] = [];
    @Input() paginatedItems: T;
    @Input() selectedValueObj: any;
    @Input() requestPaginationData: any;
    @Input() pageSize: number;
    @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
    @ViewChild(IonContent) infiniteContent: IonContent;
    items: any[] = [];
    selectedValue = '';
    searchInput = '';
    enableInfinityScroll: boolean;
    orderingColumn: string;
    orderDirections: typeof OrderDirection = OrderDirection;
    orderingOrientation: OrderDirection;
    isDesktop: boolean;
    private pageIndex = 0;

    constructor(
        private deviceHelper: DeviceHelperService,
        modalController: ModalController
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.isDesktop = this.deviceHelper.isDesktopWeb;
        this.items = cloneDeep(this.paginatedItems.entities);
        this.enableInfinityScroll = this.items.length === this.pageSize;

        if (this.selectedValueObj) {
            this.setSelectedValue(this.selectedValueObj);
        }
    }

    ngAfterViewInit(): void {
        this.prepareList(this.items.length);
    }

    setSelectedValue(item: any, modelCol?: ColSetting): void {
        if (!modelCol) {
            modelCol = this.cols.find(({ isModelInput }: ColSetting) => isModelInput);
        }

        this.selectedValueObj = item || null;
        this.selectedValue = item ? item[modelCol.value] : '';
    }

    confirm(): void {
        this.dismiss(this.selectedValueObj);
    }

    async handleSearch(): Promise<void> {
        this.pageIndex = 0;
        this.getData();
    }

    loadNextPage(event): void {
        if (!this.enableInfinityScroll) {
            event.target.complete();
            event.target.disabled = true;

            return;
        }

        this.pageIndex++;
        this.getData(event);
    }

    async order(col: ColSetting): Promise<void> {
        if (!this.items.length || !col.orderBy) {
            return;
        }

        if (this.orderingColumn !== col.orderBy) {
            this.orderingColumn = col.orderBy;
            this.orderingOrientation = OrderDirection.Asc;
        } else if (this.orderingOrientation === OrderDirection.Asc) {
            this.orderingOrientation = OrderDirection.Desc;
        } else {
            delete this.orderingColumn;
            delete this.orderingOrientation;
        }

        this.pageIndex = 0;
        this.getData();
    }

    private getData(event?: InfiniteScrollCustomEvent): void {
        this.requestPaginationData(
            this.searchInput,
            this.pageSize,
            this.pageSize * this.pageIndex,
            this.orderingColumn,
            this.orderingOrientation
        )
            .pipe(
                tap((data: any[]) => {
                    this.enableInfinityScroll = data.length === this.pageSize;
                }),
                finalize(() => event?.target?.complete())
            )
            .subscribe((data: any[]) => {
                if (event) {
                    this.items.push(...data);
                } else {
                    this.items = data;
                }

                this.prepareList(data.length);
            });
    }

    private prepareList(itemsCount: number): void {
        this.infiniteScroll.disabled = !this.enableInfinityScroll;

        if (itemsCount && !this.selectedValueObj && !this.selectedValue) {
            const modelCol: ColSetting = this.cols.find(({ isModelInput }: ColSetting) => isModelInput);
            const item = this.items.find((item) => item[modelCol.selectedValueKey]);

            this.setSelectedValue(item, modelCol);
        }
    }
}

<div class="tabs" #tabs="tqMHorizontalScroll" tqMHorizontalScroll (overflowed)="isShowNavButtons = $event">
    <div *ngFor="let step of steps"
         #stepElement
         class="tab-item"
         [class.d-none]="step.Name === 'Hidden' || step.Hidden"
         [class.active]="activeStep?.ID === step.ID"
         [class.is-disabled]="step.Disabled"
         [class.has-error]="step.StopType === 2"
         (click)="setActiveStep(step)">
        <ion-icon name="alert-circle" *ngIf="step.StopType === 2"></ion-icon>
        <div class="tab-name">
            {{ step.Name }}
        </div>
    </div>
</div>

<ng-container *ngIf="isShowNavButtons">
    <ion-button class="icon-only nav-btn"
                size="small"
                [disabled]="!tabs.canScrollStart"
                (click)="tabs.scrollLeft()"
                expand="full">
        <ion-icon name="chevron-back"></ion-icon>
    </ion-button>

    <ion-button class="icon-only nav-btn"
                size="small"
                [disabled]="!tabs.canScrollEnd"
                (click)="tabs.scrollRight()"
                expand="full">
        <ion-icon name="chevron-forward"></ion-icon>
    </ion-button>
</ng-container>

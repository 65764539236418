<div class="modal-header">
    <ion-text class="title">{{ title }}</ion-text>
</div>
<div class="modal-body">
    <ion-content>
        <form [formGroup]="form"
              class="modal-body__form"
              autocomplete="off">
            <ng-container>
                <div *ngFor="let field of fields" class="field-block"
                     [ngClass]="field.FieldType === 'Boolean' ? 'boolean' : null">
                    <ion-label class="label-text" [class.required]="field.Required === 1">
                        {{ field.DisplayText }}
                    </ion-label>

                    <ng-container [ngSwitch]="field.FieldType">
                        <ng-container *ngSwitchCase="'SinglePickList'">
                            <ion-select legacy="true"
                                        mode="md"
                                        interface="popover"
                                        *ngIf="!field.Hidden"
                                        [interfaceOptions]="{mode: 'md', alignment: 'start'}"
                                        [formControlName]="field.FieldId">
                                <ion-select-option *ngFor="let option of field.AvailableOptions"
                                                   [value]="option.ValueId">
                                    {{ option.ValueText }}
                                </ion-select-option>
                            </ion-select>
                        </ng-container>

                        <ng-container *ngSwitchCase="'Text'">
                            <ion-input legacy="true" *ngIf="!field.Hidden"
                                       [type]="field.FieldType"
                                       [formControlName]="field.FieldId"
                                       [placeholder]="field.DisplayText"
                                       autocomplete="off"></ion-input>
                        </ng-container>

                        <ng-container *ngSwitchCase="'Boolean'">
                            <ion-checkbox legacy="true" slot="start"
                                          *ngIf="!field.Hidden"
                                          [formControlName]="field.FieldId"
                                          mode="md"></ion-checkbox>
                        </ng-container>

                        <ng-container *ngSwitchCase="'Date'">
                            <div class="datepicker-field" [id]="field.ReadOnly ? null : 'open-from-' + field.FieldId">
                                <ion-input legacy="true" *ngIf="!field.Hidden"
                                           [value]="form.get(field.FieldId).value | localizedDate "
                                           readonly
                                           placeholder="{{field.DisplayText}}"></ion-input>
                                <ion-button fill="clear" slot="end" class="picker-btn" disabled="{{field.ReadOnly}}">
                                    <ion-icon name="calendar-outline"></ion-icon>
                                </ion-button>
                                <ion-popover class="popover-datepicker"
                                             [keepContentsMounted]="true"
                                             [trigger]="'open-from-' + field.FieldId"
                                             show-backdrop="false"
                                             mode="md">
                                    <ng-template>
                                        <ion-datetime #popoverDatetime
                                                      [value]="form.get(field.FieldId).value"
                                                      [showDefaultButtons]="true"
                                                      [showClearButton]="true"
                                                      [min]="field.MinDate"
                                                      [max]="field.MaxDate || maxDate"
                                                      hour-cycle="h12"
                                                      presentation="date"
                                                      mode="ios"
                                                      (ionChange)="setDateTime(field.FieldId, popoverDatetime.value)"></ion-datetime>
                                    </ng-template>
                                </ion-popover>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </form>
    </ion-content>
</div>
<div class="modal-footer">
    <ion-button fill="outline" (click)="closeModal()">
        Cancel
    </ion-button>
    <ion-button [disabled]="form.invalid" (click)="apply()">
        Save
    </ion-button>
</div>

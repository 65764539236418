import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter } from 'rxjs/operators';

import get from 'lodash/get';

import { ScriptService } from '@core/services/script.service';
import { IGNORED_ROUTES } from '@shared/constants/ignore-routes';
import { IntercomSettings } from '@shared/interfaces/intercom-settings';

import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class IntercomService {
    private isInitialized = false;
    private isVendoAppName = false;
    private settings: IntercomSettings = {
        api_base: 'https://api-iam.intercom.io',
        app_id: environment.intercomId,
        alignment: 'left',
        hide_default_launcher: false,
        vertical_padding: 90,
        horizontal_padding: 10
    };
    private windowRef: any;

    constructor(
        private router: Router,
        private scriptService: ScriptService
    ) {
        this.windowRef = window;

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd && this.isInitialized && this.isVendoAppName))
            .subscribe((event: NavigationEnd) => {
                this.settings.hide_default_launcher = this.isHideLauncher(event.urlAfterRedirects);
                this.update();
            });
    }

    init(): void {
        if (environment.stage && this.settings.app_id) {
            this.load(() => {
                this.settings.hide_default_launcher =
                    !this.settings.user_id || this.isHideLauncher(window.location.href);
                this.callIntercom('boot', this.settings);
                this.isInitialized = true;
            });
        }
    }

    isHideLauncher(route: string): boolean {
        return IGNORED_ROUTES.some((item) => route.includes(item));
    }

    setUser(user): void {
        this.isVendoAppName = get(user, 'organization.application_name', '') === 'Paradigm Vendo';
        this.settings.hide_default_launcher = !this.isVendoAppName;

        if (this.isVendoAppName) {
            this.settings.user_id = user.id;
            this.settings.name = `${user.first_name} ${user.last_name}`;
            this.settings.email = user.email;
        }
    }

    update(): void {
        this.callIntercom('update', this.settings);
    }

    clearUserData(): void {
        delete this.settings.user_id;
        delete this.settings.name;
        delete this.settings.email;
        delete this.settings.action_color;
        delete this.settings.background_color;
        // this.callIntercom('shutdown');
        this.callIntercom('update', this.settings);
    }

    private load(successLoadCallback: (ev?: Event) => any): void {
        const intercom = this.windowRef.Intercom;

        this.windowRef.intercomSettings = this.settings;

        if (typeof intercom === 'function') {
            intercom('reattach_activator');
            intercom('update', this.settings);
            successLoadCallback();
        } else {
            const i: any = function () {
                i.c(arguments);
            };

            i.q = [];
            i.c = function (args: any) {
                i.q.push(args);
            };
            this.windowRef.Intercom = i;

            this.scriptService.loadJsScript(
                `https://widget.intercom.io/widget/${this.settings.app_id}`,
                successLoadCallback
            );
            this.update();
        }
    }

    private callIntercom(fn: string, ...args): void {
        if (this.windowRef.Intercom) {
            this.windowRef.Intercom(fn, ...args);
        }
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable, of } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { DeviceHelperService } from '@core/services/device-helper.service';
import { CustomerDocumentDownload } from '@shared/interfaces/document';

import { EDocumentService } from '../../../e-document-v2/services/e-document.service';

@Component({
    selector: 'vendo-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
    @Input() name: string;
    @Input() url: string;
    private hash: string;
    private token: string;

    constructor(
        private deviceHelperService: DeviceHelperService,
        private documentService: EDocumentService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.hash = this.route.snapshot.paramMap.get('hash');
        this.token = this.route.snapshot.queryParamMap.get('token');
    }

    download(): void {
        this.downloadDocumentPdf().subscribe(({ url, name }) => this.deviceHelperService.download(url, name));
    }

    print(): void {
        this.downloadDocumentPdf().subscribe(({ url, name }) => this.deviceHelperService.printDocument(url, name));
    }

    private downloadDocumentPdf(): Observable<CustomerDocumentDownload> {
        if (this.url && this.name) {
            return of({ url: this.url, name: this.name });
        }

        return this.documentService.downloadDocumentPdf(this.hash, this.token).pipe(
            filter(({ errors, url }) => Boolean(url) || errors.valid),
            first()
        );
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { ModalController } from '@ionic/angular';

import { takeUntil } from 'rxjs/operators';

import { FormHelperService } from '@core/services/form-helper.service';
import { ToastService } from '@core/services/toast.service';
import { BaseModal } from '@shared/modals/base-modal';

@Component({
    selector: 'vendo-login-modal',
    templateUrl: './login-modal.component.html',
    styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent extends BaseModal implements OnInit {
    @Input() allowUseOfficeCredential: boolean;
    @Input() isAllowManualCredentials = true;
    @Input() integrationHash: string;
    form: UntypedFormGroup;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private formHelperService: FormHelperService,
        public modalController: ModalController,
        private toastService: ToastService
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            username: ['', this.isAllowManualCredentials ? Validators.required : null],
            password: ['', this.isAllowManualCredentials ? Validators.required : null],
            remember: false,
            use_office_credential: false
        });

        this.form
            .get('use_office_credential')
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: boolean) => {
                const rememberControl: AbstractControl = this.form.get('remember');

                if (value) {
                    if (this.isAllowManualCredentials) {
                        ['username', 'password'].forEach((controlName: string) => {
                            const control: AbstractControl = this.form.get(controlName);

                            control.clearValidators();
                            control.reset();
                            control.disable();
                        });
                    }
                    rememberControl.reset(false);
                    rememberControl.disable();
                } else {
                    if (this.isAllowManualCredentials) {
                        ['username', 'password'].forEach((controlName: string) => {
                            const control: AbstractControl = this.form.get(controlName);

                            control.setValidators(Validators.required);
                            control.enable();
                        });
                    }
                    rememberControl.enable();
                }
            });
    }

    getMessages(errors): string[] {
        return this.formHelperService.getMessages(errors);
    }

    login(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            this.toastService.showMessage('Please fill in all the required fields!');

            return;
        }

        this.dismiss(this.form.getRawValue());
    }
}

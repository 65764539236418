import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';

import Swiper from 'swiper';

import { SwiperService } from '@core/services/helpers/swiper.service';

@Component({
    selector: 'vendo-configurator-warnings',
    templateUrl: './configurator-warnings.component.html',
    styleUrls: ['./configurator-warnings.component.scss']
})
export class ConfiguratorWarningsComponent implements OnDestroy {
    @Input() set configurator(configurator) {
        if (configurator) {
            if (configurator.Limitations) {
                configurator.Limitations = configurator.Limitations.filter(
                    (item) => !item.IsHardStop || !item.SourceQuestions
                );

                const hiddenStepId = configurator.StepsInfo.find((step) => step.Name === 'Hidden')?.ID;

                if (hiddenStepId) {
                    configurator.Limitations.forEach(
                        (limitation) =>
                            (limitation.SourceQuestions = (limitation.SourceQuestions || []).filter(
                                (q) => q.StepId !== hiddenStepId
                            ))
                    );
                }
            }
            this.configuratorData = configurator;
            this.activeWarningSlideIndex = 0;
            this.initSwiper();
        }
    }
    @Output() warmingClicked: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('swiperErrorContainer', { static: false }) swiperContainer: ElementRef;

    activeWarningSlideIndex = 0;
    configuratorData;

    private swiperInstance: Swiper;

    constructor(private swiperService: SwiperService) {}

    ngOnDestroy(): void {
        if (this.swiperInstance) {
            this.swiperInstance.destroy(true, true);
        }
    }

    private initSwiper(): void {
        this.destroySwiper();

        setTimeout(() => {
            if (this.swiperContainer) {
                this.swiperInstance = this.swiperService.initSwiper(
                    this.swiperContainer.nativeElement,
                    false,
                    400,
                    null,
                    true,
                    '.next-error',
                    '.prev-error',
                    '.error-pagination'
                );

                this.swiperInstance.on(
                    'slideChange',
                    () => (this.activeWarningSlideIndex = this.swiperInstance.realIndex)
                );
            }
        }, 50);
    }

    private destroySwiper(): void {
        if (this.swiperInstance) {
            this.swiperInstance.destroy(true, true);
        }
    }
}

<div class="modal-header">
  <ion-text class="title">Monthly Investment</ion-text>
  <ion-button fill="outline" color="dark" (click)="closeModal()">
    <ion-icon slot="icon-only" name="close"></ion-icon>
  </ion-button>
</div>

<div class="modal-body">
  <div class="field-block">
    <ion-label class="required">Your monthly rate</ion-label>
    <ion-input legacy="true" [formControl]="control"
               type="number"
               inputmode="numeric"
               [min]="minValue"
               [max]="maxValue"
               step="10"
               required
               autocomplete="off"></ion-input>
    <div class="errors-wrapper" *ngIf="control.touched">
      <ion-text color="danger" class="error"
                *ngFor="let error of getMessages(control.errors)">
        {{ error }}
      </ion-text>
    </div>
  </div>
</div>

<div class="modal-footer">
  <ion-button class="classic-width outline" (click)="closeModal()">Cancel</ion-button>
  <ion-button class="classic-width" (click)="apply()" [disabled]="control.invalid">Save</ion-button>
</div>

<div class="modal-header">
  <ion-text class="title">Images</ion-text>
  <ion-button fill="outline" color="dark" [disabled]="uploadPhotos?.isStreamActive" (click)="close()">
    <ion-icon slot="icon-only" name="close"></ion-icon>
  </ion-button>
</div>
<div class="modal-body">
  <ng-container *ngIf="useTabs; else withoutTabs">
    <vendo-tabs [ngClass]="{ 'disabled': uploadPhotos?.isStreamActive }"
                [tabs]="imagesTabs"
                (tabSelected)="selectTab($event)"
                [activeTabId]="activeTabId"></vendo-tabs>

    <div class="opening-photos {{ activeTabId }}">
      <ng-container *ngIf="activeTabId === photoModalTabHashes.ProjectPhotos && tabsImages[activeTabId]">
        <vendo-upload-photos [photos]="tabsImages[activeTabId]"
                             [type]="appointmentImageType.Project"
                             [maxImages]="4"
                             #uploadPhotosComponent
                             (emitSave)="save($event, null)">
        </vendo-upload-photos>
      </ng-container>
      <ng-container *ngIf="activeTabId === photoModalTabHashes.Sales">
        <ng-container
          [ngTemplateOutlet]="isUseForOneOpening ? withOpening : withOpenings"
          [ngTemplateOutletContext]="{ isReadOnly: activeTabId === readOnlyTab }"
        >
        </ng-container>
      </ng-container>

      <ng-container *ngIf="activeTabId === photoModalTabHashes.SecondMeasure">
        <ng-container
          [ngTemplateOutlet]="isUseForOneOpening ? withOpening : withOpenings"
          [ngTemplateOutletContext]="{ isReadOnly: activeTabId === readOnlyTab }"
        >
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #withoutTabs>
  <vendo-upload-photos #uploadPhotosComponent
    [photos]="tabsImages[activeTabId]"
    [isUseTabs]="false"
    [type]="appointmentImageType.Custom"
    [openingId]="openingId"
    (emitSave)="save($event, openingId)">
  </vendo-upload-photos>
</ng-template>

<ng-template #withOpening let-isReadOnly="isReadOnly">
  <ng-container *ngIf="tabsImages[activeTabId]">
    <vendo-upload-photos #uploadPhotosComponent
      [photos]="tabsImages[activeTabId]"
      [openingId]="openingId"
      [type]="appointmentImageType.Custom"
      [isReadOnly]="isReadOnly"
      (emitSave)="save($event, openingId)">
    </vendo-upload-photos>
  </ng-container>
</ng-template>

<ng-template #withOpenings let-isReadOnly="isReadOnly">
  <ng-container *ngFor="let opening of tabsImages[activeTabId]; let i = index;">
    <div class="header-name" (click)="expandItem(i)" [ngClass]="{ 'disabled': uploadPhotos?.isStreamActive }">
      {{opening.name}}
      <ion-icon [name]="isExpanded[i] ? 'chevron-up-outline' : 'chevron-down-outline'"></ion-icon>
    </div>
    <div *ngIf="isExpanded[i]">
      <vendo-upload-photos #uploadPhotosComponent
        [photos]="opening.photos"
        [openingId]="opening.id"
        [type]="appointmentImageType.Custom"
        [isReadOnly]="isReadOnly"
        (emitSave)="save($event, opening.id)">
      </vendo-upload-photos>
    </div>
  </ng-container>
</ng-template>

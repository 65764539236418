import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ModalController, NavController } from '@ionic/angular';

import { switchMap } from 'rxjs/operators';

import cloneDeep from 'lodash/cloneDeep';

import { DeviceHelperService } from '@core/services/device-helper.service';
import { ToastService } from '@core/services/toast.service';
import { AppointmentFile } from '@shared/interfaces/appointment-file';
import { BaseModal } from '@shared/modals/base-modal';

import { AppointmentsService } from '../../../main/appointments/services/appointments.service';

@Component({
    selector: 'vendo-upload-files-modal',
    templateUrl: './upload-files-modal.component.html',
    styleUrls: ['./upload-files-modal.component.scss']
})
export class UploadFilesModalComponent extends BaseModal implements OnInit {
    @Input() appointmentId: string;
    @Input() filesList: AppointmentFile[];
    @ViewChild('fileUpload') fileInput: any;

    clonedFilesList: AppointmentFile[];
    isEditableFileLabel = [];
    isDisabledDeleteIcon = [];
    maxFileSize = 25 * 1024 * 1024;

    constructor(
        private appointmentsService: AppointmentsService,
        private deviceHelperService: DeviceHelperService,
        modalController: ModalController,
        private navController: NavController,
        protected route: ActivatedRoute,
        private toastService: ToastService
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.setClonedFiles();
    }

    closeModal(isCleanQueryParam = false): any {
        if (isCleanQueryParam && this.route.snapshot.queryParams.openUploads) {
            this.navController.navigateRoot([], {
                relativeTo: this.route,
                queryParams: {},
                replaceUrl: true
            });
        }

        super.closeModal();
    }

    setEditableFileLabel(status: boolean, index: number, isSave: boolean): void {
        if (this.isEditableFileLabel[index] === status) {
            return;
        }

        this.isEditableFileLabel[index] = status;
        this.filesList[index].name = this.filesList[index].name.trim();

        if (isSave && this.filesList[index].name !== '') {
            this.editAppointmentFile(index);
        }

        if (this.filesList[index].name === '') {
            this.filesList[index].name = this.clonedFilesList[index].name;
        }
    }

    async openFile(file: AppointmentFile): Promise<void> {
        await this.navController.navigateRoot([], {
            relativeTo: this.route,
            queryParams: { openUploads: true },
            replaceUrl: true
        });
        this.closeModal();
        this.deviceHelperService.openPdf(this.appointmentId, file.url, file.name);
    }

    async deleteFile(file: AppointmentFile, index: number): Promise<void> {
        this.isDisabledDeleteIcon[index] = true;
        const button = [
            {
                text: 'Undo',
                role: 'cancel'
            }
        ];

        const dismissDetail: { data?: any; role?: string } = await this.showToastMessage(
            `${file.name} has been deleted`,
            button
        );

        if (dismissDetail.role === 'cancel') {
            this.isDisabledDeleteIcon[index] = false;
        } else if (dismissDetail.role === 'timeout') {
            this.appointmentsService
                .deleteAppointmentFile(file.id, this.appointmentId)
                .pipe(switchMap(() => this.appointmentsService.getAppointmentFiles(this.appointmentId)))
                .subscribe((filesList: AppointmentFile[]) => {
                    this.filesList = filesList;
                    this.setClonedFiles();
                });
        }
    }

    async saveFile(event: any): Promise<void> {
        if (event) {
            const file = event.target.files[0];
            const button = [
                {
                    icon: 'close',
                    role: 'cancel'
                }
            ];

            if (file.type !== 'application/pdf') {
                await this.showToastMessage('File could not be uploaded: File type must be PDF', button);

                return;
            }

            if (file.size >= this.maxFileSize) {
                await this.showToastMessage('File could not be uploaded: Exceeds max file size of 25 MBs', button);

                return;
            }

            this.appointmentsService
                .createAppointmentFile(this.appointmentId, this.getFileName(file.name), file)
                .pipe(switchMap(() => this.appointmentsService.getAppointmentFiles(this.appointmentId)))
                .subscribe((filesList: AppointmentFile[]) => {
                    this.filesList = filesList;
                    this.setClonedFiles();
                });
        }
    }

    addFile(): void {
        this.fileInput.nativeElement.value = '';
        this.fileInput.nativeElement.click();
    }

    private setClonedFiles(): void {
        this.clonedFilesList = cloneDeep(this.filesList);
        this.isDisabledDeleteIcon = new Array(this.filesList.length).fill(false);
        this.isEditableFileLabel = new Array(this.filesList.length).fill(false);
    }

    private getFileName(fileName: string): string {
        let name = fileName.replace('.pdf', '');

        if (name.length > 25) {
            name = name.substring(0, 25);
        }

        return name;
    }

    private editAppointmentFile(index: number): void {
        this.appointmentsService
            .editAppointmentFile(this.filesList[index].id, this.appointmentId, this.filesList[index].name)
            .subscribe({
                next: (result) => {
                    this.filesList[index] = result;
                    this.setClonedFiles();
                },
                error: () => (this.filesList[index].name = this.clonedFilesList[index].name)
            });
    }

    private async showToastMessage(
        message: string,
        buttons: { text?: string; icon?: string; role: string }[]
    ): Promise<{ data?: any; role?: string }> {
        return await this.toastService.showMessage(message, 2500, 'top', 'warning-toast', buttons);
    }
}

import { Component, Input, OnInit } from '@angular/core';
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidatorFn,
    Validators
} from '@angular/forms';

import { ModalController } from '@ionic/angular';

import { merge, Observable } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { DictionaryService } from '@core/services/dictionary.service';
import { AdderAmountType } from '@shared/enums/adder-amount-type.enum';
import { AdderAppliedType } from '@shared/enums/adder-type.enum';
import { DictionaryType } from '@shared/enums/dictionary-type';
import { Dictionary } from '@shared/interfaces/dictionary';
import { BaseModal } from '@shared/modals/base-modal';

@Component({
    selector: 'vendo-adders-modal',
    templateUrl: './adders-modal.component.html',
    styleUrls: ['./adders-modal.component.scss']
})
export class AddersModalComponent extends BaseModal implements OnInit {
    @Input() addersCategories = [];
    @Input() selectedAdders = [];
    @Input() countOfColumns = 1;
    @Input() packageNames: string[] = [];
    @Input() isProjectAdders = false;
    @Input() isShowPrice: boolean;
    activeTab: any;
    activeTabIndex = 0;
    tabs: any[] = [];
    form: UntypedFormGroup;
    selectedIds: number[] = [];
    adderNameSetting: Dictionary;
    readonly adderAppliedTypes: typeof AdderAppliedType = AdderAppliedType;
    readonly amountTypes: typeof AdderAmountType = AdderAmountType;
    private isSkipHandleAddersChange = true;

    get addersCategoriesForm(): UntypedFormArray {
        return this.form.get('addersCategories') as UntypedFormArray;
    }

    constructor(
        private dictionaryService: DictionaryService,
        private formBuilder: UntypedFormBuilder,
        modalController: ModalController
    ) {
        super(modalController);
    }

    async ngOnInit(): Promise<void> {
        this.addersCategories = this.addersCategories.filter((adderCategory) => {
            adderCategory.adders = adderCategory.adders?.filter(
                (adder) =>
                    !this.selectedAdders.some(
                        (selectedAdder) =>
                            selectedAdder.id == adder.id && selectedAdder.applied_type === this.adderAppliedTypes.Auto
                    )
            );

            return Boolean(adderCategory.adders?.length);
        });
        this.tabs = this.addersCategories.map((item) => ({
            label: item.name,
            hash: item.id
        }));
        if (this.tabs.length) {
            this.setActiveTab(0);
        }
        if (!this.packageNames.length) {
            this.packageNames = new Array(this.countOfColumns).fill('');
        }
        this.selectedIds = this.selectedAdders.map(({ id }) => Number(id));
        this.initForm();
        this.adderNameSetting = await this.dictionaryService.getDictionaryName(DictionaryType.Adder);
    }

    changeTab(count: number): void {
        const tabIndex = this.activeTabIndex + count;

        if (!this.addersCategories[tabIndex]) {
            return;
        }

        this.setActiveTab(tabIndex);
    }

    selectTab(tab: any): void {
        if (tab.hash === this.activeTab?.id) {
            return;
        }

        const index: number = this.addersCategories.findIndex((item) => item.id === tab.hash);

        this.setActiveTab(index);
    }

    selectAdder(rowIndex: number): void {
        const adderForm: UntypedFormGroup = this.addersCategoriesForm.get([
            this.activeTabIndex,
            'adders',
            rowIndex
        ]) as UntypedFormGroup;
        const control: AbstractControl = (adderForm.get('packages') as UntypedFormArray).at(0);

        control.setValue(!control.value);
    }

    save(): void {
        if (this.form.invalid) {
            return;
        }

        const adderIds: any[] = this.packageNames.map(() => []);
        const addersConfigs: any[] = this.packageNames.map(() => []);
        const formValues: any[] = this.addersCategoriesForm.getRawValue();

        formValues.forEach((category, i: number) => {
            category.adders.forEach((adder, j: number) => {
                const position: number = this.selectedIds.indexOf(Number(adder.id));

                if (this.countOfColumns > 1) {
                    adder.packages.slice(1).forEach((item: any, index: number) => {
                        if (item && !adderIds[index].includes(adder.id)) {
                            adderIds[index].push(adder.id);
                            addersConfigs[index].push({
                                adder_id: adder.id,
                                ...(this.addersCategories[i].adders[j].variable && { amount: adder.amount }),
                                position
                            });
                        }
                    });
                } else if (adder.packages[1] && !adderIds[0].includes(adder.id)) {
                    adderIds[0].push(adder.id);
                    addersConfigs[0].push({
                        adder_id: adder.id,
                        ...(this.addersCategories[i].adders[j].variable && { amount: adder.amount }),
                        position
                    });
                }
            });
        });

        this.selectedAdders.forEach((adder) => {
            if (adder.categories?.length === 1 && adder.categories[0].name === 'Custom') {
                adder.packages.forEach((value: boolean, index: number) => {
                    if (value) {
                        adderIds[index].push(adder.id);
                        addersConfigs[index].push({
                            adder_id: adder.id,
                            position: this.selectedIds.indexOf(Number(adder.id))
                        });
                    }
                });
            }
        });

        this.dismiss({ adderIds, addersConfigs });
    }

    private setActiveTab(index: number): void {
        this.activeTab = this.addersCategories[index];
        this.activeTabIndex = index;
    }

    private initForm(): void {
        this.form = this.formBuilder.group({
            addersCategories: this.formBuilder.array(
                this.addersCategories.map((category) =>
                    this.formBuilder.group({
                        isSelectAll: category.adders.every((adder) =>
                            this.selectedAdders.some((selected) => Number(selected.id) === Number(adder.id))
                        ),
                        adders: this.initAddersFormArray(category.adders)
                    })
                )
            )
        });

        merge(
            ...this.addersCategoriesForm.controls.map((group: AbstractControl) =>
                this.handleAdderCategoryForm(group as UntypedFormGroup)
            )
        )
            .pipe(
                filter(() => this.isSkipHandleAddersChange),
                takeUntil(this.destroy$)
            )
            .subscribe((rowIndex: number) => {
                const adderForm: UntypedFormGroup = this.addersCategoriesForm.get([
                    this.activeTabIndex,
                    'adders',
                    rowIndex
                ]) as UntypedFormGroup;
                const packagesForm: UntypedFormArray = adderForm.get('packages') as UntypedFormArray;
                const value: boolean = packagesForm.at(0).value;
                const newValues: boolean[] = new Array(this.countOfColumns > 1 ? this.countOfColumns + 1 : 2).fill(
                    value
                );

                packagesForm.setValue(newValues, { emitEvent: false });
                const adder = this.addersCategories[this.activeTabIndex].adders[rowIndex];
                const amountControl: AbstractControl = adderForm.get('amount');

                this.setAmountValidation(adder, amountControl, newValues);

                const adderId = Number(adderForm.get('id').value);
                const index: number = this.selectedIds.findIndex((id: number) => id === adderId);

                if (value && index === -1) {
                    this.selectedIds.push(adderId);
                } else if (!value && index > -1) {
                    this.selectedIds.splice(index, 1);
                }

                this.handleAdderAmount(adderId, amountControl.value, newValues, value, adder);
            });

        merge(
            ...this.addersCategoriesForm.controls.map((group: AbstractControl) =>
                merge(
                    ...(group.get('adders') as UntypedFormArray).controls.map(
                        (nestedGroup: AbstractControl, rowIndex: number) =>
                            nestedGroup.get('amount').valueChanges.pipe(map((value: any) => ({ rowIndex, value })))
                    )
                )
            )
        )
            .pipe(
                filter(() => this.isSkipHandleAddersChange),
                takeUntil(this.destroy$)
            )
            .subscribe(({ rowIndex, value }) => {
                const adderId = Number(
                    this.addersCategoriesForm.get([this.activeTabIndex, 'adders', rowIndex, 'id']).value
                );

                this.handleAdderAmount(adderId, value);
            });

        merge(
            ...this.addersCategoriesForm.controls.map((group: AbstractControl) =>
                group.get('isSelectAll').valueChanges.pipe(
                    tap((isSelectAll: boolean) => {
                        (group.get('adders') as UntypedFormArray).controls.forEach((adderGroup: AbstractControl) =>
                            adderGroup.get(['packages', 0]).setValue(isSelectAll)
                        );
                    })
                )
            )
        )
            .pipe(takeUntil(this.destroy$))
            .subscribe();
    }

    private handleAdderAmount(
        adderId: number,
        amountValue: number,
        newValues?: boolean[],
        value?: boolean,
        adder?: any
    ): void {
        this.addersCategoriesForm.controls.forEach((group: AbstractControl, index: number) => {
            if (index === this.activeTabIndex) {
                newValues && this.setIsSelectAll(group as UntypedFormGroup);

                return;
            }

            const addersForm: UntypedFormArray = group.get('adders') as UntypedFormArray;
            const adderIndex: number = addersForm.getRawValue().findIndex(({ id }) => Number(id) === adderId);

            if (adderIndex > -1) {
                this.isSkipHandleAddersChange = false;
                const anotherAdderControl: AbstractControl = addersForm.at(adderIndex);

                if (
                    anotherAdderControl.get('amount').value !== amountValue ||
                    (newValues && anotherAdderControl.get(['packages', 0]).value !== value)
                ) {
                    anotherAdderControl.patchValue({
                        amount: amountValue,
                        ...(newValues && { packages: newValues })
                    });

                    if (newValues) {
                        this.setAmountValidation(adder, anotherAdderControl.get('amount'), newValues);
                    }
                }

                newValues && this.setIsSelectAll(group as UntypedFormGroup);
            }
        });
        this.isSkipHandleAddersChange = true;
    }

    private handleAdderCategoryForm(group: UntypedFormGroup): Observable<number> {
        return merge(
            ...(group.get('adders') as UntypedFormArray).controls.map(
                (nestedGroup: AbstractControl, rowIndex: number) =>
                    (nestedGroup.get('packages') as UntypedFormArray).at(0).valueChanges.pipe(map(() => rowIndex))
            )
        );
    }

    private initAddersFormArray(adders): UntypedFormArray {
        if (!adders?.length) {
            return this.formBuilder.array([]);
        }

        return this.formBuilder.array(
            adders.map((adder) => {
                const selectedAdder = this.selectedAdders.find((selected) => Number(selected.id) === Number(adder.id));

                if (selectedAdder) {
                    return this.initAdderFormGroup({ ...adder, amount: selectedAdder.amount }, [
                        selectedAdder.packages.some((item) => item),
                        ...selectedAdder.packages.map((checked) => checked)
                    ]);
                }

                return this.initAdderFormGroup(
                    adder,
                    new Array(this.countOfColumns > 1 ? this.countOfColumns + 1 : 2).fill(false)
                );
            })
        );
    }

    private initAdderFormGroup(adder: any, packages: boolean[]): UntypedFormGroup {
        return this.formBuilder.group({
            id: adder.id,
            amount: [adder.amount, this.getAmountValidators(adder, packages)],
            packages: this.formBuilder.array(packages)
        });
    }

    private setIsSelectAll(group: UntypedFormGroup): void {
        const isSelectAllControl: AbstractControl = group.get('isSelectAll');
        const isSelectAll: boolean = (group.get('adders') as UntypedFormArray).controls
            .map((adderGroup: AbstractControl) => adderGroup.get(['packages', 0]).value)
            .every((val: boolean) => val);

        isSelectAllControl.setValue(isSelectAll, { emitEvent: false });
    }

    private getAmountValidators(adder: any, packages: boolean[]): ValidatorFn[] | null {
        if (!adder.variable || packages.every((value: boolean) => !value)) {
            return null;
        }

        const amountValidators: ValidatorFn[] = [Validators.required, Validators.min(0.01)];

        if (adder.amount_type === AdderAmountType.PERCENTAGE_OF_LINE_ITEM) {
            amountValidators.push(Validators.max(this.isProjectAdders ? 10000 : 100));
        }

        return amountValidators;
    }

    private setAmountValidation(adder: any, amountControl: AbstractControl, packages: boolean[]): void {
        if (!adder.variable) {
            return;
        }

        const amountValidators: ValidatorFn[] = this.getAmountValidators(adder, packages);

        if (amountValidators?.length) {
            amountControl.setValidators(amountValidators);
        } else {
            amountControl.clearValidators();
        }
        amountControl.updateValueAndValidity();
    }
}

import { gql } from 'apollo-angular';

const GET_QUOTES_PRICING = gql`
    query QuotesPricing($appointmentId: ID!, $quoteId: ID, $discountIds: [ID], $geolocation: JSON) {
        price(appointment_id: $appointmentId, quote_id: $quoteId, discount_ids: $discountIds, geolocation: $geolocation)
    }
`;

const GET_QUOTE_PRICING_INFO = gql`
    query QuotesPricingInfo($quoteId: ID!) {
        priceInfo(quote_id: $quoteId) {
            deposit
            final_price
            financing_options
            quote_id
        }
    }
`;

const APPLY_CUSTOM_PROMOTION = gql`
    mutation ApplyCustomPromotion(
        $appointmentId: ID!
        $quoteId: ID!
        $name: String
        $amount: Float!
        $amount_type: String
        $discountIds: [ID]
    ) {
        applyCustomPromotion(
            appointment_id: $appointmentId
            quote_id: $quoteId
            name: $name
            amount: $amount
            amount_type: $amount_type
            discount_ids: $discountIds
        )
    }
`;

const GET_PAYMENT_INFO = gql`
    query PaymentInfo($quoteId: ID!) {
        paymentInfo(quote_id: $quoteId) {
            quote_payments {
                amount
            }
            customer {
                first_name
                last_name
                email
                phone_number
            }
            house {
                address1
                address2
                city
                country
                state
                postal_code
                additional_info
                properties
            }
            payment_system
            enable_mitek
            additional_info
        }
    }
`;

const GET_QUOTE_CLIENT = gql`
    query QuoteClient1($quoteId: ID!, $clientId: ID!) {
        quoteClient(quote_id: $quoteId, client_id: $clientId) {
            ClientId
            ClientName
            ClientNumber
            IsDefault
            BillingInfo
            DefaultShippingInfo
        }
    }
`;

const GET_QUOTE_CLIENTS = gql`
    query QuoteClientsList1(
        $quoteId: ID!
        $offset: Int
        $limit: Int
        $orderBy: String
        $orderDirection: String
        $search: String
    ) {
        quoteClientsList(
            quote_id: $quoteId
            offset: $offset
            limit: $limit
            order_by: $orderBy
            order_direction: $orderDirection
            search: $search
        ) {
            entities {
                ClientId
                ClientName
                ClientNumber
                IsDefault
                BillingInfo
                DefaultShippingInfo
            }
            defaultClientId
        }
    }
`;

const GET_QUOTE_PRICING_PROFILES = gql`
    query QuotePricingProfiles($quote_ids: [ID!]!) {
        quotePricingProfiles(quote_ids: $quote_ids) {
            quote_id
            items {
                ProfileId
                ProfileName
                StartDate
                EndDate
                PricingType
                BillingInfo
                IsDefault
                ProfileGroups
                DefaultPreferences {
                    MarginAmount
                    MarginPercentage
                    Tax1Percentage
                    Tax2Percentage
                    FreightAmount
                    FreightPercentage
                    FreightIsTaxable
                    LaborAmount
                    LaborPercentage
                    LaborIsTaxable
                }
            }
        }
    }
`;

const GET_QUOTE_PRICE_BOOKS = gql`
    query QuotePriceBooks($quote_ids: [ID!]!) {
        quotePriceBooks(quote_ids: $quote_ids) {
            quote_id
            items {
                ID
                DisplayName
                BackendName
                IsDefault
                IsSelected
            }
        }
    }
`;

const GET_QUOTES_WITH_ADDERS = gql`
    query QuotesWithAdders1($appointmentId: ID!, $presentationType: String!) {
        quotesWithAdders(appointment_id: $appointmentId, presentation_type: $presentationType) {
            id
            status
            package_name
            config_adders {
                adder_id
                amount
                quantity
                position
                created_from
                applied_type
                type
                adder {
                    id
                    name
                    applies_to
                    taxable
                    default
                    amount
                    amount_type
                    variable
                    visible
                    short_description
                    description
                    categories {
                        name
                    }
                    product_categories {
                        category_type
                    }
                }
            }
        }
    }
`;

const APPOINTMENT_QUOTES = gql`
    query AppointmentQuotes($appointmentId: ID!) {
        appointmentQuotes(appointment_id: $appointmentId) {
            id
            package_name
            opening_configurations {
                wcp_item_id
            }
            partial_quotes {
                quote_id
                wcp_url_id
                id
            }
        }
    }
`;

const GET_QUOTE_PACKAGE_NAMES = gql`
    query AppointmentQuotes($appointmentId: ID!) {
        appointmentQuotes(appointment_id: $appointmentId) {
            id
            package_name
            status
        }
    }
`;

const GET_AVAILABLE_PROMOTIONS = gql`
    query GetPromotions($appointmentId: ID) {
        myPromotions(appointment_id: $appointmentId) {
            id
            name
            type
            visible
            expire_at
        }
    }
`;
const GET_FINANCING_OPTIONS = gql`
    query GetFinancingOptions($appointmentId: ID, $quoteId: ID) {
        myFinancingOptions(appointment_id: $appointmentId, quote_id: $quoteId) {
            name
            url
            months
            interest_rate
            payment_factor
            id
            quote_id
            details
            provider {
                id
                name
                status
                logo_url
                options_count
            }
        }
    }
`;
const GET_FINANCING_OFFER_CODES = gql`
    query GetFinancingOfferCodes($financingType: String!) {
        myFinancingOfferCodes(financing_type: $financingType)
    }
`;

const GET_FINANCING_RATE_SHEETS = gql`
    query GetFinancingRateSheets($financingType: String!, $isActive: Boolean) {
        myFinancingRateSheets(financing_type: $financingType, is_active: $isActive)
    }
`;

const GET_ALL_FINANCING_OPTIONS = gql`
    query GetAllFinancingOptions($appointmentId: ID, $quoteId: ID, $financingType: String!) {
        myFinancingOptions(appointment_id: $appointmentId, quote_id: $quoteId) {
            name
            url
            months
            interest_rate
            payment_factor
            id
            quote_id
            details
            provider {
                id
                name
                status
                logo_url
                options_count
                type
            }
        }
        myFinanceUsers(financing_type: $financingType) {
            financing_merchant {
                type
            }
        }
    }
`;

const GET_PACKAGE = gql`
    query QuotePackage($appointmentId: ID!, $packageIndex: Int!) {
        quotePackage(appointment_id: $appointmentId, package_index: $packageIndex) {
            id
            package_name
        }
    }
`;

const GET_QUOTE_PRICE_PRESENTATION_VIEW_SETTINGS = gql`
    query quotePricePresentationViewSettings {
        quotePricePresentationViewSettings {
            hash
            is_active
            fields {
                hash
                value
            }
        }
    }
`;

const SHOW_CARD_PRESENTATION = gql`
    query showCardPresentation($appointmentId: ID!) {
        showCardPresentation(id: $appointmentId)
    }
`;

const GET_PRICE_CARDS = gql`
    query priceCards($appointmentId: ID!, $quoteId: ID!, $discountIds: [ID]) {
        priceCards(appointment_id: $appointmentId, quote_id: $quoteId, discount_ids: $discountIds) {
            view_settings {
                hash
                fields {
                    hash
                    value
                }
            }
            promotions {
                id
                name
            }
            cards
        }
    }
`;

const GET_EXTRA_PRICE_FIELDS = gql`
    query extraPriceFields(
        $finalPrice: Float!
        $deposit: DepositPriceFields
        $financingOption: FinancingOptionPriceFields
    ) {
        extraPriceFields(final_price: $finalPrice, deposit: $deposit, financing_option: $financingOption)
    }
`;

const GET_QUOTE_DISCOUNT_LIMITATIONS = gql`
    query QuoteDiscountLimitations($appointmentId: ID!, $quoteId: ID) {
        quoteDiscountLimitations(appointment_id: $appointmentId, quote_id: $quoteId) {
            quote_id
            percentage_limit
            static_limit
            override_phone
            apply_to
        }
    }
`;

const GET_QUOTES_FIELDS = gql`
    query QuoteFields($quoteIds: [ID!]!) {
        quoteFields(quote_ids: $quoteIds)
    }
`;

const CHECK_OUT_OF_DATA_QUOTES = gql`
    query checkOutOfDateQuotes($appointmentId: ID!) {
        checkOutOfDateQuotes(appointment_id: $appointmentId)
    }
`;

const GET_ACTIVE_QUOTE_PRICE_PRESENTATION_TYPE = gql`
    query ActiveQuotePricePresentationType {
        activeQuotePricePresentationType
    }
`;

const GET_QUOTE_PRICE_PRESENTATION_COMPARISON_SETTINGS = gql`
    query QuotePricePresentationComparisonSettings {
        quotePricePresentationComparisonSettings {
            display_options
            fields {
                hash
                fields {
                    hash
                    value
                }
            }
        }
    }
`;

const GET_BATCH_EXTRA_PRICE_FIELDS = gql`
    query BatchExtraPriceFields($inputs: [ExtraPriceFieldInput!]!) {
        batchExtraPriceFields(inputs: $inputs)
    }
`;

export {
    GET_QUOTES_PRICING,
    GET_QUOTE_PRICING_INFO,
    GET_AVAILABLE_PROMOTIONS,
    GET_FINANCING_OPTIONS,
    GET_QUOTES_WITH_ADDERS,
    GET_PAYMENT_INFO,
    GET_PACKAGE,
    APPOINTMENT_QUOTES,
    GET_QUOTE_PACKAGE_NAMES,
    GET_QUOTE_CLIENTS,
    GET_QUOTE_PRICING_PROFILES,
    GET_QUOTE_PRICE_BOOKS,
    APPLY_CUSTOM_PROMOTION,
    GET_QUOTE_PRICE_PRESENTATION_VIEW_SETTINGS,
    GET_EXTRA_PRICE_FIELDS,
    SHOW_CARD_PRESENTATION,
    GET_PRICE_CARDS,
    GET_ALL_FINANCING_OPTIONS,
    GET_FINANCING_OFFER_CODES,
    GET_QUOTE_DISCOUNT_LIMITATIONS,
    GET_QUOTES_FIELDS,
    CHECK_OUT_OF_DATA_QUOTES,
    GET_ACTIVE_QUOTE_PRICE_PRESENTATION_TYPE,
    GET_QUOTE_PRICE_PRESENTATION_COMPARISON_SETTINGS,
    GET_BATCH_EXTRA_PRICE_FIELDS,
    GET_FINANCING_RATE_SHEETS,
    GET_QUOTE_CLIENT
};

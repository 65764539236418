import { gql } from 'apollo-angular';

const GET_OPENINGS_LIST = gql`
    query OpeningsList($categoryId: ID!, $appointmentId: ID!) {
        openings(category_id: $categoryId, appointment_id: $appointmentId) {
            id
            name
            notes
            opening_details {
                question_id
                answer
                question {
                    title
                }
            }
        }
    }
`;

const GET_OPENING = gql`
    query Opening($openingId: ID!) {
        opening(opening_id: $openingId) {
            id
            name
            notes
            images {
                appointment_id
                opening_id
                type
                name
                note
                url
                position
                appointment_type
            }
            mulled
            autoconfigured
            quantity
            additional_info
            view_coordinates
            view_photo_id
            category_id
            catalog_id
            opening_details {
                question_id
                answer
            }
            opening_configurations {
                wcp_item_id
                wcp_config_id
            }
            opening_inclusions {
                id
                width
                height
                hash
                excluded
                uuid
            }
            accessories {
                id
                answers
                assigned_to {
                    item_uuid
                    answers
                    sides
                }
            }
        }
    }
`;
const GET_OPENING_CONDITIONS = gql`
    query OpeningConditions($category_type: String!) {
        myOpeningConditions(category_type: $category_type) {
            name
        }
    }
`;
const GET_ROOFING_TAKEOFF_DETAILS = gql`
    query TakeoffDetailsWithOpenings($catalogId: ID!, $appointmentId: ID!) {
        takeoffDetailsWithOpenings(catalog_id: $catalogId, appointment_id: $appointmentId) {
            takeoff_details {
                catalog_id
                appointment_id
                details
            }
            openings {
                id
                name
                additional_info
            }
        }
    }
`;

export { GET_OPENINGS_LIST, GET_OPENING, GET_OPENING_CONDITIONS, GET_ROOFING_TAKEOFF_DETAILS };

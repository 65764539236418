import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { ModalController, NavController } from '@ionic/angular';

import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { isActiveOfflineMode } from '@core/functions/is-active-offline-mode';
import { CHILD_VISIT_STEP, COMPLETE_STEP, SKIP_STEP, VISIT_STEP } from '@core/mutations/steps.mutation';
import { AuthService } from '@core/services/auth.service';
import { DataCompareHelperService } from '@core/services/data-compare-helper.service';
import { DeviceHelperService } from '@core/services/device-helper.service';
import { StorageService } from '@core/services/storage.service';
import { ToastService } from '@core/services/toast.service';
import { AppointmentType } from '@shared/enums/appointment-type';
import { SaleStepType } from '@shared/enums/sale-step-type.enum';
import { Appointment, AppointmentDeeplink } from '@shared/interfaces/appointment';
import { AppointmentStep, AppointmentSteps } from '@shared/interfaces/appointment-step';

import { ConfirmationModalComponent } from '../../components/confirmation-modal/confirmation-modal.component';
import { AppointmentsService } from '../../main/appointments/services/appointments.service';

@Injectable({
    providedIn: 'root'
})
export class StepsService {
    private readonly isOffline: boolean = isActiveOfflineMode();

    constructor(
        private apollo: Apollo,
        private appointmentService: AppointmentsService,
        private authService: AuthService,
        private dataCompareHelperService: DataCompareHelperService,
        private deviceHelperService: DeviceHelperService,
        private modalController: ModalController,
        private navController: NavController,
        private storageService: StorageService,
        private toastService: ToastService
    ) {}

    completeStep(appointment_id: string | number, stepHash: string): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: COMPLETE_STEP,
                variables: {
                    appointment_id,
                    stepHash
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.completeStep));
    }

    childVisitStep(appointmentId: string | number, stepHash: string, childId, visitInfo): Observable<any> {
        return this.apollo.mutate({
            mutation: CHILD_VISIT_STEP,
            variables: {
                appointment_id: appointmentId,
                stepHash,
                child_id: childId,
                visit_info: visitInfo
            },
            context: {
                extensions: {
                    background: true
                }
            }
        });
    }

    skipStep(appointment_id: number | string, stepHash: string): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: SKIP_STEP,
                variables: {
                    appointment_id,
                    stepHash
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.skipStep));
    }

    visitStep(appointment_id: number, stepHash: string, background = false): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: VISIT_STEP,
                variables: {
                    appointment_id,
                    stepHash
                },
                context: {
                    extensions: {
                        background
                    }
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.visitStep));
    }

    getStepName(appointmentType: AppointmentType, stepHash: SaleStepType): string {
        const user = this.authService.getUser();
        const steps: AppointmentSteps = user.office.steps.find(
            ({ appointment_type }) => appointment_type === appointmentType
        );
        const step: AppointmentStep = steps.items.find(({ hash }) => hash === stepHash.replace('-', '_'));

        if (!step) {
            return '';
        }

        const names: string[] = [step.name];

        if (!this.dataCompareHelperService.isEmpty(step.second_name)) {
            names.push(step.second_name);
        }

        return names.join(' ');
    }

    async openStep(step, appointment: Appointment, url?: string, queryParams?: Params): Promise<void> {
        if (step.disabled) {
            return;
        }

        if (appointment.deeplinks?.items?.length) {
            const deeplink: AppointmentDeeplink = this.findDeeplink(step.hash, appointment);

            if (deeplink) {
                const isOpenDeeplink = await this.openDeeplink(deeplink, appointment);

                if (isOpenDeeplink) {
                    await this.markStepAsVisited(step.hash, appointment);
                }

                return;
            }
        }

        const stepHash = step.hash.replace('_', '-');

        this.navController.navigateRoot(
            url || `/main/appointments/${appointment.id}/${stepHash}`,
            queryParams ? { queryParams } : undefined
        );
    }

    async handleDeeplink(url: string, appointment: Appointment, conditionIfNotOpenDeeplink: boolean): Promise<any> {
        const stepHash: string = this.getStepHashByUrl(url);

        if (appointment.deeplinks?.items?.length) {
            const deeplink: AppointmentDeeplink = this.findDeeplink(stepHash, appointment);

            if (deeplink) {
                const isOpenDeeplink: boolean = await this.openDeeplink(deeplink, appointment);

                this.navController.navigateRoot(`/main/appointments/${appointment.id}`).then(() => {
                    if (isOpenDeeplink) {
                        this.markStepAsVisited(stepHash, appointment);
                    }
                });

                return EMPTY;
            }
        }

        await this.markStepAsVisited(stepHash, appointment);

        return conditionIfNotOpenDeeplink;
    }

    getRequiredViewingList(): string[] {
        const user = this.authService.getUser();
        const steps = user.office.steps.find((step) => step.appointment_type === AppointmentType.Sales);

        return steps.items.find((item) => item.hash === SaleStepType.CONFIGURE).required_viewing;
    }

    private findDeeplink(stepHash: string, appointment: Appointment): AppointmentDeeplink {
        return appointment.deeplinks.items.find(({ hash }) => hash === `${stepHash}_step`);
    }

    private getStepHashByUrl(url: string): string {
        switch (true) {
            case url.includes('/needs-assessment'):
                return SaleStepType.NEEDS_ASSESSMENT.replace('-', '_');
            case url.includes('/takeoff'):
                return SaleStepType.TAKE_OFF;
            case url.includes('/demo'):
                return SaleStepType.DEMO;
            case url.includes('/configure'):
                return SaleStepType.CONFIGURE;
            case url.includes('/quote'):
                return SaleStepType.QUOTE;
            case url.includes('/documents'):
                return 'documents';
            case url.includes('/measure'):
                return SaleStepType.MEASURE;
            case url.includes('/review'):
                return SaleStepType.REVIEW;
        }
    }

    private async markStepAsVisited(hash: string, appointment: Appointment): Promise<void> {
        if (
            !this.isOffline &&
            !appointment.activities.some((activity) => activity.hash === hash) &&
            hash &&
            Object.values<string>(SaleStepType).includes(hash.replace('_', '-'))
        ) {
            const isVisited: boolean = await this.visitStep(appointment.id, hash, true).toPromise();

            if (isVisited) {
                appointment.activities.push({
                    hash,
                    status: 'in_progress'
                });

                await this.storageService.set('activeAppointment', appointment);
            }
        }
    }

    private async openDeeplink(deeplink: AppointmentDeeplink, appointment: Appointment): Promise<boolean> {
        let isConfirmed = !appointment.deeplinks.is_show_confirmation;

        if (appointment.deeplinks.is_show_confirmation) {
            const modal: HTMLIonModalElement = await this.modalController.create({
                backdropDismiss: false,
                component: ConfirmationModalComponent,
                componentProps: {
                    message: `You are being redirect to ${deeplink.name}`
                }
            });

            await modal.present();

            const { data } = await modal.onWillDismiss();

            isConfirmed = data;
        }

        if (isConfirmed) {
            if (appointment.deeplinks.is_paste_data_to_device && !this.deviceHelperService.isWeb) {
                const appointmentData = await this.appointmentService
                    .getAppointmentForExternalResource(appointment.id)
                    .toPromise();

                const prefix = `${appointmentData.id} ${appointmentData.appointmentIntegrationId || ''}`;

                await this.deviceHelperService.copyToClipboard(appointmentData, prefix);
            }

            await this.appointmentService.openAppointmentDeeplink(appointment.id, deeplink.hash).toPromise();

            try {
                const url = new URL(deeplink.url);

                this.deviceHelperService.openUrl(url.toString(), '_system');

                return true;
            } catch (err) {
                await this.toastService.showMessage('Deeplink URL is invalid');
            }
        }

        return false;
    }
}

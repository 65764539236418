import { Injectable, Inject, EventEmitter } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { THEMES, ACTIVE_THEME, Theme } from '../symbols';
import { isActiveOfflineMode } from '@core/functions/is-active-offline-mode';
import { defaultTheme } from '@core/modules/theme/constants/themes.constant';
import { GET_ORGANIZATION_THEME_OPTIONS } from '@core/queries/theme.queries';

@Injectable()
export class ThemeService {
    themeChange = new EventEmitter<Theme>();
    private readonly themeName: string = 'customTheme';

    constructor(
        @Inject(THEMES) public themes: Theme[],
        @Inject(ACTIVE_THEME) public theme: string,
        private apollo: Apollo
    ) {}

    init(user): void {
        const themeProperties = this.getUserThemeColors(user);

        if (themeProperties) {
            this.registerTheme({
                name: this.themeName,
                properties: themeProperties
            });
            this.setTheme(this.themeName);
        }
    }

    getOrganizationThemeOptions(subDomain: string): Observable<any> {
        return this.apollo
            .query({
                query: GET_ORGANIZATION_THEME_OPTIONS,
                variables: {
                    subDomain
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.themeOptions));
    }

    getTheme(name: string): Theme {
        return this.themes.find((t) => t.name === name);
    }

    getUserThemeColors(user): any {
        const officeColors = get(user, 'office.colors', {});
        const organizationColors = get(user, 'organization.colors', {});

        if (!isEmpty(officeColors)) {
            return this.generateThemeProperties(officeColors);
        } else if (!isEmpty(organizationColors)) {
            return this.generateThemeProperties(organizationColors);
        }

        return null;
    }

    getActiveTheme(): Theme {
        if (this.theme) {
            return this.getTheme(this.theme);
        }
    }

    getActiveThemeColor(colorName: string): string {
        return get(this.getActiveTheme(), `properties.${colorName}`, '#000000');
    }

    getProperty(propName: string): any {
        return this.getActiveTheme().properties[propName];
    }

    setTheme(name: string): void {
        this.theme = name;
        this.themeChange.emit(this.getActiveTheme());
    }

    registerTheme(theme: Theme): void {
        if (this.getTheme(theme.name)) {
            this.updateTheme(theme.name, theme.properties);

            return;
        }
        this.themes.push(theme);
    }

    updateTheme(name: string, properties: { [key: string]: string }): void {
        const theme = this.getTheme(name);

        theme.properties = {
            ...theme.properties,
            ...properties
        };

        if (name === this.theme) {
            this.themeChange.emit(theme);
        }
    }

    private generateThemeProperties(colors: any): any {
        const isOffline: boolean = isActiveOfflineMode();
        const primaryColor = isOffline ? colors.secondary_color : colors.primary_color;
        const primaryTextColor = isOffline ? colors.secondary_text_color : colors.primary_text_color;
        const secondaryColor = isOffline ? colors.primary_color : colors.secondary_color;
        const secondaryTextColor = isOffline ? colors.primary_text_color : colors.secondary_text_color;

        return {
            ...defaultTheme.properties,
            '--ion-color-base': primaryColor,
            '--ion-color-primary': primaryColor,
            '--ion-color-primary-rgb': this.hexToRgb(primaryColor),
            '--ion-color-primary-shade': primaryColor,
            '--ion-color-primary-tint': primaryColor,
            '--ion-color-on-primary': primaryTextColor,
            '--ion-color-secondary': secondaryColor,
            '--ion-color-secondary-rgb': this.hexToRgb(secondaryColor),
            '--ion-color-secondary-shade': secondaryColor,
            '--ion-color-secondary-tint': secondaryColor,
            '--ion-color-on-secondary': secondaryTextColor,
            '--ion-color-tertiary': colors.tertiary_color,
            '--ion-color-on-tertiary': colors.tertiary_text_color,
            '--logo-background': colors.logo_background_color,
            '--ion-color-warning': '#f19d38',
            '--swiper-pagination-color': primaryColor
        };
    }

    private hexToRgb(hex: string): string {
        if (!hex) {
            return '';
        }

        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

        hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        const rgbObj = result
            ? {
                  r: parseInt(result[1], 16),
                  g: parseInt(result[2], 16),
                  b: parseInt(result[3], 16)
              }
            : null;

        return rgbObj ? `${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b}` : '';
    }
}

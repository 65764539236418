export enum UserPreference {
    FavoriteDocuments = 'favorite_documents',
    DemoResourcesOrder = 'demo_resources_order',
    ShowPricing = 'show_pricing',
    DefaultDepositAsPercent = 'default_deposit_as_percent',
    AugmentRealityMeasurement = 'augment_reality_measurement',
    BoschMeasurement = 'bosch_measurement',
    QuickTakeoff = 'quick_takeoff',
    CustomProductPromotion = 'custom_product_promotion',
    ShowPriceModifiers = 'show_price_modifiers',
    BiometricLogin = 'biometric_login'
}

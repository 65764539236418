import { Injectable } from '@angular/core';

import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';

import { DeviceHelperService } from '@core/services/device-helper.service';
import { StorageService } from '@core/services/storage.service';
import { ApplicationVersion } from '@shared/interfaces/application-version';

import versions from '../../../environments/app-versions';

@Injectable({
    providedIn: 'root'
})
export class GraphqlHelperService {
    constructor(
        private appVersion: AppVersion,
        private deviceHelperService: DeviceHelperService,
        private storageService: StorageService
    ) {}

    async getApplicationVersion(): Promise<ApplicationVersion> {
        const versionsObject: ApplicationVersion = {
            actualVersion: null,
            apiVersion: await this.storageService.get('apiVersion'),
            installedVersion: null
        };

        if (this.deviceHelperService.isWeb) {
            versionsObject.installedVersion = versions.appVersion;

            return versionsObject;
        }

        versionsObject.installedVersion = await this.appVersion.getVersionNumber();

        if (versionsObject.installedVersion !== versions.appVersion) {
            versionsObject.actualVersion = versions.appVersion;
        }

        return versionsObject;
    }
}

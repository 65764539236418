import { DOCUMENT } from '@angular/common';
import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnInit,
    Output
} from '@angular/core';

import { fromEvent, interval, merge } from 'rxjs';
import { filter, map, pairwise, switchMap, take, takeUntil } from 'rxjs/operators';

import { Unsubscriber } from '@shared/helpers/unsubscriber';

@Directive({
    selector: '[tqMHorizontalScroll]',
    exportAs: 'tqMHorizontalScroll'
})
export class HorizontalScrollDirective extends Unsubscriber implements OnInit, AfterViewInit {
    @Input() scrollUnit = 25;
    @Output() readonly overflowed = new EventEmitter<boolean>();
    private isOverflowed = false;

    constructor(
        @Inject(DOCUMENT) private document: any,
        private readonly elementRef: ElementRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.initScrollGesture();
    }

    ngAfterViewInit(): void {
        interval(500)
            .pipe(take(6))
            .subscribe((x) => this.onWindowResize());
    }

    scrollLeft(scrollUnit?: number): void {
        this.scroll(-1, scrollUnit);
    }

    scrollRight(scrollUnit?: number): void {
        this.scroll(1, scrollUnit);
    }

    get canScrollStart(): boolean {
        return this.element.scrollLeft > 0;
    }

    get canScrollEnd(): boolean {
        return this.element.scrollLeft + this.element.clientWidth < this.element.scrollWidth;
    }

    private get element(): any {
        return this.elementRef.nativeElement;
    }

    private scroll(direction: number, scrollUnit?: number): void {
        this.element.scrollLeft += (scrollUnit || this.scrollUnit) * direction;
    }

    @HostListener('window:resize')
    private onWindowResize(): void {
        this.isOverflowed = this.element.clientWidth < this.element.scrollWidth;
        this.overflowed.emit(this.isOverflowed);
    }

    private initScrollGesture(): void {
        merge(
            fromEvent(this.element, 'touchstart').pipe(
                filter(() => this.isOverflowed),
                switchMap(() => {
                    return fromEvent(this.element, 'touchmove').pipe(
                        takeUntil(fromEvent(this.element, 'touchend')),
                        takeUntil(fromEvent(this.element, 'touchcancel')),
                        pairwise()
                    );
                }),
                map(
                    ([{ changedTouches: firstChangedTouches }, { changedTouches: secondChangedTouches }]: [
                        TouchEvent,
                        TouchEvent
                    ]) => [firstChangedTouches[0].clientX, secondChangedTouches[0].clientX]
                )
            ),
            fromEvent(this.element, 'mousedown').pipe(
                filter(() => this.isOverflowed),
                switchMap(() => {
                    return fromEvent(this.document, 'mousemove').pipe(
                        takeUntil(fromEvent(this.element, 'mouseup')),
                        takeUntil(fromEvent(this.element, 'mouseleave')),
                        pairwise()
                    );
                }),
                map(([{ clientX: firstClientX }, { clientX: secondClientX }]: [MouseEvent, MouseEvent]) => [
                    firstClientX,
                    secondClientX
                ])
            )
        )
            .pipe(takeUntil(this.destroy$))
            .subscribe(([startX, endX]: number[]) => {
                const { left, right } = this.element.getBoundingClientRect();

                if (startX < left || startX > right || endX < left || endX > right) {
                    return;
                }

                if (startX > endX && this.canScrollEnd) {
                    this.scrollRight(Math.floor(startX - endX));
                } else if (startX < endX && this.canScrollStart) {
                    this.scrollLeft(Math.floor(endX - startX));
                }
            });
    }
}

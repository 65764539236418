import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GetCommonItem, GetCommonItems, GetOptionList } from '@core/queries/common.queries';
import { OptionListType } from '@shared/enums/option-list-type.enum';
import { CommonItems } from '@shared/interfaces/common-items';
import { DropdownItem } from '@shared/interfaces/dropdown-item';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    constructor(private apollo: Apollo) {}

    getCommonItem(type: string): Observable<any> {
        return this.apollo
            .query({
                query: GetCommonItem,
                variables: {
                    type
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.commonItem));
    }

    getCommonItems(types?: string[], silentRequest = false): Observable<CommonItems> {
        return this.apollo
            .query({
                query: GetCommonItems,
                variables: {
                    types
                },
                context: {
                    extensions: {
                        background: silentRequest
                    }
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.commonItems));
    }

    getOptionList(type: OptionListType, filter?: any): Observable<DropdownItem[]> {
        return this.apollo
            .query({
                query: GetOptionList,
                variables: {
                    type,
                    filter
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.optionList));
    }
}

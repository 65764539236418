<div class="modal-header">
    <ion-text class="title">{{ measurementSystem.hash | replace: '_':'' | titlecase }} Integration</ion-text>
    <ion-button fill="outline" color="dark" (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
</div>

<div class="modal-body">
    <ion-content>
        <form [formGroup]="measurementDetailsForm">
            <ng-container *ngIf="itemsFormArray.controls.length" formArrayName="items">
                <div class="mb-10">
                    <ng-container *ngIf="report.id; else textWhenImporting">
                        You can modify or delete the import below. Adding or removing a category will erase all saved line-items from the Inspection and Configure steps.
                    </ng-container>

                    <ng-template #textWhenImporting>
                        Select categories to import
                    </ng-template>
                </div>

                <div *ngFor="let categoryForm of itemsFormArray.controls; let i = index"
                     [formGroupName]="i">
                    <ion-item lines="none" mode="md">
                        <ion-label>{{ availableCategories[i].mapped_category_type }}</ion-label>
                        <ion-checkbox legacy="true" slot="start"
                                      mode="md"
                                      formControlName="active"></ion-checkbox>
                    </ion-item>

                    <div *ngIf="itemsFormArray.get([i, 'active']).value"
                         class="category-sub-fields"
                         formGroupName="takeoffDetails">
                        <div *ngIf="details.takeoff_details[availableCategories[i].original_category_type].hasOwnProperty('siding_type')" class="field-block">
                            <ion-label class="required">Siding Type</ion-label>
                            <ion-select class="w-100"
                                        legacy="true"
                                        mode="md"
                                        interface="popover"
                                        [interfaceOptions]="{mode: 'md', alignment: 'start'}"
                                        formControlName="siding_type"
                                        placeholder="Siding Type">
                                <ion-select-option *ngFor="let type of details.siding_types" [value]="type">
                                    {{ type }}
                                </ion-select-option>
                            </ion-select>
                            <div class="errors-wrapper"
                                 *ngIf="itemsFormArray.get([i, 'takeoffDetails', 'siding_type']).touched">
                                <ion-text color="danger"
                                          class="error"
                                          *ngFor="let error of getMessages(itemsFormArray.get([i, 'takeoffDetails', 'siding_type']).errors)">
                                    {{ error }}
                                </ion-text>
                            </div>
                        </div>

                        <div *ngIf="details.takeoff_details[availableCategories[i].original_category_type].hasOwnProperty('waste_factor')"
                             class="field-block">
                            <ion-label class="required">Waste factor (%)</ion-label>
                            <ion-input legacy="true" type="number"
                                       formControlName="waste_factor"
                                       placeholder="Waste factor (%)"
                                       [required]="itemsFormArray.get([i, 'active']).value"
                                       min="0"
                                       max="100"
                                       autocomplete="no"></ion-input>
                            <div class="errors-wrapper"
                                 *ngIf="itemsFormArray.get([i, 'takeoffDetails', 'waste_factor']).touched">
                                <ion-text color="danger"
                                          class="error"
                                          *ngFor="let error of getMessages(itemsFormArray.get([i, 'takeoffDetails', 'waste_factor']).errors)">
                                    {{ error }}
                                </ion-text>
                            </div>
                        </div>

                        <ion-item *ngIf="details.takeoff_details[availableCategories[i].original_category_type].hasOwnProperty('exclude_openings')"
	                              lines="none"
	                              mode="md">
                            <ion-label>Exclude Openings</ion-label>
                            <ion-checkbox legacy="true" slot="start"
                                          mode="md"
                                          formControlName="exclude_openings"></ion-checkbox>
                        </ion-item>
                    </div>
                </div>
            </ng-container>

            <div *ngIf="!itemsFormArray.controls.length" class="no-data">
                No available categories
            </div>
        </form>
    </ion-content>
</div>

<div class="modal-footer" [class.ion-justify-content-center]="!report.id">
    <ion-button *ngIf="report.id"
                class="classic-width"
                color="danger"
                fill="outline"
                (click)="remove()">
        Delete Report
    </ion-button>

    <div class="main-actions" [class.ion-justify-content-end]="report.id">
        <ion-button class="classic-width outline"
                    (click)="closeModal()">
            Cancel
        </ion-button>

        <ion-button class="classic-width"
                    [disabled]="measurementDetailsForm.invalid || report.status === measurementSystemStatuses.Failed"
                    (click)="import()">
            {{ report.id ? 'Save' : 'Import' }}
        </ion-button>
    </div>
</div>

import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl, SafeStyle } from '@angular/platform-browser';

import get from 'lodash/get';
import some from 'lodash/some';

import { AbstractForm } from '@shared/helpers/abstract-form';

@Component({
    selector: 'vendo-file-drop',
    templateUrl: './file-drop.component.html',
    styleUrls: ['./file-drop.component.scss']
})
export class FileDropComponent extends AbstractForm implements OnInit, OnChanges {
    @Input() allowedMimeTypes: string[] = ['image/png', 'image/gif', 'image/jpeg'];
    @Input() allowedExtensions: string[] = ['.png', '.jpg', '.jpeg', '.gif'];
    @Input() isDisabled = false;
    @Input() maxFileSize = 5 * 1024 * 1024; // 5Mb
    @Input() showErrors = false;
    @Output() fileSelected: EventEmitter<File> = new EventEmitter();

    allowedTypes: string;
    errors: any = {};
    file: any = '';
    hasFile = false;
    previewUrl: SafeResourceUrl;
    backgroundImageStyle: SafeStyle;
    @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

    constructor(
        private cdr: ChangeDetectorRef,
        private sanitizer: DomSanitizer
    ) {
        super();
    }

    ngOnInit(): void {
        this.setAllowedTypes();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (get(changes, 'allowedExtensions.currentValue')) {
            this.setAllowedTypes();
        }
    }

    clearFile(): void {
        this.fileInput.nativeElement.value = '';
        this.file = null;
        this.previewUrl = null;
        this.backgroundImageStyle = null;
        this.fileSelected.emit(this.file);
        this.cdr.markForCheck();
    }

    fileChanged(event): void {
        this.hasFile = get(event, 'target.files.length');
        this.file = get(event, 'target.files.[0]', null);
        this.validate();
        this.uploadFile();
    }

    openUploadDialog(): void {
        this.fileInput.nativeElement.click();
    }

    uploadFile(): void {
        if (!this.showErrors) {
            this.fileSelected.emit(this.file);
            this.previewUrl = URL.createObjectURL(this.file);
            this.backgroundImageStyle = this.sanitizer.bypassSecurityTrustStyle(`url(${this.previewUrl})`);
        }
    }

    private setAllowedTypes(): void {
        this.allowedTypes = this.allowedExtensions.join(', ');
    }

    private validate(): void {
        this.errors.maxFileSize = this.validateFileSize();
        this.errors.fileExtension = this.validateExtension();
        this.showErrors = some(this.errors, (invalid) => invalid);
        this.cdr.detectChanges();
    }

    private validateExtension(): ValidationErrors {
        if (this.file) {
            if (this.file.type && !this.allowedMimeTypes.includes(this.file.type)) {
                return { allowedMimeTypes: this.allowedMimeTypes };
            }

            if (
                this.file.name &&
                !this.allowedExtensions.includes(`.${this.file.name.split('.').pop().toLowerCase()}`)
            ) {
                return { allowedExtensions: this.allowedExtensions };
            }
        }

        return null;
    }

    private validateFileSize(): ValidationErrors {
        if (this.file && this.maxFileSize < this.file.size) {
            return { maxSize: this.maxFileSize / (1024 * 1024) };
        }

        return null;
    }
}

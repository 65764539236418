import { DocumentViewMode } from '@shared/enums/document-view-mode';

export const SIGNATURE_SELECTORS = {
    [DocumentViewMode.SellerReview]: {
        signature: '.signature[data-name="seller_signature"]',
        signature_not_signed: '.signature[data-name="seller_signature"]:not(.signed)',
        initials: '.signature[data-name="seller_initials"]',
        initials_not_signed: '.signature[data-name="seller_initials"]:not(.signed)'
    },
    [DocumentViewMode.PrimaryCustomerSign]: {
        signature: '.signature[data-name="customer_signature"]',
        signature_not_signed: '.signature[data-name="customer_signature"]:not(.signed)',
        initials: '.signature[data-name="customer_initials"]',
        initials_not_signed: '.signature[data-name="customer_initials"]:not(.signed)'
    },
    [DocumentViewMode.SecondaryCustomerSign]: {
        signature: '.signature[data-name="secondary_signature"]',
        signature_not_signed: '.signature[data-name="secondary_signature"]:not(.signed)',
        initials: '.signature[data-name="secondary_initials"]',
        initials_not_signed: '.signature[data-name="secondary_initials"]:not(.signed)'
    }
};

import { Injectable } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { FileEntry } from '@awesome-cordova-plugins/file';

import { Observable, Subscriber } from 'rxjs';

import { isActiveOfflineMode } from '@core/functions/is-active-offline-mode';
import { DeviceHelperService } from '@core/services/device-helper.service';
import { FilesService } from '@core/services/files.service';
import { ToastService } from '@core/services/toast.service';

@Injectable({
    providedIn: 'root'
})
export class ImageService {
    readonly allowedFileExtensions = ['image/png', 'image/gif', 'image/jpeg'];
    private readonly isOffline: boolean = isActiveOfflineMode();
    private maxFileSize = 5 * 1024 * 1024; // 5Mb
    private filePrefix = 'file://';
    private errors = {
        maxFileSize: 'File size should be less than 5Mb!',
        fileExtension: 'File extension should be one of: .png, .jpg, .gif!'
    };
    private windowRef: any;
    private winUrl: any;

    constructor(
        private camera: Camera,
        private fileService: FilesService,
        private deviceHelperService: DeviceHelperService,
        private sanitizer: DomSanitizer,
        private toastService: ToastService
    ) {
        this.windowRef = window;
        this.winUrl = this.windowRef.URL || this.windowRef.webkitURL;
    }

    /**
     * Get original image
     *
     * @param source
     * @param type
     * @returns {any}
     */
    getImage(source, type: XMLHttpRequestResponseType = 'blob'): any {
        return new Observable((observer: Subscriber<any>) => {
            if (!this.isOffline || source.startsWith('blob:')) {
                const xhr = new XMLHttpRequest();

                xhr.onload = () => {
                    if (type === 'blob') {
                        observer.next(URL.createObjectURL(xhr.response));
                    } else if (type === 'arraybuffer') {
                        observer.next(this.converterEngine(xhr.response));
                    } else {
                        observer.next(xhr.responseText);
                    }
                };

                xhr.open('GET', source);
                if (type) {
                    xhr.responseType = type;
                }
                xhr.send();
            } else {
                const filePathParts = source.split('/').reverse();

                this.fileService.getBase64File(`${filePathParts[1]}/${filePathParts[0]}`).then((fileSource) => {
                    observer.next(fileSource);
                });
            }
        });
    }

    getPicture(hash: 'camera' | 'upload', fileName?: string): Promise<any> {
        const options: CameraOptions = {
            sourceType:
                hash === 'camera'
                    ? this.camera.PictureSourceType.CAMERA
                    : this.camera.PictureSourceType.SAVEDPHOTOALBUM,
            destinationType: this.deviceHelperService.isIOSPlatform
                ? this.camera.DestinationType.DATA_URL
                : this.camera.DestinationType.FILE_URI,
            encodingType: this.camera.EncodingType.JPEG,
            mediaType: this.camera.MediaType.PICTURE,
            correctOrientation: true,
            cameraDirection: this.camera.Direction.BACK,
            saveToPhotoAlbum: false,
            quality: 45,
            targetHeight: 768
        };

        return this.camera.getPicture(options).then(async (imageData) => {
            const blob = this.deviceHelperService.isIOSPlatform
                ? this.fileService.base64ToBlob('data:image/jpeg;base64,' + imageData, 'image/jpeg', 512, fileName)
                : await this.getFileEntry(imageData);

            this.camera.cleanup();

            return blob;
        });
    }

    getFileEntry(imgUri): Promise<any> {
        const imageUri = imgUri.startsWith(this.filePrefix) ? imgUri : `${this.filePrefix}${imgUri}`;

        return new Promise((resolve, reject) => {
            try {
                this.windowRef.resolveLocalFileSystemURL(imageUri, (fileEntry: FileEntry) => {
                    this.createBlob(fileEntry).then((blob) => {
                        resolve(blob);
                    });
                });
            } catch (e) {
                reject(e);
            }
        });
    }

    async isValidImage(file): Promise<boolean> {
        if (!file) {
            return false;
        }

        if (file.size >= this.maxFileSize) {
            await this.toastService.showMessage(this.errors.maxFileSize);

            return false;
        }

        if (!this.allowedFileExtensions.includes(file.type)) {
            await this.toastService.showMessage(this.errors.fileExtension);

            return false;
        }

        return true;
    }

    createObjectURL(image): any {
        return this.winUrl.createObjectURL(image);
    }

    revokeObjectURL(image): void {
        this.winUrl.revokeObjectURL(image);
    }

    getBackgroundImage(backgroundUrl): SafeStyle {
        return this.sanitizer.bypassSecurityTrustStyle(
            `url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8bw8AAkMBQJZrKwoAAAAASUVORK5CYII=) center center / cover  no-repeat, url("${backgroundUrl}") center center / cover  no-repeat`
        );
    }

    converterEngine(input): string {
        // fn BLOB => Binary => Base64 ?
        const uInt8Array = new Uint8Array(input);
        let i = uInt8Array.length;
        const biStr = []; //new Array(i);

        while (i--) {
            biStr[i] = String.fromCharCode(uInt8Array[i]);
        }
        const base64 = window.btoa(biStr.join(''));

        return base64;
    }

    async rotateBase64Image(base64Image: string | ArrayBuffer, degrees: number, fileType: string): Promise<string> {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const img = new Image();

        img.src = base64Image as string;

        return await new Promise((resolve, reject) => {
            try {
                img.onload = () => {
                    const width = img.width;
                    const height = img.height;

                    if (Math.abs(degrees) === 90) {
                        canvas.width = height;
                        canvas.height = width;
                    } else {
                        canvas.width = width;
                        canvas.height = height;
                    }

                    // transform context before drawing image
                    switch (degrees) {
                        case -180:
                            ctx.transform(-1, 0, 0, -1, width, height);
                            break;
                        case 90:
                            ctx.transform(0, 1, -1, 0, height, 0);
                            break;
                        case -90:
                            ctx.transform(0, -1, 1, 0, 0, width);
                            break;
                        default:
                            break;
                    }
                    ctx.drawImage(img, 0, 0);

                    resolve(canvas.toDataURL(fileType, 100));
                    canvas.remove();
                };
            } catch (e) {
                reject(e);
            }
        });
    }

    createBlob(fileEntry): Promise<any> {
        return new Promise((resolve, reject) => {
            fileEntry.file((resFile) => {
                const reader = new FileReader();

                reader.onloadend = (evt: any) => {
                    const imgBlob: any = new Blob([evt.target.result], { type: 'image/jpeg' });

                    imgBlob.name = resFile.name;
                    resolve(imgBlob);
                };

                reader.onerror = (e) => {
                    // eslint-disable-next-line no-console
                    console.log('Failed file read: ' + e.toString());
                    reject(e);
                };

                reader.readAsArrayBuffer(resFile);
            });
        });
    }
}

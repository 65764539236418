import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { ModalController } from '@ionic/angular';

import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import omitBy from 'lodash/omitBy';

import { QuoteService } from '@core/services/quote.service';
import { StorageService } from '@core/services/storage.service';
import { paradigmFinanceOfferId } from '@shared/constants/paradigm-finance-offer-id';
import { BaseModal } from '@shared/modals/base-modal';
import { ValidateMatchPattern } from '@shared/validators/match-pattern.validator';

@Component({
    selector: 'vendo-request-financing-application-modal',
    templateUrl: './request-financing-application-modal.component.html',
    styleUrls: ['./request-financing-application-modal.component.scss']
})
export class RequestFinancingApplicationModalComponent extends BaseModal implements OnInit {
    @Input() quoteId: number;
    @Input() offerCodeName: number;
    @Input() isApplyFinancing = false;

    form = new UntypedFormGroup({
        offer_code: new UntypedFormControl(null),
        first_name: new UntypedFormControl({ value: '', disabled: true }),
        last_name: new UntypedFormControl({ value: '', disabled: true }),
        address_1: new UntypedFormControl({ value: '', disabled: true }),
        address_2: new UntypedFormControl({ value: '', disabled: true }),
        city: new UntypedFormControl({ value: '', disabled: true }),
        state: new UntypedFormControl({ value: '', disabled: true }),
        zip_code: new UntypedFormControl({ value: '', disabled: true }),
        email: new UntypedFormControl('', [Validators.email, Validators.required]),
        phone: new UntypedFormControl({ value: '', disabled: true }, [
            ValidateMatchPattern(
                /^[\+]?\d{0,}?[-\s\.]?[(]?\d{2,}[)]?[-\s\.]?\d{2,}?[-\s\.]?\d{2,}[-\s\.]?\d{0,9}$/im,
                'Phone Number has wrong format'
            ),
            Validators.required
        ])
    });
    selectedOption = '';

    constructor(
        public modalController: ModalController,
        public storageService: StorageService,
        public quoteService: QuoteService
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        if (this.offerCodeName) {
            this.form.get('offer_code').setValue(this.offerCodeName);
        }

        this.storageService.get('activeAppointment').then((appointment) => {
            const selectedOption = !appointment.customer.email && appointment.customer.phone_number ? 'phone' : 'email';

            this.changeOption(selectedOption);
            this.form.patchValue({
                email: appointment.customer.email,
                phone: appointment.customer.phone_number,
                first_name: appointment.customer.first_name,
                last_name: appointment.customer.last_name,
                address_1: appointment.house.address1,
                address_2: appointment.house.address2,
                city: appointment.house.city,
                state: appointment.house.state,
                zip_code: appointment.house.postal_code
            });
        });
    }

    changeOption(value: string): void {
        if (value === this.selectedOption) {
            return;
        }
        this.selectedOption = value;
        const emailControl: AbstractControl = this.form.get('email');
        const phoneControl: AbstractControl = this.form.get('phone');

        switch (value) {
            case 'email':
                emailControl.setValidators([Validators.required, Validators.email]);
                phoneControl.setValidators(
                    ValidateMatchPattern(
                        /^[\+]?\d{0,}?[-\s\.]?[(]?\d{2,}[)]?[-\s\.]?\d{2,}?[-\s\.]?\d{2,}[-\s\.]?\d{0,9}$/im,
                        'Phone Number has wrong format'
                    )
                );
                emailControl.enable();
                phoneControl.disable();
                break;
            case 'phone':
                emailControl.setValidators(Validators.email);
                phoneControl.setValidators([
                    Validators.required,
                    ValidateMatchPattern(
                        /^[\+]?\d{0,}?[-\s\.]?[(]?\d{2,}[)]?[-\s\.]?\d{2,}?[-\s\.]?\d{2,}[-\s\.]?\d{0,9}$/im,
                        'Phone Number has wrong format'
                    )
                ]);
                emailControl.disable();
                phoneControl.enable();
                break;
        }
        emailControl.updateValueAndValidity();
        phoneControl.updateValueAndValidity();
    }

    save(): void {
        this.form.markAllAsTouched();

        if (this.form.invalid) {
            return;
        }

        const data = omitBy(this.form.getRawValue(), (val) => !val);

        if (this.selectedOption === 'email') {
            delete data.phone;
        } else {
            delete data.email;
        }

        if (this.isApplyFinancing) {
            this.quoteService
                .setFinancingOption(this.quoteId, paradigmFinanceOfferId)
                .pipe(switchMap(() => this.requestFinancingApplication(data)))
                .subscribe();
        } else {
            this.requestFinancingApplication(data).subscribe();
        }
    }

    private requestFinancingApplication(data): Observable<void> {
        return this.quoteService.requestFinancingApplication(this.quoteId, data).pipe(tap(() => this.dismiss(true)));
    }
}

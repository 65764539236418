import { Injectable } from '@angular/core';

import { Network } from '@awesome-cordova-plugins/network/ngx';

import { AlertController } from '@ionic/angular';

import { BehaviorSubject } from 'rxjs';

import { isActiveOfflineMode } from '@core/functions/is-active-offline-mode';
import { FeaturesService } from '@core/services/features.service';
import { NetworkStatus } from '@shared/enums/network-status';

import { AppointmentsService } from '../../main/appointments/services/appointments.service';

@Injectable({
    providedIn: 'root'
})
export class NetworkService {
    private networkStatus: BehaviorSubject<NetworkStatus> = new BehaviorSubject<NetworkStatus>(NetworkStatus.Online);
    get currentNetworkStatus(): NetworkStatus {
        return this.networkStatus.getValue();
    }
    private modalShown = false;

    constructor(
        private alertController: AlertController,
        private appointmentsService: AppointmentsService,
        private featuresService: FeaturesService,
        private network: Network
    ) {
        // HACK: fix alert not presented when offline, due to lazy loading the alert controller.
        // Can be removed once #17450 is resolved: https://github.com/ionic-team/ionic/issues/17450
        this.alertController.create({ animated: false }).then((t) => {
            t.present();
            t.dismiss();
        });
    }

    initializeNetworkEvents(): void {
        this.network.onChange().subscribe((status: NetworkStatus) => {
            if (status === NetworkStatus.Offline && !isActiveOfflineMode()) {
                this.showAlert();
            }
            this.networkStatus.next(status);
        });
    }

    async showAlert(): Promise<void> {
        if (this.modalShown) {
            return;
        }

        this.modalShown = true;
        let header: string;
        let message: string;
        const buttons = [
            {
                text: 'Dismiss',
                handler: () => {
                    this.modalShown = false;
                }
            }
        ];

        if (this.featuresService.isOfflineModeAvailable()) {
            const isSynchronizing: boolean = this.appointmentsService.getIsSynchronizing();

            header = 'No Internet Connection Available';
            message = isSynchronizing
                ? 'Your demo resources are not finished downloading to your device. If you continue to offline mode, some demo resources may not be available or may be broken. Changes you make to your appointment will be synced up once online.'
                : 'Turn offline mode on to keep working on your appointment. Items will be synced up once online.';
            buttons.push({
                text: 'Go to offline mode',
                handler: () => {
                    window.localStorage.setItem('offline_mode', '1');
                    (window as any).location.reload();
                }
            });
        } else {
            header = 'Lost Internet Connectivity';
            message = 'Please check your internet connection to regain full functionality.';
        }

        const alert = await this.alertController.create({
            header,
            message,
            backdropDismiss: false,
            buttons
        });

        alert.onDidDismiss().then(() => (this.modalShown = false));
        await alert.present();
    }
}

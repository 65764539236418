import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

import { merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationService } from '@core/services/notification.service';
import { Unsubscriber } from '@shared/helpers/unsubscriber';
import { Notification } from '@shared/interfaces/notification';

@Component({
    selector: 'vendo-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent extends Unsubscriber implements OnInit {
    @HostBinding('class.ml-80') @Input() isShowLeftMenu: boolean;
    @Output() closed: EventEmitter<Notification> = new EventEmitter<Notification>();
    notifications: Notification[] = [];

    constructor(private notificationService: NotificationService) {
        super();
    }

    ngOnInit(): void {
        merge(
            this.notificationService.requestApprovalNotifications$,
            this.notificationService.transactionStatusNotifications$
        )
            .pipe(takeUntil(this.destroy$))
            .subscribe((notification: Notification) => {
                this.removeNotification(notification.id);
                this.notifications.push(notification);
            });

        this.notificationService.autoCloseNotification$
            .pipe(takeUntil(this.destroy$))
            .subscribe((id: string) => this.removeNotification(id));
    }

    close(notification: Notification, index: number): void {
        this.removeNotification(notification.id, index);
        this.closed.emit(notification);
    }

    private removeNotification(notificationId: string, index?: number): void {
        if (index === undefined) {
            index = this.notifications.findIndex(({ id }) => id === notificationId);
        }

        if (index > -1) {
            this.notifications.splice(index, 1);
        }
    }
}

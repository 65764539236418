import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'wcpDrawingUrl'
})
export class WcpDrawingUrlPipe implements PipeTransform {
    transform(drawingUrl: string, baseUrl: string): string {
        if (!baseUrl) {
            return drawingUrl;
        }

        return `https://${baseUrl}/config${drawingUrl}`;
    }
}

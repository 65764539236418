<div class="modal-header">
    <ion-text class="title">User Preferences</ion-text>
</div>

<div class="modal-body">
    <ion-content>
        <ion-list *ngFor="let group of availablePreferences">
            <ion-list-header *ngIf="group.values.length">
                {{ group.label }}
            </ion-list-header>
            <ng-container *ngFor="let preference of group.values">
                <ion-item *ngIf="preference.isShow">
                    <ion-label>
                        {{ preference.label }}
                    </ion-label>
                    <ion-toggle *ngIf="userPreferences[preference.hash]"
                                mode="md"
                                slot="end"
                                [id]="preference.id"
                                [(ngModel)]="userPreferences[preference.hash].value"
                                (ngModelChange)="preferenceChanged(preference.hash)"></ion-toggle>
                </ion-item>
            </ng-container>
        </ion-list>
    </ion-content>
</div>

<div class="modal-footer">
    <ion-button class="classic-width" (click)="done()">Done</ion-button>
</div>

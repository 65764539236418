import { HttpHeaders, provideHttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { createFragmentRegistry } from '@apollo/client/cache';
import { ApolloClientOptions, ApolloLink, InMemoryCache, NextLink, Operation, split } from '@apollo/client/core';
import { DefaultOptions } from '@apollo/client/core/ApolloClient';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpBatchLink, HttpLink } from 'apollo-angular/http';
import { extractFiles } from 'extract-files';
import { createClient } from 'graphql-ws';

import { Platform } from '@ionic/angular';

import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

import { RollbarErrorHandler } from '@core/handlers/rollbar-error-handler';
import { DeviceHelperService } from '@core/services/device-helper.service';
import { GraphqlHelperService } from '@core/services/graphql-helper.service';
import { StorageService } from '@core/services/storage.service';

import { environment } from '../../../../environments/environment';
import { MeFragment } from '../../../components/auth/mutations/auth.mutations';

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'none'
    }
};

export function createApollo(
    httpBatchLink: HttpBatchLink,
    httpLink: HttpLink,
    graphqlHelperService: GraphqlHelperService,
    deviceHelperService: DeviceHelperService,
    platform: Platform,
    storageService: StorageService,
    rollbarErrorHandler: RollbarErrorHandler
): ApolloClientOptions<any> {
    const http = httpLink.create({
        uri(operation) {
            const silentMode = get(operation.getContext(), 'extensions.background');

            return silentMode ? '/graphql?background=true' : '/graphql';
        },
        headers: new HttpHeaders({
            'Apollo-Require-Preflight': 'true'
        }),
        extractFiles
    });

    const ws = new GraphQLWsLink(
        createClient({
            url: environment.wsUrl,
            connectionParams: async () => {
                await platform.ready();
                const { installedVersion, actualVersion } = await graphqlHelperService.getApplicationVersion();
                const token = await storageService.get('token');

                return {
                    'x-app-version': installedVersion || actualVersion,
                    'x-live-updated-version': deviceHelperService.isWeb
                        ? 'mobile-latest'
                        : actualVersion || installedVersion,
                    'x-token': token
                };
            }
        })
    );

    const telemetryLink = new ApolloLink((operation: Operation, forward: NextLink) => {
        return forward(operation).map((response) => {
            rollbarErrorHandler.captureEventFromTelemetry({
                requestData: {
                    query: cloneDeep(operation.query.loc?.source.body),
                    variables: cloneDeep(operation.variables)
                }
            });

            return response;
        });
    });

    const link = ApolloLink.from([
        telemetryLink,
        split(
            ({ query }) => {
                const { kind, operation } = getMainDefinition(query) as any;

                return kind === 'OperationDefinition' && operation === 'subscription';
            },
            ws,
            http
        )
    ]);

    return {
        link,
        cache: new InMemoryCache({
            fragments: createFragmentRegistry(MeFragment)
        }),
        defaultOptions
    };
}

@NgModule({
    exports: [ApolloModule],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [
                HttpBatchLink,
                HttpLink,
                GraphqlHelperService,
                DeviceHelperService,
                Platform,
                StorageService,
                RollbarErrorHandler
            ]
        },
        provideHttpClient()
    ]
})
export class GraphQLModule {}

<ng-container [formGroup]="formGroupRow">
    <div class="fields-row w-100">
        <ng-container *ngFor="let field of fieldsConfigs">
            <ng-container *ngIf="field.valueType !== fieldValues.Hidden">
                <ng-container *ngIf="isOldLabel && field.fieldType === fieldTypes.Dropdown || field.fieldType === fieldTypes.Input">
                    <ion-label class="old-label"
                               [class.required]="field.required !== false">
                        <span>{{ field.name }}</span>
                    </ion-label>
                </ng-container>

                <ng-container [ngSwitch]="field.fieldType">
                    <ion-item *ngSwitchCase="fieldTypes.Dropdown"
                              mode="md"
                              fill="outline"
                              [class.mb-20]="formGroupRow.get(field.key).valid || formGroupRow.get(field.key).untouched"
                              [ngStyle]="{ 'flex-basis': 'calc(' + (100 / fieldsConfigs.length) + '% - 10px)' }">
                        <ion-label [class.hidden]="isOldLabel" position="floating">
                            <span>{{ field.name }}</span>
                        </ion-label>
                        <ion-select legacy="true"
                                    mode="md"
                                    interface="popover"
                                    [interfaceOptions]="{mode: 'md', alignment: 'start'}"
                                    [placeholder]="field.name"
                                    [required]="field.required !== false"
                                    [formControlName]="field.key"
                                    (ionBlur)="markAsTouched()">
                            <ion-select-option *ngFor="let option of field.items"
                                               class="fs-14"
                                               [value]="option.value">
                                <span>{{ option.label }}</span>
                            </ion-select-option>
                        </ion-select>
                        <ion-note *ngIf="formGroupRow.get(field.key).invalid && formGroupRow.get(field.key).touched"
                                  slot="error">
                            <span *ngFor="let error of getMessages(formGroupRow.get(field.key).errors) | slice:0:1">
                                {{ error }}
                            </span>
                        </ion-note>
                    </ion-item>

                    <ion-item *ngSwitchCase="fieldTypes.Input"
                              mode="md"
                              fill="outline"
                              [class.mb-20]="formGroupRow.get(field.key).valid || formGroupRow.get(field.key).untouched"
                              [ngStyle]="{ 'flex-basis': 'calc(' + (100 / fieldsConfigs.length) + '% - 10px)' }">
                        <ion-label class="hidden" position="floating">
                            {{ field.name }}
                        </ion-label>
                        <ion-input legacy="true" [type]="field.valueType"
                                   [placeholder]="field.name"
                                   [required]="field.required !== false"
                                   [formControlName]="field.key"
                                   (ionBlur)="markAsTouched()"></ion-input>
                        <ion-note *ngIf="formGroupRow.get(field.key).invalid && formGroupRow.get(field.key).touched"
                                  slot="error">
                            <span *ngFor="let error of getMessages(formGroupRow.get(field.key).errors) | slice:0:1">
                                {{ error }}
                            </span>
                        </ion-note>
                    </ion-item>

                    <ion-item *ngSwitchCase="fieldTypes.Checkbox"
                              lines="none"
                              mode="md"
                              class="without-padding mb-20"
                              [ngStyle]="{ 'flex-basis': 'calc(' + (100 / fieldsConfigs.length) + '% - 10px)' }">
                        <ion-label>{{ field.name }}</ion-label>
                        <ion-checkbox legacy="true" slot="start"
                                      mode="md"
                                      [formControlName]="field.key"></ion-checkbox>
                    </ion-item>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</ng-container>

import { gql } from 'apollo-angular';

const GET_MEASURE_QUOTE = gql`
    query MeasureQuote($appointmentId: ID!) {
        measureQuote(appointment_id: $appointmentId) {
            id
            config_adders {
                adder_id
                amount
                quantity
                position
                created_from
                applied_type
                type
                adder {
                    id
                    name
                    categories {
                        name
                    }
                    applies_to
                    taxable
                    default
                    amount
                    amount_type
                    variable
                    visible
                    short_description
                    description
                }
            }
            measure_configurations {
                opening_id
                quote_id
                confirmed
                opening {
                    name
                    catalog_id
                    quantity
                    images {
                        type
                        appointment_type
                        name
                        note
                        url
                        position
                        opening_id
                    }
                }
                wcp_changes {
                    label
                    old
                    new
                }
                custom_changes {
                    label
                    old
                    new
                }
                wcp_answers
            }
        }
    }
`;

const GET_MEASURE_CONFIGURATOR_WITH_OPENING = gql`
    query MeasureConfiguratorWithOpening(
        $quoteId: ID!
        $openingId: ID!
        $productListSelections: ProductListSelections
        $productImageUrl: String
        $type: MeasureConfigurationTypeEnum
    ) {
        measureConfigurator(
            quote_id: $quoteId
            opening_id: $openingId
            product_list_selections: $productListSelections
            product_image_url: $productImageUrl
            type: $type
        )
        opening(opening_id: $openingId) {
            id
            name
            notes
            images {
                appointment_id
                opening_id
                type
                name
                note
                url
                position
                appointment_type
            }
            quantity
            view_coordinates
            category {
                id
                category_type
                catalog_type
                allow_configure_questions
            }
            catalog {
                is_hide_product_image
                allow_request_hidden_step_questions
                predefined_images {
                    appointment_type
                    category_type
                    predefined_images {
                        label
                        is_required
                    }
                }
            }
            view_photo {
                img_url
            }
            catalog_id
        }
    }
`;

const GET_MEASURE_QUOTE_CHANGES = gql`
    query MeasureQuoteChanges($appointmentId: ID!) {
        measureQuoteChanges(appointment_id: $appointmentId) {
            line_items {
                name {
                    old
                    new
                }
                adders {
                    label
                    old
                    new
                }
                changes {
                    label
                    old
                    new
                }
                wcp_changes {
                    label
                    old
                    new
                }
                custom_changes {
                    label
                    old
                    new
                }
            }
            adders {
                label
                old
                new
            }
            measure_notes
        }
    }
`;

const GET_CATEGORIES = gql`
    query CategoriesList($appointmentId: ID!) {
        myCategories(appointment_id: $appointmentId) {
            allow_pricing_profiles
            use_provia_portal_login
            category {
                id
                category_type
            }
            id
            name
        }
    }
`;

export { GET_MEASURE_QUOTE, GET_MEASURE_QUOTE_CHANGES, GET_MEASURE_CONFIGURATOR_WITH_OPENING, GET_CATEGORIES };

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { DocumentViewer } from '@awesome-cordova-plugins/document-viewer/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { SecureStorageEcho } from '@awesome-cordova-plugins/secure-storage-echo/ngx';

import { GraphQLModule } from './modules/graphql/graphql.module';
import { AuthGuard } from '@core/guards/auth.guard';
import { OfflineModeGuard } from '@core/guards/offline-mode.guard';
import { DeviceHelperService } from '@core/services/device-helper.service';

@NgModule({
    declarations: [],
    imports: [CommonModule, GraphQLModule],
    providers: [
        AuthGuard,
        AppVersion,
        CallNumber,
        LaunchNavigator,
        FileTransfer,
        FileOpener,
        DocumentViewer,
        DeviceHelperService,
        OfflineModeGuard,
        SecureStorageEcho
    ],
    exports: [GraphQLModule]
})
export class CoreModule {}

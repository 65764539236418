import { Injectable } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { from, Observable, Subject } from 'rxjs';

import { StatusBarModalComponent } from '@shared/modals/status-bar-modal/status-bar-modal.component';

@Injectable({
    providedIn: 'root'
})
export class StatusBarService {
    private statusBarPercentage: Subject<number> = new Subject<number>();
    statusBarPercentage$: Observable<number> = this.statusBarPercentage.asObservable();
    statusBarModalDidDismiss$: Observable<any>;
    private modal: HTMLIonModalElement;

    constructor(private modalController: ModalController) {}

    async openStatusBarModal(text: string): Promise<void> {
        this.modal = await this.modalController.create({
            component: StatusBarModalComponent,
            backdropDismiss: false,
            cssClass: 'status-bar-modal',
            componentProps: {
                text
            }
        });

        await this.modal.present();

        this.statusBarModalDidDismiss$ = from(this.modal.onDidDismiss());
    }

    clearStatusBarModalDidDismiss(): void {
        this.statusBarModalDidDismiss$ = null;
    }

    async closeModal(): Promise<void> {
        if (this.modal) {
            await this.modal.dismiss();
            this.clearStatusBarModalDidDismiss();
        }
    }

    emitStatusBarPercentage(value: number): void {
        this.statusBarPercentage.next(value);
    }
}

<div class="invoice-form">
    <form [formGroup]="form">
        <div class="field-block">
            <ion-label class="required">Invoice Title</ion-label>
            <ion-input legacy="true"
                       type="text"
                       formControlName="title"
                       required
                       autocomplete="no"></ion-input>
            <div class="errors-wrapper" *ngIf="form.get('title').touched">
                <ion-text color="danger" class="error"
                          *ngFor="let error of getMessages(form.get('title').errors)">
                    {{ error }}
                </ion-text>
            </div>
        </div>

        <div class="field-block">
            <ion-label class="required">Products & Services</ion-label>
            <ion-input legacy="true" type="text"
                       formControlName="products_and_services"
                       required
                       autocomplete="no"></ion-input>
            <div class="errors-wrapper" *ngIf="form.get('products_and_services').touched">
                <ion-text color="danger" class="error"
                          *ngFor="let error of getMessages(form.get('products_and_services').errors)">
                    {{ error }}
                </ion-text>
            </div>
        </div>

        <div class="field-block">
            <ion-label class="required">Send to</ion-label>
            <ion-input legacy="true"
                       type="text"
                       formControlName="email"
                       required
                       autocomplete="no"></ion-input>
            <div class="errors-wrapper" *ngIf="form.get('email').touched">
                <ion-text color="danger" class="error"
                          *ngFor="let error of getMessages(form.get('email').errors)">
                    {{ error }}
                </ion-text>
            </div>
        </div>

        <div class="remaining-balance">
            <div class="field-block amount-field">
                <ion-label class="required">Amount</ion-label>
                <ion-input legacy="true"
                           type="number"
                           inputmode="numeric"
                           [pattern]="amountPattern"
                           formControlName="amount"
                           required
                           autocomplete="no"></ion-input>
                <div class="errors-wrapper" *ngIf="form.get('amount').touched">
                    <ion-text color="danger" class="error"
                              *ngFor="let error of getMessages(form.get('amount').errors)">
                        {{ error }}
                    </ion-text>
                </div>
            </div>

            <div class="toggle-wrapper">
                <ion-toggle mode="md"
                            [(ngModel)]="isRemainingBalanceType"
                            [ngModelOptions]="{standalone: true}"
                            (ionChange)="setToRemainingBalanceChanged()"></ion-toggle>
                <ion-label>Remaining Balance</ion-label>
            </div>
        </div>
    </form>
</div>

import { Component, Input, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

import find from 'lodash/find';

import { ToastService } from '@core/services/toast.service';
import {
    SeriesChangeGroup,
    SeriesChangeInput,
    SeriesChangeLineResult,
    SeriesChangesResult
} from '@shared/interfaces/series-change';
import { BaseModal } from '@shared/modals/base-modal';

import { SeriesChangesService } from '../../../main/appointments/configure/services/series-changes.service';

@Component({
    selector: 'vendo-series-changes-modal',
    templateUrl: './series-changes-modal.component.html',
    styleUrls: ['./series-changes-modal.component.scss']
})
export class SeriesChangesModalComponent extends BaseModal implements OnInit {
    @Input() lineItems: any[];
    @Input() packageName: string;
    @Input() quoteId: number;
    @Input() series: SeriesChangeGroup[];

    allItemsSelected: boolean[] = [];
    oneItemAtLeastSelected: boolean;
    selectedSeries: string[] = [];
    isNeedToReloadConfigurationList: boolean;

    constructor(
        modalController: ModalController,
        private seriesChangeService: SeriesChangesService,
        private toastService: ToastService
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.allItemsSelected = new Array(this.series.length).fill(false);
        this.selectedSeries = new Array(this.series.length).fill(null);
        this.series.forEach((item: SeriesChangeGroup) => {
            item.lineItems = [];
            item.opening_ids.forEach((id: number) => {
                item.lineItems.push({
                    selected: false,
                    ...find(this.lineItems, { id: `${id}` })
                });
            });
        });
    }

    handleMassSelect(index: number): void {
        this.series[index].lineItems.forEach((item) => (item.selected = this.allItemsSelected[index]));
        this.setOneItemAtLeastSelected();
    }

    itemSelectionChanged(index: number): void {
        this.allItemsSelected[index] = this.series[index].lineItems.every(({ selected }) => selected);
        this.setOneItemAtLeastSelected();
    }

    async save(): Promise<any> {
        const data: SeriesChangeInput[] = [];

        this.series.forEach((item: SeriesChangeGroup, index: number) => {
            if (!this.selectedSeries[index]) {
                return;
            }

            const openingIds: number[] = item.lineItems
                .filter(({ selected }) => selected)
                .map((line) => {
                    delete line.errors;

                    return Number(line.id);
                });

            if (!openingIds.length) {
                return;
            }

            data.push({
                series_name: this.selectedSeries[index],
                opening_ids: openingIds
            });
        });

        if (!data.length) {
            await this.toastService.showMessage('Please select line items to update and/or a series!');

            return;
        }

        this.seriesChangeService.applySeriesChange(this.quoteId, data).subscribe((res: SeriesChangesResult) => {
            if (!this.isNeedToReloadConfigurationList) {
                this.isNeedToReloadConfigurationList = res.ExistUpdatedLines;
            }

            if (res.LineResults?.length) {
                res.LineResults.forEach((lineItem: SeriesChangeLineResult) => {
                    for (const item of this.series) {
                        const currentLine = item.lineItems.find((line) =>
                            find(line.opening_configurations, { wcp_item_id: lineItem.LineItemMasterId })
                        );

                        if (currentLine) {
                            currentLine.errors = lineItem.UpdateStatus === 'Failed' ? lineItem.Messages : [];
                            break;
                        }
                    }
                });
            }

            const isFailedLinesExist = res.LineResults?.some((lineItem) => lineItem.UpdateStatus === 'Failed');

            if (!isFailedLinesExist) {
                this.dismiss(this.isNeedToReloadConfigurationList);
            }
        });
    }

    setOneItemAtLeastSelected(): void {
        this.oneItemAtLeastSelected = Boolean(
            this.series.filter(
                (item: SeriesChangeGroup, index: number) =>
                    this.selectedSeries[index] && item.lineItems.some(({ selected }) => selected)
            ).length
        );
    }
}

import { Component, OnInit } from '@angular/core';

import { AlertController, ModalController } from '@ionic/angular';

import { forkJoin } from 'rxjs';

import cloneDeep from 'lodash/cloneDeep';
import keyBy from 'lodash/keyBy';
import some from 'lodash/some';

import { AuthService } from '@core/services/auth.service';
import { DeviceHelperService } from '@core/services/device-helper.service';
import { FeaturesService } from '@core/services/features.service';
import { PriceConfigurationService } from '@core/services/price-configuration.service';
import { StepsService } from '@core/services/steps.service';
import { StorageService } from '@core/services/storage.service';
import { UserPreferencesService } from '@core/services/user-preferences.service';
import { USER_PREFERENCES_GROUPS } from '@shared/constants/user-preferences';
import { AppointmentType } from '@shared/enums/appointment-type';
import { PriceConfigurationDisplayStatus } from '@shared/enums/price-configuration-display-status';
import { SaleStepType } from '@shared/enums/sale-step-type.enum';
import { UserPreference } from '@shared/enums/user-preference.enum';
import { BaseModal } from '@shared/modals/base-modal';

@Component({
    selector: 'vendo-user-preferences-modal',
    templateUrl: './user-preferences-modal.component.html',
    styleUrls: ['./user-preferences-modal.component.scss']
})
export class UserPreferencesModalComponent extends BaseModal implements OnInit {
    availablePreferences: any[] = [];
    userPreferences: { [key in UserPreference]?: any } = {};

    constructor(
        private alertController: AlertController,
        private authService: AuthService,
        private deviceHelperService: DeviceHelperService,
        private featureService: FeaturesService,
        public modalController: ModalController,
        private priceConfigurationService: PriceConfigurationService,
        private stepsService: StepsService,
        private storageService: StorageService,
        private userPreferenceService: UserPreferencesService
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        forkJoin([
            this.userPreferenceService.getPreferences([
                UserPreference.ShowPricing,
                UserPreference.DefaultDepositAsPercent,
                UserPreference.AugmentRealityMeasurement,
                UserPreference.BoschMeasurement,
                UserPreference.QuickTakeoff,
                UserPreference.CustomProductPromotion,
                UserPreference.ShowPriceModifiers,
                UserPreference.BiometricLogin
            ]),
            this.priceConfigurationService.allowDisplayListPriceStatus(),
            this.priceConfigurationService.allowDisplayPriceModifiersStatus(),
            this.deviceHelperService.isFingerPrintAvailable()
        ]).subscribe(
            ([preferences, allowDisplayListPriceStatus, allowDisplayPriceModifiersStatus, isFingerPrintAvailable]) => {
                this.userPreferences = keyBy(preferences, 'type');

                const userPreferencesGroups = cloneDeep(USER_PREFERENCES_GROUPS);

                this.availablePreferences = userPreferencesGroups.filter((group: any) => {
                    group.values = group.values.filter(
                        (value: any) => !value.features || this.featureService.hasFeature(value.features)
                    );

                    group.values.forEach((value) => {
                        if (value.hash === UserPreference.BiometricLogin) {
                            value.isShow = isFingerPrintAvailable;
                        }

                        if (value.hash === UserPreference.ShowPricing) {
                            this.setPricePreferenceValue(value.hash, allowDisplayListPriceStatus);

                            value.isShow = PriceConfigurationDisplayStatus.AlwaysOff !== allowDisplayListPriceStatus;
                        }

                        if (value.hash === UserPreference.ShowPriceModifiers) {
                            this.setPricePreferenceValue(value.hash, allowDisplayPriceModifiersStatus);

                            value.isShow =
                                PriceConfigurationDisplayStatus.AlwaysOff !== allowDisplayPriceModifiersStatus;
                        }
                    });

                    if (group.label.startsWith('Window')) {
                        this.replaceTakeOffLabels(group);
                    }

                    return (
                        (!group.features || this.featureService.hasFeature(group.features, false)) &&
                        some(group.values, (value) => value.isShow)
                    );
                });
            }
        );
    }

    async preferenceChanged(hash: UserPreference): Promise<void> {
        if (hash === UserPreference.BiometricLogin && this.userPreferences[hash].value) {
            const alert = await this.alertController.create({
                header: 'Please confirm',
                message:
                    'Anyone with access via a stored biometric authenticator or that knows the passcode on the device can log in to the application as the user. Would you like to proceed?',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => this.setPreviousValue(hash)
                    },
                    {
                        text: 'Proceed',
                        handler: () => this.savePreference(hash)
                    }
                ]
            });

            await alert.present();

            return;
        }

        this.savePreference(hash);
    }

    done(): void {
        this.closeModal();
    }

    private savePreference(hash: UserPreference): void {
        this.userPreferenceService.saveUserPreference(hash, this.userPreferences[hash].value).subscribe(
            async () => {
                if (hash === UserPreference.BiometricLogin) {
                    if (this.userPreferences[hash].value) {
                        const userName: string = await this.storageService.get('userName');

                        this.authService.getBiometricID(this.deviceHelperService.getDeviceUUID, userName);
                    } else {
                        this.authService.removeDataForBiometricLogin();
                    }
                }
            },
            () => this.setPreviousValue(hash)
        );
    }

    private setPreviousValue(hash: UserPreference): void {
        this.userPreferences[hash].value = !this.userPreferences[hash].value;
    }

    private setPricePreferenceValue(hash: string, status: PriceConfigurationDisplayStatus): void {
        if (!this.userPreferences[hash].__typename) {
            this.userPreferences[hash].value = [
                PriceConfigurationDisplayStatus.DefaultOn,
                PriceConfigurationDisplayStatus.AlwaysOffInConfigurator
            ].includes(status);
        }
    }

    private replaceTakeOffLabels(group: any): void {
        const value = group.values.find((value) => (value.hash = 'quick_takeoff'));
        const takeoffStepName: string = this.stepsService.getStepName(AppointmentType.Sales, SaleStepType.TAKE_OFF);

        group.label = `Window ${takeoffStepName}`;
        value.label = `Quick ${takeoffStepName}`;
    }
}

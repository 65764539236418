<div class="modal-header">
    <ion-text class="title">Promotions Summary</ion-text>
    <ion-button fill="outline" color="dark" (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
</div>

<div class="modal-body">
    <ion-grid>
        <ion-content>
            <ion-row *ngFor="let discount of discounts; let i = index" class="row-item">
                <ion-col size="7">
                    <div class="label">
                        {{ discount.name }} Savings
                    </div>
                    <div *ngIf="discount.expire_at" class="sub-label">
                        Exp {{ discount.expire_at | localizedDate }}
                    </div>
                </ion-col>
                <ion-col size="2.5">
                    {{ discountTypes[i] }}
                </ion-col>
                <ion-col class="value" size="2.5">
                    <ng-container *ngIf="discount.discount_amount">-</ng-container>{{ discount.discount_amount | customCurrency: true }}
                </ion-col>
            </ion-row>
        </ion-content>

        <ion-row class="row-item total-promotion">
            <ion-col size="8">
                <span class="label">
                  {{ totalPromotionLabel }}
                </span>
            </ion-col>
            <ion-col class="value" size="4">
                {{ totalPromotion | customCurrency: true }}
            </ion-col>
        </ion-row>
    </ion-grid>
</div>

import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    COMPLETE_QUOTE_DOCUMENT,
    PICK_CUSTOMER_SIGNATURE_TYPE,
    PICK_QUOTE_DOCUMENTS,
    SET_DOCUMENT_SIGNERS,
    STORE_QUOTE_DOCUMENT,
    UPLOAD_CUSTOMER_DOCUMENT_IMAGE,
    UPLOAD_SELLER_DOCUMENT_IMAGE
} from '../mutations/document.mutations';
import {
    GET_DOCUMENT_CUSTOMERS,
    GET_DOCUMENTS_SUMMARY,
    GET_DOCUMENTS_SUMMARY_AND_CUSTOMERS,
    GET_DOCUMENTS_USE_SUMMARY,
    GET_QUOTE_DOCUMENT,
    GET_QUOTE_DOCUMENTS,
    GET_QUOTES_DOCUMENTS,
    GET_RESULT_ABLE_DOCUMENTS,
    GET_SEND_ABLE_DOCUMENTS,
    GET_SIGN_ABLE_DOCUMENTS,
    GET_MY_SIGNATURE_AND_INITIALS,
    HAS_PENDING_DOCUMENTS
} from '../queries/document.queries';
import { AvailableDocument } from '@shared/enums/available-document';
import { DocumentType } from '@shared/enums/document-type';
import { SignatureType } from '@shared/enums/signature-type';
import { SigningType } from '@shared/enums/signing-type';
import { CustomerSigner } from '@shared/interfaces/customer-signer';
import { Document, QuoteDocument, QuoteWithDocuments } from '@shared/interfaces/document';
import { DocumentInstruction } from '@shared/interfaces/document-instruction';
import { DocumentsSummary } from '@shared/interfaces/documents-summary';

@Injectable({
    providedIn: 'root'
})
export class DocumentsService {
    constructor(private apollo: Apollo) {}

    getMySignatureAndInitials(): Observable<any> {
        return this.apollo
            .query({
                query: GET_MY_SIGNATURE_AND_INITIALS
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data));
    }

    hasPendingDocuments(appointmentId: number): Observable<boolean> {
        return this.apollo
            .query({
                query: HAS_PENDING_DOCUMENTS,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.myDocumentsSummary.has_pending_documents));
    }

    getAvailableDocuments(appointmentId: string): Observable<AvailableDocument[]> {
        return this.apollo
            .query({
                query: GET_SIGN_ABLE_DOCUMENTS,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.mySignAbleDocuments));
    }

    getAvailableESignDocuments(appointmentId: string): Observable<any> {
        return this.apollo
            .query({
                query: GET_SEND_ABLE_DOCUMENTS,
                variables: {
                    appointmentId
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) => {
                    return res.data.mySendAbleDocuments.reduce(
                        (acc, document) => {
                            switch (document.type) {
                                case DocumentType.PromoCardContract:
                                    acc.promoCardContracts.push(document);
                                    break;
                                case DocumentType.Attachment:
                                    acc.attachments.push(document);
                                    break;
                                case DocumentType.InspectionReport:
                                    acc.inspectionReports.push(document);
                                    break;
                                case DocumentType.PriceConditioning:
                                    acc.priceConditionings.push(document);
                                    break;
                                case DocumentType.MeasurementReport:
                                    acc.measurementReports.push(document);
                                    break;
                                case DocumentType.AppointmentFile:
                                    acc.appointmentFiles.push(document);
                                    break;
                                default:
                                    acc.documents.push(document);
                                    break;
                            }

                            return acc;
                        },
                        {
                            documents: [],
                            promoCardContracts: [],
                            attachments: [],
                            inspectionReports: [],
                            priceConditionings: [],
                            measurementReports: [],
                            appointmentFiles: []
                        }
                    );
                })
            );
    }

    getQuotesDocuments(appointmentId: string): Observable<QuoteWithDocuments[]> {
        return this.apollo
            .query({
                query: GET_QUOTES_DOCUMENTS,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.myQuotesDocuments));
    }

    getQuoteDocuments(appointmentId: string, quoteId: string): Observable<QuoteWithDocuments> {
        return this.apollo
            .query({
                query: GET_QUOTE_DOCUMENTS,
                variables: {
                    appointmentId,
                    quoteId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.myQuoteDocuments));
    }

    getDocumentsSummaryAndCustomers(
        appointmentId: string
    ): Observable<{ myDocumentCustomers: CustomerSigner[]; myDocumentsSummary: DocumentsSummary }> {
        return this.apollo
            .query({
                query: GET_DOCUMENTS_SUMMARY_AND_CUSTOMERS,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data));
    }

    getDocumentsSummary(appointmentId: string, isUseSummary = false): Observable<DocumentsSummary> {
        return this.apollo
            .query({
                query: isUseSummary ? GET_DOCUMENTS_USE_SUMMARY : GET_DOCUMENTS_SUMMARY,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.myDocumentsSummary));
    }

    getDocumentCustomers(appointmentId: string): Observable<CustomerSigner[]> {
        return this.apollo
            .query({
                query: GET_DOCUMENT_CUSTOMERS,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.myDocumentCustomers));
    }

    getQuoteDocument(id: string): Observable<Document> {
        return this.apollo
            .query({
                query: GET_QUOTE_DOCUMENT,
                variables: {
                    id
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.myQuoteDocument));
    }

    getFollowUpDocuments(appointmentId: string | number): Observable<any[]> {
        return this.apollo
            .query({
                query: GET_RESULT_ABLE_DOCUMENTS,
                variables: {
                    appointmentId
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) => {
                    return res.data.myResultAbleDocuments.map((document) => {
                        document.selected = document.signed;

                        return document;
                    });
                })
            );
    }

    pickQuoteDocuments(quoteId: string, documentTemplateIds: string[]): Observable<QuoteWithDocuments> {
        return this.apollo
            .mutate({
                mutation: PICK_QUOTE_DOCUMENTS,
                variables: {
                    quoteId,
                    documentTemplateIds
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.pickQuoteDocuments));
    }

    setDocumentSigners(appointmentId: string, customerIds: string[]): Observable<any> {
        return this.apollo.mutate({
            mutation: SET_DOCUMENT_SIGNERS,
            variables: {
                appointmentId,
                customerIds
            }
        });
    }

    pickCustomerSignatureType(appointmentId: string, signingOn: SigningType, customerId?: string): Observable<any> {
        return this.apollo.mutate({
            mutation: PICK_CUSTOMER_SIGNATURE_TYPE,
            variables: {
                appointmentId,
                signingOn,
                customerId
            }
        });
    }

    storeQuoteDocument(
        id: string,
        instructions: DocumentInstruction[],
        updatedAt: string,
        leftPlacesToSign: number
    ): Observable<QuoteDocument> {
        return this.apollo
            .mutate({
                mutation: STORE_QUOTE_DOCUMENT,
                variables: {
                    id,
                    instructions,
                    updatedAt,
                    leftPlacesToSign
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res?.data?.storeMyQuoteDocument || null));
    }

    completeQuoteDocument(
        id: string,
        customerId: string,
        instructions: DocumentInstruction[],
        updatedAt: string,
        leftPlacesToSign: number
    ): Observable<QuoteDocument> {
        return this.apollo
            .mutate({
                mutation: COMPLETE_QUOTE_DOCUMENT,
                variables: {
                    id,
                    instructions,
                    updatedAt,
                    customerId,
                    leftPlacesToSign
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.customerSignQuoteDocument));
    }

    uploadSellerSignatureImage(type: SignatureType, file: File): Observable<string> {
        return this.apollo
            .mutate({
                mutation: UPLOAD_SELLER_DOCUMENT_IMAGE,
                variables: {
                    type,
                    file
                },
                context: {
                    useMultipart: true
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.uploadUserImage));
    }

    uploadCustomerSignatureImage(customerId: string, type: SignatureType, file: File): Observable<string> {
        return this.apollo
            .mutate({
                mutation: UPLOAD_CUSTOMER_DOCUMENT_IMAGE,
                variables: {
                    customerId,
                    type,
                    file
                },
                context: {
                    useMultipart: true
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.uploadCustomerImage));
    }
}

<div class="modal-header">
    <ion-text class="title">Copying Package Configurations - {{ copyingPackage.name }}</ion-text>
</div>

<div class="modal-body">
    <div class="help-text">
        Select Packages to which you want to copy the current Package
    </div>

    <ion-list>
        <ion-item *ngFor="let package of copyToList">
            <ion-checkbox mode="md"
                          [(ngModel)]="package.isChecked"
                          (ionChange)="checkHandler()">
                {{ package.name }}
            </ion-checkbox>
        </ion-item>
    </ion-list>
</div>

<div class="modal-footer">
    <ion-button class="classic-width outline" (click)="closeModal()">Cancel</ion-button>
    <ion-button class="classic-width" (click)="copy()" [disabled]="!canDoCopy">Copy</ion-button>
</div>

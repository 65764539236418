import { Component, forwardRef, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import {
    ControlValueAccessor,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validators
} from '@angular/forms';

import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { RuleRowComponent } from '@shared/controls/rule-builder/rule-row/rule-row.component';
import { AbstractForm } from '@shared/helpers/abstract-form';
import { FieldSetting } from '@shared/interfaces/form-fields-settings';
import { ValidateItemUnique } from '@shared/validators/array-item-unique.validator';
import { ValidateArrayMaxLength } from '@shared/validators/array-max-length.validator';

@Component({
    selector: 'vendo-rule-builder',
    templateUrl: './rule-builder.component.html',
    styleUrls: ['./rule-builder.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RuleBuilderComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => RuleBuilderComponent),
            multi: true
        }
    ]
})
export class RuleBuilderComponent extends AbstractForm implements ControlValueAccessor, OnInit, OnChanges {
    @Input() config: FieldSetting[] = [];
    @Input() initialRequiredValues: any[] = [];
    @Input() isHandleDisablingDependsOn = true;
    @Input() addButtonName: string;
    @Input() isCanAddOrEdit = true;
    @Input() maxRows: number;
    @Input() buttonParams: { width: string; position: 'left' | 'center' | 'right' } = null;
    @Input() isRequiredAtLeastOne = false;
    @Input() fieldIndexOfUniqueValues: number[];
    values: any[] = [];
    form: UntypedFormGroup;
    @ViewChildren(RuleRowComponent) ruleComponents: QueryList<RuleRowComponent>;

    constructor(private formBuilder: UntypedFormBuilder) {
        super();
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            rules: this.formBuilder.array([], this.getValidators())
        });

        this.rulesFormArray.valueChanges
            .pipe(debounceTime(100), distinctUntilChanged(), takeUntil(this.destroy$))
            .subscribe((rules: any[]) => this.onChange(rules));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.form && this.maxRows && changes.maxRows?.currentValue) {
            this.rulesFormArray.setValidators(this.getValidators());
            this.rulesFormArray.updateValueAndValidity();
        }
    }

    registerOnChange(fn: (T) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.form.disable() : this.form.enable();
    }

    writeValue(values: any): void {
        this.rulesFormArray.clear();
        if (values && Array.isArray(values)) {
            values.forEach((row) => this.addRow(row));
        }
    }

    onChange(val: any): void {}

    onTouched(): void {}

    validate(control: UntypedFormControl): ValidationErrors {
        if (control && this.form.invalid) {
            return { required: true };
        }

        return null;
    }

    get rulesFormArray(): UntypedFormArray {
        return this.form.get('rules') as UntypedFormArray;
    }

    addRow(data?): void {
        this.markAsTouched();
        const control = this.formBuilder.control(data || {});

        this.rulesFormArray.push(control);
    }

    removeRow(index: number): void {
        this.markAsTouched();
        this.rulesFormArray.markAsTouched();
        this.rulesFormArray.removeAt(index);
    }

    markAsTouched(): void {
        this.onTouched();
    }

    markFieldsAsTouched(): void {
        this.deepMarkAllFieldsAsTouched(this.form);
        if (this.ruleComponents.length) {
            this.ruleComponents.forEach((component: RuleRowComponent) => component.markFieldsAsTouched());
        }
    }

    private getValidators(): any[] {
        const validators = [];

        if (this.isRequiredAtLeastOne) {
            validators.push(Validators.required);
        }

        if (this.fieldIndexOfUniqueValues !== undefined) {
            this.fieldIndexOfUniqueValues.forEach((index: number) => {
                validators.push(ValidateItemUnique(this.config[index].name, this.config[index].key));
            });
        }

        if (this.maxRows) {
            validators.push(
                ValidateArrayMaxLength(this.maxRows, `List must contain less than or equal to ${this.maxRows} rows`)
            );
        }

        return validators;
    }
}

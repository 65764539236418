import { AlertController, NavController } from '@ionic/angular';

import { EMPTY, from, Observable } from 'rxjs';

import isArray from 'lodash/isArray';

import { AuthService } from '@core/services/auth.service';
import { DataCompareHelperService } from '@core/services/data-compare-helper.service';
import { OfflineStorageService } from '@core/services/offline/offline-storage.service';
import { AppointmentType } from '@shared/enums/appointment-type';
import { OfflineTableName } from '@shared/enums/offline-table-name';
import { SaleStepType } from '@shared/enums/sale-step-type.enum';
import { Appointment, AppointmentDeeplink } from '@shared/interfaces/appointment';
import { AppointmentStep, AppointmentSteps } from '@shared/interfaces/appointment-step';

declare let moment: any;

export class StepsOffline {
    constructor(
        private alertController: AlertController,
        private authService: AuthService,
        private dataCompareHelperService: DataCompareHelperService,
        private navController: NavController,
        private offlineStorageService: OfflineStorageService
    ) {}

    completeStep(appointmentId: any, stepHash: string): Observable<any> {
        const user = this.authService.getUser();
        const promise = new Promise<void>(async (resolve, reject) => {
            const appointment = await this.offlineStorageService.findOne(
                `
                    SELECT *
                    FROM ${OfflineTableName.Appointments}
                    WHERE id = '${appointmentId}'
                      AND seller_id = '${user.id}'
                      AND office_id = '${user.office.id}'
                `,
                OfflineTableName.Appointments
            );

            if (!appointment) {
                reject('Appointment not found');
            }

            const completedAt = moment.utc().format('YYYY-MM-DD HH:mm:ss');
            const activity = {
                status: 'completed',
                hash: stepHash,
                view_history: {
                    completed_at: completedAt
                }
            };

            if (isArray(appointment.activities)) {
                const existingActivity = appointment.activities.find((item) => item.hash === stepHash);

                if (existingActivity) {
                    existingActivity.status = 'completed';
                    if (existingActivity.view_history) {
                        existingActivity.view_history.completed_at = completedAt;
                    } else {
                        existingActivity.view_history = activity.view_history;
                    }
                } else {
                    appointment.activities.push(activity);
                }
            } else {
                appointment.activities = [activity];
            }

            appointment.updated = '1';

            await this.offlineStorageService.insertOne(OfflineTableName.Appointments, appointment);

            resolve();
        });

        return from(promise);
    }

    getStepName(appointmentType: AppointmentType, stepHash: SaleStepType): string {
        const user = this.authService.getUser();
        const steps: AppointmentSteps = user.office.steps.find(
            ({ appointment_type }) => appointment_type === appointmentType
        );
        const step: AppointmentStep = steps.items.find(({ hash }) => hash === stepHash.replace('-', '_'));
        const names: string[] = [step.name];

        if (!this.dataCompareHelperService.isEmpty(step.second_name)) {
            names.push(step.second_name);
        }

        return names.join(' ');
    }

    async openStep(step, appointment: Appointment, url?: string, queryParams?: any): Promise<void> {
        if (step.disabled) {
            return;
        }

        if (appointment.deeplinks?.items?.length) {
            const deeplink: AppointmentDeeplink = this.findDeeplink(step.hash, appointment);

            if (deeplink) {
                await this.openDeeplink(deeplink, appointment);

                return;
            }
        }

        const stepHash = step.hash.replace('_', '-');

        this.navController.navigateRoot(
            url || `/main/appointments/${appointment.id}/${stepHash}`,
            queryParams ? { queryParams } : undefined
        );
    }

    async handleDeeplink(url: string, appointment: Appointment, conditionIfNotOpenDeeplink: boolean): Promise<any> {
        const stepHash: string = this.getStepHashByUrl(url);

        if (appointment.deeplinks?.items?.length) {
            const deeplink: AppointmentDeeplink = this.findDeeplink(stepHash, appointment);

            if (deeplink) {
                await this.openDeeplink(deeplink, appointment);

                this.navController.navigateRoot(`/main/appointments/${appointment.id}`);

                return EMPTY;
            }
        }

        return conditionIfNotOpenDeeplink;
    }

    private findDeeplink(stepHash: string, appointment: Appointment): AppointmentDeeplink {
        return appointment.deeplinks.items.find(({ hash }) => hash === `${stepHash}_step`);
    }

    private getStepHashByUrl(url: string): string {
        switch (true) {
            case url.includes('/needs-assessment'):
                return SaleStepType.NEEDS_ASSESSMENT.replace('-', '_');
            case url.includes('/takeoff'):
                return SaleStepType.TAKE_OFF;
            case url.includes('/demo'):
                return SaleStepType.DEMO;
        }
    }

    private async openDeeplink(deeplink: AppointmentDeeplink, appointment: Appointment): Promise<void> {
        const alert = await this.alertController.create({
            header: 'Warning',
            message: `You need to be online to redirect to ${deeplink.name}`,
            backdropDismiss: false,
            buttons: [
                {
                    text: 'OK'
                }
            ]
        });

        await alert.present();
    }
}

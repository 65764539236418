<div class="modal-header">
    <ion-text class="title">Custom promotion</ion-text>
</div>

<div class="modal-body">
    <form [formGroup]="form" autocomplete="off">
        <div class="field-block">
            <ion-label>Name</ion-label>
            <ion-input legacy="true" formControlName="name"
                       type="text"
                       [maxlength]="charLimit"
                       autocomplete="off"></ion-input>
            <ion-text class="char-limit">{{ charLimit - form.get('name').value.length }} Characters remaining</ion-text>
            <div class="errors-wrapper" *ngIf="form.get('name').touched">
                <ion-text color="danger" class="error"
                          *ngFor="let error of getMessages(form.get('name').errors)">
                    {{ error }}
                </ion-text>
            </div>
        </div>

        <div class="field-block">
            <ion-label class="required">Amount</ion-label>
            <div class="promotion-amount">
                <ion-input legacy="true" formControlName="amount"
                           type="number"
                           inputmode="numeric"
                           pattern="^[0-9]*[.]?[0-9]{0,2}$"
                           min="0"
                           step="10"
                           select-all-text
                           required
                           autocomplete="off"></ion-input>

                <ion-item class="ion-text-center" lines="none">
                    <ion-label [attr.data-active]="!form.get('isAmountPromotion').value">%</ion-label>
                    <ion-toggle mode="md" formControlName="isAmountPromotion"></ion-toggle>
                    <ion-label [attr.data-active]="form.get('isAmountPromotion').value">$</ion-label>
                </ion-item>
            </div>
            <div class="errors-wrapper" *ngIf="form.get('amount').touched">
                <ion-text color="danger" class="error"
                          *ngFor="let error of getMessages(form.get('amount').errors)">
                    {{ error }}
                </ion-text>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer">
    <ion-button class="classic-width outline"
                (click)="closeModal()">
        Cancel
    </ion-button>
    <ion-button class="classic-width"
                id="save-custom-promotion-button"
                [disabled]="!form.valid"
                (click)="confirm()">
        Apply
    </ion-button>
</div>
